import React from 'react';
import { AccordionBodyItemView2 } from 'component/Accordion';
import { DirectBeneficiariesView } from './DirectBeneficiaries';
import { HandoutsView } from './Handouts';
import { IndirectBeneficiariesView } from './IndirectBeneficiaries';

export const BeneficiariesView = (props) => {
  const { formData, handouts } = props;

  return (
    <>
      <AccordionBodyItemView2
        accordionSubId="2.11"
        titleUA="Бенефіціари"
        titleEN="Beneficiaries"
      />
      <div className="beneficiaries-view">
        <DirectBeneficiariesView formData={formData} />
        <HandoutsView
          formData={formData}
          handouts={handouts}
          type="direct_beneficiaries"
        />
        <IndirectBeneficiariesView formData={formData} />
        <HandoutsView
          formData={formData}
          handouts={handouts}
          type="indirect_beneficiaries"
        />
      </div>
    </>
  );
};
