import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { featureCollection } from '@turf/helpers';
import moment from 'moment';
import { Loader } from 'component/Loader';
import { Map } from 'component/Map';
import { axiosWrapper } from 'utils/Helper';
import { MapModal } from 'component/MapModal';
import { getDroneData } from 'api/DroneDataApi';
import { MapLayers } from './MapLayers';
import 'assets/styles/pages/global-map.scss';
import { FilterCheckboxes } from './FilterCheckboxes';
import { RightBox } from './RightBox';
import { setMapboxWMSLayers } from './Layers';

export const GlobalMapPage = () => {
  const [loader, setLoader] = useState(false);
  const [map, setMap] = useState(null);
  const [legendActive, setLegendActive] = useState(null);
  const [filterCheckbox, setFilterCheckbox] = useState('by-hazard_area_type');
  const [mapModalData, setMapModalData] = useState(null);
  const [mapPopupData, setMapPopupData] = useState(null);
  const [mapModalOpen, setMapModalOpen] = useState(false);

  const [lmGeojson, setLmGeojson] = useState(featureCollection([]));
  const [bmGeojson, setBmGeojson] = useState(featureCollection([]));
  const [turnPointsGeojson, setTurnPointsGeojson] = useState(featureCollection([]));
  const [indirectEvidenceGeojson, setIndirectEvidenceGeojson] = useState(featureCollection([]));
  const [directEvidenceGeojson, setDirectEvidenceGeojson] = useState(featureCollection([]));

  const [teamFilter, setTeamFilter] = useState(null);
  const [dateFrom, setDateFromFilter] = useState(null);
  const [dateTo, setDateToFilter] = useState(null);

  const [internalIdFilter, setInternalIdFilter] = useState(null);
  const [imsmaIdFilter, setImsmaIdFilter] = useState(null);
  const [validStatusFilter, setValidStatusFilter] = useState(null);
  const [areaTypeFilter, setAreaTypeFilter] = useState(null);
  const [hazardTypeFilter, setHazardTypeFilter] = useState(null);
  const [regionFilter, setRegionFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [localCommunityFilter, setLocalCommunityFilter] = useState(null);
  const [settlementFilter, setSettlementFilter] = useState(null);
  const [resetFilter, setResetFilter] = useState(false);
  const [droneData, setDroneData] = useState([]);
  const [contentSize, setContentSize] = useState('standart');

  const clickHandlerRef = useRef(null);
  const [mapLayersLoads, setMapLayerLoads] = useState(false);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    document.title = 'Global Map';

    const handleVisibilityChange = () => {
      if (document.hidden) {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      abortControllerRef.current.abort();
    };
  }, []);

  useEffect(() => {
    if (!map) return;

    const controller = new AbortController();
    abortControllerRef.current = controller;

    setMapboxWMSLayers(map, controller.signal)
      .then(() => {
        setMapLayerLoads(true);
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('Error loading WMS layers:', error);
        }
      });

    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort();
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (clickHandlerRef.current) {
      map.off('click', 'turnPointsPolygon-layer', clickHandlerRef.current);
      map.off('click', 'imsma-all-hz_polygons-layer', clickHandlerRef.current);
      map.off('click', 'imsma-fsd-hz_polygons-layer', clickHandlerRef.current);
      map.off('click', 'feodal-polygon-polygons_field-layer', clickHandlerRef.current);
    }

    const newClickHandler = (e, sizeContent) => {
      setMapPopupData(e.features[0].properties);
      setMapModalOpen(true);
      setContentSize(sizeContent);
    };

    map.on('click', () => {
      setMapModalOpen(false);
    });
    map.on('click', 'turnPointsPolygon-layer', (e) => newClickHandler(e, 'standart'));
    map.on('click', 'imsma-all-hz_polygons-layer', (e) => newClickHandler(e, 'full'));
    map.on('click', 'imsma-fsd-hz_polygons-layer', (e) => newClickHandler(e, 'full'));
    map.on('click', 'feodal-polygon-polygons_field-layer', (e) => newClickHandler(e, 'full'));

    clickHandlerRef.current = newClickHandler;
  }, [map, turnPointsGeojson]);

  const getUrlForGlobalMap = (legend) => {
    const checkboxFilter = filterCheckbox.split('-')[1];
    const colorParam = `color_by=${checkboxFilter}&`;
    const legendPath = legend ? 'legend/' : '';
    const teamParam = () => {
      if (teamFilter?.length === 0) return '';
      let param = '';
      teamFilter?.forEach((option) => {
        param += `&team_id=${option.value}`;
      });
      return param;
    };
    const dateFromParam = dateFrom
      ? `date_from=${moment(dateFrom, 'DD.MM.YYYY').format('DD.MM.YYYY')}&`
      : '';
    const dateToParam = dateTo
      ? `date_to=${moment(dateTo, 'DD.MM.YYYY').format('DD.MM.YYYY')}&`
      : '';
    const internalIdParam = () => {
      if (internalIdFilter?.length === 0) return '';
      let param = '';
      internalIdFilter?.forEach((option) => {
        param += `&internal_id=${option.value}`;
      });
      return param;
    };
    const imsmaIdParam = () => {
      if (imsmaIdFilter?.length === 0) return '';
      let param = '';
      imsmaIdFilter?.forEach((option) => {
        param += `&imsma_id=${option.value}`;
      });
      return param;
    };
    const validStatusParam = () => {
      if (validStatusFilter?.length === 0) return '';
      let param = '';
      validStatusFilter?.forEach((option) => {
        param += `&validation_status=${option.value}`;
      });
      return param;
    };
    const areaTypeParam = () => {
      if (areaTypeFilter?.length === 0) return '';
      let param = '';
      areaTypeFilter?.forEach((option) => {
        param += `&hazard_area_type=${option.value}`;
      });
      return param;
    };
    const hazardTypeParam = () => {
      if (hazardTypeFilter?.length === 0) return '';
      let param = '';
      hazardTypeFilter?.forEach((option) => {
        param += `&hazard_type=${option.value}`;
      });
      return param;
    };
    const regionParam = () => {
      if (!regionFilter) return '';
      return `&region=${regionFilter}`;
    };
    const districtParam = () => {
      if (!districtFilter) return '';
      return `&district=${districtFilter}`;
    };
    const localCommunityParam = () => {
      if (!localCommunityFilter) return '';
      return `&local_community=${localCommunityFilter}`;
    };
    const settlementParam = () => {
      if (!settlementFilter) return '';
      return `&settlement_name=${settlementFilter}`;
    };
    const url = `/reports/global-map/${legendPath}?${colorParam}${teamParam()}${dateFromParam}${dateToParam}${internalIdParam()}${imsmaIdParam()}${validStatusParam()}${areaTypeParam()}${hazardTypeParam()}${regionParam()}${districtParam()}${localCommunityParam()}${settlementParam()}`;
    return url;
  };

  const getGlobalMapLegend = () => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    axiosWrapper(() => {
      axios.get(getUrlForGlobalMap(true), { signal: controller.signal })
        .then((res) => {
          setMapModalData(res.data);
        })
        .catch((err) => {
          setMapModalData([]);
          if (err.name !== 'AbortError') {
            console.error('Error loading legend:', err);
            toast.error('Error! Legend is not loaded.');
          }
        });
    });
  };

  const getGlobalMapGeojson = () => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    axiosWrapper(() => {
      axios.get(getUrlForGlobalMap(false), { signal: controller.signal })
        .then((res) => {
          setLmGeojson(res.data.lm);
          setBmGeojson(res.data.bm);
          setTurnPointsGeojson(res.data.polygon);
          setIndirectEvidenceGeojson(res.data.indirect_evidences);
          setDirectEvidenceGeojson(res.data.direct_evidences);
          setLoader(false);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error('Error loading geojson:', err);
            setLmGeojson(featureCollection([]));
            setBmGeojson(featureCollection([]));
            setTurnPointsGeojson(featureCollection([]));
            setIndirectEvidenceGeojson(featureCollection([]));
            setDirectEvidenceGeojson(featureCollection([]));
            toast.error('Error loading geojson.');
            setLoader(false);
          }
        });
    });
  };

  const getDroneInfo = () => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    axiosWrapper(() => {
      getDroneData(null, null, null, true, { signal: controller.signal })
        .then((res) => {
          setDroneData(res.data);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error('Error loading drone data:', err);
            toast.error('Drone data is not loaded');
            setDroneData([]);
          }
        });
    });
  };

  useEffect(() => {
    if (resetFilter) {
      getGlobalMapGeojson();
      getGlobalMapLegend();
      setResetFilter(false);
    }
  }, [resetFilter]);

  useEffect(() => {
    setLoader(true);
    getGlobalMapGeojson();
    getGlobalMapLegend();
    getDroneInfo();
  }, [filterCheckbox]);

  return (
    <>
      <section className="no-padding">
        <Map
          map={map}
          setMap={setMap}
          height="full"
          turnPointsPolygon={turnPointsGeojson}
          evidenceGeojson={directEvidenceGeojson}
          indirectEvidenceGeojson={indirectEvidenceGeojson}
          lmGeojson={lmGeojson}
          bmGeojson={bmGeojson}
          googleWms
        />
        <MapModal
          visible={mapModalOpen}
          data={mapPopupData}
          contentSize={contentSize}
        />
        <FilterCheckboxes
          filter={filterCheckbox}
          setFilter={setFilterCheckbox}
          legendActive={legendActive}
          setLegendActive={setLegendActive}
        />
        <MapLayers
          map={map}
          setMapPopupData={setMapModalData}
          setMapModalOpen={setMapModalOpen}
          mapLayersLoads={mapLayersLoads}
        />
        <RightBox
          filter={filterCheckbox}
          legendActive={legendActive}
          setLegendActive={setLegendActive}
          legendData={mapModalData}
          teamFilter={teamFilter}
          setTeamFilter={setTeamFilter}
          dateTo={dateTo}
          dateFrom={dateFrom}
          setDateFromFilter={setDateFromFilter}
          setDateToFilter={setDateToFilter}
          internalIdFilter={internalIdFilter}
          setInternalIdFilter={setInternalIdFilter}
          imsmaIdFilter={imsmaIdFilter}
          setImsmaIdFilter={setImsmaIdFilter}
          validStatusFilter={validStatusFilter}
          setValidStatusFilter={setValidStatusFilter}
          areaTypeFilter={areaTypeFilter}
          setAreaTypeFilter={setAreaTypeFilter}
          hazardTypeFilter={hazardTypeFilter}
          setHazardTypeFilter={setHazardTypeFilter}
          regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
          districtFilter={districtFilter}
          setDistrictFilter={setDistrictFilter}
          localCommunityFilter={localCommunityFilter}
          setLocalCommunityFilter={setLocalCommunityFilter}
          settlementFilter={settlementFilter}
          setSettlementFilter={setSettlementFilter}
          updateGeojson={getGlobalMapGeojson}
          updateLegend={getGlobalMapLegend}
          resetFilter={resetFilter}
          setResetFilter={setResetFilter}
          setLoader={setLoader}
          droneData={droneData}
          map={map}
        />
      </section>
      <Loader visible={loader} />
      <ToastContainer />
    </>
  );
};
