import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';

export const HazardType = (props) => {
  if (props.formType !== 'ha') return null;

  const updateHazardType = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          hazard_type: value,
        },
        hazard_type: {
          ...prevFormData.info.hazard_type,
          anti_personnel_mines: value === 'minefield'
            ? prevFormData.info.hazard_type.anti_personnel_mines
            : false,
          anti_transport_mines: value === 'minefield'
            ? prevFormData.info.hazard_type.anti_transport_mines
            : false,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.11</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Тип небезпеки"
      titleEN="Hazard type"
      children={(
        <div className="flex-group no-wrap ">
          <RadioButton
            id="minefield"
            name="minefield"
            value="minefield"
            label="Мінне поле/Minefield"
            checked={props.formData.info.general.hazard_type.includes('minefield')}
            handleInput={updateHazardType}
            disable="false"
          />
          <RadioButton
            id="battlefield"
            name="battlefield"
            value="battlefield"
            label="Поле бою/BAC"
            checked={props.formData.info.general.hazard_type.includes('battlefield')}
            handleInput={updateHazardType}
            disable="false"
          />
        </div>
      )}
    />
  );
};
