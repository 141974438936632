import axios from 'axios';

export const getTeamAutocomplete = async () => {
  const url = '/reports/global-map/choices/team-id/';
  const response = await axios.get(url);
  return response;
};

export const getInternalIdAutocomplete = async () => {
  const url = '/reports/global-map/choices/internal-id/';
  const response = await axios.get(url);
  return response;
};

export const getImsmaIdAutocomplete = async () => {
  const url = '/reports/global-map/choices/imsma-id/';
  const response = await axios.get(url);
  return response;
};

export const getRegionAutocomplete = async () => {
  const url = '/reports/global-map/choices/region/';
  const response = await axios.get(url);
  return response;
};

export const getDistrictAutocomplete = async () => {
  const url = '/reports/global-map/choices/district/';
  const response = await axios.get(url);
  return response;
};

export const getLocalCommunityAutocomplete = async () => {
  const url = '/reports/global-map/choices/local-community/';
  const response = await axios.get(url);
  return response;
};

export const getSettlementAutocomplete = async () => {
  const url = '/reports/global-map/choices/settlement-name/';
  const response = await axios.get(url);
  return response;
};
