import React from 'react';
import { AccordionHeaderTitle } from 'component/Accordion';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  DistrictView, LocalCommunityView, LocalityView, LocationMapView, RegionView, SettlementCodeView,
} from './Location';
import { DateOfSessionView } from './DateOfSession';
import { TypeOfSessionImsmaView, TypeOfSessionInternalView } from './TypeOfSession';
import { TypeOfPlaceImsmaView, TypeOfPlaceInternalView } from './TypeOfPlace';
import { DescriptionPlaceView } from './DescriptionPlace';
import { NumberOfSessionView } from './NumberOfSession';
import { BeneficiariesView } from './Beneficiaries/Beneficiaries';
import { ContactPersonView } from './ContactPerson';

export const SessionInfo = (props) => {
  const { formData, zone, handouts } = props;

  return (
    <AccordionItem uuid="sessionInformation">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            titleUA="Інформація щодо сесії"
            titleEN="Information about the session"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <RegionView formData={formData} />
        <DistrictView formData={formData} />
        <LocalCommunityView formData={formData} />
        <LocalityView formData={formData} />
        <SettlementCodeView formData={formData} />
      </AccordionItemPanel>
      <AccordionItemPanel className="custom-accordion-item-panel w-100">
        <LocationMapView
          formData={formData}
          zone={zone}
        />
      </AccordionItemPanel>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <DateOfSessionView formData={formData} />
      </AccordionItemPanel>
      <AccordionItemPanel className="custom-accordion-item-panel rounded-bg-grey">
        <TypeOfSessionInternalView formData={formData} />
        <TypeOfSessionImsmaView formData={formData} />
        <TypeOfPlaceImsmaView formData={formData} />
        <TypeOfPlaceInternalView formData={formData} />
        <DescriptionPlaceView formData={formData} />
        <NumberOfSessionView formData={formData} />
        <BeneficiariesView
          formData={formData}
          handouts={handouts}
        />
        <ContactPersonView formData={formData} />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
