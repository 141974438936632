import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const InternalHazardIdView = (props) => {
  const { formData, accordionSubId = '1.1' } = props;
  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Внутрішній ID небезпечної території в організації"
      titleEN="Internal Hazard ID"
      children={
        formData.info?.general?.custom_internal_id
        || formData.info?.general?.internal_id
        || 'ID генерується автоматично'
      }
    />
  );
};
