import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import {
  criticalInfrastructureOptions,
  educationFacilityOptions,
  stateInstitutionOptions, typeOfPlaceImsmaOptions,
  typeOfPlaceInternalOptions,
} from '../../Create/SessionInfo/Place';

export const TypeOfPlaceInternalView = (props) => {
  const { formData } = props;

  const value = typeOfPlaceInternalOptions.find((option) => option.value === formData
    ?.info?.session_information?.place_type_internal?.value);

  return (
    <>
      <AccordionBodyItemView
        accordionSubId="2.8.1"
        titleUA="Тип проведення"
        titleEN="Type of holding (internal)"
        children={value?.label}
      />
      {formData?.info?.session_information?.place_type_internal.value === 'other' && (
        <AccordionBodyItemView
          accordionSubId=""
          titleUA="Інший варіант"
          titleEN="Other"
          children={formData?.info?.session_information?.place_type_internal.place_type_other}
        />
      )}
      {formData?.info?.session_information?.place_type_internal.value === 'state_institution' && (
        <StateInstitutionView formData={formData} />
      )}
      {formData?.info?.session_information?.place_type_internal.value === 'education_facility' && (
        <EducationFacilityView formData={formData} />
      )}
      {formData?.info?.session_information?.place_type_internal.value === 'critical_infrastructure' && (
        <CriticalInfrastructureView formData={formData} />
      )}
    </>
  );
};

export const StateInstitutionView = (props) => {
  const { formData } = props;

  const value = stateInstitutionOptions.find((option) => option.value === formData
    ?.info?.session_information?.place_type_internal.state_institution);

  return (
    <AccordionBodyItemView
      accordionSubId=""
      titleUA="Державна установа"
      titleEN="State institution"
      children={value?.label}
    />
  );
};

export const EducationFacilityView = (props) => {
  const { formData } = props;

  const value = educationFacilityOptions.find((option) => option.value === formData
    ?.info?.session_information?.place_type_internal.education_facility);

  return (
    <AccordionBodyItemView
      accordionSubId=""
      titleUA="Навчальний заклад"
      titleEN="Education facility"
      children={value?.label}
    />
  );
};

export const CriticalInfrastructureView = (props) => {
  const { formData } = props;

  const value = criticalInfrastructureOptions.find((option) => option.value === formData
    ?.info?.session_information?.place_type_internal.critical_infrastructure);

  return (
    <AccordionBodyItemView
      accordionSubId=""
      titleUA="Критична інфраструктура"
      titleEN="Critical infrastructure"
      children={value?.label}
    />
  );
};

export const TypeOfPlaceImsmaView = (props) => {
  const { formData } = props;

  const value = typeOfPlaceImsmaOptions.find((option) => option.value === formData
    ?.info?.session_information?.place_type_imsma.value);

  return (
    <>
      <AccordionBodyItemView
        accordionSubId="2.8.2"
        titleUA="Тип проведення"
        titleEN="Type of holding (internal)"
        children={value?.label}
      />
      {formData?.info?.session_information?.place_type_imsma.value === 'other' && (
        <AccordionBodyItemView
          accordionSubId=""
          titleUA="Інший варіант"
          titleEN="Other"
          children={formData?.info?.session_information?.place_type_imsma.place_type_other}
        />
      )}
    </>
  );
};
