import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import { SelectComponent } from 'component/Select';
import React from 'react';

export const typeOfSessionInternalOptions = [{
  label: 'Он-лайн / On-line',
  value: 'online',
}, {
  label: 'Офф-лайн / Off-line',
  value: 'offline',
}, {
  label: 'Інструктаж з техніки безпеки / Safety Briefing',
  value: 'safety_briefing',
}, {
  label: 'Інше / Other',
  value: 'other',
}];

export const typeOfSessionImsmaOptions = [{
  label: 'Взаємодія віч-на-віч / Face-to-face',
  value: 'face_to_face',
}, {
  label: 'Онлайн сесії / Online sessions',
  value: 'online',
}, {
  label: 'Навчання тренерів / Training of trainers',
  value: 'tot',
}, {
  label: 'Розповсюдження матеріалів / Distribution of materials',
  value: 'materials_distr',
}];

export const TypeOfSessionInternal = (props) => {
  const { formData, setFormData, errors } = props;

  const handleTypeOfSession = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          eore_type_internal: value,
          eore_type_internal_other: '',
        },
      },
    }));
  };

  const handleOtherType = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          eore_type_internal_other: value,
        },
      },
    }));
  };

  return (
    <>
      <AccordionBodyItem
        accordionSubId={(
          <div>2.7.1</div>
        )}
        titleUA="Тип проведення (internal)"
        titleEN="Type of holding (internal)"
        children={(
          <SelectComponent
            options={typeOfSessionInternalOptions}
            value={formData?.info?.session_information?.eore_type_internal
              ? {
                label: typeOfSessionInternalOptions.find((option) => option.value === formData
                  ?.info?.session_information?.eore_type_internal).label,
                value: formData?.info?.session_information?.eore_type_internal,
              }
              : typeOfSessionInternalOptions[0]}
            handleSelect={handleTypeOfSession}
          />
        )}
      />
      {formData?.info?.session_information?.eore_type_internal === 'other' && (
        <AccordionBodyItem
          titleUA="Інший варіант"
          titleEN="Other"
          className={errors?.includes('eore_type_internal_other') ? 'error' : ''}
          children={(
            <InputComponent
              id="other_type"
              placeholder="Enter other type"
              value={formData?.info?.session_information?.eore_type_internal_other || ''}
              handleInput={handleOtherType}
            />
          )}
        />
      )}
    </>
  );
};

export const TypeOfSessionImsma = (props) => {
  const { formData, setFormData, errors } = props;

  const handleTypeOfSession = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          eore_type_imsma: value,
          eore_type_imsma_other: '',
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>2.7.2</div>
      )}
      titleUA="Тип проведення (IMSMA)"
      titleEN="Type of holding (IMSMA)"
      className={errors?.includes('eore_type_imsma_other') ? 'error' : ''}
      children={(
        <SelectComponent
          options={typeOfSessionImsmaOptions}
          value={formData?.info?.session_information?.eore_type_imsma
            ? {
              label: typeOfSessionImsmaOptions.find((option) => option.value === formData
                ?.info?.session_information?.eore_type_imsma).label,
              value: formData?.info?.session_information?.eore_type_imsma,
            }
            : typeOfSessionImsmaOptions[0]}
          handleSelect={handleTypeOfSession}
        />
      )}
    />
  );
};
