import React, { useState, useCallback } from 'react';
import { ButtonComponent } from 'component/Button';
import { InputComponent } from 'component/Input';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import 'assets/styles/pages/settings.scss';
import { SelectComponent } from 'component/Select';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderUserSettings } from 'component/Accordion';
import { getSettings, updateSettings } from 'api/SettingsApi';
import ClipLoader from 'react-spinners/ClipLoader';
import { UserLabel } from 'pages/Profile/Notifications/Table';
import { toast } from 'react-toastify';
import { deleteSettingsById, getAllUsersForTokens } from 'api/UserAPI';
import { useUser } from 'routes/UserContext';

const IMSMARights = ({ users, onUpdate }) => {
  const [userSettings, setUserSettings] = useState({});
  const [expandedItem, setExpandedItem] = useState(null);
  const [loadingUser, setLoadingUser] = useState(null);
  const [savingUser, setSavingUser] = useState(null);
  const [allUsersWithoutSettings, setAllUsersWithoutSettings] = useState([]);
  const [loadingDeleteSettings, setLoadingDeleteSettings] = useState(false);
  const userContext = useUser();

  const handleAccordionToggle = useCallback(async (userId) => {
    if (userId !== expandedItem) {
      setExpandedItem(userId);
      setLoadingUser(userId);
      if (!userSettings[userId]) {
        try {
          const [responseSettings, responseUsers] = await Promise.all([
            getSettings(userId),
            getAllUsersForTokens(false),
          ]);

          setUserSettings((prevSettings) => ({
            ...prevSettings,
            [userId]: {
              ...responseSettings.data,
              selectedUser: null,
            },
          }));
          setAllUsersWithoutSettings(responseUsers.data);
        } catch (error) {
          console.log('Error fetching settings or users', error);
        } finally {
          setLoadingUser(null);
        }
      } else {
        setLoadingUser(null);
      }
    } else {
      setExpandedItem(null);
    }
  }, [expandedItem, userSettings]);

  const handleSave = async (userId) => {
    const settings = userSettings[userId];
    setSavingUser(userId);
    if (!settings.username) {
      toast.error('Username is required');
      setSavingUser(null);
      return;
    }
    if (!settings.password) {
      toast.error('Password is required');
      setSavingUser(null);
      return;
    }

    try {
      const updatedSettings = {
        id: userId,
        username: settings.username,
        password: settings.password,
        imsma_token: settings.imsma_token,
        deepl_token: settings.deepl_token,
        user: settings.selectedUser?.value,
      };

      await updateSettings(updatedSettings);
      onUpdate();
      setUserSettings((prevSettings) => ({
        ...prevSettings,
        [userId]: {
          ...prevSettings[userId],
          selectedUser: null,
        },
      }));
      toast.success('Settings updated successfully');
    } catch (error) {
      toast.error('Error saving settings');
      console.error('Error saving settings', error);
    } finally {
      setSavingUser(null);
    }
  };

  const handleDelete = async (settingsId) => {
    setLoadingDeleteSettings(true);
    try {
      await deleteSettingsById(settingsId);
      toast.success('Settings deleted successfully');
      onUpdate();
    } catch (error) {
      toast.error('Error deleting settings');
    } finally {
      setLoadingDeleteSettings(false);
    }
  };

  const convertObjectToArrayUsers = (usersWithoutSettings) => usersWithoutSettings?.map((user) => {
    const transformUserToLabel = {
      email: user.email,
      id: user.id,
      name: user.name,
      surname: user.surname,
      short_role_name: user.role.short_name,
      role_color: user.role.color,
    };

    return {
      value: user.id,
      label: <UserLabel user={transformUserToLabel} />,
    };
  });

  return (
    users.map((user) => {
      const settings = userSettings[user.settings_id] || {};
      return (
        <AccordionItem
          key={user.settings_id}
          onClick={() => handleAccordionToggle(user.settings_id)}
        >
          <AccordionItemHeading>
            <AccordionItemButton>
              <AccordionHeaderUserSettings
                title={user.role.short_name}
                userName={user.name}
                email={user.email}
                backgroundColor={user.role.color}
              />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-validation-panel">
            {loadingUser === user.settings_id ? (
              <div className="block-with-cliploader">
                <ClipLoader
                  color="rgba(0, 177, 183, 1)"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  loading
                />
              </div>
            ) : (
              <div className="imsma-rights-container">
                <div className="inputs-container">
                  <div
                    className="top-inputs"
                  >
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      id="imsma-username"
                      name="imsma-username"
                      handleInput={(value) => setUserSettings((prevSettings) => ({
                        ...prevSettings,
                        [user.settings_id]: {
                          ...prevSettings[user.settings_id],
                          username: value,
                        },
                      }))}
                      value={settings.username || ''}
                      label="IMSMA username"
                    />
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      id="imsma-password"
                      name="imsma-password"
                      handleInput={(value) => setUserSettings((prevSettings) => ({
                        ...prevSettings,
                        [user.settings_id]: {
                          ...prevSettings[user.settings_id],
                          password: value,
                        },
                      }))}
                      value={settings.password || ''}
                      label="IMSMA password"
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <InputComponent
                    classNameWrapper="w-100"
                    style={{ width: '-webkit-fill-available' }}
                    id="imsma-token"
                    name="imsma-token"
                    handleInput={(value) => setUserSettings((prevSettings) => ({
                      ...prevSettings,
                      [user.settings_id]: {
                        ...prevSettings[user.settings_id],
                        imsma_token: value,
                      },
                    }))}
                    value={settings.imsma_token || ''}
                    label="IMSMA Token"
                  />
                </div>
                <div className="imsma-rights-container-footer">
                  <div
                    style={{
                      display: 'flex', width: '100%', gap: '16px',
                    }}
                  >
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      id="deepl-token"
                      name="deepl-token"
                      handleInput={(value) => setUserSettings((prevSettings) => ({
                        ...prevSettings,
                        [user.settings_id]: {
                          ...prevSettings[user.settings_id],
                          deepl_token: value,
                        },
                      }))}
                      value={settings.deepl_token || ''}
                      label="Deepl Token"
                    />
                    <div className="select-wrapper" style={{ gap: '0' }}>
                      <span className="label">User</span>
                      <SelectComponent
                        id="users"
                        name="users"
                        label="User"
                        value={settings.selectedUser?.value
                          ? {
                            value: settings.selectedUser.value,
                            label: settings.selectedUser.label,
                          }
                          : null}
                        options={convertObjectToArrayUsers(allUsersWithoutSettings)}
                        handleSelect={(value, label) => {
                          setUserSettings((prevSettings) => ({
                            ...prevSettings,
                            [user.settings_id]: {
                              ...prevSettings[user.settings_id],
                              selectedUser: { value, label },
                            },
                          }));
                        }}
                        placeholder={(
                          settings.selectedUser ? (
                            <UserLabel user={settings.selectedUser} />
                          ) : (
                            <UserLabel user={user} />
                          )
                        )}
                      />
                    </div>
                  </div>
                  {userContext?.user?.permissions?.change_settings && (
                    <div className="buttons-group-end">
                      <ButtonComponent
                        iconLeft={loadingDeleteSettings
                          ? (
                            <ClipLoader
                              color="currentColor"
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          )
                          : <DeleteIcon />}
                        className="danger"
                        label="Delete"
                        handleButton={() => handleDelete(user.settings_id)}
                      />
                      <ButtonComponent
                        iconLeft={<SaveIcon />}
                        label="Save"
                        handleButton={() => handleSave(user.settings_id)}
                        disabled={savingUser === user.settings_id}
                      />
                    </div>
                  )}

                </div>
              </div>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      );
    })
  );
};

export default IMSMARights;
