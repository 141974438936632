import './index.scss';
import 'assets/styles/table.scss';
import { React, useEffect, useState } from 'react';
import { Loader } from 'component/Loader';
import { useUser } from 'routes/UserContext';
import { getHierarchyListById, getUserAvatarById, getUserById } from 'api/UserAPI';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { ButtonComponent } from 'component/Button';
import { initialUserInfo } from 'consts/Users';
import { Table } from 'component/Table';
import { toast } from 'react-toastify';
import { UserInfoPopup } from './Popup/UserInfo';
import { UserPopup } from './Popup/UserPopup';
import UserRow from './UserRow';

export const UsersPage = () => {
  const [loader, setLoader] = useState(true);
  const [userDataForList, setUserDataForList] = useState(null);
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [openUserPopup, setOpenUserPopup] = useState(false);
  const [mode, setMode] = useState(null);
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [afterCreate, setAfterCreate] = useState(false);

  const [directors, setDirectors] = useState([]);
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [avatarImage, setAvatarImage] = useState(null);

  const { user } = useUser();

  const resetState = () => {
    setLoader(false);
    setUserDataForList(null);
    setOpenInfoPopup(false);
    setOpenUserPopup(false);
    setMode(null);
    setUserInfo(initialUserInfo);
    setSelectedUserId(null);
    setAfterCreate(false);
    setDirectors([]);
    setRole(null);
    setPermissions({});
    setAvatarImage(null);
  };

  const getHierarchyOfUsers = (id) => {
    setLoader(true);
    getHierarchyListById(id)
      .then((res) => {
        setUserDataForList(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (user?.id) {
      getHierarchyOfUsers(user?.id);
    }
  }, [user]);

  const getAvatar = (id) => {
    getUserAvatarById(id)
      .then((response) => {
        setAvatarImage(URL.createObjectURL(response.data));
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (selectedUserId) {
      setLoader(true);
      getUserById(selectedUserId)
        .then((res) => {
          setUserInfo({
            id: res.data.id,
            team_id: res.data.team_id,
            name: res.data.name,
            surname: res.data.surname,
            middle_name: res.data.middle_name,
            function: res.data.function,
            email: res.data.email,
            password: null,
            phone_number: res.data.phone_number,
            reporter_position: res.data.reporter_position,
            permissions: Object.keys(res.data.permissions),
            role: res.data.role.id,
            directors: res.data.directors?.map((director) => director?.id),
          });
          setDirectors(res.data.directors?.map((director) => ({
            value: director?.id,
            label: director?.name,
          })));
          setRole({ value: res.data.role?.id, label: res.data.role?.name });
          setPermissions(res.data.permissions || {});
          getAvatar(res.data.id);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get user data');
          setOpenUserPopup(false);
          setLoader(false);
        });
    }
  }, [selectedUserId]);

  return (
    <section>
      <div className="users-header">
        <div className="users-title">Users</div>
      </div>
      <div className="users">
        <Table>
          {userDataForList ? (
            <tbody style={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}>
              <UserRow
                setLoader={setLoader}
                user={userDataForList}
                getHierarchyOfUsers={getHierarchyOfUsers}
                setOpenInfoPopup={setOpenInfoPopup}
                setOpenUserPopup={setOpenUserPopup}
                setMode={setMode}
                setSelectedUserId={setSelectedUserId}
                setAfterCreate={setAfterCreate}
              />
            </tbody>
          ) : (
            <tbody style={{
              display: 'flex',
              alignItems: 'center',
              color: '#868686',
              margin: '20px',
            }}
            >
              <td>Дані відсутні</td>
            </tbody>
          )}
        </Table>
        <hr className="users-line" />
        <div className="users-footer">
          {user?.permissions?.add_user && (
            <ButtonComponent
              configuration="filled"
              state="enabled"
              iconLeft={<AddSvg />}
              label="Add User"
              className="menu-btn"
              handleButton={() => {
                setUserInfo(initialUserInfo);
                setAvatarImage(null);
                setRole(null);
                setDirectors(null);
                setPermissions({});
                setOpenUserPopup(true);
                setMode('create');
                setSelectedUserId(null);
              }}
            />
          )}
        </div>
      </div>
      <UserPopup
        mode={mode}
        afterCreate={afterCreate}
        setLoader={setLoader}
        open={openUserPopup}
        setOpen={setOpenUserPopup}
        setInfoOpen={setOpenInfoPopup}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        setUserId={setSelectedUserId}
        setAfterCreate={setAfterCreate}
        directors={directors}
        setDirectors={setDirectors}
        role={role}
        setRole={setRole}
        permissions={permissions}
        setPermissions={setPermissions}
        avatarImage={avatarImage}
        setAvatarImage={setAvatarImage}
      />
      <UserInfoPopup
        afterCreate={afterCreate}
        setLoader={setLoader}
        open={openInfoPopup}
        setOpen={setOpenInfoPopup}
        setAfterCreate={setAfterCreate}
        setOpenUserPopup={setOpenUserPopup}
        setMode={setMode}
        userInfo={userInfo}
        avatarImage={avatarImage}
        role={role}
        resetState={resetState}
        getHierarchyOfUsers={getHierarchyOfUsers}

      />
      <Loader visible={loader} />
    </section>
  );
};
