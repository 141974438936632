import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const UnexploadedOrdnanceView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="3.3"
      titleUA="ВНП, що не вибухнули (II категорія)"
      titleEN="Unexploded Ordnance"
      children={
        formData.info.hazard_type.unexploded_ordnance && (
          <CheckBoxIcon />
        )
      }
    />
  );
};
