/* eslint-disable camelcase */
import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';
import { directEvidencesTypes } from 'consts/Evidences';
import { Table, TableHeader, TableRow } from 'component/Table';
import { Map } from 'component/Map';

export const DirectEvidencesView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const { direct_evidences } = formData.info.evidences;

  const mineAccidentsOptions = {
    mine_erw_accidents:
      'Нещасні випадки з мінами/ВЗВ \\ Mine/ERW related accident',
    explosions: 'Вибухи побачені/почуті \\ Explosions',
    mines_direct_report:
      'Прямий звіт про мінування \\ Direct report about mines',
    visible_mine_erw: 'Видимі міни/ВЗВ \\ Visible Mine/ERW',
    debris: 'Уламки/фрагменти ВЗВ \\ Debris',
    other: 'Інше \\ Other',
  };

  const selectedMineAccidents = Object.keys(mineAccidentsOptions)
    .filter((option) => direct_evidences.evidence_types.includes(option))
    .map((option) => mineAccidentsOptions[option]);

  return (
    <>
      <AccordionBodyItem
        accordionSubId="4.1"
        titleUA="Прямі докази"
        titleEN="Direct evidence"
      >
        <div>
          {selectedMineAccidents.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon style={{ width: '20px', height: '20px' }} />
              {item}
            </div>
          ))}
        </div>
      </AccordionBodyItem>
      <AccordionBodyItem
        bgStyle="bgGrey no-border-radius-bottom"
        accordionSubId="4.2"
        titleUA="Координати прямих доказів"
        titleEN="Direct evidence coordinates"
      />
      <div className="accordion-body-wrapper bgGrey">
        <Table className="table-lines-between">
          <TableHeader>
            <th className="no-border-radius">
              <div>Тип доказу</div>
              <div>Type of evidence</div>
            </th>
            <th aria-label="line">
              <div className="line" />
            </th>
            <th>Long/UTM X</th>
            <th aria-label="line">
              <div className="line" />
            </th>
            <th>Lat/UTM Y</th>
            <th aria-label="line">
              <div className="line" />
            </th>
            <th className="no-border-radius">Опис\ Description</th>
          </TableHeader>
          <tbody>
            {!direct_evidences?.items?.length && (
              <TableRow>
                <td>Empty</td>
              </TableRow>
            )}
            {direct_evidences
              && direct_evidences?.items?.map((evidence, index) => (
                <TableRow key={index}>
                  <td>
                    {directEvidencesTypes[evidence.evidence_type]
                      || 'Невідомо / Unknown'}
                  </td>
                  <td aria-label="line">
                    <div className="line" />
                  </td>
                  <td>{evidence?.long?.toFixed(0)}</td>
                  <td aria-label="line">
                    <div className="line" />
                  </td>
                  <td>{evidence?.lat?.toFixed(0)}</td>
                  <td aria-label="line">
                    <div className="line" />
                  </td>
                  <td className="center">
                    {translateState && (translatedFormData[`direct_evidence_description_${index}`] || 'No data')}
                    {!translateState && (evidence.description || 'Немає даних')}
                  </td>
                </TableRow>
              ))}
          </tbody>
        </Table>
      </div>
      <Map
        id="direct-map"
        map={props.map}
        setMap={props.setMap}
        turnPointsPolygon={props.formData.info.turn_points.geojson}
        evidenceGeojson={props.formData.info.evidences.evidence_geojson}
        hazardAreaType={props.formData.info.general.hazard_area_type}
        evidences={props.formData.info.evidences}
        googleWms
      />

      <div className="flex-column-group">
        <AccordionBodyItem
          accordionSubId="4.3"
          titleUA="Коротке пояснення прямих доказів за потреби"
          titleEN="A brief explanation of direct evidence as needed"
        />
        <AccordionItemPanel>
          <div
            className="informator-block-comment"
            style={{ marginLeft: '40px', marginRight: '40px' }}
          >
            {translateState && translatedFormData.direct_evidences_main_description}
            {!translateState && formData.info.evidences.direct_evidences.description}
          </div>
        </AccordionItemPanel>
      </div>
    </>
  );
};
