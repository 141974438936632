import React, { useEffect, useState } from 'react';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { InputComponent } from 'component/Input';
import { ReactComponent as LockSvg } from 'assets/images/icons/password.svg';
import { ReactComponent as MailSvg } from 'assets/images/icons/mail_outline.svg';
import { ReactComponent as EyeSvg } from 'assets/images/icons/Eye-off.svg';
import { LogoFSD } from 'component/Logo';
import { ButtonComponent } from 'component/Button';
import { useAuth } from 'routes/AuthContext';
import { Loader } from 'component/Loader';

export const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loader, setLoader] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const { loginUser } = useAuth();

  useEffect(() => {
    document.title = 'Login';
  }, []);

  const handleLoginForm = (e) => {
    e.preventDefault();
    setLoader(true);
    loginUser({ email, password })
      .then((res) => {
        if (res.status === 200) {
          navigate('/dashboard/');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setLoginError(err.response.data.detail);
        }
        setLoader(false);
      });
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email) && email) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    validateEmail();
  }, [email]);

  return (
    <main className="auth-main">
      <div className="auth-filter">
        <div className="auth-wrapper">
          <form className="login-form">
            <LogoFSD type="dark" text="on" />
            <LoginError message={loginError} />
            <InputComponent
              name="email"
              id="email"
              label="Email Address"
              placeholder="test@gmail.com"
              type="text"
              value={email}
              handleInput={setEmail}
              iconLeft={<MailSvg />}
              description={emailError}
              hasError={emailError}
            />
            <InputComponent
              name="password"
              id="password"
              label="Password"
              // blueLink='Forgot Password?'
              iconLeft={<LockSvg />}
              iconRight={<EyeSvg />}
              placeholder="password"
              type="password"
              value={password}
              handleInput={setPassword}
            />
            <ButtonComponent
              configuration="filled"
              state="enabled"
              label="Login"
              handleButton={handleLoginForm}
              disabled={emailError}
            />
          </form>
          <div className="big-text">
            For 25 years, FSD has been conducting humanitarian mine action
            operations around the world.
          </div>
        </div>
      </div>
      <Loader visible={loader} />
    </main>
  );
};

const LoginError = (props) => {
  if (!props.message) return false;
  return <span className="error-text">{props.message}</span>;
};
