import { Table, TableRow } from 'component/Table';
import React, { useEffect, useState } from 'react';
import 'assets/styles/pages/roles.scss';
import { DeleteRoleDialog } from 'component/Dialog';
import { axiosWrapper, convertColorToRgba } from 'utils/Helper';
import { getRoles, deleteRole } from 'api/UserAPI';
import { toast } from 'react-toastify';
import { useUser } from 'routes/UserContext';
import { Loader } from 'component/Loader';
import RolePopup from './RolePopup/RolePopup';

export const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [loader, setLoader] = useState(true);
  const { user } = useUser();

  const getRolesList = () => {
    axiosWrapper(() => {
      setLoader(true);
      getRoles()
        .then((res) => {
          setRoles(res.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Roles are not loaded');
          setRoles([]);
        }).finally(() => {
          setLoader(false);
        });
    });
  };

  const deleteUserRole = (id) => {
    axiosWrapper(() => {
      setLoader(true);
      deleteRole(id)
        .then(() => {
          setLoader(false);
          toast.success('Role is successfully deleted');
          getRolesList();
        })
        .catch((err) => {
          console.log(err);
          toast.error('Role is not deleted');
        }).finally(() => {
          setLoader(false);
        });
    });
  };

  useEffect(() => {
    getRolesList();
  }, []);

  return (
    <div className="roles-container">
      <div className="roles">
        <Table>
          <tbody>
            {roles.map((item) => (
              <TableRow className="role" key={item?.id}>
                <td>
                  <div className="role-icon" style={{ backgroundColor: convertColorToRgba(item?.color, 0.7) }}>
                    {item?.short_name}
                  </div>
                </td>
                <td aria-label="line">
                  <hr />
                </td>
                {user?.permissions?.change_settings && (
                  <td aria-label="icons">
                    <div className="icons">
                      <RolePopup mode="edit" role={item} getRolesList={getRolesList} />
                      <DeleteRoleDialog deleteUserRole={deleteUserRole} role={item} />
                    </div>
                  </td>
                )}

              </TableRow>
            ))}
          </tbody>
        </Table>
        <Loader visible={loader} />
        <RolePopup
          mode="create"
          getRolesList={getRolesList}
        />
      </div>
    </div>
  );
};
