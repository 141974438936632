import { React, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as NotificationIcon } from 'assets/images/icons/alert.svg';
import 'assets/styles/pages/notifications.scss';
import { deleteAllNotification, deleteNotification, getNotifications } from 'api/NotificationsApi';
import { Loader } from 'component/Loader';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { NotificationsTable } from './Table';

export const Notifications = () => {
  const [notifications, setNotifications] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUserNotifications = async () => {
    getNotifications()
      .then((response) => {
        setNotifications(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setNotifications([]);
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  const handleDeleteNotification = (id) => {
    setLoading(true);
    deleteNotification(id)
      .then(() => {
        getUserNotifications();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed to delete notification');
        setLoading(false);
      });
  };

  const handleDeleteAllNotification = () => {
    setLoading(true);
    deleteAllNotification()
      .then(() => {
        getUserNotifications();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed to delete all notifications');
        setLoading(false);
      });
  };

  return (
    <>
      <div className="notifications-icon">
        <NotificationIcon onClick={() => setOpen(!open)} />
        <span className={notifications ? '' : 'loading'}>
          {notifications !== null
            ? notifications.length
            : (
              <Oval
                height={5}
                width={5}
                color="#FFFFFF"
              />
            )}
        </span>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <div className="confirm-popup notification-popup">
          <div className="header">
            <div className="flex-group">
              <span className="title">Notifications</span>
              <DeleteIcon
                color="#D26E6E"
                title="Delete all"
                width="27"
                height="29"
                className="hover-pointer"
                onClick={() => handleDeleteAllNotification()}
              />
            </div>

            <span className="closeBtn" onClick={() => setOpen(false)} />
          </div>
          <div className="flex-column-group">
            <NotificationsTable
              notifications={notifications}
              handleDeleteNotification={handleDeleteNotification}
              setOpen={setOpen}
            />
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={() => setOpen(false)}
              label="Close"
            />
          </div>
        </div>
        <Loader visible={loading} />
      </Dialog>
    </>
  );
};
