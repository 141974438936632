import axios from 'axios';

export const setNewTemplate = async (body) => {
  const url = '/templates/';
  const response = await axios.post(url, body);
  return response;
};

export const getNtsTemplatesSchema = async () => {
  const url = '/templates/nts/schema/';
  const response = await axios.get(url);
  return response;
};

export const getHaTemplatesSchema = async () => {
  const url = '/templates/ha/schema/';
  const response = await axios.get(url);
  return response;
};

export const getTemplatesList = async (page) => {
  const pageAttr = page ? `?page=${page}` : '';
  const url = `/templates/${pageAttr}`;
  const response = await axios.get(url);
  return response;
};

export const getTemplatesFiltered = async (page) => {
  const pageAttr = page ? `?page=${page}` : '';
  const url = `/templates/filtered/${pageAttr}`;
  const response = await axios.get(url);
  return response;
};

export const setNewTemplateFiltered = async (body) => {
  const url = '/templates/filtered/';
  const response = await axios.post(url, body);
  return response;
};

export const deleteTemplate = async (id) => {
  const url = `/templates/${id}`;
  const response = await axios.delete(url);
  return response;
};

export const deleteReport = async (id) => {
  const url = `/templates/filtered/${id}`;
  const response = await axios.delete(url);
  return response;
};
