import React from 'react';
import { CheckboxInput } from 'component/Checkbox';

export const NTSAttributesBlock = (props) => (
  props.NTSAttributes?.map((attribute, index) => (
    <div className="flex-column-group" key={index}>
      <CheckboxInput
        id={`checkbox-${attribute.name}`}
        name={attribute.name}
        label={(
          <div className="double-text">
            <span>{attribute.ua_name || '-'}</span>
            <span>{attribute.eng_name || '-'}</span>
          </div>
        )}
        value={props.selectedNtsBlocks[attribute.name]}
        checked={
          props.selectedNtsBlocks.hasOwnProperty(attribute.name)
            ? props.findKey(props.selectedNtsBlocks, attribute.name, '')
            : props.findKey(props.selectedNtsAttr, attribute.name, props.selectedNtsBlocks)
        }
        disabled={props.viewMode}
        handleInput={(value, checked) => {
          props.handleTemplateBlockCheckbox(attribute.name, value, checked);
          props.handleTemplateCheckbox(
            props.selectedNtsAttr,
            props.selectedNtsBlocks,
            attribute.name,
            checked,
          );
        }}
      />
      {props.selectedNtsBlocks[attribute.name] && (
        <div className="checkbox-children">
          {attribute.children?.map((сhildAttr) => (
            <div className="flex-column-group">
              <CheckboxInput
                id={`checkbox-${сhildAttr.name}`}
                name={сhildAttr.name}
                label={(
                  <div className="double-text">
                    <span>{сhildAttr.ua_name || '-'}</span>
                    <span>{сhildAttr.eng_name || '-'}</span>
                  </div>
                )}
                value={props.findKey(
                  props.selectedNtsAttr[attribute.name],
                  сhildAttr.name,
                  props.selectedNtsBlocks,
                )}
                checked={props.findKey(
                  props.selectedNtsAttr[attribute.name],
                  сhildAttr.name,
                  props.selectedNtsBlocks,
                )}
                disabled={props.viewMode}
                handleInput={(value, isChecked) => {
                  props.handleTemplateCheckbox(props.selectedNtsAttr, props.selectedNtsBlocks, `${attribute.name}__${сhildAttr.name}`, isChecked);
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  ))
);
