import { AccordionBodyItem } from 'component/Accordion';
import React, { useEffect } from 'react';
import { useUser } from 'routes/UserContext';

export const TeamId = (props) => {
  const {
    formData,
    setFormData,
    errors,
  } = props;

  const { user } = useUser();

  useEffect(() => {
    if (user?.team_id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          general: {
            ...prevFormData.info.general,
            team_id: user.team_id,
          },
        },
      }));
    }
  }, [user]);

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.7</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="ID команди"
      titleEN="Team ID"
      className={errors?.general?.team_id ? 'error' : ''}
      children={(
        formData?.info?.general?.team_id
      )}
    />
  );
};
