import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const ReporterPosition = (props) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>{props.formType === 'ha' ? '1.8' : '1.5'}</span>
        <span className="star">*</span>
      </div>
    )}
    titleUA="Посада особи, що звітує"
    titleEN="Reporter position"
    children={(
      <span className="no-wrap">
        {props.formData.info.general.reporter_occupation}
      </span>
    )}
  />
);
