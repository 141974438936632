import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { Vegetation } from './GeographicDescription/Vegetation';
import { GroundProfile } from './GeographicDescription/GroundProfile';
import { SlopeGradient } from './GeographicDescription/SlopeGradient';
import { SeasonalAccessibility } from './GeographicDescription/SeasonalAccessibility';
import { FieldMarking } from './GeographicDescription/FieldMarking';

export const GeographicDescriptionInfo = (props) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          accordionId="6"
          validationForm={props.validationForm}
          block="geographic_and_terrain_description"
          titleUA={(
            <div className="flex-group">
              <span>Географічний опис підтвердженої/підозрюваної території</span>
              <span className="star">*</span>
            </div>
          )}
          titleEN="CHA/SHA geographic & terrain description"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <Vegetation
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
      <GroundProfile
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
      <SlopeGradient
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
      <SeasonalAccessibility
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
      <FieldMarking
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
    </AccordionItemPanel>
  </>
);
