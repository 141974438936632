import moment from 'moment';
import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { axiosWrapper } from 'utils/Helper';
import { ReactComponent as FileSvg } from 'assets/images/icons/Files.svg';
import { Table, TableHeader, TableRow } from 'component/Table';
import { DownloadExport } from './DownloadExport';
import { DeleteReport } from './DeleteReport';
import { NewExport } from './NewExport';
import { useUser } from 'routes/UserContext';

const CopyExportUrl = (props) => {
  const [publicUrl, setPublicUrl] = useState(null);

  const copyTextToClipboard = (text) => {
    if (!text) return false;
    if ('clipboard' in navigator) {
      toast.success('Copied.');
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  const getPublicUrl = (id) => {
    axiosWrapper(() => {
      axios.get(`/templates/filtered/${id}/export/xlsx/public/url/`)
        .then((res) => {
          setPublicUrl(res.data);
        })
        .catch((err) => {
          console.log(err);
          setPublicUrl(null);
        });
    });
  };

  useEffect(() => {
    getPublicUrl(props.id);
  }, []);

  return publicUrl && (
    <Tooltip title={publicUrl}>
      <FileSvg
        className="copy-icon"
        onClick={() => copyTextToClipboard(publicUrl)}
      />
    </Tooltip>
  );
};

export const Reports = (props) => {
  const { user } = useUser();
  return (
    <div className="export-table flex-column-group">
      <Table>
        <TableHeader>
          <th>Date</th>
          <th>Export report name</th>
          <th>Template name</th>
          <th>Attribute</th>
          <th>Action</th>
        </TableHeader>
        {!props.reports.length
          && (
            <TableRow>
              <td>No data</td>
            </TableRow>
          )}
        {props.reports.map((report, index) => (
          <TableRow key={index}>
            <td>{moment(report.created_at).format('L')}</td>
            <td>{report.name || '-'}</td>
            <td>{report.export_template?.name || '-'}</td>
            <td>{report.attributes_count || '-'}</td>
            <td
              className="icons"
              aria-label="icons"
            >
              <CopyExportUrl id={report.id} />
              <DownloadExport id={report.id} />
              {user?.permissions?.delete_templates && (
                <DeleteReport
                  id={report.id}
                  getReports={props.getReports}
                  setLoader={props.setLoader}
                />
              )}
            </td>
          </TableRow>
        ))}
        {props.next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                props.setLoader(true);
                props.getReports(props.page);
              }}
            >
              Load more...
            </button>
          </div>
        )}
      </Table>
      <div className="donors-footer">
        <NewExport
          setLoader={props.setLoader}
          getReports={props.getReports}
        />
      </div>
    </div>
  );
};