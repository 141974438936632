import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';
import { InputComponent } from 'component/Input';
import { React, useEffect, useState } from 'react';

export const GroundProfile = (props) => {
  const geographicDescription = props.formData.info.geographic_and_terrain_description;
  const [otherCheckbox, setOtherCheckbox] = useState(false);

  useEffect(() => {
    setOtherCheckbox(geographicDescription.relief.includes('other'));
  }, [geographicDescription]);

  const handleGeographicDescription = (value, isChecked) => {
    const relief = [...geographicDescription.relief];

    if (isChecked) {
      relief.push(value);
    } else {
      const index = relief.indexOf(value);
      if (index !== -1) {
        relief.splice(index, 1);
      }
    }

    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          relief,
        },
      },
    }));
  };

  const handleOtherDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          relief_other: value,
        },
      },
    }));
  };

  return (
    <>
      {props.errors?.geographic_and_terrain_description?.relief
        && <span className="error-text">Потрібно обрати хоча би один з варіантів.</span>}
      <AccordionBodyItem
        accordionSubId={(
          <div>
            <span>6.2</span>
            <span className="star">*</span>
          </div>
        )}
        titleUA="Рел’єф"
        titleEN="Ground profile"
        className={props.errors?.geographic_and_terrain_description?.relief ? 'error' : ''}
        rightContentClassName="w-60"
        children={(
          <>
            <div className="flex-group flex-wrap">
              <CheckboxInput
                id="gully"
                name="relief"
                value="gully"
                label="Яр \ Gully"
                checked={geographicDescription.relief.includes('gully')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="hillside"
                name="relief"
                value="hillside"
                label="Схил пагорба \ Hillside"
                checked={geographicDescription.relief.includes('hillside')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="relief_unknown"
                name="relief"
                value="unknown"
                label="Невідомо \ Unknown"
                checked={geographicDescription.relief.includes('unknown')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="ridge"
                name="relief"
                value="ridge"
                label="Гребінь \ Ridge"
                checked={geographicDescription.relief.includes('ridge')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="flat"
                name="relief"
                value="flat"
                label="Низина \ Flat"
                checked={geographicDescription.relief.includes('flat')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="relief_other"
                name="relief"
                value="other"
                label="Інше \ Other"
                checked={geographicDescription.relief.includes('other')}
                handleInput={(value, checked) => {
                  setOtherCheckbox(checked);
                  handleGeographicDescription(value, checked);
                }}
                disable="false"
              />
            </div>
            {otherCheckbox && (
              <InputComponent
                id="relief_other"
                style={{ width: 'calc(100% - 40px)' }}
                handleInput={handleOtherDescription}
                value={geographicDescription.relief_other || ''}
                blueLink
                limitChar
              />
            )}
          </>
        )}
      />
    </>

  );
};
