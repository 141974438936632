import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { axiosWrapper } from 'utils/Helper';
import { URLS } from 'routes/Urls';
import { UploadButton } from './UploadButton';

export const FileUploadMultiple = (props) => {
  const {
    id,
    type,
    callback,
    postFiles,
    fileName,
    types,
    setFormData,
  } = props;

  const [fileList, setFileList] = useState();
  const [fileStatus, setFileStatus] = useState(true);
  const [loading, setFileLoading] = useState(true);

  const files = fileList ? [...fileList] : [];

  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  const handleUploadClick = () => {
    if (!fileList) {
      return;
    }
    setFileLoading(true);

    const data = new FormData();
    files.forEach((file) => {
      data.append(`${type}`, file, file.name);
    });

    if (type === 'turn_points' && props.files?.lm_bm) {
      data.append('lm_bm', props.files.lm_bm, props.files.lm_bm.name);
    }
    if (type === 'lm_bm' && props.files?.turn_points) {
      data.append('turn_points', props.files.turn_points, props.files.turn_points.name);
    }

    axiosWrapper(() => {
      axios.post(`${URLS.POST_ARCHIVES}`, data)
        .then((res) => {
          if (res.status === 200) {
            setFileStatus(true);
            setFileLoading(false);
            if (callback) {
              setFormData((prevFormData) => ({
                ...prevFormData,
                file_zone: res.data?.file_zone || '',
              }));
              callback(res.data, [...data], res.data.file_zone);
            }
          } else {
            setFileStatus(false);
            setFileLoading(false);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Error');
          }
          console.error(err);
          setFileStatus(false);
          setFileLoading(false);
        });
    });
  };

  useEffect(() => {
    if (!postFiles) return;
    handleUploadClick();
  }, [fileList]);

  return (
    <div id={id}>
      <ul className="files-wrapper">
        {!files.length && !fileName
          && <span className="text no-files">Information about attached files</span>}
        {!files.length && fileName
          && (
            <li
              style={{ color: '#00B1B7' }}
            >
              <StatusArrows color="#00B1B7" />
              <span className="cut-text">{fileName}</span>
              {/* <RemoveFileIcon removeFile={callback} /> */}
            </li>
          )}
        {files.map((file, i) => (
          <li
            key={i}
            style={{ color: fileStatus ? '#00B1B7' : '#D26E6E' }}
          >
            {fileStatus && !loading && <StatusArrows color="#00B1B7" />}
            {!fileStatus && !loading && <StatusArrows color="#D26E6E" />}
            {loading
              && <StatusLoading />}
            <span className="cut-text">{file.name}</span>
            {/* <RemoveFileIcon removeFile={callback} /> */}
          </li>
        ))}
      </ul>
      <div className="text-center">
        <UploadButton
          id={type}
          handleUploadButton={handleFileChange}
          accept={types}
        />
      </div>
    </div>
  );
};

export const StatusArrows = (props) => {
  const { color } = props;
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 6L11.5 18L7 13.5M7 18L2.5 13.5M17.5 6L11.125 13.3125"
        stroke={color || '#00B1B7'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StatusLoading = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M12.5 6V3M16.75 7.75L18.9 5.6M18.5 12H21.5M16.75 16.25L18.9 18.4M12.5 18V21M8.25 16.25L6.1 18.4M6.5 12H5H3.5M8.25 7.75L6.1 5.6"
      stroke="#F0F0F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 6V3M16.75 7.75L18.9 5.6M18.5 12H21.5M16.75 16.25L18.9 18.4"
      stroke="#00B1B7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
