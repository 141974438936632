import axios from 'axios';

export const getDonors = async (page, entire) => {
  const pageParam = page ? `?page=${page}` : '';
  const entireParam = entire ? `?entire=${true}` : '';
  const url = `/donors/${pageParam}${entireParam}`;
  const response = await axios.get(url);
  return response;
};

export const setNewDonor = async (body) => {
  const url = '/donors/';
  const response = await axios.post(url, body);
  return response;
};

export const getDonorsNames = async () => {
  const url = '/donors/restricted/';
  const response = await axios.get(url);
  return response;
};

export const getDonorById = async (id) => {
  const url = `/donors/${id}/`;
  const response = await axios.get(url);
  return response;
};
