import React from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/User.svg';

export const UserName = (props) => {
  const handleName = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      name: value,
    }));
  };

  return (
    <InputComponent
      type="text"
      upperRightText="Name"
      id="user-name"
      name="user-name"
      placeholder="John"
      value={props.name}
      handleInput={handleName}
      iconLeft={<PeopleSvg />}
      autoComplete="off"
    />
  );
};

export const NameView = (props) => (
  <div className="double-text">
    <span>Name</span>
    <span>{props.name || '-'}</span>
  </div>
);
