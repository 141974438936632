import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';

export const PhoneNumber = (props) => (
  <AccordionBodyItem
    accordionSubId={`9.${props.id}`}
    titleUA="Телефон"
    titleEN="Phone"
    children={(
      <InputComponent
        value={props.formData.info.informators[props.index].phone}
        handleInput={(value) => props.handleInformator('phone', value)}
      />
    )}
  />
);
