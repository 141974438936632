import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';

export const ReportingOrganization = (props) => {
  const { general } = props.formData.info;

  const options = [
    { value: 'FSD', label: 'FSD' },
  ];

  const handleInput = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          reporter_organization: value,
        },
      },
    }));
  };
  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{props.formType === 'ha' ? '1.5' : '1.3'}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Організація, що звітує"
      titleEN="Reporting organization"
      children={(
        <SelectComponent
          options={options}
          value={general.reporter_organization
            ? {
              label: general.reporter_organization,
              value: general.reporter_organization,
            }
            : options[0]}
          handleSelect={handleInput}
        />
      )}
    />
  );
};
