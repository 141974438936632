import React from 'react';
import { validationStatus } from 'consts/ValidationStatus';
import { FilterMap } from './FilterMap';
import { DroneLegend } from './DroneLegend';

export const RightBox = (props) => {
  const getRightBoxTitle = () => {
    switch (props.legendActive) {
      case 'drone':
        return 'Drone data';
      case 'filter':
        return 'Filter';
      case 'legend':
        if (props.filter) {
          return `Legend (${props.filter.split('-').join(' ').replaceAll('_', ' ')})`;
        }
        return 'Legend';

      default:
        return 'Legend';
    }
  };

  return props.legendActive && (
    <div className="right-box">
      <div className="header">
        <span className="title">{getRightBoxTitle()}</span>
        <span
          className="closeBtn"
          onClick={() => props.setLegendActive(null)}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#D26E6E" />
          </svg>
        </span>
      </div>
      <div className="body">
        {props.legendActive === 'legend'
          && props.filter === 'by-validation_status'
          && Object.keys(props.legendData)?.map((key) => (
            <div key={key} className="flex-group">
              <span
                style={{ background: props.legendData[key] }}
                className="color-item"
              />
              <span style={{ width: 'fit-content' }}>
                {key}
              </span>
            </div>
          ))}
        {props.legendActive === 'legend'
          && props.filter !== 'by-validation_status'
          && props.legendData.length > 0
          && props.legendData?.map((item, index) => (
            <div key={index} className="flex-group">
              <span
                style={{ background: item.color }}
                className="color-item"
              />
              <span style={{ width: 'fit-content' }}>
                {props.filter === 'by-validation_status'
                  ? validationStatus[item.value]
                  : item.value || 'Unknown'}
              </span>
            </div>
          ))}
        {props.legendActive === 'filter'
          && (
            <FilterMap
              teamFilter={props.teamFilter}
              setTeamFilter={props.setTeamFilter}
              dateTo={props.dateTo}
              dateFrom={props.dateFrom}
              setDateFromFilter={props.setDateFromFilter}
              setDateToFilter={props.setDateToFilter}
              internalIdFilter={props.internalIdFilter}
              setInternalIdFilter={props.setInternalIdFilter}
              imsmaIdFilter={props.imsmaIdFilter}
              setImsmaIdFilter={props.setImsmaIdFilter}
              validStatusFilter={props.validStatusFilter}
              setValidStatusFilter={props.setValidStatusFilter}
              areaTypeFilter={props.areaTypeFilter}
              setAreaTypeFilter={props.setAreaTypeFilter}
              hazardTypeFilter={props.hazardTypeFilter}
              setHazardTypeFilter={props.setHazardTypeFilter}
              regionFilter={props.regionFilter}
              setRegionFilter={props.setRegionFilter}
              districtFilter={props.districtFilter}
              setDistrictFilter={props.setDistrictFilter}
              localCommunityFilter={props.localCommunityFilter}
              setLocalCommunityFilter={props.setLocalCommunityFilter}
              settlementFilter={props.settlementFilter}
              setSettlementFilter={props.setSettlementFilter}
              updateGeojson={props.updateGeojson}
              updateLegend={props.updateLegend}
              resetFilter={props.resetFilter}
              setResetFilter={props.setResetFilter}
              setLoader={props.setLoader}
            />
          )}
        {props.legendActive === 'drone'
          && (
            <DroneLegend
              droneData={props.droneData}
              map={props.map}
            />
          )}
      </div>
    </div>
  );
};
