import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const UnexplodedOrdnance = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.3"
      titleUA="ВНП, що не вибухнули (II категорія)"
      titleEN="Unexploded Ordnance"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="unexplodedOrdnance"
          name="unexploded_ordnance"
          value="unexploded_ordnance"
          checked={hazardType.unexploded_ordnance}
          handleInput={props.handleContamination}
          disable="false"
        />
      )}
    />
  );
};
