import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const SettlementId = (props) => (
  <AccordionBodyItem
    accordionSubId="2.5"
    titleUA="Код нас. пункту (згідно кодифікатору)"
    titleEN="Settlement ID (new kodyficator)"
    children={props.settlementId}
  />
);
