import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const SlopeGradientView = (props) => {
  const { formData } = props;
  const geographic = formData.info.geographic_and_terrain_description;

  const slopeGradientOptions = {
    low_0_10: 'Низький (0-10%) \\  Low (0-10%)',
    medium_10_30: 'Середній (10-30%) \\ Medium (10-30%)',
    high_30: 'Високий (30+%) \\ High (30+%)',
  };

  const selectedSlopeGradientWithBackslashes = Object.keys(slopeGradientOptions)
    .filter((option) => geographic.slope_gradient.includes(option))
    .map((option) => slopeGradientOptions[option]);

  return (
    <AccordionBodyItem
      accordionSubId="6.3"
      titleUA="Градус нахилу"
      titleEN="Slope gradient"
      rightContentClassName="w-60"
      children={(
        <div className="flex-group flex-wrap">
          {selectedSlopeGradientWithBackslashes.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon
                style={{ width: '20px', height: '20px' }}
              />
              {item}
            </div>
          ))}
        </div>
      )}
    />
  );
};
