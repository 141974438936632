import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ReactComponent as ExportIcon } from 'assets/images/icons/export-icon.svg';

export const DownloadDocxBtn = (props) => {
  const exportDocx = async (id) => {
    props.setIsLoading(true);
    try {
      const res = await axios.get(`/reports/ha/${id}/export/docx`, {
        method: 'GET',
        responseType: 'blob',
      });
      const fileName = res.headers['content-type'].split(';')[1];
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      props.setIsLoading(false);
      toast.error('Export error!');
      console.error(err);
    } finally {
      props.setIsLoading(false);
      props.setIsFileUploading(false);
      props.setFileUploadingId(null);
    }
  };

  const handleDownloadDocxBtn = (itemId) => {
    if (!props.isFileUploading) {
      props.setIsFileUploading(true);
      props.setFileUploadingId(itemId);
      exportDocx(itemId);
    }
  };
  return (
    props.isFileUploading && props.fileUploadingId === props.reportId
      ? (
        <ClipLoader
          color="rgba(0, 177, 183, 1)"
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )
      : (
        <>
          {!props.isLoading && (
            <ExportIcon
              className="export-icon"
              onClick={() => handleDownloadDocxBtn(props.reportId)}
            />
          )}
          {props.isLoading || props.isFileUploading ? (
            <ExportIcon className="export-icon-disabled" />
          ) : null}
        </>
      )
  );
};
