/* eslint-disable react/no-unstable-nested-components */
import { Loader } from 'component/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { React, useEffect, useState } from 'react';
import ArrowIcon from 'assets/images/icons/arrow_left.svg';
import { getNTSById } from 'api/NtsApi';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'assets/styles/tabs.scss';
import { NTSFormData } from 'consts/Forms';
import { NTSForm } from '../Create/NTS';
import CheckNts from '../tabs/CheckNts';

export const EditNTSReportPage = () => {
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState(NTSFormData);
  const navigate = useNavigate();
  const { reportId } = useParams();
  const [pointExist, setPointExist] = useState(false);
  const [coords3857, setCoords3857] = useState([null, null]);
  const [zone, setZone] = useState(null);

  const PageTitle = (props) => (
    <div className="page-title">
      <img
        src={ArrowIcon}
        alt="Back"
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
      />
      <div>{props.title}</div>
      <span className="blue-text">
        |
        {props.name}
      </span>
    </div>
  );

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    setLoader(true);
    getNTSById(reportId)
      .then((response) => {
        response.data.draft = true;
        setFormData(response.data);
        if (response.data.point) {
          setCoords3857(response.data.point.coordinates);
          setZone(response.data.zone);
          setPointExist(true);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.detail === 'Not found.') {
          toast.error('Report not found');
          setTimeout(() => navigate('/nts-ha-report'), 2000);
        }
        console.error('Error fetching NTS data:', error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    document.title = `Edit NTS report - ${formData?.info?.general.hazard_name || 'Unknown'}`;
  }, [formData]);

  return (
    <section>
      <PageTitle
        title="Edit NTS Report"
        navigate={navigate}
        name={formData?.info?.general.hazard_name || 'Unknown'}
      />
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="flex-right">
          <TabList>
            <Tab>Attributes</Tab>
            <Tab>Check</Tab>
          </TabList>
        </div>

        <TabPanel>
          <NTSForm
            formData={formData}
            mode="edit"
            setFormData={setFormData}
            pointExist={pointExist}
            setPointExist={setPointExist}
            coords3857={coords3857}
            setCoords3857={setCoords3857}
            zone={zone}
            setZone={setZone}
            setLoader={setLoader}
          />
        </TabPanel>
        <TabPanel>
          <CheckNts
            formData={formData}
            setFormData={setFormData}
            coords3857={coords3857}
            setLoader={setLoader}
          />
        </TabPanel>
      </Tabs>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
