import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const ReportDateView = (props) => {
  const { formData, accordionSubId = '1.4' } = props;
  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Дата звіту"
      titleEN="Report date"
      children={formData.info?.general?.report_date}
    />
  );
};
