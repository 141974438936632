import moment from 'moment';
import React from 'react';
import { DownloadEORETemplate } from 'pages/EORE/Templates/Download';

export const TemplateVersion = (props) => {
  const { templateVersion } = props;

  if (!templateVersion) return null;

  return (
    <div className="template-version">
      <div>
        <TemplateDate templateDate={templateVersion?.date} />
        <TemplateNumber templateNumber={templateVersion?.version} />
      </div>
      <DownloadEORETemplate
        id={templateVersion?.template_file_id || templateVersion?.id}
        fileName={templateVersion?.name || templateVersion?.file_name}
        setLoader={props.setLoader}
        onlyIcon={false}
      />
    </div>
  );
};

const TemplateDate = (props) => {
  const { templateDate } = props;

  return (
    <div className="date">{moment(templateDate).format('DD.MM.YYYY')}</div>
  );
};

const TemplateNumber = (props) => {
  const { templateNumber } = props;

  return (
    <div className="text">
      Version
      {' '}
      {templateNumber}
      .0
    </div>
  );
};
