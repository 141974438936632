import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const SeasonalAccessibilityView = (props) => {
  const { formData } = props;
  const geographic = formData.info.geographic_and_terrain_description;

  const seasonalAccessibilityOptions = {
    seasonal_from_month_to_month: `Сезонність 
            з (${geographic.seasonal_accessibility_months?.from.split(' /')[0]}) 
            по (${geographic.seasonal_accessibility_months?.to.split(' /')[0]}) 
            \\ Seasonal 
            from (${geographic.seasonal_accessibility_months?.from.split('/ ')[1]}) 
            to (${geographic.seasonal_accessibility_months?.to.split('/ ')[1]})`,
    all_season: 'Позасезонність \\ All-season',
  };

  const selectedSeasonalAccessibility = Object.keys(seasonalAccessibilityOptions)
    .filter((option) => geographic.seasonal_accessibility.includes(option))
    .map((option) => seasonalAccessibilityOptions[option]);

  return (
    <AccordionBodyItem
      accordionSubId="6.4"
      titleUA="Сезонна доступність"
      titleEN="Seasonal accessibility"
      rightContentClassName="w-60"
      children={(
        <div className="flex-group flex-wrap">
          {selectedSeasonalAccessibility.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon
                style={{ width: '20px', height: '20px' }}
              />
              {item}
            </div>
          ))}
        </div>
      )}
    />
  );
};
