import { getDonorsNames } from 'api/DonorsApi';
import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import React, { useEffect, useState } from 'react';

export const Donor = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getDonorsNames()
      .then((res) => {
        const resOptions = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(resOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDonor = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      donor: value,
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.6</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Донор"
      titleEN="Donor"
      className={props.errors?.general?.donor ? 'error' : ''}
      children={(
        <div className="flex-group no-wrap">
          <SelectComponent
            isMulti={false}
            value={props.formData.donor ? {
              value: options.find((obj) => obj.value === props.formData?.donor)?.value,
              label: options.find((obj) => obj.value === props.formData?.donor)?.label,
            } : []}
            handleSelect={handleDonor}
            options={options}
            placeholder="Select"
            isSearchable
          />
        </div>
      )}
    />
  );
};
