import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const IndirectEvidence = (props) => {
  const evidences = props.formData.info.evidences.indirect_evidences;

  const handleEvidenceInput = (value, isChecked) => {
    const evidenceTypes = [...evidences.evidence_types];

    if (isChecked) {
      evidenceTypes.push(value);
    } else {
      const index = evidenceTypes.indexOf(value);
      if (index !== -1) {
        evidenceTypes.splice(index, 1);
      }
    }

    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        evidences: {
          ...prevFormData.info.evidences,
          indirect_evidences: {
            ...prevFormData.info.evidences.indirect_evidences,
            evidence_types: evidenceTypes,
          },
        },
      },
    }));
  };
  return (
    <AccordionBodyItem
      accordionSubId="4.4"
      titleUA="Непрямі докази"
      titleEN="Indirect evidence"
      children={(
        <div className="flex-group flex-wrap">
          <CheckboxInput
            id="former_military_position_checkpoint"
            name="former_military_position_checkpoint"
            value="former_military_position_checkpoint"
            label="Колишня військова позиція/блокпост /
                        Former military position/checkpoint"
            checked={evidences.evidence_types?.includes('former_military_position_checkpoint')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="indirect_mines_report"
            name="indirect_mines_report"
            value="indirect_mines_report"
            label="Непрямий звіт про мінування /
                        Indirect report about mines"
            checked={evidences.evidence_types?.includes('indirect_mines_report')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="mine_signs"
            name="mine_signs"
            value="mine_signs"
            label="Попереджувальні знаки про мінну небезпеку/загородження /
                        Mine signs"
            checked={evidences.evidence_types?.includes('mine_signs')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="previous_military_demine_reports"
            name="previous_military_demine_reports"
            value="previous_military_demine_reports"
            label="Попередні звіти з розмінування військовими"
            checked={evidences.evidence_types?.includes('previous_military_demine_reports')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="indirect-other"
            name="indirect-other"
            value="other"
            label="Інше"
            checked={evidences.evidence_types?.includes('other')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
        </div>
)}
    />
  );
};
