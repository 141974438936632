import React from 'react';
import { Link } from 'react-router-dom';
import 'assets/styles/pages/404.scss';
import { URLS } from 'routes/Urls';

export const NotFoundPage = () => (
  <main className="auth-main error-404">
    <div className="flex-column-group">
      <span>404</span>
      <span>Not Found</span>
      <Link to={URLS.LOGINPAGE}>Go to login</Link>
    </div>
  </main>
);
