import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';

export const Address = (props) => (
  <AccordionBodyItem
    accordionSubId={`9.${props.id}`}
    titleUA="Адреса"
    titleEN="Address"
    children={(
      <InputComponent
        value={props.formData.info.informators[props.index].address}
        handleInput={(value) => props.handleInformator('address', value)}
        blueLink
        limitChar
      />
    )}
  />
);
