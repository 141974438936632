import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';
import React from 'react';

export const PriorityLevel = (props) => {
  const landreleaseActivity = props.formData.info.next_landrelease_activity;

  const handlePriority = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          priority: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>7.1</span>
          <span className="star">*</span>
        </div>
)}
      titleUA="Рівень пріоритету"
      titleEN="Priority level"
      rightContentClassName="w-60"
      children={(
        <div className="flex-group flex-wrap">
          <RadioButton
            label="Високий \ High"
            checked={landreleaseActivity.priority === 'high'}
            id="priority-high"
            value="high"
            name="priority"
            disabled={false}
            handleInput={handlePriority}
          />
          <RadioButton
            label="Середній \ Medium"
            checked={landreleaseActivity.priority === 'medium'}
            id="priority-medium"
            value="medium"
            name="priority"
            disabled={false}
            handleInput={handlePriority}
          />
          <RadioButton
            label="Низький \ Low"
            checked={landreleaseActivity.priority === 'low'}
            id="priority-low"
            value="low"
            name="priority"
            disabled={false}
            handleInput={handlePriority}
          />
          <RadioButton
            label="Відсутній \ None"
            checked={landreleaseActivity.priority === 'none'}
            id="priority-none"
            value="none"
            name="priority"
            disabled={false}
            handleInput={handlePriority}
          />
        </div>
)}
    />
  );
};
