import React from 'react';
import { AccordionBodyItemView2 } from 'component/Accordion';

export const ReasonsView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const landReleaseActivity = formData.info.next_landrelease_activity;

  return (
    <AccordionBodyItemView2
      accordionSubId="7.3"
      titleUA="Причини та коментарі по 7.1 та 7.2"
      titleEN="Reasons and comments on 7.1 and 7.2"
      rightContentClassName="w-60"
      children={(
        <div className="flex-column-group">
          <div className="flex-group flex-wrap">
            {translateState && translatedFormData.reasons_and_comments_on_71_72}
            {!translateState && landReleaseActivity.reasons_and_comments_on_71_72}
          </div>
          <div className="flex-group flex-wrap">
            {translateState && translatedFormData.reasons_and_comments_on_71_72_extended}
            {!translateState && landReleaseActivity.reasons_and_comments_on_71_72_extended}
          </div>
        </div>
      )}
    />
  );
};
