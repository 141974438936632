import { AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const DescriptionPlaceView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.9"
      titleUA="Опис типу місця"
      titleEN="Description of place type"
      children={formData?.info?.session_information?.description_of_place_type}
    />
  );
};
