import React, { useState } from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as LockSvg } from 'assets/images/icons/password.svg';
import { ReactComponent as EyeSvg } from 'assets/images/icons/Eye-off.svg';

export const UserPassword = (props) => {
  const handlePassword = (value) => {
    if (props.placeholder === 'New password') {
      props.setUserInfo((prev) => ({
        ...prev,
        password: value,
      }));
    } else if (props.placeholder === 'Confirm password') {
      props.setConfirmPassword(value);
    }
  };

  return (
    <InputComponent
      type="password"
      upperRightText={props.placeholder}
      id="user-password"
      name="user-password"
      placeholder={props.placeholder}
      value={props.password}
      handleInput={handlePassword}
      iconLeft={<LockSvg />}
      iconRight={<EyeSvg />}
      autoComplete="off"
    />
  );
};

export const PasswordView = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex-between">
      <div className="double-text">
        <span>Password</span>
        <span>{showPassword ? props.password || '-' : '********'}</span>
      </div>
      <EyeSvg
        className="text-hover"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      />
    </div>
  );
};
