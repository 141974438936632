export const directEvidencesTypes = {
  mine_erw_accidents: 'Нещасні випадки з мінами/ВЗВ / Mine/ERW related accident',
  explosions: 'Вибухи побачені/почуті / Explosions',
  mines_direct_report: 'Прямий звіт про мінування / Direct report about mines',
  visible_mine_erw: 'Видимі міни/ВЗВ / Visible Mine/ERW',
  debris: 'Уламки/фрагменти ВЗВ / Debris',
  other: 'Інше / Other',
};

export const indirectEvidencesTypes = {
  former_military_position_checkpoint: 'Колишня військова позиція/блокпост / Former military position/checkpoint',
  indirect_mines_report: 'Непрямий звіт про мінування / Indirect report about mines',
  mine_signs: 'Попереджувальні знаки про мінну небезпеку/загородження / Mine signs',
  previous_military_demine_reports: 'Попередні звіти з розмінування військовими / Previous military demine reports',
  other: 'Інше / Other',
};

export const engEvidencesType = {
  mine_erw_accidents: 'Mine/ERW related accident',
  explosions: 'Explosions',
  mines_direct_report: 'Direct report about mines',
  visible_mine_erw: 'Visible Mine/ERW',
  debris: 'Debris',
  other: 'Other',
  former_military_position_checkpoint: 'Former military position/checkpoint',
  indirect_mines_report: 'Indirect report about mines',
  mine_signs: 'Mine signs',
  previous_military_demine_reports: 'Previous military demine reports',
};
