import axios from 'axios';
import { CheckboxInput } from 'component/Checkbox';
import { React, useEffect, useState } from 'react';

export const DroneLegend = (props) => {
  const [checkboxes, setCheckboxes] = useState(props.droneData.map((item) => ({
    layerName: `${item.name}, ${item.file_name}`,
    checked: false,
  })));

  const setDroneLayer = (droneDataId, layerName) => {
    props.map.addSource(`drone-source-${layerName}`, {
      type: 'raster',
      tiles: [`${axios.defaults.baseURL}drones/${droneDataId}/tiff/details/{bbox-epsg-3857}`],
      tileSize: 256,
    });

    props.map.addLayer({
      id: `drone-source-${layerName}-layer`,
      type: 'raster',
      source: `drone-source-${layerName}`,
    });
  };

  const sourceExist = (layer) => {
    if (!props.map) return false;
    return props.map.getSource(`drone-source-${layer}`);
  };

  const handleDroneCheckbox = (checked, droneData) => {
    const { id } = droneData;
    const fileName = droneData.file_name;
    if (!sourceExist(fileName)) {
      setDroneLayer(id, fileName);
    }
    const visibility = checked ? 'visible' : 'none';
    props.map.setLayoutProperty(`drone-source-${fileName}-layer`, 'visibility', visibility);
  };

  const hideAllDroneLayers = () => {
    props.droneData.forEach((item) => {
      handleDroneCheckbox(false, item);
    });
  };

  useEffect(() => () => {
    hideAllDroneLayers();
  }, []);

  return (
    props.droneData.map((item, index) => (
      item.file_name
      && (
        <div className="flex-column-group" key={index}>
          <div className="flex-group flex-between">
            <CheckboxInput
              margin="no-y-margin"
              id={`drone-${index}`}
              label={`${item.name}, ${item.file_name}`}
              name={`drone-${index}`}
              value={`${item.name}, ${item.file_name}`}
              checked={checkboxes.find((cb) => cb.layerName === `${item.name}, ${item.file_name}`).checked}
              handleInput={(value, checked) => {
                handleDroneCheckbox(checked, item);
                const updatedCheckboxes = checkboxes.map((cb) => {
                  if (cb.layerName === `${item.name}, ${item.file_name}`) {
                    return { ...cb, checked };
                  }
                  return cb;
                });
                setCheckboxes(updatedCheckboxes);
              }}
            />
            {item.centroid && (
              <div
                className="text-hover"
                onClick={() => {
                  if (item.centroid) {
                    props.map.flyTo({
                      center: item.centroid?.map((coord) => Number(coord)).reverse(),
                      essential: true,
                      zoom: 12,
                    });
                  }
                }}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 14C11.7614 14 14 11.7614 14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14ZM9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z" fill="#868686" />
                  <path d="M8 1C8 0.447715 8.44772 0 9 0C9.55228 0 10 0.447715 10 1V5C10 5.55228 9.55228 6 9 6C8.44772 6 8 5.55228 8 5V1Z" fill="#868686" />
                  <path d="M8 13C8 12.4477 8.44772 12 9 12C9.55228 12 10 12.4477 10 13V17C10 17.5523 9.55228 18 9 18C8.44772 18 8 17.5523 8 17V13Z" fill="#868686" />
                  <path d="M0 9C0 8.44772 0.447715 8 1 8H5C5.55228 8 6 8.44772 6 9C6 9.55228 5.55228 10 5 10H1C0.447715 10 0 9.55228 0 9Z" fill="#868686" />
                  <path d="M12 9C12 8.44772 12.4477 8 13 8H17C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H13C12.4477 10 12 9.55228 12 9Z" fill="#868686" />
                  <path d="M10 9C10 9.55229 9.55229 10 9 10C8.44771 10 8 9.55229 8 9C8 8.44771 8.44771 8 9 8C9.55229 8 10 8.44771 10 9Z" fill="#868686" />
                </svg>
              </div>
            )}
          </div>
        </div>
      )
    ))
  );
};
