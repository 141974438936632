import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { LocationComponent } from './Location/Location';
import { SettlementId } from './Location/SettlementId';
import { ClosestSettlement } from './Location/ClosestSettlement';

export const LocationInfo = (props) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          accordionId="2"
          validationForm={props.validationForm}
          block="location"
          titleUA={(
            <div className="flex-group">
              <span>Місцезнаходження</span>
              <span className="star">*</span>
            </div>
          )}
          titleEN="Location"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <LocationComponent
        formData={props.formData}
        setFormData={props.setFormData}
        formType={props.formType}
        errors={props.errors}
        fieldBlock="location"
      />
      <SettlementId
        settlementId={props.formData.info.location.settlement_code}
      />
      <ClosestSettlement
        formData={props.formData}
        setFormData={props.setFormData}
      />
    </AccordionItemPanel>
  </>
);
