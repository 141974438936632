/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';
import { Table, TableHeader, TableRow } from 'component/Table';
import { Map } from 'component/Map';
import { indirectEvidencesTypes } from 'consts/Evidences';
import { featureCollection, point } from '@turf/helpers';
import proj4 from 'proj4';
import { alphaVal } from 'utils/Helper';

export const IndirectEvidencesView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const { indirect_evidences } = formData.info.evidences;

  const evidencesOptions = {
    former_military_position_checkpoint:
      'Колишня військова позиція/блокпост / Former military position/checkpoint',
    indirect_mines_report:
      'Непрямий звіт про мінування / Indirect report about mines',
    mine_signs:
      'Попереджувальні знаки про мінну небезпеку/загородження / Mine signs',
    previous_military_demine_reports:
      'Попередні звіти з розмінування військовими / Previous military demine reports',
    other: 'Інше / Other',
  };

  const selectedEvidencesOptions = Object.keys(evidencesOptions)
    .filter((option) => indirect_evidences.evidence_types.includes(option))
    .map((option) => evidencesOptions[option]);

  const getGeojson = () => {
    const featureCollectionGeojson = featureCollection([]);

    indirect_evidences.items?.forEach((item) => {
      if (!item.long && !item.lat) return;
      const coords = proj4('EPSG:32637', 'EPSG:4326', [
        Number(item.long),
        Number(item.lat),
      ]);
      if (Number.isNaN(coords[0]) || Number.isNaN(coords[1])) return;
      const pointFeature = point(coords, {
        evidence_type: item.evidence_type,
        description: item.description,
        photo: item.photo,
      });

      featureCollectionGeojson.features.push(pointFeature);
    });

    return featureCollectionGeojson.features.length
      ? featureCollectionGeojson
      : featureCollection([]);
  };

  const [indirectEvidencesGeojson, setIndirectEvidencesGeojson] = useState(getGeojson());

  useEffect(() => {
    setIndirectEvidencesGeojson(getGeojson());
  }, [formData.info.evidences.indirect_evidences.items]);

  return (
    <>
      <AccordionBodyItem
        accordionSubId="4.4"
        titleUA="Непрямі докази"
        titleEN="Indirect evidence"
      >
        <div>
          {selectedEvidencesOptions.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon style={{ width: '20px', height: '20px' }} />
              {item}
            </div>
          ))}
        </div>
      </AccordionBodyItem>
      <div className="flex-column-group">
        <AccordionBodyItem
          bgStyle="bgGrey no-border-radius-bottom"
          accordionSubId="4.5"
          titleUA="Координати непрямих доказів (за наявності)"
          titleEN="Indirect evidence coordinates (if any)"
        />
        <div className="accordion-body-wrapper bgGrey">
          <Table className="table-lines-between">
            <TableHeader>
              <th className="no-border-radius">
                <div>Тип доказу</div>
                <div>Type of evidence</div>
              </th>
              <th aria-label="line">
                <div className="line" />
              </th>
              <th>Long/UTM X</th>
              <th aria-label="line">
                <div className="line" />
              </th>
              <th>Lat/UTM Y</th>
              <th aria-label="line">
                <div className="line" />
              </th>
              <th className="no-border-radius">Опис\ Description</th>
            </TableHeader>
            <tbody>
              {!indirect_evidences?.items?.length && (
                <TableRow>
                  <td>Empty</td>
                </TableRow>
              )}
              {indirect_evidences
                && indirect_evidences?.items?.map((evidence, index) => (
                  <TableRow key={index}>
                    <td>
                      {indirectEvidencesTypes[evidence.evidence_type]
                        || 'Невідомо / Unknown'}
                    </td>
                    <td aria-label="line">
                      <div className="line" />
                    </td>
                    <td>{evidence?.long?.toFixed(0)}</td>
                    <td aria-label="line">
                      <div className="line" />
                    </td>
                    <td>{evidence?.lat?.toFixed(0)}</td>
                    <td aria-label="line">
                      <div className="line" />
                    </td>
                    <td className="center">
                      {translateState && (translatedFormData[`indirect_evidence_description_${index}`] || 'No data')}
                      {!translateState && (evidence.description || 'Немає даних')}
                    </td>
                  </TableRow>
                ))}
            </tbody>
          </Table>
        </div>
        <Map
          map={props.map}
          setMap={props.setMap}
          id="indirect-map"
          turnPointsPolygon={props.formData.info.turn_points.geojson}
          hazardAreaType={props.formData.info.general.hazard_area_type}
          indirectEvidenceGeojson={indirectEvidencesGeojson}
          evidences={props.formData.info.evidences}
          googleWms
        />
        <div className="flex-column-group">
          <AccordionBodyItem
            accordionSubId="4.6"
            titleUA="Коротке пояснення непрямих доказів за потреби"
            titleEN="A brief explanation of indirect evidence as needed"
          />

          {!indirect_evidences?.informators?.length && (
            <AccordionBodyItem
              titleUA="Інформатори відсутні"
              titleEN="No informants"
            />
          )}
          {indirect_evidences?.informators?.map((informator, index) => (
            <div key={index}>
              <AccordionBodyItem
                titleUA={`Інформатор ${alphaVal(index + 1)}`}
                titleEN={`Informant ${alphaVal(index + 1)}`}
              />

              <div className="accordion-body-wrapper flex-left">
                <div className="numberId" />

                <div className="informator-block-comment">
                  {translateState && translatedFormData[`indirect_informator_description_${index}`]}
                  {!translateState && informator}
                </div>
              </div>
            </div>
          ))}
          <AccordionItemPanel>
            <div
              className="informator-block-comment"
              style={{ marginLeft: '40px', marginRight: '40px' }}
            >
              {translateState && translatedFormData.indirect_evidences_main_description}
              {!translateState && formData.info.evidences.indirect_evidences.description}
            </div>
          </AccordionItemPanel>
        </div>
      </div>
    </>
  );
};
