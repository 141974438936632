import React, { useEffect, useState } from 'react';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderUserSettings } from 'component/Accordion';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as PlusSvg } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as ViewIcon } from 'assets/images/icons/Eye-on.svg';
import { deleteValidationFlow, getAllUsers, getValidationsByUserId } from 'api/UserAPI';
import { Loader } from 'component/Loader';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { DeleteValidationFlowDialog } from 'component/Dialog';
import { useUser } from 'routes/UserContext';

const ReportRow = ({ handleActionClick }) => {
  const [users, setUsers] = useState([]);
  const [usersValidations, setUsersValidations] = useState([]);
  const [openUserIds, setOpenUserIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isValidationsLoading, setIsValidationsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const userContext = useUser();

  const getUsers = (hasValidations) => {
    setLoading(true);
    getAllUsers(hasValidations)
      .then((response) => {
        setUsers(response.data);
      })
      .catch(() => {
        console.error('Error fetching users');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getValidations = (userId) => {
    setIsValidationsLoading(true);
    getValidationsByUserId(userId)
      .then((response) => {
        setUsersValidations((prevUsersValidations) => ({
          ...prevUsersValidations,
          [userId]: {
            validations: response?.data?.data,
            canAddMoreFlows: response?.data?.can_add_more_flows,
          },
        }));
      })
      .catch(() => {
        console.error('Error fetching validations');
      })
      .finally(() => {
        setIsValidationsLoading(false);
      });
  };

  useEffect(() => {
    getUsers(true);
  }, []);

  const handleAccordionChange = (userId) => {
    setIsValidationsLoading(true);
    if (openUserIds.includes(userId)) {
      setOpenUserIds(openUserIds.filter((id) => id !== userId));
      setUsersValidations((prevUsersValidations) => {
        const newUsersValidations = { ...prevUsersValidations };
        delete newUsersValidations[userId];
        return newUsersValidations;
      });
      setIsValidationsLoading(false);
    } else {
      setSelectedUserId(userId);
      setOpenUserIds([...openUserIds, userId]);
      getValidations(userId);
    }
  };

  const handleDelete = (userId, reportType) => {
    const body = {
      user: userId,
      report_type: Object.keys(reportType)[0],
    };
    setLoading(true);
    deleteValidationFlow(body)
      .then(() => {
        toast.success('Validation flow deleted successfully');
        getValidations(userId);
      })
      .catch((error) => {
        toast.error('Error deleting validation flow');
        console.error('Error deleting validation flow:', error);
      }).finally(() => {
        setLoading(false);
        getUsers(true);
      });
  };
  return (
    loading ? <Loader />
      : users?.map((user) => (
        <AccordionItem key={user?.id}>
          <AccordionItemHeading onClick={() => handleAccordionChange(user?.id)}>
            <AccordionItemButton>
              <AccordionHeaderUserSettings
                title={user?.role?.short_name}
                userName={user?.name}
                email={user?.email}
                backgroundColor={user?.role?.color}
              />
            </AccordionItemButton>
          </AccordionItemHeading>
          {openUserIds.includes(user?.id) && (
            <AccordionItemPanel className="accordion-validation-panel">
              {isValidationsLoading && selectedUserId === user?.id && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ClipLoader
                    color="rgba(0, 177, 183, 1)"
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    loading={isValidationsLoading}
                  />
                </div>
              )}
              {usersValidations[user?.id]?.validations?.map((validation) => (
                <AccordionItem key={validation?.id} className="accordion-validation-item">
                  <span
                    className="validation-flow-title"
                  >
                    {Object.values(validation?.report_type)[0]}
                  </span>
                  <span className="line" />
                  <div className="validation-actions">
                    <ViewIcon
                      className="view-icon"
                      onClick={() => handleActionClick(user?.id, validation, 'view')}
                    />
                    {userContext?.user?.permissions?.change_settings && (
                      <>
                        <EditIcon
                          className="edit-icon"
                          onClick={() => handleActionClick(user?.id, validation, 'edit')}
                        />
                        <DeleteValidationFlowDialog
                          onDelete={() => handleDelete(user?.id, validation?.report_type)}
                          reportType={validation?.report_type}
                        />
                      </>
                    )}

                  </div>
                </AccordionItem>
              ))}
              <AccordionItem>
                <span className="validation-line" />
                <div
                  className="add-validation-button-container"
                >
                  {usersValidations[user?.id]?.canAddMoreFlows
                    && userContext?.user?.permissions?.add_settings
                    && (
                      <ButtonComponent
                        iconLeft={<PlusSvg />}
                        label="Add validation"
                        handleButton={() => handleActionClick(user?.id, null, '')}
                      />
                    )}
                </div>
              </AccordionItem>
            </AccordionItemPanel>
          )}
        </AccordionItem>
      ))
  );
};

export default ReportRow;
