import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle, AccordionBodyItem } from 'component/Accordion';
import { Table, TableHeader, TableRow } from 'component/Table';
import { LocationIcon } from 'component/LocationIcon';
import { directEvidencesTypes } from '../../../consts/Evidences';

export const EvidenceInformation = (props) => {
  const evidences = props.formData.info.evidences.direct_evidences;

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            titleUA="Інформація про докази"
            titleEN="Evidence information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <AccordionBodyItem
          accordionSubId=""
          titleUA="Координати прямих доказів"
          titleEN="Direct evidence coordinates"
        />
        <div className="accordion-body-wrapper bgGrey">
          <Table className="table-lines-between">
            <TableHeader>
              <th className="double-text">
                <div>Тип доказу</div>
                <div>Type of evidence</div>
              </th>
              <th aria-label="line"><div className="line" /></th>
              <th>Long/UTM X</th>
              <th aria-label="line"><div className="line" /></th>
              <th>Lat/UTM Y</th>
              <th aria-label="line"><div className="line" /></th>
              <th>Опис\ Description</th>
            </TableHeader>
            <tbody>
              {!evidences?.items?.length
                && (
                  <TableRow>
                    <td className="empty">Empty</td>
                  </TableRow>
                )}
              {evidences && evidences?.items?.map((evidence, index) => (
                <TableRow key={index}>
                  <td className="flex-group">
                    <span>
                      4.2.
                      {index + 1}
                    </span>
                    <span>{directEvidencesTypes[evidence.evidence_type] || 'Невідомо'}</span>
                  </td>
                  <td aria-label="line"><div className="line" /></td>
                  <td>{evidence.long.toFixed(0)}</td>
                  <td aria-label="line"><div className="line" /></td>
                  <td>{evidence.lat.toFixed(0)}</td>
                  <td aria-label="line"><div className="line" /></td>
                  <td className="flex-group center">
                    <span>{evidence.description || 'Немає даних'}</span>
                  </td>
                  <td aria-label="location">
                    <LocationIcon
                      coords={[Number(`${evidence.long}`), Number(`${evidence.lat}`)]}
                      map={props.map}
                      id={index}
                      source="evidence"
                      zone={props.formData.file_zone}
                    />
                  </td>
                </TableRow>
              ))}
            </tbody>

          </Table>
        </div>

      </AccordionItemPanel>
    </>
  );
};
