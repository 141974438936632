import { ButtonComponent } from 'component/Button';
import React, { useState } from 'react';
import { ReactComponent as UserIcon } from 'assets/images/icons/User.svg';
import 'assets/styles/pages/validation.scss';
import { useUser } from 'routes/UserContext';
import AccordionValidation from './AccordionValidation';
import ValidationProcess from './ValidationProcess';

const Validation = () => {
  const [showValidationProcess, setShowValidationProcess] = useState(false);

  const [mode, setMode] = useState({
    user: null,
    type: null,
    action: null,
    flowId: null,
  });

  const { user } = useUser();

  const toggleValidationProcess = () => {
    setShowValidationProcess(!showValidationProcess);
  };

  const handleActionClick = (userId, validation, action) => {
    setMode({
      user: userId,
      type: validation?.report_type,
      action,
      flowId: validation?.id,
    });
    toggleValidationProcess();
  };

  return (
    !showValidationProcess
      ? (
        <>
          <div className="validation-container">
            <AccordionValidation
              handleActionClick={handleActionClick}
              setMode={setMode}
            />
          </div>
          {user?.permissions?.add_settings && (
            <div className="add-validation-user-button">
              <ButtonComponent
                iconLeft={<UserIcon />}
                label="Add validation user"
                handleButton={() => handleActionClick(null, null, null)}
              />
            </div>
          )}
        </>
      )
      : (
        <ValidationProcess
          toggleValidationProcess={toggleValidationProcess}
          mode={mode}
          setMode={setMode}
        />
      )
  );
};

export default Validation;
