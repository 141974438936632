import React from 'react';
import { Table, TableHeader, TableRow } from 'component/Table';
import { TextareaComponent } from 'component/Textarea';
import { ReactComponent as RedirectionIcon } from 'assets/images/icons/change.svg';
import { ImageGalleryBlock } from '../ImageGallery';

export const LmBmInfo = (props) => {
  const { lm } = props.formData.info;
  const { bm } = props.formData.info;

  const handleLmDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        lm: {
          ...prevFormData.info.lm,
          description: value,
        },
      },
    }));
  };

  const handleBmDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        bm: {
          ...prevFormData.info.bm,
          description: value,
        },
      },
    }));
  };

  return (
    <div className="accordion-body-wrapper bgGrey">
      <Table className="table-lines-between">
        <TableHeader>
          <th
            className="double-text text-center"
            style={{ justifyContent: 'center', textAlign: 'center' }}
          >
            <div>Точки відліку</div>
            <div>Reference points</div>
          </th>
          <th aria-label="line"><div className="line" /></th>
          <th>Long/UTM X</th>
          <th aria-label="line"><div className="line" /></th>
          <th>Lat/UTM Y</th>
          <th aria-label="line"><div className="line" /></th>
          <th>Опис\ Description</th>
        </TableHeader>
        <tbody>
          <TableRow>
            <td style={{ justifyContent: 'center' }}>
              <div className={`double-text ${props.errors.lm ? 'error' : ''} text-center`}>
                <span>Фіксований орієнтир</span>
                <span>Landmark (LM)</span>
              </div>
            </td>
            <td aria-label="line"><div className="line" /></td>
            <td>{lm?.geometry?.coordinates[0]?.toFixed(0) || 0}</td>
            <td aria-label="line"><div className="line" /></td>
            <td>{lm?.geometry?.coordinates[1]?.toFixed(0) || 0}</td>
            <td aria-label="line"><div className="line" /></td>
            <td
              className="flex-group"
              aria-label="textarea"
            >
              <TextareaComponent
                value={lm.description}
                handleTextarea={handleLmDescription}
                blueLink
                limitChar
              />
            </td>
            <td aria-label="imageBlock">
              <ImageGalleryBlock
                formData={props.formData}
                photos={props.photos}
                setPhotos={props.setPhotos}
                loader={props.loader}
                setLoader={props.setLoader}
                dependency="lm"
                item={null}
                imageError={props.errors?.lm}
                setLoaderPhotos={props.setLoaderPhotos}
              />
            </td>
          </TableRow>
          <TableRow>
            <td style={{ justifyContent: 'center' }}>
              <div className={`double-text ${props.errors.bm ? 'error' : ''}`}>
                <span>Опорна точка</span>
                <span>Benchmark (BM)</span>
              </div>
            </td>
            <td aria-label="line"><div className="line" /></td>
            <td>{bm?.geometry?.coordinates[0]?.toFixed(0) || 0}</td>
            <td aria-label="line"><div className="line" /></td>
            <td>{bm?.geometry?.coordinates[1]?.toFixed(0) || 0}</td>
            <td aria-label="line"><div className="line" /></td>
            <td
              className="flex-group"
              aria-label="textarea"
            >
              <TextareaComponent
                value={bm.description}
                handleTextarea={handleBmDescription}
                blueLink
                limitChar
              />
            </td>
            <td aria-label="image-block">
              <ImageGalleryBlock
                formData={props.formData}
                photos={props.photos}
                setPhotos={props.setPhotos}
                loader={props.loader}
                setLoader={props.setLoader}
                dependency="bm"
                item={null}
                imageError={props.errors?.bm}
                setLoaderPhotos={props.setLoaderPhotos}
              />
            </td>
          </TableRow>
          <TableRow className="separator">
            <td style={{ justifyContent: 'center' }}>
              <span
                className="text-hover no-wrap"
                onClick={() => props.handleTabChange(1)}
              >
                Gis redirect
                <RedirectionIcon />
              </span>
            </td>
          </TableRow>
        </tbody>
      </Table>
    </div>

  );
};
