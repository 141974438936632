import React from 'react';
import { SelectComponent } from 'component/Select';

export const AreaTypeFilter = (props) => {
  const options = [
    { value: 'I3T|SHA', label: 'I3T|SHA' },
    { value: '3T|CHA', label: '3T|CHA' },
  ];
  return (
    <div className="flex-column-group">
      <span>Sort by area type</span>
      <SelectComponent
        className="full"
        value={props.areaTypeFilter}
        options={options}
        handleSelect={props.setAreaTypeFilter}
        isMulti
      />
    </div>
  );
};
