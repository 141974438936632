import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const HazardAreaTypeView = (props) => {
  const { formData } = props;

  const hazardAreaTypeOptions = {
    'I3T|SHA': 'I3T|SHA',
    '3T|CHA': '3T|CHA',
  };

  const selectedHazardAreaTypes = Object.keys(hazardAreaTypeOptions).filter(
    (option) => formData.info.general?.hazard_area_type?.includes(option),
  );
  return (
    <AccordionBodyItemView
      accordionSubId="1.10"
      titleUA="Тип небезпеки"
      titleEN="Area type"
      children={selectedHazardAreaTypes.join(', ') || '-'}
    />
  );
};
