import { AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const SurveyBeforeSessionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={(
        <div>3.1</div>
        )}
      titleUA="Опитування ДО сесії"
      titleEN="Survey BEFORE the session"
      children={`${formData?.info?.final_indicators?.percentage_before_session} %`}
    />
  );
};
