/* eslint-disable no-shadow */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'component/Loader';
import { ToastContainer, toast } from 'react-toastify';

import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow_left.svg';
import { ReactComponent as MapIcon } from 'assets/images/icons/map3.svg';
import { ReactComponent as FilesIcon } from 'assets/images/icons/Files.svg';

import 'assets/styles/tabs.scss';
import { axiosWrapper } from 'utils/Helper';
import { getHAById } from 'api/HaApi';

import 'react-tabs/style/react-tabs.css';
import './styles.scss';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion';

import { AccordionHeaderTitle } from 'component/Accordion';

import { getPhotoByName } from 'api/ImagesApi';
import { ButtonComponent } from 'component/Button';
import { useUser } from 'routes/UserContext';
import { ReactComponent as TranslateSvg } from 'assets/images/icons/translate_24px.svg';
import { TranslateWrapperComponent } from 'component/Translation';
import { FileInformation } from '../blocks/FileInformation';
import { MapInfo } from '../blocks/MapInfo';
import { GeneralInfoView } from '../ViewBlocks/GeneralInfo/GeneralInfo';
import { LocationView } from '../ViewBlocks/Location/Location';
import { ContaminationView } from '../ViewBlocks/Contamination/Contamination';
import { EvidencesView } from '../ViewBlocks/Evidences/Evidences';
import { FileInfoView } from '../ViewBlocks/FileInfo/FileInfo';
import { GeographicView } from '../ViewBlocks/Geographic/Geographic';
import { LandRelease } from '../ViewBlocks/LandRelease/LandRelease';
import { BeneficiariesView } from '../ViewBlocks/Beneficiaries/Beneficiaries';
import { InformatorsView } from '../ViewBlocks/Informators/Informators';
import { PhotosView } from '../ViewBlocks/Photos/Photos';
import { AnnexMapView } from '../ViewBlocks/AnnexMap/AnnexMap';
import { rejectHaReport } from '../../../api/HaApi';
import { HAformData } from '../../../consts/Forms';

const ViewHAReportPage = () => {
  const [formData, setFormData] = useState(HAformData);
  const [loader, setLoader] = useState(true);

  const [map, setMap] = useState(null);

  const [annexImages, setAnnexImages] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [translatedFormData, setTranslatedFormData] = useState([]);
  const [translateState, setTranslateState] = useState(false);

  const { reportId } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    document.title = `View HA report - ${formData.info.general.hazard_name || 'Unknow'}`;
  }, [formData]);

  const getAnnexImage = (reportId, imageFile) => {
    if (!imageFile || !imageFile.file_name) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile.file_name, true)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile.file_name, {
                type: 'image/jpeg',
              });

              setAnnexImages((prevPhotos) => [
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  file_name: imageFile.file_name,
                  is_main: imageFile.is_main,
                },
              ]);
              resolve();
            } else {
              reject('Annex maps request failed');
            }
          })
          .catch((err) => {
            toast.error('Annex maps not loaded');
            reject(err);
          });
      });
    });
  };

  const getAnnexImages = (res) => new Promise((resolve) => {
    const promises = [];

    res.data.info.annex?.items?.forEach((item) => {
      promises.push(getAnnexImage(res.data.id, item));
    });

    Promise.all(promises)
      .then(() => {
        resolve('ok');
      })
      .catch(() => {
        toast.error('Annex maps not loaded');
      });
  });

  const getPhoto = (reportId, imageFile, dependency, item) => {
    if (!imageFile || !imageFile.file_name) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile.file_name)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile.file_name, {
                type: 'image/jpeg',
              });

              setPhotos((prevPhotos) => [
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  name: imageFile.file_name,
                  dependency,
                  item,
                  description: imageFile.description || '',
                  lat: imageFile.lat || '',
                  long: imageFile.long || '',
                  date: imageFile.date || '',
                },
              ]);

              resolve();
            } else {
              reject('Photo request failed');
            }
          })
          .catch((err) => {
            toast.error(
              `Photo with file name ${imageFile.file_name} not loaded`,
            );
            reject(err);
          });
      });
    });
  };
  const getAllPhotos = (res) => new Promise((resolve) => {
    const promises = [];

    res.data.info.general_photos.items?.forEach((item) => {
      promises.push(getPhoto(res.data.id, item, 'general', null));
    });

    res.data.info.evidences.direct_evidences.items?.forEach((item, index) => {
      if (!item.photo) return;
      item.photo?.forEach((photo) => {
        promises.push(
          getPhoto(res.data.id, photo, 'direct_evidence', index + 1),
        );
      });
    });

    res.data.info.evidences.indirect_evidences.items?.forEach(
      (item, index) => {
        if (!item.photo) return;
        item.photo?.forEach((photo) => {
          promises.push(
            getPhoto(res.data.id, photo, 'indirect_evidence', index + 1),
          );
        });
      },
    );

    if (res.data.info.lm.photo.length > 0) {
      res.data.info.lm.photo.forEach((photo) => {
        promises.push(getPhoto(res.data.id, photo, 'lm', null));
      });
      res.data.info.bm.photo.forEach((photo) => {
        promises.push(getPhoto(res.data.id, photo, 'bm', null));
      });
    } else {
      promises.push(getPhoto(res.data.id, res.data.info.lm.photo, 'lm', null));
      promises.push(getPhoto(res.data.id, res.data.info.bm.photo, 'bm', null));
    }

    Promise.allSettled(promises)
      .then(() => {
        resolve('ok');
      })
      .catch(() => {
        toast.error('Photos not loaded');
      });
  });

  const PageTitle = (props) => (
    <div className="page-title">
      <ArrowIcon
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
      />
      <div>{props.title}</div>
      <span className="blue-text">
        |
        {props.name}
      </span>
    </div>
  );

  const handleRejectButton = (status) => {
    setLoader(true);
    rejectHaReport(formData.id, { status })
      .then(() => {
        toast.success('HA report rejected from IMSMA');
        setLoader(false);
        setTimeout(() => {
          navigate('/nts-ha-report');
        }, 2000);
      })
      .catch(() => {
        toast.error('Error rejecting HA report from IMSMA');
        setLoader(false);
      });
  };

  useEffect(() => {
    getHAById(reportId)
      .then((res) => {
        if (res.status === 200) {
          setFormData(res.data);
          getAnnexImages(res);
          getAllPhotos(res);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  return (
    <section>
      <PageTitle
        title="View HA Report"
        name={formData.info?.general.hazard_name || 'Unknown'}
        navigate={navigate}
      />
      <TranslateWrapperComponent
        formData={formData}
        setTranslatedFormData={setTranslatedFormData}
        translationState={translateState}
      >
        <form className="form-wrapper">
          <div
            className="text-hover flex-group flex-right"
            onClick={() => setTranslateState(!translateState)}
          >
            <TranslateSvg />
            <span>Translate</span>
          </div>
          <div className="info_block_header">
            <div className="info_block_header_left">Атрибути</div>
            <span className="info_block_header_line" />
            <div className="info_block_header_right">Attributes</div>
          </div>

          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={[
              'general',
              'location',
              'contamination',
              'evidence',
              'fileInfo',
              'geographic',
              'landrelease',
              'beneficiaries',
              'informators',
            ]}
            className="custom-accordion"
          >
            <GeneralInfoView
              formData={formData}
              comments={[]}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <LocationView
              formData={formData}
              comments={[]}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <ContaminationView
              formData={formData}
              comments={[]}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <EvidencesView
              formData={formData}
              comments={[]}
              map={map}
              setMap={setMap}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <FileInfoView
              formData={formData}
              comments={[]}
              map={map}
              setMap={setMap}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <GeographicView
              formData={formData}
              comments={[]}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <LandRelease
              formData={formData}
              comments={[]}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

            <BeneficiariesView
              formData={formData}
              comments={[]}
            />

            <InformatorsView
              formData={formData}
              comments={[]}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />

          </Accordion>
          <div className="info_block_header">
            <div className="info_block_header_left">ГІС</div>
            <span className="info_block_header_line" />
            <div className="info_block_header_right">Gis</div>
          </div>

          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={['fileInfo', 'evidenceInfo', 'map', 'annexMaps']}
            className="custom-accordion"
          >
            <AccordionItem uuid="fileInfo">
              <FileInformation
                formData={formData}
                accordionId={<FilesIcon style={{ color: '#00b1b7' }} />}
                tab="gis"
              />
            </AccordionItem>

            <AccordionItem uuid="map">
              <MapInfo
                formData={formData}
                accordionId={<MapIcon />}
                map={map}
                setMap={setMap}
                evidences={formData.info.evidences}
              />
            </AccordionItem>
          </Accordion>
          <div className="info_block_header">
            <div className="info_block_header_left">Фото</div>
            <span className="info_block_header_line" />
            <div className="info_block_header_right">Photos</div>
          </div>
          <Accordion
            className="custom-accordion"
            allowMultipleExpanded
            allowZeroExpanded
          >
            <AccordionItem uuid="photos">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle titleUA="Фото" titleEN="Photo" />
                </AccordionItemButton>
              </AccordionItemHeading>

              <PhotosView photos={photos} />
            </AccordionItem>
          </Accordion>
          <Accordion
            className="custom-accordion"
            allowMultipleExpanded
            allowZeroExpanded
          >
            <AccordionItem uuid="annexMaps">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle titleUA="Annex Maps" />
                </AccordionItemButton>
              </AccordionItemHeading>

              <AnnexMapView annexImages={annexImages} />
            </AccordionItem>
          </Accordion>
          <div className="form-buttons buttons-group py-10">
            {formData.validation_status?.value === 'validated'
              && user.permissions.reject_imsma_nto_ha
              && (
                <ButtonComponent
                  handleButton={() => {
                    handleRejectButton('rejected');
                  }}
                  label="Reject from IMSMA"
                  disabled={false}
                  className="danger"
                  type="button"
                />
              )}
          </div>
        </form>
      </TranslateWrapperComponent>

      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export default ViewHAReportPage;
