import moment from 'moment';

export const HAformData = {
  user: null,
  send_to_imsma: true,
  draft: false,
  info: {
    files: {
      turn_points: '',
      evidence: '',
      lm_bm: '',
    },
    general_photos: {
      items: [],
      description: '',
    },
    annex: {
      items: [],
    },
    lm: {
      geometry: {
        type: 'Point',
        coordinates: [],
      },
      photo: [],
      description: '',
    },
    bm: {
      geometry: {
        type: 'Point',
        coordinates: [],
      },
      photo: [],
      description: '',
    },
    polygon: {
      type: 'string',
      coordinates: [],
    },
    general: {
      internal_id: '',
      custom_internal_id: '',
      imsma_id: '',
      name: '',
      hazard_name: '',
      report_date: moment(new Date()).format('DD.MM.YYYY'),
      reporter_organization: 'FSD',
      reporter_name: '',
      reporter_occupation: '',
      group_id: 654,
      examination_date: {
        from: moment(new Date()).format('DD.MM.YYYY'),
        to: moment(new Date()).format('DD.MM.YYYY'),
      },
      hazard_area_type: 'I3T|SHA',
      hazard_type: 'minefield',
    },
    location: {
      region: '',
      district: '',
      local_community: '',
      settlement_name: '',
      settlement_code: '',
      nearest_settlement_name: {
        matches: false,
        settlement_name: '',
      },
    },
    hazard_type: {
      anti_personnel_mines: false,
      anti_transport_mines: false,
      unexploded_ordnance: false,
      abandoned_explosive_ordnance: false,
      cluster_munition: false,
      improvised_explosive_device: false,
      small_arms_ammunition: false,
      explosives: false,
      description: '',
    },
    evidences: {
      direct_evidences: {
        evidence_types: [],
        items: [],
        description: '',
      },
      indirect_evidences: {
        evidence_types: [],
        informators: [],
        items: [],
        description: '',
      },
      evidence_geojson: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [],
            },
          },
        ],
      },
    },
    polygon_info: {
      polygon_count: 0,
      coordinate_system: 'wgs_84',
      zone_number: '',
    },
    geographic_and_terrain_description: {
      vegetation: [],
      vegetation_other: '',
      relief: [],
      relief_other: '',
      slope_gradient: '',
      seasonal_accessibility: '',
      seasonal_accessibility_months: {
        from: '',
        to: '',
      },
      current_field_marking: '',
      current_field_marking_other: '',
    },
    next_landrelease_activity: {
      priority: 'none',
      next_most_appropriate_operation: [],
      reasons_and_comments_on_71_72: '',
      reasons_and_comments_on_71_72_extended: '',
      recommended_clearance_depth: '',
      recommended_clearance_depth_description: '',
      additional_comments_on_hazard_area: '',
      additional_comments_on_hazard_area_extended: '',
    },
    landuse_and_beneficiaries: {
      residental_areas: 0,
      social_services: 0,
      infrastructure_industrial_objects: 0,
      roads_railways_bridges: 0,
      agricultural: 0,
      natural_resources: 0,
      number_of_direct_beneficiaries: {
        men: 0,
        women: 0,
        boys: 0,
        girls: 0,
      },
      number_of_indirect_beneficiaries: {
        men: 0,
        women: 0,
        boys: 0,
        girls: 0,
      },
    },
    informators: [],
    turn_points: {
      points: [],
      magnetic_declination: 0,
      total_area_meters: 0,
      geojson: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [],
            },
          },
        ],
      },
    },
  },
};

export const NTSFormData = {
  id: 0,
  user: null,
  draft: true,
  send_to_imsma: true,
  info: {
    general: {
      imsma_id: '',
      report_date: moment(new Date()).format('DD.MM.YYYY'),
      reporter_organization: 'FSD',
      reporter_name: '',
      reporter_occupation: '',
      non_tech_examination_date: moment(new Date()).format('DD.MM.YYYY'),
      name: '',
      hazard_name: '',
    },
    location: {
      region: '',
      district: '',
      local_community: '',
      settlement_name: '',
      description: '',
      settlement_code: '',
    },
    nto_result: {
      village_by_village: false,
      nto_reason: 'planned',
      evidences: false,
      single_ammo_report_needed: false,
      new_hazard_area_report_needed: false,
      comment: '',
    },
    point: {
      type: 'Point',
      coordinates: [],
    },
  },
};

export const EOREFormData = {
  info: {
    general: {
      report_date: moment(new Date()).format('DD.MM.YYYY'),
      reporter_position: '',
      reporter_organization: 'FSD',
      reporter_name: '',
      custom_internal_id: '',
      internal_id: '',
      imsma_id: '',
      team_id: '',
    },
    session_information: {
      report_date: moment(new Date()).format('DD.MM.YYYY'),
      region: '',
      district: '',
      local_community: '',
      settlement_name: '',
      settlement_code: '',
      eore_date: moment(new Date()).format('DD.MM.YYYY'),
      eore_type_imsma: 'face_to_face',
      eore_type_imsma_other: '',
      eore_type_internal: 'online',
      eore_type_internal_other: '',
      place_type_internal: {
        value: 'admin_day',
        state_institution: '',
        education_facility: '',
        critical_infrastructure: '',
        place_type_other: '',
      },
      place_type_imsma: {
        value: 'educational_facility',
        place_type_other: '',
      },
      number_of_session: null,
      description_of_place_type: '',
      direct_beneficiaries: {
        female_adult_direct_count: 0,
        male_adult_direct_count: 0,
        female_child_direct_count: 0,
        male_child_direct_count: 0,
        handouts: [],
      },
      indirect_beneficiaries: {
        female_adult_direct_additional_count: 0,
        male_adult_direct_additional_count: 0,
        female_child_direct_additional_count: 0,
        male_child_direct_additional_count: 0,
        handouts: [],
      },
      contact_person: {
        name: '',
        phone_number: '',
      },
      point: {
        type: 'Point',
        coordinates: [],
      },
    },
    final_indicators: {
      percentage_before_session: 0,
      percentage_after_session: 0,
    },
    general_photos: [],
    beneficiaries: {
      files: [],
    },
  },
  send_to_imsma: false,
  draft: false,
  donor: null,
  is_internal_id_editable: true,
};
