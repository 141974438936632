import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const ReporterOccupationView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  return (
    <AccordionBodyItemView
      accordionSubId="1.8"
      titleUA="Посада особи, що звітує"
      titleEN="Reporter position"
      children={(
        <span className="no-wrap">
          {translateState && translatedFormData?.reporter_position}
          {!translateState && formData.info?.general?.reporter_position || formData.info?.general?.reporter_occupation}
        </span>
      )}
    />
  );
};
