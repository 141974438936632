import { AccordionBodyItem } from 'component/Accordion';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';
import React from 'react';
import { useUser } from 'routes/UserContext';

export const SessionDate = (props) => {
  const { formData } = props;
  const { user } = useUser();

  const date = formData?.info?.session_information?.report_date;

  const updateSessionDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          report_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.6</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата проведення"
      titleEN="The date of the event"
      children={(
        user?.permmisions?.change_eore_report_date ? (
          <DatePickerWrapper
            value={date ? moment(date, 'DD.MM.YYYY') : null}
            handleDatePicker={updateSessionDate}
          />
        ) : date
      )}
    />
  );
};
