import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const PriorityLevelView = (props) => {
  const { formData } = props;

  const priorityOptions = {
    high: 'Високий \\ High',
    medium: 'Середній \\ Medium',
    low: 'Низький \\ Low',
    none: 'Відсутній \\ None',
  };
  const selectedPriority = priorityOptions[formData.info.next_landrelease_activity.priority];

  return (
    <AccordionBodyItem
      accordionSubId="7.1"
      titleUA="Рівень пріоритету"
      titleEN="Priority level"
      rightContentClassName="w-80"
      children={(
        <div className="flex-group">
          <CheckBoxIcon style={{ width: '20px', height: '20px' }} />
          {selectedPriority}
        </div>
      )}
    />
  );
};
