import {
  React,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from './Cookies';

const DeepLContext = createContext();

export const DeepLProvider = ({ children }) => {
  const [token, setDeepLToken] = useState(null);

  const getDeepLToken = () => {
    if (!getCookie('access_token')) return;
    axios.get('/core/deepl-token/')
      .then((res) => {
        setDeepLToken(res.data.deepl_token);
      })
      .catch((err) => {
        toast.error('Error DeepL token');
        console.log(err);
        setDeepLToken(null);
      });
  };

  useEffect(() => {
    getDeepLToken();
  }, []);

  const deepLValue = useMemo(() => ({
    token, getDeepLToken, setDeepLToken,
  }), [token, getDeepLToken, setDeepLToken]);

  return (
    <DeepLContext.Provider value={deepLValue}>
      {children}
    </DeepLContext.Provider>
  );
};

export const useDeepL = () => useContext(DeepLContext);
