import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const ExaminationDateView = (props) => {
  const { formData } = props;
  return (
    <AccordionBodyItemView
      accordionSubId="1.9"
      titleUA="Дата проведення нетехнічного обстеження"
      titleEN="Report date"
      children={`
                ${formData.info?.general?.examination_date?.from} -
                ${formData.info?.general?.examination_date?.to}
            `}
    />
  );
};
