import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';

export const ReportDate = (props) => {
  const { formData } = props;

  const date = formData?.info?.general?.report_date;

  const updateReportDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          report_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата звіту"
      titleEN="Report date"
      className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <DatePickerWrapper
          value={date ? moment(date, 'DD.MM.YYYY') : null}
          handleDatePicker={updateReportDate}
        />
      )}
    />
  );
};

export const ReportDateString = (props) => {
  const { formData } = props;

  const date = formData?.info?.general?.report_date;

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата звіту"
      titleEN="Report date"
      className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <div>
          {date}
        </div>
      )}
    />
  );
}