import React from 'react';
import { AccordionBodyItemView2 } from 'component/Accordion';

export const ClearanceDepthView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const landReleaseActivity = formData.info.next_landrelease_activity;
  return (
    <AccordionBodyItemView2
      accordionSubId="7.4"
      titleUA="Рекомендована глибина розмінування (см):"
      titleEN="Recommended clearance depth, sm"
      children={(
        <div className="flex-column-group flex-wrap">
          <span>
            {translateState && translatedFormData.recommended_clearance_depth}
            {!translateState && landReleaseActivity.recommended_clearance_depth}
          </span>
          <span>
            {translateState && translatedFormData.recommended_clearance_depth_description}
            {!translateState && landReleaseActivity.recommended_clearance_depth_description}
          </span>
        </div>
      )}
    />
  );
};
