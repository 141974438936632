import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const VegetationView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const geographic = formData.info.geographic_and_terrain_description;

  const vegetationOptions = {
    grass: 'Трава \\ Grass',
    bulrush: 'Очерет \\ Bulrush',
    bushes: 'Кущі \\ Bushes',
    none: 'Відсутня \\ None',
    unknown: 'Невідомо \\ Unknown',
    trees: 'Дерева \\ Trees',
    other: 'Інше \\ Other',
  };

  const selectedVegetationWithBackslashes = Object.keys(vegetationOptions)
    .filter((option) => geographic.vegetation.includes(option))
    .map((option) => vegetationOptions[option]);

  return (
    <AccordionBodyItem
      accordionSubId="6.1"
      titleUA="Рослинність"
      titleEN="Vegetation"
      rightContentClassName="w-60"
      children={(
        <div className="flex-column-group align-left">
          {selectedVegetationWithBackslashes.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon
                style={{ width: '20px', height: '20px' }}
              />
              {item}
            </div>
          ))}
          {geographic.vegetation_other && (
          <div>
            {translateState && translatedFormData.vegetation_other}
            {!translateState && geographic.vegetation_other}
          </div>
          )}
        </div>
      )}
    />
  );
};
