import React from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/User.svg';

export const UserMiddleName = (props) => {
  const handleMiddleName = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      middle_name: value,
    }));
  };

  return (
    <InputComponent
      type="text"
      upperRightText="Middle name"
      id="user-middle-name"
      name="user-middle-name"
      placeholder="John"
      value={props.middlename}
      handleInput={handleMiddleName}
      iconLeft={<PeopleSvg />}
      autoComplete="off"
    />
  );
};

export const MiddleNameView = (props) => (
  <div className="double-text">
    <span>Middle name</span>
    <span>{props.middlename || '-'}</span>
  </div>
);
