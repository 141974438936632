import { AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const SurveyAfterSessionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={(
        <div>3.2</div>
        )}
      titleUA="Опитування ПІСЛЯ сесії"
      titleEN="Survey after the session"
      children={`${formData?.info?.final_indicators?.percentage_after_session} %`}
    />
  );
};
