import { AccordionBodyItem } from 'component/Accordion';
import React from 'react';

export const IMSMAID = (props) => {
  const { formData } = props;
  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.2</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Ідентифікаційний номер IMSMA"
      titleEN="IMSMA identification number"
      children={formData.info.general.imsma_id
        || (
          <span>
            Ідентифікаційний номер звіту генерується автоматично
            <br />
            The report identification number is generated automatically
          </span>
        )}
    />
  );
};
