import React from 'react';
import 'assets/styles/uploadButton.scss';
import { ReactComponent as UploadIcon } from 'assets/images/icons/Download.svg';

export const UploadButton = (props) => (
  <div
    id={`${props.id}-upload-btn`}
    className={`custom-input ${props.label ? "without-bg" : ""}`}
  >
    <label
      htmlFor={`${props.id}-upload`}
      className="flex-center align-center"
    >
      {!props.label && (
        <>
          <UploadIcon />
          <span>Upload</span>
        </>
      )}
      {props.label && (
        <>{props.label}</>
      )}
      <input
        type="file"
        id={`${props.id}-upload`}
        className="hidden"
        onChange={props.handleUploadButton}
        multiple
        accept={props.accept}
      />
    </label>
  </div>
);
