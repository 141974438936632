import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { alphaVal } from 'utils/Helper';
import { Name } from './Name';
import { Position } from './Position';
import { Age } from './Age';
import { Sex } from './Sex';
import { PhoneNumber } from './Phone';
import { Address } from './Address';

export const Informator = (props) => {
  const subId = props.informatorId === 0
    ? props.informatorId
    : (props.informatorId + 5) * props.informatorId;

  const handleInformator = (key, value) => {
    props.setFormData((prevFormData) => {
      const updatedInformators = prevFormData.info.informators
        .map((item, i) => (i === props.informatorId ? { ...item, [key]: value } : item));

      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          informators: updatedInformators,
        },
      };
    });
  };
  return (
    <>
      <AccordionBodyItem
        titleUA={`Інформатор ${alphaVal(props.informatorId + 1)}`}
        titleEN={`Informant ${alphaVal(props.informatorId + 1)}`}
      />
      <Name
        id={subId + 1}
        formData={props.formData}
        setFormData={props.setFormData}
        index={props.informatorId}
        handleInformator={handleInformator}
        errors={props.errors}
      />
      <Position
        id={subId + 2}
        formData={props.formData}
        setFormData={props.setFormData}
        index={props.informatorId}
        handleInformator={handleInformator}
      />
      <Age
        id={subId + 3}
        formData={props.formData}
        setFormData={props.setFormData}
        index={props.informatorId}
        handleInformator={handleInformator}
      />
      <Sex
        id={subId + 4}
        formData={props.formData}
        setFormData={props.setFormData}
        index={props.informatorId}
        handleInformator={handleInformator}
      />
      <PhoneNumber
        id={subId + 5}
        formData={props.formData}
        setFormData={props.setFormData}
        index={props.informatorId}
        handleInformator={handleInformator}
      />
      <Address
        id={subId + 6}
        formData={props.formData}
        setFormData={props.setFormData}
        index={props.informatorId}
        handleInformator={handleInformator}
      />
    </>
  );
};
