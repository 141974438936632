import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { ImageGalleryBlock } from '../blocks/ImageGallery';
import { AnnexMap } from '../blocks/AnnexMaps';

export const PhotosTab = (props) => {
  const { formData } = props;
  const { setFormData } = props;

  return (
    <div className="form-wrapper">
      <div className="header">
        <span>Фото</span>
        <span className="line" />
        <span>Photos</span>
      </div>
      <div className="text error">*Рекомендуємо проставляти порядок фотографій після завантаження усіх.</div>
      <div className="text error">*We recommend that you put the order of the photos after uploading them all.</div>
      <div className="photo-cards flex-group flex-center">
        <ImageGalleryBlock
          formData={formData}
          photos={props.photos}
          setPhotos={props.setPhotos}
          loader={props.loader}
          setLoader={props.setLoader}
          setLoaderPhotos={props.setLoaderPhotos}
        />
      </div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['map', 'annexMaps']}
        className="custom-accordion"
      >
        <AccordionItem uuid="map">
          {/* <MapInfo
                        formData={formData}
                    /> */}
        </AccordionItem>
        <AccordionItem uuid="annexMaps">
          <AnnexMap
            formData={formData}
            setFormData={setFormData}
            annexImages={props.annexImages}
            setAnnexImages={props.setAnnexImages}
            errors={props.errors}
            setLoaderPhotos={props.setLoaderPhotos}
          />
        </AccordionItem>
      </Accordion>
    </div>

  );
};
