import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const ClosestSettlementView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const closestSettlementNameObj = formData.info.location.nearest_settlement_name.matches;

  return (
    <AccordionBodyItemView
      accordionSubId="2.6"
      titleUA="Найближчий населений пункт"
      titleEN="Closest settlement"
      children={closestSettlementNameObj
        ? <div>Співпадає з 2.5 \ Matches 2.5</div>
        : (
          <div>
            <div>
              Не співпадає з 2.5 (вказати нижче ТГ, населений пункт) \
              Doesn’t match 2.5 (specify below council, settlement)
            </div>
            {formData.info.location.nearest_settlement_name.settlement_name
            && (
            <div>
              {translateState && translatedFormData?.nearest_settlement_name}
              {!translateState && formData.info.location.nearest_settlement_name.settlement_name}
            </div>
            )}
          </div>
        )}
    />
  );
};
