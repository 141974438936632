import { React, useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/icons/People.svg';
import { ReactComponent as ShedulleSvg } from 'assets/images/icons/Shedulle.svg';
import { InputComponent } from 'component/Input';
import { SelectComponent } from 'component/Select';
import { DatePickerWrapper } from 'component/DataPicker';
import { axiosWrapper, downloadXlsx } from 'utils/Helper';
import { getTemplatesList, setNewTemplateFiltered } from 'api/TemplatesApi';
import { getTeamIds } from 'api/TeamApi';

export const NewExport = (props) => {
  const [open, setOpen] = useState(false);
  const [exportTemplate, setExportTemplate] = useState(null);
  const [exportName, setExportName] = useState(null);
  const [exportTemplateOptions, setExportTemplateOptions] = useState([]);
  const [teams, setTeams] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setExportTemplate(null);
    setExportName(null);
    setTeams(null);
    setDateFrom(null);
    setDateTo(null);
  };

  const getTemplatesOptions = () => {
    axiosWrapper(() => {
      getTemplatesList()
        .then((res) => {
          setExportTemplateOptions(res.data.map((template) => ({
            value: template.id, label: template.name,
          })));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const getTeamOptions = () => {
    axiosWrapper(() => {
      getTeamIds()
        .then((res) => {
          setTeamOptions(res.data.map((team) => ({
            value: team, label: team,
          })));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const uploadNewExport = () => {
    if (!exportName) {
      toast.warning('You need to set report name');
      return;
    }
    if (!exportTemplate?.value) {
      toast.warning('You need to set export template');
      return;
    }

    props.setLoader(true);
    axiosWrapper(() => {
      const body = {
        filters: {
          team_id: teams?.map((team) => team.value) || [],
          date_from: dateFrom,
          date_to: dateTo,
        },
        name: exportName,
        export_template: exportTemplate?.value,

      };
      setNewTemplateFiltered(body)
        .then((res) => {
          if (res.status === 201) {
            handleClose();
            toast.success('Success.');
            props.setLoader(false);
            props.getReports(1);
            downloadXlsx(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
          handleClose();
          props.setLoader(false);
          toast.error('Error creating template.');
        });
    });
  };

  useEffect(() => {
    getTemplatesOptions();
    getTeamOptions();
  }, []);

  return (
    <div className="drone-new-btn">
      <ButtonComponent
        iconLeft={<DownloadSvg />}
        handleButton={handleClickOpen}
        label="Export report"
        className=""
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Export report</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>
          <div className="flex-column-group">
            <InputComponent
              id="export-report-name"
              name="export-report-name"
              iconLeft={<ShedulleSvg />}
              value={exportName}
              handleInput={setExportName}
              label="Export report name"
              classNameWrapper="align-left"
              placeholder="Enter report name"
            />
            <div className="label-input">
              <span>Template</span>
              <SelectComponent
                id="template"
                name="template"
                label="Template"
                className="full"
                placeholder={(
                  <div>
                    <ShedulleSvg />
                    <span>Select template for export</span>
                  </div>
                )}
                value={exportTemplate?.value
                  ? { value: exportTemplate.value, label: exportTemplate.label }
                  : null}
                handleSelect={(value, label) => {
                  setExportTemplate({ value, label });
                }}
                options={exportTemplateOptions}
                isMulti={false}
                isDisabled={false}
              />
            </div>
            <div className="label-input">
              <span>Team</span>
              <SelectComponent
                id="team"
                name="team"
                label="Team"
                className="full"
                placeholder={(
                  <div>
                    <PeopleIcon />
                    <span>Select team</span>
                  </div>
                )}
                value={teams}
                handleSelect={setTeams}
                options={teamOptions}
                isMulti
                isDisabled={false}
              />
            </div>
            <div className="label-input">
              <span>Period from</span>
              <DatePickerWrapper
                value={dateFrom ? moment(dateFrom, 'DD.MM.YYYY') : null}
                handleDatePicker={(value) => (value
                  ? setDateFrom(moment(value, 'DD.MM.YYYY').format('L'))
                  : setDateFrom(null))}
                disableDateAfter={dateTo ? dateTo : null}
                className="full"
              />
            </div>
            <div className="label-input">
              <span>Period to</span>
              <DatePickerWrapper
                value={dateTo ? moment(dateTo, 'DD.MM.YYYY') : null}
                handleDatePicker={(value) => (value
                  ? setDateTo(moment(value, 'DD.MM.YYYY').format('L'))
                  : setDateTo(null))}
                disableDateBefore={dateFrom ? dateFrom : null}
                className="full"
              />
            </div>
          </div>
          <div className="buttons-group">
            <ButtonComponent
              className="danger"
              iconLeft={<CancelSvg />}
              handleButton={() => {
                handleClose();
              }}
              label="Cancel"
            />
            <ButtonComponent
              iconLeft={<DownloadSvg />}
              handleButton={uploadNewExport}
              label="Export"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
