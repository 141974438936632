import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const LocalityView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.4"
      titleUA="Населений пункт"
      titleEN="Locality"
      children={formData.info.location?.settlement_name}
    />
  );
};
