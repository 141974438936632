import { InputComponent } from 'component/Input';
import React from 'react';

export const ContactPerson = (props) => {
  const { formData } = props;

  const contactPersonName = formData.info.session_information.contact_person.name;
  const contactPersonPhone = formData.info.session_information.contact_person.phone_number;

  const handleContactPerson = (value, key) => {
    if (key === 'phone_number') {
      if (!/^\+?[0-9]*$/.test(value)) return;
      if (value && !value.startsWith('+')) {
        value = `+${value}`;
      }
    }

    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          contact_person: {
            ...prevFormData.info.session_information.contact_person,
            [key]: value,
          },
        },
      },
    }));
  };

  return (
    <div className="contact-person-block">
      <div className="flex-column-group">
        <div className="flex-group">
          <div className="double-text">
            <span>Контактна особа</span>
            <span>Contact person</span>
          </div>
          <InputComponent
            id="contact-person"
            placeholder="Contact person name"
            name="contact-person"
            value={contactPersonName}
            handleInput={(value) => handleContactPerson(value, 'name')}
          />
        </div>
        <div className="flex-group">
          <div className="double-text">
            <span>Номер телефону</span>
            <span>Phone number</span>
          </div>
          <InputComponent
            id="contact-phone"
            placeholder="Phone number"
            name="contact-phone"
            value={contactPersonPhone}
            handleInput={(value) => handleContactPerson(value, 'phone_number')}
          />
        </div>
      </div>
    </div>
  );
};
