import { AccordionHeaderTitle } from 'component/Accordion';
import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { SurveyBeforeSessionView } from './SurveyBeforeSession';
import { SurveyAfterSessionView } from './SurveyAfterSession';

export const FinalIndicatorsView = (props) => {
  const { formData } = props;

  return (
    <AccordionItem uuid="finalIndicators">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            block="finalIndicators"
            titleUA={(
              <div className="flex-group">
                <span>Фінальні показники</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="Final indicators"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <SurveyBeforeSessionView
          formData={formData}
        />
        <SurveyAfterSessionView
          formData={formData}
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
