import { React, useState, useEffect } from 'react';
import { Loader } from 'component/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { Tab } from 'component/Tab';
import { getEoreReports, getEoreTemplates } from 'api/EOREApi';
import { Link } from 'react-router-dom';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { useUser } from 'routes/UserContext';
import { getPageValue } from 'utils/Helper';
import { NewEORETemplate } from './Templates/Create';
import { EORETable } from './Templates/List';
import { EoreDatapickers, EoreFilter } from './Filter';
import { EOREReportsTable } from './Reports/List';
import 'assets/styles/pages/eore.scss';

export const EOREPage = () => {
  const [loader, setLoader] = useState(true);
  const [dateFromTemplates, setDateFromTemplates] = useState(null);
  const [dateToTemplates, setDateToTemplates] = useState(null);
  const [templatesPageList, setTemplatesPageList] = useState([]);
  const [templatesPageCount, setTemplatesPageCount] = useState(1);
  const [nextTemplates, setNextTemplates] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // 0 - Templates, 1 - Reports

  const [dateFromReports, setDateFromReports] = useState(null);
  const [dateToReports, setDateToReports] = useState(null);
  const [reportsPageList, setReportsPageList] = useState([]);
  const [reportsPageCount, setReportsPageCount] = useState(1);
  const [nextReports, setNextReports] = useState(false);
  const { user } = useUser();

  const getTemplatesPermissions = () => user?.permissions?.eore_templates;

  const getReportsPermissions = () => user?.permissions?.create_eore_reports
    || user?.permissions?.view_eore_report
    || user?.permissions?.change_eore_report
    || user?.permissions?.delete_eore_report
    || user?.permissions?.validate_eore_report;

  const getEoreTepmlatesList = (pageAfterCreate = false) => {
    setLoader(true);
    const page = pageAfterCreate ? pageAfterCreate : templatesPageCount;
    getEoreTemplates(page, dateFromTemplates, dateToTemplates)
      .then((response) => {
        if (pageAfterCreate) {
          setTemplatesPageList(response.data.results);
        } else {
          setTemplatesPageList([...templatesPageList, ...response.data.results]);
        }
        setNextTemplates(response.data.next);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        toast.error('Error loading templates');
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getEoreReportsList = (pageAfterCreate = false) => {
    setLoader(true);
    const page = pageAfterCreate ? pageAfterCreate : reportsPageCount;
    getEoreReports(page, dateFromReports, dateToReports)
      .then((response) => {
        if (pageAfterCreate) {
          setReportsPageList(response.data.results);
        } else {
          setReportsPageList([...reportsPageList, ...response.data.results]);
        }
        setNextReports(response.data.next);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        toast.error('Error loading reports');
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    document.title = 'EORE';
    if (getTemplatesPermissions()) {
      getEoreTepmlatesList();
      setActiveTabIndex(0);
    }
    if (getReportsPermissions()) {
      getEoreReportsList();
      setActiveTabIndex(1);
    }
  }, [user]);

  useEffect(() => {
    if (getTemplatesPermissions()) {
      getEoreTepmlatesList(1);
    }
  }, [dateFromTemplates, dateToTemplates]);

  useEffect(() => {
    if (getReportsPermissions()) {
      getEoreReportsList(1);
    }
  }, [dateFromReports, dateToReports]);

  useEffect(() => {
    if (getReportsPermissions()) {
      getEoreReportsList();
    }
  }, [reportsPageCount]);

  useEffect(() => {
    if (getTemplatesPermissions()) {
      getEoreTepmlatesList();
    }
  }, [templatesPageCount]);

  return (
    <section>
      <div className="drone-header">
        <div className="title">
          EORE Reports
        </div>
        <div className="header-datepicker">
          {/* <EoreFilter /> */}
          <span>Date:</span>
          {(activeTabIndex === 0 && getTemplatesPermissions())
            && (
              <EoreDatapickers
                dateFrom={dateFromTemplates}
                setDateFrom={setDateFromTemplates}
                dateTo={dateToTemplates}
                setDateTo={setDateToTemplates}
              />
            )}
          {(activeTabIndex === 1 && getReportsPermissions())
            && (
              <EoreDatapickers
                dateFrom={dateFromReports}
                setDateFrom={setDateFromReports}
                dateTo={dateToReports}
                setDateTo={setDateToReports}
              />
            )}
        </div>
        <Tab>
          {getTemplatesPermissions() && (
            <div
              className={`tab-item-custom ${activeTabIndex === 0 ? 'active' : ''}`}
              onClick={() => setActiveTabIndex(0)}
            >
              Templates
            </div>
          )}
          {getReportsPermissions()
            && (
              <div
                className={`tab-item-custom ${activeTabIndex === 1 ? 'active' : ''}`}
                onClick={() => setActiveTabIndex(1)}
              >
                Reports
              </div>
            )}
        </Tab>
      </div>
      {activeTabIndex === 0 && (
        <>
          <EORETable
            templatesData={templatesPageList}
            setTemplatesPageList={setTemplatesPageList}
            next={nextTemplates}
            templatesPageCount={templatesPageCount}
            setTemplatesPageCount={setTemplatesPageCount}
            setLoader={setLoader}
          />
          <div className="drone-footer">
            <NewEORETemplate
              setLoader={setLoader}
              getEoreTepmlatesList={getEoreTepmlatesList}
            />
          </div>
        </>
      )}
      {activeTabIndex === 1 && (
        <>
          <EOREReportsTable
            reportsData={reportsPageList}
            next={nextReports}
            reportsPageCount={reportsPageCount}
            setReportsPageCount={setReportsPageCount}
            setLoader={setLoader}
            setReportsPageList={setReportsPageList}
          />
          <div className="drone-footer">
            <Link to="/reports/eore/create">
              <ButtonComponent
                iconLeft={<AddSvg />}
                className="primary"
                handleButton={() => { }}
                label="Create report"
              />
            </Link>
          </div>
        </>
      )}
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
