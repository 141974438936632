import React from 'react';
import 'assets/styles/infoItem.scss';

export const InfoItem = (props) => {
  const {
    titleUA,
    titleEN,
    data,
    errors,
  } = props;

  return (
    <div className={`info-item ${errors?.landuse_and_beneficiaries?.number_of_direct_beneficiaries ? 'error' : ''}`}>
      <div className="titles">
        <span>{titleUA}</span>
        <span>{titleEN}</span>
      </div>
      <div className="info-data">{data}</div>
    </div>
  );
};
