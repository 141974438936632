import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { Loader } from 'component/Loader';
import { toast, ToastContainer } from 'react-toastify';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';

import { useNavigate, useParams } from 'react-router-dom';
import { getEoreReportById, getHandouts } from 'api/EOREApi';
import { EOREFormData } from 'consts/Forms';
import { ButtonComponent } from 'component/Button';
import axios from 'axios';
import { axiosWrapper } from 'utils/Helper';
import { InputComponent } from 'component/Input';
import { FinalIndicatorsView } from './Blocks/View/FinalIndicators/FinalIndicators';
import { SessionInfo } from './Blocks/View/SessionInfo/SessionInfo';
import { PageTitle } from './Create';
import { getAttachments, getEorePhotos } from './Blocks/PhotosBlock';
import GeneralInfo from './Blocks/View/GeneralInfo/GeneralInfo';
import PhotosBlockView from './Blocks/View/PhotosBlockView/PhotosBlockView';
import AttachmentsBlockView from './Blocks/View/AttachmentsBlockView/AttachmentsBlockView';

export const ValidationEOREReportPage = () => {
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState(EOREFormData);
  const [templateVersion, setTemplateVersion] = useState('');
  const [handouts, setHandouts] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [zone, setZone] = useState('');
  const { reportId } = useParams();
  const [commentForm, setCommentForm] = useState({
    general: '',
    session_information: '',
    final_indicators: '',
  });

  const [savedComment, setSavedComment] = useState({
    general: commentForm.general,
    session_information: commentForm.session_information,
    final_indicators: commentForm.final_indicators,
  });
  const [comments, setComments] = useState([]);
  const [commentEntered, setCommentEntered] = useState({
    general: false,
    session_information: false,
    final_indicators: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    getEoreReportById(reportId)
      .then((res) => {
        setFormData(res.data);
        getEorePhotos(res.data.id, res.data.info.general_photos, setPhotos);
        getAttachments(res.data, setAttachments);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setTemplateVersion(formData.template_version);
  }, [formData.template_version]);

  const handleComments = (block, value) => {
    setCommentForm((prevForm) => ({
      ...prevForm,
      [block]: value,
    }));
  };

  const getAllComments = () => {
    axiosWrapper(() => {
      axios.get(`/validations/eore/${reportId}`).then((data) => {
        const allComments = data.data.iterations;
        setComments(allComments);
      });
    });
  };

  useEffect(() => {
    getAllComments();
    getHandouts()
      .then((res) => {
        setHandouts(res.data?.map((handout) => ({
          handout_id: handout.id,
          name: handout.name,
          count: 0,
        })));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const filteredGeneralComments = comments
    .filter((item) => {
      const commentText = item?.comments?.general;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredSessionInformationComments = comments
    .filter((item) => {
      const commentText = item?.comments?.session_information;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredFinalIndicatorsComments = comments
    .filter((item) => {
      const commentText = item?.comments?.final_indicators;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const handleValidationAcceptButton = (status, event) => {
    setCommentForm({
      general: '',
      session_information: '',
      final_indicators: '',
    });
    event.preventDefault();
    setLoader(true);
    axiosWrapper(() => {
      axios
        .put(`/validations/eore/${reportId}/`, {
          status,
          comments: savedComment,
        })
        .then(() => {
          toast.success('Accepted');
          setLoader(false);
          setTimeout(() => {
            navigate('/reports/eore/');
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Error').then(() => toast.success('Please try again'));
          }
          toast.error('Error accepting EORE report. Please try again.');
          setLoader(false);
        });
    });
  };

  const handleValidationRejectButton = (status, event) => {
    setLoader(true);
    event.preventDefault();
    axiosWrapper(() => {
      axios
        .put(`/validations/eore/${reportId}/`, {
          status,
          comments: savedComment,
        })
        .then(() => {
          toast.success('Rejected');
          setLoader(false);
          setTimeout(() => {
            navigate('/reports/eore/');
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Error').then(() => toast.success('Please try again'));
          }
          toast.error('Error rejected EORE report. Please try again.');
          setLoader(false);
        });
    });
  };

  const onSaveComment = (event, block) => {
    setSavedComment((prevComment) => ({
      ...prevComment,
      [block]: commentForm[block],
    }));

    setCommentForm((prevForm) => ({
      ...prevForm,
      [block]: '',
    }));
    setCommentEntered((prevState) => ({
      ...prevState,
      [block]: true,
    }));
  };

  const onDeleteComment = (event, block) => {
    event.preventDefault();

    const confirmDelete = window.confirm(
      'Are you sure you want to delete this comment?',
    );

    if (confirmDelete) {
      setSavedComment((prevComment) => ({
        ...prevComment,
        [block]: '',
      }));
    }
    setCommentEntered((prevState) => ({
      ...prevState,
      [block]: false,
    }));
  };

  return (
    <section>
      <PageTitle
        title="Validation EORE report"
        formData={formData}
        templateVersion={templateVersion}
        setLoader={setLoader}
      />
      <div className="info_block_header">
        <div className="info_block_header_left">Атрибути</div>
        <span className="info_block_header_line" />
        <div className="info_block_header_right">Attributes</div>
      </div>
      {/* <TranslateWrapperComponent
      formData={formData}
    // setTranslatedFormData={setTranslatedFormData}
    // translationState={translateState}
    > */}
      <div className="form-wrapper">
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={[
            'general',
            'sessionInformation',
            'finalIndicators',
          ]}
          className="custom-accordion"
        >
          <GeneralInfo
            formData={formData}
          />
          <>
            {filteredGeneralComments?.map((item, index) => {
              const isLastComment = index === 0;
              const commentClassName = isLastComment
                ? 'block-with-comment-green'
                : 'block-with-comment-gray';

              return (
                <div key={index} className={commentClassName}>
                  <div className="icon">
                    <ChatIcon
                      style={{
                        width: '20px',
                        color: '#868686',
                      }}
                    />
                  </div>
                  {item?.comments?.general}
                </div>
              );
            })}
            <div className="comment-view-block">
              {savedComment?.general && (
                <div className="block-with-comment">
                  <div className="icon-with-comment">
                    <ChatIcon style={{ width: '20px' }} />
                    <div className="comment">
                      {savedComment?.general}
                    </div>
                  </div>
                  <div className="comment-buttons">
                    <DeleteIcon
                      className="delete-comment-button"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => onDeleteComment(e, 'general')}
                    />
                  </div>
                </div>
              )}

              {!commentEntered.general && (
                <div className="input-with-button">
                  <InputComponent
                    classNameWrapper="w-100"
                    style={{ width: '-webkit-fill-available' }}
                    className="input-view"
                    handleInput={(value) => handleComments('general', value)}
                    onBlur={(e) => onSaveComment(e, 'general')}
                    value={commentForm?.general}
                    placeholder="Add comment"
                  />
                </div>
              )}
            </div>
          </>
          <SessionInfo
            formData={formData}
            handouts={handouts}
            zone={zone}
            setZone={setZone}
          />
          <>
            {filteredSessionInformationComments?.map((item, index) => {
              const isLastComment = index === 0;
              const commentClassName = isLastComment
                ? 'block-with-comment-green'
                : 'block-with-comment-gray';

              return (
                <div key={index} className={commentClassName}>
                  <div className="icon">
                    <ChatIcon
                      style={{
                        width: '20px',
                        color: '#868686',
                      }}
                    />
                  </div>
                  {item?.comments?.session_information}
                </div>
              );
            })}
            <div className="comment-view-block">
              {savedComment?.session_information && (
                <div className="block-with-comment">
                  <div className="icon-with-comment">
                    <ChatIcon style={{ width: '20px' }} />
                    <div className="comment">
                      {savedComment?.session_information}
                    </div>
                  </div>
                  <div className="comment-buttons">
                    <DeleteIcon
                      className="delete-comment-button"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => onDeleteComment(e, 'session_information')}
                    />
                  </div>
                </div>
              )}

              {!commentEntered?.session_information && (
                <div className="input-with-button">
                  <InputComponent
                    classNameWrapper="w-100"
                    style={{ width: '-webkit-fill-available' }}
                    className="input-view"
                    handleInput={(value) => handleComments('session_information', value)}
                    onBlur={(e) => onSaveComment(e, 'session_information')}
                    value={commentForm?.session_information}
                    placeholder="Add comment"
                  />
                </div>
              )}
            </div>
          </>
          <FinalIndicatorsView
            formData={formData}
          />
          <>
            {filteredFinalIndicatorsComments?.map((item, index) => {
              const isLastComment = index === 0;
              const commentClassName = isLastComment
                ? 'block-with-comment-green'
                : 'block-with-comment-gray';

              return (
                <div key={index} className={commentClassName}>
                  <div className="icon">
                    <ChatIcon
                      style={{
                        width: '20px',
                        color: '#868686',
                      }}
                    />
                  </div>
                  {item?.comments?.final_indicators}
                </div>
              );
            })}
            <div className="comment-view-block">
              {savedComment?.final_indicators && (
                <div className="block-with-comment">
                  <div className="icon-with-comment">
                    <ChatIcon style={{ width: '20px' }} />
                    <div className="comment">
                      {savedComment?.final_indicators}
                    </div>
                  </div>
                  <div className="comment-buttons">
                    <DeleteIcon
                      className="delete-comment-button"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => onDeleteComment(e, 'final_indicators')}
                    />
                  </div>
                </div>
              )}

              {!commentEntered?.final_indicators && (
                <div className="input-with-button">
                  <InputComponent
                    classNameWrapper="w-100"
                    style={{ width: '-webkit-fill-available' }}
                    className="input-view"
                    handleInput={(value) => handleComments('final_indicators', value)}
                    onBlur={(e) => onSaveComment(e, 'final_indicators')}
                    value={commentForm?.final_indicators}
                    placeholder="Add comment"
                  />
                </div>
              )}
            </div>
          </>
          <PhotosBlockView
            formData={formData}
            photos={photos}
            setLoader={setLoader}
          />
          <AttachmentsBlockView
            attachments={attachments}
            setLoader={setLoader}
            formData={formData}
          />
          <div className="form-buttons-end buttons-group py-10">
            <ButtonComponent
              handleButton={(event) => handleValidationRejectButton('rejected', event)}
              label="Reject"
              disabled={false}
              className="danger"
              type="button"
            />
            <ButtonComponent
              handleButton={(event) => handleValidationAcceptButton('accepted', event)}
              label="Accept"
              disabled={false}
              type="button"
            />
          </div>
        </Accordion>
      </div>
      {/* </TranslateWrapperComponent> */}
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
