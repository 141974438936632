import { React, useState } from 'react';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete_24px.svg';
import { UploadButton } from './UploadButton';
import { ButtonComponent } from './Button';

export const FileUploadTiff = (props) => {
  const {
    tiff,
    setTiff,
    type,
    types,
  } = props;
  const [key, setKey] = useState(Date.now());

  const handleFileChange = (e) => {
    if (!e.target.files) {
      setTiff(null);
    } else {
      setTiff(e.target.files);
    }
  };

  return (
    <div className="flex-column-group justify-center align-center">
      {
        !tiff
        && (
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="20" fill="#F4F5F7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45 21.25V45C45 46.3261 44.4732 47.5978 43.5355 48.5355C42.5978 49.4732 41.3261 50 40 50H37.5V47.5H40C40.663 47.5 41.2989 47.2366 41.7678 46.7678C42.2366 46.2989 42.5 45.663 42.5 45V21.25H37.5C36.5054 21.25 35.5516 20.8549 34.8483 20.1517C34.1451 19.4484 33.75 18.4946 33.75 17.5V12.5H20C19.337 12.5 18.7011 12.7634 18.2322 13.2322C17.7634 13.7011 17.5 14.337 17.5 15V37.5H15V15C15 13.6739 15.5268 12.4021 16.4645 11.4645C17.4021 10.5268 18.6739 10 20 10H33.75L45 21.25ZM14.82 41.28V49.6225H12.835V41.28H10V39.625H17.66V41.28H14.82ZM20.1475 39.625V49.6225H18.1725V39.625H20.1475ZM23.58 49.6225V45.6475H27.595V44.0475H23.58V41.2575H27.975V39.625H21.6025V49.6225H23.58ZM30.75 45.6475V49.6225H28.7725V39.625H35.1425V41.2575H30.75V44.05H34.7625V45.645L30.75 45.6475Z"
              fill="#868686"
            />
          </svg>
        )
      }
      {
        tiff && (
          <div className="tiff-file">
            {[...tiff][0].name}
          </div>
        )
      }
      <div className="buttons-group">
        <UploadButton
          key={key}
          id={type}
          handleUploadButton={handleFileChange}
          accept={types}
        />
        {tiff && (
          <DeleteTiff
            handleDeleteButton={handleFileChange}
            setKey={setKey}
          />
        )}
      </div>

    </div>
  );
};

const DeleteTiff = (props) => {
  const {
    handleDeleteButton,
    setKey,
  } = props;
  return (
    <ButtonComponent
      className="danger"
      label={
        <DeleteSvg />
      }
      handleButton={(e) => {
        handleDeleteButton(e);
        setKey(Date.now());
      }}
    />
  );
};
