import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const DescriptionView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="3.9"
      titleUA="Опис типу забруднення (за потреби)"
      titleEN="Description of the type of contamination"
      children={(
        <>
          {translateState && translatedFormData.hazard_type_description}
          {!translateState && formData.info.hazard_type.description}
        </>
      )}
    />
  );
};
