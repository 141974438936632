import { AccordionHeaderTitle } from 'component/Accordion';
import React, { useEffect, useState } from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { LocationComponent } from 'pages/Reports/blocks/Location/Location';
import { SettlementId } from 'pages/Reports/blocks/Location/SettlementId';
import { NTSmap } from 'pages/Reports/blocks/NTSmap';
import { SessionDate } from './SessionDate';
import {
  CriticalInfrastructure,
  EducationFacility,
  PlaceTypeOfImsma,
  PlaceTypeOfInternal,
  StateInstitution,
} from './Place';
import { TypeOfSessionImsma, TypeOfSessionInternal } from './TypeOfSession';
import { DescriptionPlace } from './DescriptionPlace';
import { NumberOfSession } from './NumberOfSession';
import { Beneficiaries } from './Beneficiaries/Beneficiaries';
import { ContactPerson } from './ContactPerson';

export const SessionInfo = (props) => {
  const {
    formData,
    setFormData,
    handouts,
    errors,
    zone,
    setZone,
  } = props;
  const [pointExist, setPointExist] = useState(false);
  const [coords3857, setCoords3857] = useState([null, null]);
  const [map, setMap] = useState(null);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      point: formData.info.point,
    }));
  }, [formData.info.point]);

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            validationForm={props.validationForm}
            block="sessionInformation"
            titleUA={(
              <div className="flex-group">
                <span>Інформація щодо сесії</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="Information about the session"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <LocationComponent
          fieldBlock="session_information"
          formData={formData}
          setFormData={setFormData}
          setCoords3857={setCoords3857}
          setZone={setZone}
          pointExist={pointExist}
          setPointExist={setPointExist}
          errors={errors}
        />
        <SettlementId
          settlementId={props.formData.info.session_information.settlement_code}
        />
        <NTSmap
          map={map}
          setMap={setMap}
          formData={formData}
          setFormData={setFormData}
          coords3857={coords3857}
          setCoords3857={setCoords3857}
          zone={zone}
          errors={props.errors}
          mode="edit"
        />
        <SessionDate
          formData={formData}
          setFormData={setFormData}
          errors={props.errors}
        />
        <div className="grey-wrapper">
          <TypeOfSessionInternal
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <TypeOfSessionImsma
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <PlaceTypeOfInternal
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <StateInstitution
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <EducationFacility
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <CriticalInfrastructure
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <PlaceTypeOfImsma
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <DescriptionPlace
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <NumberOfSession
            formData={formData}
            setFormData={setFormData}
            errors={props.errors}
          />
          <Beneficiaries
            formData={formData}
            setFormData={setFormData}
            handouts={handouts}
          />
          <ContactPerson
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </AccordionItemPanel>
    </>
  );
};
