import React from 'react';
import { Oval } from 'react-loader-spinner';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/User.svg';
import { ReactComponent as ChangeIcon } from 'assets/images/icons/change.svg';

const AvatarExistsBlock = (props) => {
  const {
    handleUploadButton,
    avatarImageLoading,
    avatarImage,
    handleDeleteAvatar,
    id,
    accept,
  } = props;

  return (
    <div className="profile-with-avatar">
      {avatarImageLoading
        && (
          <div className="section-avatar">
            <Oval
              height={40}
              width={40}
              color="#00B1B7"
              secondaryColor="#00B1B7"
            />
          </div>
        )}
      {!avatarImageLoading && (
        <>
          <div className="section-avatar">
            <img src={avatarImage} alt="avatar" />
          </div>
          <div className="change-image-with-btn">
            <span
              className="text-hover no-wrap"
              onClick={() => {
                const fileInput = document.getElementById(
                  `${id}-upload`,
                );
                if (fileInput) {
                  fileInput.click();
                }
              }}
            >
              <ChangeIcon />
              Change image
            </span>
            <input
              type="file"
              id={`${id}-upload`}
              className="hidden"
              onChange={handleUploadButton}
              multiple
              accept={accept}
              style={{ display: 'none' }}
            />
            <ButtonComponent
              label={<DeleteIcon />}
              className="danger"
              handleButton={handleDeleteAvatar}
            />
          </div>
        </>
      )}
    </div>
  );
};

const AvatarDoesNotExistBlock = (props) => {
  const {
    handleUploadButton,
    id,
    avatarImageLoading,
    accept,
  } = props;

  return (
    <div className="profile profile-without-avatar">
      {avatarImageLoading && (
        <div className="section-avatar">
          <Oval
            height={40}
            width={40}
            color="#00B1B7"
            secondaryColor="#00B1B7"
          />
        </div>
      )}
      {!avatarImageLoading && (
        <>
          <UserIcon />
          <div id={`${id}-upload-btn`} className="custom-input">
            <label
              htmlFor={`${id}-upload`}
              className="flex-center"
            >
              <span>+ Add image</span>
              <input
                type="file"
                id={`${id}-upload`}
                className="hidden"
                onChange={handleUploadButton}
                multiple
                accept={accept}
              />
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export const Avatar = (props) => {
  const handleUploadButton = (e) => {
    const selectedFiles = e.target.files;
    props.setAvatarFile(selectedFiles[0]);
    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        props.setAvatarImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <p className="top-section-header text-center">Profile picture</p>
      {props.avatarImage
        ? (
          <AvatarExistsBlock
            handleUploadButton={handleUploadButton}
            avatarImageLoading={props.avatarImageLoading}
            avatarImage={props.avatarImage}
            handleDeleteAvatar={props.handleDeleteAvatar}
            id={props.id}
            accept={props.accept}
          />
        )
        : (
          <AvatarDoesNotExistBlock
            handleUploadButton={handleUploadButton}
            id={props.id}
            avatarImageLoading={props.avatarImageLoading}
            avatarImage={props.avatarImage}
            accept={props.accept}
          />
        )}
    </>
  );
};

export const AvatarView = (props) => (
  <div className="avatar-view">
    {props.avatarImage && <img src={props.avatarImage} alt="avatar" />}
    {!props.avatarImage && <UserIcon color="#898989" />}
  </div>
);
