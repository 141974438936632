import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const CommandId = (props) => {
  if (props.formType !== 'ha') return null;
  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.7</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Ідентифікаційний номер групи/команди НТО"
      titleEN="Team ID"
      children={(
        <span className="no-wrap">
          {props.formData.info.general.team_id || '-'}
        </span>
      )}
    />
  );
};
