import { AccordionBodyItem } from 'component/Accordion';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';
import { React, useState, useEffect } from 'react';

export const DateOfHA = (props) => {
  const [error, setError] = useState(null);

  const updateNonTechnicalReportDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          non_tech_examination_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  const updateHAReportDate = (value, type) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          examination_date: {
            ...prevFormData.info.general.examination_date,
            [type]: moment(value, 'DD.MM.YYYY').format('L'),
          },
        },
      },
    }));
  };

  const setErrorAccordingToFormType = () => {
    if (props.formType !== 'ha') {
      return props.errors?.general?.non_tech_examination_date ? 'error' : '';
    }
    if (props.formType === 'ha') {
      return props.errors?.general?.examination_date?.from
        || props.errors?.general?.examination_date?.to ? 'error' : '';
    }
    return null;
  };

  useEffect(() => {
    setError(setErrorAccordingToFormType());
  }, [props.errors]);

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{props.formType === 'ha' ? '1.9' : '1.6'}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата проведення нетехнічного обстеження"
      titleEN="Report date"
      className={error}
      children={props.formType === 'ha'
        ? (
          <div className="flex-group" style={{ width: '300px' }}>
            <DatePickerWrapper
              value={moment(props.formData.info.general.examination_date.from, 'DD.MM.YYYY')}
              handleDatePicker={(value) => updateHAReportDate(value, 'from')}
              disableDateAfter={props.formData?.info?.general?.examination_date?.to}
            />
            <DatePickerWrapper
              value={moment(props.formData.info.general.examination_date.to, 'DD.MM.YYYY')}
              handleDatePicker={(value) => updateHAReportDate(value, 'to')}
              disableDateAfter={props.formData.info.general?.report_date}
            />
          </div>
        )
        : (
          <DatePickerWrapper
            value={moment(props.formData.info.general.non_tech_examination_date, 'DD.MM.YYYY')}
            handleDatePicker={updateNonTechnicalReportDate}
          />
        )}
    />
  );
};
