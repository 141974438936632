import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const AbandonedExplosiveOrdnance = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.4"
      titleUA="Залишені ВНП (I категорія)"
      titleEN="Abandoned Explosive Ordnance"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="abandonedExplosiveOrdnance"
          name="abandoned_explosive_ordnance"
          value="abandoned_explosive_ordnance"
          checked={hazardType.abandoned_explosive_ordnance}
          handleInput={props.handleContamination}
          disable="false"
        />
      )}
    />
  );
};
