import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const ImprovisedExplosiveDevice = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.6"
      titleUA="Саморобні вибухові пристрої"
      titleEN="Improvised Explosive Device"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="improvisedExplosiveDevice"
          name="improvised_explosive_device"
          value="improvised_explosive_device"
          checked={hazardType.improvised_explosive_device}
          handleInput={props.handleContamination}
          disable="false"
        />
      )}
    />
  );
};
