/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import { Loader } from 'component/Loader';
import {
  React, useEffect, useState, useRef, useCallback,
} from 'react';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowSvg } from 'assets/images/icons/arrow_left.svg';
import { axiosWrapper } from 'utils/Helper';
import { URLS } from 'routes/Urls';
import axios from 'axios';

import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Accordion, AccordionItem } from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import { ButtonComponent } from 'component/Button';
import { HAformData, NTSFormData } from 'consts/Forms';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { useUser } from 'routes/UserContext';
import { NTOResult } from '../blocks/NTOResult';
import { NonTechnical } from '../blocks/Non-technical';
import { GeneralInfo } from '../blocks/GeneralInfo';

export const CreateNTSReportPage = () => {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState(NTSFormData);
  const [pointExist, setPointExist] = useState(false);
  const [coords3857, setCoords3857] = useState([null, null]);
  const [zone, setZone] = useState(null);
  const { user } = useUser();

  const { name } = location.state || '';

  useEffect(() => {
    if (!name) navigate('/nts-ha-report/');
    NTSFormData.user = user?.id;
    NTSFormData.info.general.hazard_name = name;
    NTSFormData.info.general.reporter_name = `${user?.name} ${user?.surname}`;
    NTSFormData.info.general.reporter_occupation = user?.reporter_position;
    NTSFormData.info.general.team_id = user?.team_id;
    setFormData(NTSFormData);
  }, [name]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const PageTitle = (props) => (
    <div className="page-title">
      <ArrowSvg
        className="hover-pointer"
        onClick={() => navigate(-1)}
      />
      <div>{props.title}</div>
      <span className="blue-text">
        |
        {props.formData?.info?.general?.hazard_name || 'Unknown'}
      </span>
    </div>
  );

  return (
    <section>
      <PageTitle title="Creating NTS" formData={formData} />
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="flex-right">
          <TabList>
            <Tab>Attributes</Tab>
            {/* <Tab>Check</Tab> */}
          </TabList>
        </div>
        <TabPanel>
          <NTSForm
            name={name || 'Unknown'}
            formData={formData}
            setFormData={setFormData}
            coords3857={coords3857}
            setCoords3857={setCoords3857}
            zone={zone}
            setZone={setZone}
            pointExist={pointExist}
            setPointExist={setPointExist}
            user={user}
            setLoader={setLoader}
          />
        </TabPanel>
        {/* <TabPanel>
            <CheckNts formData={formData} setFormData={setFormData} />
          </TabPanel> */}
      </Tabs>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export const NTSForm = (props) => {
  const [NTSErrors, setNTSErrors] = useState({});
  const generalRef = useRef(null);

  const { formData } = props;

  const [btnState, setButtonState] = useState(null);

  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [sendHAButtonDisabled, setSendHAButtonDisabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          hazard_name: props.name || props.formData.info.general.hazard_name,
        },
      },
    }));
  }, []);

  useBeforeUnload(
    useCallback((e) => {
      console.log(e);
      e.preventDefault();
      e.returnValue = '';
    }, []),
  );

  const scrollToGeneral = () => {
    if (generalRef.current) {
      generalRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (props.mode === 'edit') {
      return;
    }
    if (!props.user) return;
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      user: props.user.id,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          reporter_name: `${props.user.name} ${props.user.surname}`,
          reporter_occupation: props.user.reporter_position,
          team_id: props.user.team_id,
        },
      },
    }));
  }, [props.user]);

  useEffect(() => {
    localStorage.removeItem('reportName');
  }, []);

  const handleDraftButton = (e) => {
    e.preventDefault();
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      draft: true,
    }));
    setButtonState('draft');
  };

  const updateNTSReport = (callback) => {
    props.setLoader(true);
    axiosWrapper(() => {
      axios
        .put(`${URLS.CREATE_REPORT_NTS}${formData.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            callback(res.data);
            props.setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          props.setLoader(false);
          if (err.response.status === 400) {
            setNTSErrors(err.response.data);
            scrollToGeneral();
          }
          setSendButtonDisabled(false);
          toast.error('Error! NTS report not updated.');
        });
    });
  };

  const sendNTSReport = (callback) => {
    props.setLoader(true);
    axiosWrapper(() => {
      axios
        .post(URLS.CREATE_REPORT_NTS, formData)
        .then((res) => {
          if (res.status === 201) {
            callback(res.data);
            props.setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          props.setLoader(false);
          if (err.response.status === 400) {
            setNTSErrors(err.response.data);
            scrollToGeneral();
          }
          setSendButtonDisabled(false);
          toast.error('Error! NTS report not created.');
        });
    });
  };

  const sendHAReport = (callback) => {
    axiosWrapper(() => {
      axios
        .post(URLS.CREATE_REPORT_HA, HAformData)
        .then((res) => {
          if (res.status === 201) {
            callback(res.data);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.nts_report) {
            toast.error('HA report with this NTS report already exists.');
          }
          console.log(err);
          sendHAButtonDisabled(false);
          toast.error('Error! HA report not created.');
        });
    });
  };

  const handleSendButton = (e) => {
    e.preventDefault();
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      draft: false,
    }));
    setButtonState('sendNts');
  };

  const handleSendAndHAButton = (e) => {
    e.preventDefault();

    HAformData.user = formData.user;
    HAformData.draft = true;

    HAformData.info.general.report_date = formData.info.general.report_date;
    HAformData.info.general.hazard_name = formData.info.general.hazard_name;
    HAformData.info.general.reporter_organization = formData.info.general.reporter_organization;
    HAformData.info.general.reporter_name = formData.info.general.reporter_name;
    HAformData.info.general.reporter_occupation = formData.info.general.reporter_occupation;
    HAformData.info.general.team_id = formData.info.general.team_id;

    HAformData.info.location.region = formData.info.location.region;
    HAformData.info.location.district = formData.info.location.district;
    HAformData.info.location.local_community = formData.info.location.local_community;
    HAformData.info.location.settlement_name = formData.info.location.settlement_name;
    HAformData.info.location.settlement_code = formData.info.location.settlement_code;

    setButtonState('sendNtsAndHa');
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    navigate(URLS.DASHBOARD);
  };

  useEffect(() => {
    document.title = 'Create NTS report';
  }, []);

  useEffect(() => {
    if (btnState === 'draft') {
      if (formData.id !== 0) {
        updateNTSReport((newForm) => {
          newForm.draft = true;
          props.setFormData(newForm);
          toast.success('NTS report updated');
        });
      } else {
        sendNTSReport((newForm) => {
          newForm.draft = true;
          props.setFormData(newForm);
          toast.success('NTS report drafted');
          // navigate(URLS.DASHBOARD);
          setTimeout(() => {
            const { id } = newForm;
            navigate(`/edit-nts-report/${id}`);
          }, 1500);
        });
      }
    }
    if (btnState === 'sendNts') {
      setSendButtonDisabled(true);
      if (formData.id !== 0) {
        updateNTSReport((newForm) => {
          newForm.draft = false;
          props.setFormData(newForm);
          toast.success('NTS report updated');
          setTimeout(() => {
            navigate(URLS.DASHBOARD);
          }, 1000);
        });
      } else {
        sendNTSReport((newForm) => {
          newForm.draft = false;
          props.setFormData(newForm);
          toast.success('NTS report created');
          setTimeout(() => {
            navigate(URLS.DASHBOARD);
          }, 1000);
        });
      }
    }
    if (btnState === 'sendNtsAndHa') {
      setSendButtonDisabled(true);
      setSendHAButtonDisabled(true);
      if (formData.id !== 0) {
        formData.draft = false;
        updateNTSReport((newForm) => {
          newForm.draft = false;
          props.setFormData(newForm);
          toast.success('NTS report updated');

          HAformData.nts_report = newForm.id;
          if (!newForm.ha_report) {
            sendHAReport((newForm) => {
              toast.success('HA created');
              setTimeout(() => {
                navigate(URLS.CREATE_HA_REPORT_PAGE, {
                  state: { haReportId: newForm.id },
                });
              }, 1000);
            });
          } else {
            setTimeout(() => {
              navigate(`/edit-ha-report/${formData.ha_report}/`, {
                state: { selectedHaReportId: formData.ha_report },
              });
            }, 1000);
          }
        });
      } else {
        formData.draft = false;
        sendNTSReport((res) => {
          toast.success('NTS report created');

          HAformData.nts_report = res.id;
          if (!res.ha_report) {
            sendHAReport((newForm) => {
              toast.success('HA report created');
              setTimeout(() => {
                navigate(URLS.CREATE_HA_REPORT_PAGE, {
                  state: { haReportId: newForm.id },
                });
              }, 1000);
            });
          } else {
            setTimeout(() => {
              navigate(`/edit-ha-report/${formData.ha_report}/`);
            }, 1000);
          }
        });
      }
    }
    setButtonState(null);
  }, [formData?.draft, btnState]);

  return (
    <form
      className="form-wrapper"
      style={{ 'min-height': 'calc(100vh - 215px)' }}
    >
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['general', 'nonTechnical', 'ntoResult']}
        className="custom-accordion"
      >
        <div ref={generalRef}>
          <AccordionItem uuid="general">
            <GeneralInfo
              formData={formData}
              setFormData={props.setFormData}
              errors={NTSErrors}
            />
          </AccordionItem>
        </div>
        <AccordionItem uuid="nonTechnical">
          <NonTechnical
            formData={formData}
            setFormData={props.setFormData}
            setCoords3857={props.setCoords3857}
            coords3857={props.coords3857}
            zone={props.zone}
            setZone={props.setZone}
            pointExist={props.pointExist}
            setPointExist={props.setPointExist}
            errors={NTSErrors}
            mode={props.mode}
          />
        </AccordionItem>
        <AccordionItem uuid="ntoResult">
          <NTOResult
            formData={formData}
            setFormData={props.setFormData}
          />
        </AccordionItem>
      </Accordion>
      <div className="form-buttons buttons-group py-10">
        <ButtonComponent
          iconLeft={<PencilSvg />}
          handleButton={handleDraftButton}
          label="Draft"
          disabled={false}
          className="warning"
        />
        <ButtonComponent
          iconLeft={<SendSvg />}
          handleButton={handleSendButton}
          label="Send"
          disabled={sendButtonDisabled}
        />
        {formData?.info.nto_result.new_hazard_area_report_needed
          && formData?.validation_status?.value !== 'draft_rejected'
          && (
            <ButtonComponent
              iconLeft={<SendSvg />}
              handleButton={handleSendAndHAButton}
              label="Send & go to HA"
              disabled={sendHAButtonDisabled}
            />
          )}

        <ButtonComponent
          iconLeft={<CloseSvg />}
          handleButton={handleCancelButton}
          label="Cancel"
          disabled={false}
          className="danger"
        />
      </div>
    </form>
  );
};
