import React from 'react';

export const RadioButton = (props) => {
  const {
    id,
    name,
    value,
    checked,
    disabled,
    handleInput,
    label,
  } = props;
  return (
    <div className="radio-wrapper">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          if (handleInput) {
            handleInput(e.target.value);
          }
        }}
      />
      <label
        htmlFor={id}
        className="radio-wrapper"
      >
        {label}
      </label>
    </div>
  );
};
