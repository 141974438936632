/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

import RadioDisabledIcon from 'assets/images/icons/radio_disabled.svg';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'component/Loader';
import { axiosWrapper } from 'utils/Helper';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';

import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import NTSPoint from 'assets/images/icons/location.png';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { ReactComponent as TranslateSvg } from 'assets/images/icons/translate_24px.svg';

import {
  AccordionBodyItemView,
  AccordionHeaderTitle,
} from 'component/Accordion';
import { ButtonComponent } from 'component/Button';
import { HAformData } from 'consts/Forms';
import { URLS } from 'routes/Urls';
import axios from 'axios';
import { Map } from 'component/Map';
import { featureCollection, point } from '@turf/helpers';
import { TranslateWrapperComponent } from 'component/Translation';

const CheckNts = (props) => {
  const [loader] = useState(false);
  const navigate = useNavigate();
  const [btnState, setButtonState] = useState(null);
  const [comments, setComments] = useState([]);
  const [map, setMap] = useState(null);
  const [translatedFormData, setTranslatedFormData] = useState([]);
  const [translateState, setTranslateState] = useState(false);

  const { formData, setFormData } = props;

  const reasonLabels = {
    planned: 'Заплановане НТО / Planned NTO',
    application: 'По заявці інше / Application other',
    farmer: 'По заявці від фермерів (Feodal) / Farmer application (Feodal)',
    repeated: 'Повторне НТО / Repeated NTO',
  };
  const filteredGeneralComments = comments
    .filter((item) => {
      const commentText = item?.comments?.general;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredLocationComments = comments
    .filter((item) => {
      const commentText = item?.comments?.location;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredNtoResultComments = comments
    .filter((item) => {
      const commentText = item?.comments?.nto_result;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const selectedReasonLabel = reasonLabels[formData.info.nto_result.nto_reason];

  const selectedNTSReportId = formData.id || '';

  useEffect(() => {
    if (formData) {
      setFormData(formData);
    }
  }, [formData]);

  const addMapboxPointLayer = (geojson) => {
    if (!geojson) return;
    if (!map.getSource('nts-point')) {
      map.addSource('nts-point', {
        type: 'geojson',
        data: geojson,
        generateId: true,
      });

      [{ 'nts-point-icon': NTSPoint }].forEach((icon) => {
        map.loadImage(Object.values(icon)[0], (error, image) => {
          if (error) throw error;
          map.addImage(Object.keys(icon)[0], image);
        });
      });

      map.addLayer({
        id: 'nts-point-layer',
        type: 'symbol',
        source: 'nts-point',
        layout: {
          'icon-image': 'nts-point-icon',
          'icon-size': 1,
          'icon-offset': [0, -12],
          'icon-allow-overlap': true,
        },
      });
    } else {
      map.getSource('nts-point').setData(geojson);
    }
  };

  const handleDraftButton = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: true,
    }));
    setButtonState('draft');
  };

  const updateNTSReport = (callback) => {
    props.setLoader(true);
    axiosWrapper(() => {
      axios
        .put(`${URLS.CREATE_REPORT_NTS}${formData.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            callback(res.data);
            props.setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          props.setLoader(false);
          toast.error('Error! NTS report not updated.');
        });
    });
  };

  const sendNTSReport = (callback) => {
    props.setLoader(true);
    axiosWrapper(() => {
      axios
        .post(URLS.CREATE_REPORT_NTS, formData)
        .then((res) => {
          if (res.status === 201) {
            callback(res.data);
            props.setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          props.setLoader(false);
          toast.error('Error! NTS report not created.');
        });
    });
  };

  const sendHAReport = (callback) => {
    props.setLoader(true);
    axiosWrapper(() => {
      axios
        .post(URLS.CREATE_REPORT_HA, HAformData)
        .then((res) => {
          if (res.status === 201) {
            callback(res.data);
            props.setLoader(false);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.nts_report) {
            toast.error('HA report with this NTS report already exists.');
          }
          console.log(err);
          toast.error('Error! HA report not created.');
        });
    });
  };

  const handleSendButton = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: false,
    }));
    setButtonState('sendNts');
  };

  const handleSendAndHAButton = (e) => {
    e.preventDefault();

    HAformData.team_lead = formData.team_lead;
    HAformData.send_to_imsma = formData.send_to_imsma;
    HAformData.draft = true;

    HAformData.info.general.report_date = formData.info.general.report_date;
    HAformData.info.general.hazard_name = formData.info.general.hazard_name;
    HAformData.info.general.reporter_organization = formData.info.general.reporter_organization;
    HAformData.info.general.reporter_name = formData.info.general.reporter_name;
    HAformData.info.general.reporter_occupation = formData.info.general.reporter_occupation;

    HAformData.info.location.region = formData.info.location.region;
    HAformData.info.location.district = formData.info.location.district;
    HAformData.info.location.local_community = formData.info.location.local_community;
    HAformData.info.location.settlement_name = formData.info.location.settlement_name;
    HAformData.info.location.settlement_code = formData.info.location.settlement_code;

    setButtonState('sendNtsAndHa');
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    navigate(URLS.DASHBOARD);
  };

  const getAllComments = () => {
    axiosWrapper(() => {
      axios.get(`/validations/nts/${selectedNTSReportId}`).then((data) => {
        const allComments = data.data.iterations;
        setComments(allComments);
      });
    });
  };

  useEffect(() => {
    if (!map) return;
    const prop = formData.info.point?.coordinates;

    if (prop && prop[0] && prop[1]) {
      addMapboxPointLayer(featureCollection([point(prop)]));
    } else {
      addMapboxPointLayer(featureCollection([]));
    }
  }, [map, formData]);

  useEffect(() => {
    getAllComments();
  }, []);

  useEffect(() => {
    localStorage.removeItem('reportName');
  }, []);

  useEffect(() => {
    if (btnState === 'draft') {
      if (formData.id !== 0) {
        updateNTSReport((newForm) => {
          newForm.draft = true;
          setFormData(newForm);
          toast.success('NTS report updated');
        });
      } else {
        sendNTSReport((newForm) => {
          newForm.draft = true;
          setFormData(newForm);
          toast.success('NTS report drafted');
          // navigate(URLS.DASHBOARD);
        });
      }
    }
    if (btnState === 'sendNts') {
      if (formData.id !== 0) {
        updateNTSReport((newForm) => {
          newForm.draft = false;
          setFormData(newForm);
          toast.success('NTS report updated');
          setTimeout(() => {
            navigate(URLS.DASHBOARD);
          }, 1000);
        });
      } else {
        sendNTSReport((newForm) => {
          newForm.draft = false;
          setFormData(newForm);
          toast.success('NTS report created');
          setTimeout(() => {
            navigate(URLS.DASHBOARD);
          }, 1000);
        });
      }
    }
    if (btnState === 'sendNtsAndHa') {
      if (formData.id !== 0) {
        formData.draft = false;
        updateNTSReport((newForm) => {
          newForm.draft = false;
          setFormData(newForm);
          toast.success('NTS report updated');

          HAformData.nts_report = newForm.id;
          if (!newForm.ha_report) {
            sendHAReport((newForm) => {
              toast.success('HA created');
              setTimeout(() => {
                navigate(URLS.CREATE_HA_REPORT_PAGE, {
                  state: { haReportId: newForm.id },
                });
              }, 1000);
            });
          } else {
            setTimeout(() => {
              navigate(`/edit-ha-report/${formData.ha_report}/`, {
                state: { selectedHaReportId: formData.ha_report },
              });
            }, 1000);
          }
        });
      } else {
        formData.draft = false;
        sendNTSReport((res) => {
          toast.success('NTS report created');

          HAformData.nts_report = res.id;
          if (!res.ha_report) {
            sendHAReport((newForm) => {
              toast.success('HA report created');
              setTimeout(() => {
                navigate(URLS.CREATE_HA_REPORT_PAGE, {
                  state: { haReportId: newForm.id },
                });
              }, 1000);
            });
          } else {
            setTimeout(() => {
              navigate(`/edit-ha-report/${formData.ha_report}/`);
            }, 1000);
          }
        });
      }
    }
    setButtonState(null);
  }, [formData.draft, btnState]);

  return (
    <main className="dashboard-main">
      <TranslateWrapperComponent
        formData={formData}
        setTranslatedFormData={setTranslatedFormData}
        translationState={translateState}
      >
        <form className="form-wrapper" style={{ width: '100%' }}>
          <div
            className="text-hover flex-group flex-right"
            onClick={() => setTranslateState(!translateState)}
          >
            <TranslateSvg />
            <span>Translate</span>
          </div>
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={['general', 'nonTechnical', 'ntoResult']}
            className="custom-accordion"
          >
            <AccordionItem uuid="general" className="custom-accordion">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle
                    accordionId="1"
                    titleUA="Загальна інформація"
                    titleEN="General Information"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="custom-accordion-item-panel">
                <AccordionBodyItemView
                  accordionSubId="1.1"
                  titleUA="Ідентифікаційний номер IMSMA"
                  titleEN="IMSMA identification number"
                  children={
                    formData.info.general.imsma_id
                    || 'Ідентифікаційний номер звіту генерується автоматично'
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="1.1.1"
                  titleUA="Відправляти до IMSMA"
                  titleEN="Send to IMSMA"
                  children={formData.send_to_imsma ? 'Так / Yes' : 'Ні / No'}
                />
                <AccordionBodyItemView
                  accordionSubId="1.2"
                  titleUA="Дата звіту"
                  titleEN="Report date"
                  children={formData.info.general.report_date}
                />
                <AccordionBodyItemView
                  accordionSubId="1.3"
                  titleUA="Організація, що звітує"
                  titleEN="Reporting organization"
                  children={formData?.info.general.reporter_organization}
                />
                <AccordionBodyItemView
                  accordionSubId="1.4"
                  titleUA="Звіт надав"
                  titleEN="Report provided"
                  children={(
                    <span className="no-wrap">
                      {formData.info.general.reporter_name}
                    </span>
                  )}
                />
                <AccordionBodyItemView
                  accordionSubId="1.5"
                  titleUA="Посада особи, що звітує"
                  titleEN="Reporter position"
                  children={(
                    <span className="no-wrap">
                      {translateState && translatedFormData.reporter_occupation}
                      {!translateState && formData.info.general.reporter_occupation}
                    </span>
                  )}

                />
                <AccordionBodyItemView
                  accordionSubId="1.6"
                  titleUA="Дата проведення нетехнічного обстеження"
                  titleEN="Report date"
                  children={formData.info.general.non_tech_examination_date}
                />

                {filteredGeneralComments?.map((item, index) => {
                  const isLastComment = index === 0;
                  const commentClassName = isLastComment
                    ? 'block-with-comment-green'
                    : 'block-with-comment-gray';

                  return (
                    <div key={index} className={commentClassName}>
                      <div className="icon">
                        <ChatIcon
                          style={{
                            width: '20px',
                            color: '#868686',
                          }}
                        />
                      </div>
                      {item?.comments?.general}
                    </div>
                  );
                })}
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="nonTechnical">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle
                    accordionId="2"
                    titleUA="Місце проведення нетехнічного обстеження"
                    titleEN="Place of non-technical examination"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="custom-accordion-item-panel">
                <AccordionBodyItemView
                  accordionSubId="2.1"
                  titleUA="Область"
                  titleEN="Region"
                  children={formData?.info?.location.region}
                />

                <AccordionBodyItemView
                  accordionSubId="2.2"
                  titleUA="Район"
                  titleEN="District"
                  children={formData?.info?.location.district}
                />
                <AccordionBodyItemView
                  accordionSubId="2.3"
                  titleUA="Територіальна громада"
                  titleEN="Local community"
                  children={formData?.info?.location.local_community}
                />

                <AccordionBodyItemView
                  accordionSubId="2.4"
                  titleUA="Населений пункт"
                  titleEN="Locality"
                  children={formData?.info?.location.settlement_name}
                />

                <AccordionBodyItemView
                  accordionSubId="2.5"
                  titleUA="Код нас. пункту (згідно кодифікатору)"
                  titleEN="Settlement ID (new kodyficator)"
                  children={formData.info.location.settlement_code}
                />
                <AccordionBodyItemView
                  accordionSubId="2.6"
                  titleUA="Опис місця проведення нетехнічного обстеження"
                  titleEN="Description of the location of the non-technical inspection"
                  children={(
                    <>
                      {!translateState && formData.info.location.description}
                      {translateState && translatedFormData.location_description}
                    </>
                  )}
                />
                <div className="flex-column-group no-y-margin w-100">
                  <Map
                    map={map}
                    setMap={setMap}
                  />
                  <div className="flex-group">
                    <div className="double-text">
                      <span>Координати</span>
                      <span>Coordinates</span>
                    </div>
                    <div className="line" />
                    <div className="flex-group text flex-wrap">
                      <span>
                        Long:
                        {props?.coords3857[0]?.toFixed() || 0}
                      </span>
                      <span>
                        Lat:
                        {props?.coords3857[1]?.toFixed() || 0}
                      </span>
                    </div>
                  </div>
                </div>
                {filteredLocationComments?.map((item, index) => {
                  const isLastComment = index === 0;
                  const commentClassName = isLastComment
                    ? 'block-with-comment-green'
                    : 'block-with-comment-gray';

                  return (
                    <div key={index} className={commentClassName}>
                      <div className="icon">
                        <ChatIcon
                          style={{
                            width: '20px',
                            color: '#868686',
                          }}
                        />
                      </div>
                      {item?.comments?.location}
                    </div>
                  );
                })}
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="ntoResult">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle
                    accordionId="3"
                    titleUA="Результат НТО"
                    titleEN="Result of NTO"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="custom-accordion-item-panel">
                <AccordionBodyItemView
                  accordionSubId="3.0"
                  titleUA="Village by village"
                  titleEN="Village by village"
                  children={
                    formData?.info?.nto_result.village_by_village ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.1"
                  titleUA="Причини проведення HTO"
                  titleEN="Reasons for performing NTS"
                  children={selectedReasonLabel}
                />
                <AccordionBodyItemView
                  accordionSubId="3.2"
                  titleUA="Чи виявлені будь-які докази?"
                  titleEN="Have any evidence been found?"
                  children={
                    formData?.info?.nto_result.evidences ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.3"
                  titleUA="Чи має бути створено звіт про виявлення одиничного боєприпасу?"
                  titleEN="Should a report be created for the detection of a single ordnance?"
                  children={
                    formData?.info?.nto_result?.single_ammo_report_needed ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.4"
                  titleUA="Чи має бути створено нову небезпечну територію?"
                  titleEN="Should a new hazardous area be established?"
                  children={
                    formData?.info?.nto_result.new_hazard_area_report_needed ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.5"
                  titleUA="Коментар"
                  titleEN="Comment"
                  children={(
                    <>
                      {!translateState && formData.info.nto_result?.comment}
                      {translateState && translatedFormData.nto_result_comment}
                    </>
                  )}
                />
                {filteredNtoResultComments?.map((item, index) => {
                  const isLastComment = index === 0;
                  const commentClassName = isLastComment
                    ? 'block-with-comment-green'
                    : 'block-with-comment-gray';

                  return (
                    <div key={index} className={commentClassName}>
                      <div className="icon">
                        <ChatIcon
                          style={{
                            width: '20px',
                            color: '#868686',
                          }}
                        />
                      </div>
                      {item?.comments?.nto_result}
                    </div>
                  );
                })}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div className="form-buttons buttons-group py-10">
            <ButtonComponent
              iconLeft={<PencilSvg />}
              handleButton={handleDraftButton}
              label="Draft"
              disabled={false}
              className="warning"
            />
            <ButtonComponent
              iconLeft={<SendSvg />}
              handleButton={handleSendButton}
              label="Send"
              disabled={false}
            />
            {formData?.info.nto_result.new_hazard_area_report_needed
              && formData?.validation_status?.value !== 'draft_rejected'
              && (
                <ButtonComponent
                  iconLeft={<SendSvg />}
                  handleButton={handleSendAndHAButton}
                  label="Send & go to HA"
                  disabled={false}
                />
              )}
            <ButtonComponent
              iconLeft={<CloseSvg />}
              handleButton={handleCancelButton}
              label="Cancel"
              disabled={false}
              className="danger"
            />
          </div>
        </form>
      </TranslateWrapperComponent>

      <Loader visible={loader} />
      <ToastContainer />
    </main>
  );
};

export default CheckNts;
