import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';
import { BriefExplanation } from './BriefExplanation';

export const IndirectEvidenceDescription = (props) => {
  const handleEvidenceDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        evidences: {
          ...prevFormData.info.evidences,
          indirect_evidences: {
            ...prevFormData.info.evidences.indirect_evidences,
            description: value,
          },
        },
      },
    }));
  };

  return (
    <div className="flex-column-group">
      <AccordionBodyItem
        accordionSubId="4.6"
        titleUA="Коротке пояснення непрямих доказів за потреби"
        titleEN="A brief explanation of indirect evidence as needed"
      />
      <BriefExplanation
        formData={props.formData}
        setFormData={props.setFormData}
      />
      <TextareaComponent
        style={{ width: '-webkit-fill-available' }}
        handleTextarea={handleEvidenceDescription}
        value={props.formData.info.evidences.indirect_evidences.description}
        limitChar
        blueLink
        maxLength={1500}
      />

    </div>
  );
};
