import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'assets/styles/datepicker.scss';
import moment from 'moment';
import 'moment/locale/uk';

export const DatePickerWrapper = (props) => {
  const {
    value,
    handleDatePicker,
    type,
    disableDateBefore,
    disableDateAfter,
    className,
  } = props;

  const isDateBefore = (date, beforeDate) => date.isBefore(beforeDate, 'day');

  const isDateAfter = (date, afterDate) => date.isAfter(afterDate, 'day');

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="uk">
      <DatePicker
        className={`custom-datepicker ${className ? className : ''}`}
        value={value}
        onChange={(dateValue) => handleDatePicker(dateValue, type)}
        dateFormat="dd/MM/yyyy"
        slotProps={{
          textField: {
            size: 'small',
          },
        }}
        shouldDisableDate={(date) => {
          if (disableDateBefore) {
            return isDateBefore(date, moment(disableDateBefore, 'DD/MM/YYYY'));
          }
          if (disableDateAfter) {
            return isDateAfter(date, moment(disableDateAfter, 'DD/MM/YYYY'));
          }
          return false;
        }}
      />
    </LocalizationProvider>
  );
};
