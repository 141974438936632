import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const SmallAmmunitionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="3.7"
      titleUA="Боєприпаси до стрілецької зброї (<20мм)"
      titleEN="Small Arms Ammunition (<20mm)"
      children={
        formData.info.hazard_type.small_arms_ammunition && (
          <CheckBoxIcon />
        )
      }
    />
  );
};
