import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const IMSMASwitcherView = (props) => {
  const { formData, accordionSubId = '1.2.1' } = props;
  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Відправляти до IMSMA"
      titleEN="Send to IMSMA"
      children={formData?.send_to_imsma ? 'Так / Yes' : 'Ні / No'}
    />
  );
};
