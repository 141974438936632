import axios from 'axios';

export const getRegion = async () => {
  const url = '/reports/locations/';
  const response = await axios.get(url);
  return response;
};

export const getDistrict = async (region) => {
  const regionUkr = `${region.split('/')[0]}`;
  const url = `/reports/locations/${regionUkr}`;
  const response = await axios.get(url);
  return response;
};

export const getLocalCommunities = async (region, district) => {
  const regionUkr = `${region.split('/')[0]}`.trim();
  const districtUkr = `${district.split('/')[0]}`.trim();
  const url = `/reports/locations/${regionUkr}/${districtUkr}`;
  const response = await axios.get(url);
  return response;
};

export const getSettlement = async (region, district, community) => {
  const regionUkr = `${region.split('/')[0]}`.trim();
  const districtUkr = `${district.split('/')[0]}`.trim();
  const communityUkr = `${community.split('/')[0]}`.trim();
  const url = `/reports/locations/${regionUkr}/${districtUkr}/${communityUkr}`;
  const response = await axios.get(url);
  return response;
};
