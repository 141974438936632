const lowerCasedAlphabets = [...'abcdefghijklmnopqrstuvwxyz'.split('')];
const upperCasedAlphabets = lowerCasedAlphabets.map((alphabet) => alphabet.toUpperCase());

const numbers = [...'1234567890'.split('').map((num) => +num)];
const symbols = [...'!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'.split('')];

const getRandomNumber = (max) => Math.floor(Math.random() * max);

const generatePassword = () => {
  const initialValues = {
    length: 10,
    includeLowerCase: true,
    includeUpperCase: true,
    includeNumber: true,
    includeSymbols: true,
  };
  const {
    length,
    includeLowerCase,
    includeNumber,
    includeSymbols,
    includeUpperCase,
  } = initialValues;
  const generatedPasssword = [];

  for (let i = 0; i < 40; i++) {
    includeUpperCase
      && generatedPasssword.push(
        upperCasedAlphabets[getRandomNumber(upperCasedAlphabets.length)],
      );
    includeLowerCase
      && generatedPasssword.push(
        lowerCasedAlphabets[getRandomNumber(lowerCasedAlphabets.length)],
      );
    includeNumber
      && generatedPasssword.push(numbers[getRandomNumber(numbers.length)]);
    includeSymbols
      && generatedPasssword.push(symbols[getRandomNumber(symbols.length)]);
  }

  if (!generatedPasssword.length) {
    return length
      ? generatePassword().slice(0, length)
      : generatePassword();
  }
  return length
    ? generatedPasssword.slice(0, length).join('')
    : generatedPasssword.slice(0, 16).join('');
};

export default generatePassword;
