import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const DirectEvidence = (props) => {
  const evidences = props.formData.info.evidences.direct_evidences;

  const handleEvidenceInput = (value, isChecked) => {
    const evidenceTypes = [...evidences.evidence_types];

    if (isChecked) {
      evidenceTypes.push(value);
    } else {
      const index = evidenceTypes.indexOf(value);
      if (index !== -1) {
        evidenceTypes.splice(index, 1);
      }
    }

    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        evidences: {
          ...prevFormData.info.evidences,
          direct_evidences: {
            ...prevFormData.info.evidences.direct_evidences,
            evidence_types: evidenceTypes,
          },
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId="4.1"
      titleUA="Прямі докази"
      titleEN="Direct evidence"
      children={(
        <div className="flex-group flex-wrap">
          <CheckboxInput
            id="mine_erw_accidents"
            name="mine_erw_accidents"
            value="mine_erw_accidents"
            label="Нещасні випадки з мінами/ВЗВ /
                        Mine/ERW related accident"
            checked={evidences.evidence_types?.includes('mine_erw_accidents')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="explosions"
            name="explosions"
            value="explosions"
            label="Вибухи побачені/почуті /
                        Explosions"
            checked={evidences.evidence_types?.includes('explosions')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="mines_direct_report"
            name="mines_direct_report"
            value="mines_direct_report"
            label="Прямий звіт про мінування /
                        Direct report about mines"
            checked={evidences.evidence_types?.includes('mines_direct_report')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="visible_mine_erw"
            name="visible_mine_erw"
            value="visible_mine_erw"
            label="Видимі міни/ВЗВ /
                        Visible Mine/ERW"
            checked={evidences.evidence_types?.includes('visible_mine_erw')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="debris"
            name="debris"
            value="debris"
            label="Уламки/фрагменти ВЗВ /
                        Debris"
            checked={evidences.evidence_types?.includes('debris')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
          <CheckboxInput
            id="evidence-other"
            name="other"
            value="other"
            label="Інше /
                        Other"
            checked={evidences.evidence_types?.includes('other')}
            handleInput={handleEvidenceInput}
            disable="false"
          />
        </div>
)}
    />
  );
};
