import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';
import { removeLeadingZeros } from '../../../../utils/Helper';

export const PolygonInfoView = (props) => {
  const { formData } = props;
  const polygonInfo = formData.info.polygon_info;

  return (
    <AccordionBodyItem
      accordionSubId="5.1"
      children={(
        <div className="lm-bm-header">
          <div
            className="flex-group no-wrap"
            style={{ paddingRight: '25px' }}
          >
            <div className="flex-column-group">
              <span>Кількість полігонів:</span>
              <span>Number of polygons:</span>
            </div>
            <span className="line" />
            <div>{removeLeadingZeros(polygonInfo?.polygon_count)}</div>
            <span className="line" />
            <div className="flex-column-group">
              <span>Система координат:</span>
              <span>Coordinate system:</span>
            </div>
            <div style={{ marginLeft: '20px' }}>
              {polygonInfo?.coordinate_system === 'wgs_84' && (
                <div>
                  <CheckBoxIcon />
                  {' '}
                  WGS 84
                </div>
              )}
            </div>
            <div style={{ marginLeft: '20px' }}>
              {polygonInfo?.coordinate_system === 'utm' && (
                <div>
                  <CheckBoxIcon />
                  {' '}
                  UTM
                </div>
              )}
            </div>
          </div>
          <div className="flex-group ">
            <div
              className="flex-column-group"
              style={{ marginLeft: '20px', marginRight: '10px' }}
            >
              <span>Номер зони:</span>
              <span>Zone number:</span>
            </div>
            <div>{polygonInfo?.zone_number}</div>
          </div>
        </div>
      )}
    />
  );
};
