import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ButtonComponent } from 'component/Button';
import { SelectComponent } from 'component/Select';
import { ReactComponent as FilterSvg } from 'assets/images/icons/filter.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { getValidationStatuses } from 'api/FilterApi';

export const FilterByStatusDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    nts: {
      selectedStatus: [],
      selectedAreaType: [],
      selectedFilters: [],
    },
    ha: {
      selectedStatus: [],
      selectedAreaType: [],
      selectedFilters: [],
    },
    all: {
      selectedStatus: [],
      selectedAreaType: [],
      selectedFilters: [],
    },
  });
  const fetchValidationStatus = (type) => getValidationStatuses(type)
    .then((response) => {
      setStatusOptions((prevStatusOptions) => ({
        ...prevStatusOptions,
        [type]: response.data,
      }));
    })
    .catch(() => {
      console.log('Error fetching validation statuses');
    });

  const handleClickOpen = () => {
    setOpen(true);
    const type = props.activeTabIndex === 0 ? 'nts' : 'ha';
    fetchValidationStatus(type);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const applyFilters = () => {
    setOpen(false);
    props.handleSelectedStatusChange(
      selectedValues.nts.selectedStatus,
      selectedValues.ha.selectedStatus,
      selectedValues.all.selectedStatus,
    );
  };

  const handleFilterSelection = (selected) => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedFilters: selected,
      },
    }));
  };

  const handleStatusSelection = (selected) => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedStatus: selected,
      },
    }));
  };

  const handleAreaTypeSelection = (selected) => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedAreaType: selected,
      },
    }));
  };

  const resetFilteredData = () => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        selectedStatus: [],
        selectedAreaType: [],
        selectedFilters: [],
      },
    }));
  };

  useEffect(() => {
    if (props.resetFiltersStatus === true) {
      resetFilteredData();
    }
  }, [props.resetFiltersStatus]);

  const currentStatusOptions = props.activeTabIndex === 0
    ? statusOptions.nts
    : props.activeTabIndex === 1
      ? statusOptions.ha
      : statusOptions.all || [];

  const currentSelectedValues = props.activeTabIndex === 0
    ? selectedValues.nts
    : props.activeTabIndex === 1
      ? selectedValues.ha
      : selectedValues.all;

  return (
    <div className="text-center">
      <ButtonComponent
        className="filter-btn"
        configuration="filled"
        state="enabled"
        iconRight={<FilterSvg />}
        label="Filter"
        handleButton={handleClickOpen}
      />
      <Dialog
        className="filter-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Filter</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="select-wrapper w-100" style={{ gap: '0' }}>
            <span className="label">Select filters</span>
            <SelectComponent
              placeholder="Select..."
              className="w-100"
              value={currentSelectedValues.selectedFilters}
              options={
                props.activeTabIndex !== 0
                  ? [
                    { value: 'status', label: 'Status' },
                    { value: 'areaType', label: 'Area type' },
                  ]
                  : [{ value: 'status', label: 'Status' }]
              }
              handleSelect={handleFilterSelection}
              isMulti
            />
          </div>

          {currentSelectedValues.selectedFilters.some((item) => item.value === 'areaType')
            && props.activeTabIndex === 1 && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Select area type</span>
                <SelectComponent
                  placeholder="Select..."
                  className="w-100"
                  value={currentSelectedValues.selectedAreaType}
                  options={[
                    { value: 'I3T|SHA', label: 'I3T|SHA' },
                    { value: '3T|CHA', label: '3T|CHA' },
                  ]}
                  handleSelect={handleAreaTypeSelection}
                  isMulti
                />
              </div>
            )}
          {currentSelectedValues.selectedFilters.some((item) => item.value === 'status') && (
            <div className="select-wrapper w-100" style={{ gap: '0' }}>
              <span className="label">Select status</span>
              <SelectComponent
                placeholder="Select..."
                className="filter-status"
                value={currentSelectedValues.selectedStatus}
                options={currentStatusOptions}
                handleSelect={handleStatusSelection}
                isMulti
                menuPortalTarget={document.body}
              />
            </div>
          )}
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={resetFilteredData}
              label="Reset"
            />
            <ButtonComponent
              iconLeft={<FilterSvg />}
              handleButton={() => {
                applyFilters();
              }}
              label="Apply"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};