import React from 'react';
import { CheckboxInput } from 'component/Checkbox';

export const HAAttributesBlock = (props) => (

  props.HAAttributes?.map((attribute, index1) => (
    <div className="flex-column-group" key={index1}>
      <CheckboxInput
        id={`checkbox-${attribute.name}`}
        name={`checkbox-${attribute.name}`}
        label={(
          <div className="double-text">
            <span>{attribute.ua_name || '-'}</span>
            <span>{attribute.eng_name || '-'}</span>
          </div>
        )}
        disabled={props.viewMode}
        value={props.findKey(props.selectedHaAttr, attribute.name, props.selectedHaBlocks)}
        checked={props.selectedHaBlocks.hasOwnProperty(attribute.name)
          ? props.findKey(props.selectedHaBlocks, attribute.name, '')
          : props.findKey(props.selectedHaAttr, attribute.name, props.selectedHaBlocks)}
        handleInput={(value, checked) => {
          props.handleTemplateBlockCheckbox(attribute.name, value, checked, attribute.name);
          props.handleTemplateCheckbox(
            props.selectedHaAttr,
            props.selectedHaBlocks,
            attribute.name,
            checked,
          );
        }}
      />
      {props.selectedHaBlocks[attribute.name] && (
        <div className="checkbox-children">
          {attribute.children?.map((сhildAttr, index2) => (

            <div className="flex-column-group" key={index2}>
              <CheckboxInput
                id={`checkbox-${attribute.name}-${сhildAttr.name}`}
                name={`checkbox-${attribute.name}-${сhildAttr.name}`}
                label={(
                  <div className="double-text">
                    <span>{сhildAttr.ua_name || '-'}</span>
                    <span>{сhildAttr.eng_name || '-'}</span>
                  </div>
                )}
                disabled={props.viewMode}
                value={props.findKey(
                  props.selectedHaAttr[attribute.name],
                  сhildAttr.name,
                  props.selectedHaBlocks,
                )}
                checked={сhildAttr.name === 'hazard_type' || сhildAttr.name === 'hazard_area_type'
                  ? props.selectedHaAttr[attribute.name][сhildAttr.name]
                  : props.findKey(
                    props.selectedHaAttr[attribute.name],
                    сhildAttr.name,
                    props.selectedHaBlocks,
                  )}
                handleInput={(value, isChecked) => {
                  if (сhildAttr.name !== 'hazard_type') {
                    props.handleTemplateBlockCheckbox(сhildAttr.name, value, isChecked, `${attribute.name}__${сhildAttr.name}`);
                  }
                  props.handleTemplateCheckbox(props.selectedHaAttr, props.selectedHaBlocks, `${attribute.name}__${сhildAttr.name}`, isChecked);
                }}
              />
              {props.selectedHaBlocks[сhildAttr.name] && (
                <div className="checkbox-children">
                  {сhildAttr.children?.map((сhildAttr2, index3) => (
                    <div className="flex-column-group" key={index3}>
                      <CheckboxInput
                        id={`checkbox-${сhildAttr.name}-${сhildAttr2.name}`}
                        name={`checkbox-${сhildAttr.name}-${сhildAttr2.name}`}
                        label={(
                          <div className="double-text">
                            <span>{сhildAttr2.ua_name || '-'}</span>
                            <span>{сhildAttr2.eng_name || '-'}</span>
                          </div>
                        )}
                        disabled={props.viewMode}
                        value={props.findKey(
                          props.selectedHaAttr[attribute.name][сhildAttr.name],
                          сhildAttr2.name,
                          props.selectedHaBlocks,
                        )}
                        checked={props.findKey(
                          props.selectedHaAttr[attribute.name][сhildAttr.name],
                          сhildAttr2.name,
                          props.selectedHaBlocks,
                        )}
                        handleInput={(value, isChecked) => {
                          props.handleTemplateCheckbox(props.selectedHaAttr, props.selectedHaBlocks, `${attribute.name}__${сhildAttr.name}__${сhildAttr2.name}`, isChecked);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  ))
);
