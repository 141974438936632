import React from 'react';
import logo1 from 'assets/images/logo/Type=Dark, Text=true.svg';
import logo2 from 'assets/images/logo/Type=White, Text=False.svg';
import logo3 from 'assets/images/logo/Type=White, Text=true.svg';
import 'assets/styles/logo.scss';

export const LogoFSD = (props) => {
  const logoSrc = () => {
    if (props.type === 'dark') return logo1;
    if (props.type === 'white' && props.text === 'off') {
      return logo2;
    }
    if (props.type === 'white' && props.text === 'on') {
      return logo3;
    }
    return logo1;
  };

  return (
    <img
      src={logoSrc()}
      alt="FSD logo"
      className="logo"
    />
  );
};
