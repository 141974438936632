import React from 'react';

export const ContactPersonView = (props) => {
  const { formData } = props;

  return (
    <div className="contact-person-view">
      <div className="flex-column-group">
        <div className="double-text">
          <span>Контактна особа</span>
          <span>Contact Person</span>
        </div>
        <div>{formData.info.session_information?.contact_person?.name}</div>
      </div>
      <div className="flex-column-group">
        <div className="double-text">
          <span>Номер телефону</span>
          <span>Phone number</span>
        </div>
        <div>{formData.info.session_information?.contact_person?.phone_number}</div>
      </div>
    </div>
  );
};
