import { toast } from 'react-toastify';

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

const validatePassword = (password) => password?.length >= 8;

const checkDirectors = (userInfo) => {
  if (!userInfo.directors || userInfo.directors?.length === 0) {
    toast.error('Please select at least one director');
    return false;
  }
  return true;
};

const checkAccessLevel = (userInfo) => {
  if (!userInfo.permissions || Object.keys(userInfo.permissions)?.length === 0) {
    toast.error('Please select access level');
    return false;
  }
  return true;
};

const checkPassword = (userInfo) => {
  if (!validatePassword(userInfo.password)) {
    toast.error('Password must be at least 8 characters long');
    return false;
  }
  return true;
};

const checkEmail = (userInfo) => {
  if (!validateEmail(userInfo.email)) {
    toast.error('Please enter a valid email address');
    return false;
  }
  return true;
};

const checkName = (userInfo) => {
  if (!userInfo.name) {
    toast.error('Please enter a name');
    return false;
  }
  return true;
};

const checkTeam = (userInfo) => {
  if (!userInfo.team_id) {
    toast.error('Please enter a team');
    return false;
  }
  return true;
};

export const handleAddUser = (userInfo) => {
  if (!checkName(userInfo)) return false;
  if (!checkEmail(userInfo)) return false;
  if (!checkPassword(userInfo)) return false;
  if (!checkTeam(userInfo)) return false;
  if (!checkDirectors(userInfo)) return false;
  if (!checkAccessLevel(userInfo)) return false;
  return true;
};

export const handleUpdateUser = (userInfo) => {
  if (!checkName(userInfo)) return false;
  if (!checkEmail(userInfo)) return false;
  if (!checkTeam(userInfo)) return false;
  if (!checkDirectors(userInfo)) return false;
  if (!checkAccessLevel(userInfo)) return false;
  return true;
};

export const handleUserErrors = (errors) => {
  if (errors?.name) {
    toast.error(errors.name[0]);
  }
  if (errors?.email) {
    toast.error(errors.email[0]);
  }
  if (errors?.phone_number) {
    toast.error(errors.phone_number[0]);
  }
  if (errors?.team_id) {
    toast.error(errors.team_id[0]);
  }
  if (errors?.password) {
    toast.error(errors.password[0]);
  }
  if (errors?.role) {
    toast.error(errors.role[0]);
  }
  if (errors?.directors) {
    toast.error(errors.directors[0]);
  }
  if (errors?.permissions) {
    toast.error(errors.permissions[0]);
  }
};
