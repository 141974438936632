import axios from 'axios';
import moment from 'moment';

export const getFMSMachineryTracks = async (fromDate, toDate) => {
  const fromDateParam = moment(fromDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const fromToParam = moment(toDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const url = `/machinery/fms-machinery-period?fromDate=${fromDateParam}&toDate=${fromToParam}`;
  const response = await axios.get(url);
  return response;
};

export const getFMSMachineryLocation = async (date) => {
  const dateParam = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const url = `/machinery/fms-machinery-location/?date=${dateParam}`;
  const response = await axios.get(url);
  return response;
};
