import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const HazardNameView = (props) => {
  const { formData } = props;
  return (
    <AccordionBodyItemView
      accordionSubId="1.3"
      titleUA="Назва небезпечної території"
      titleEN="Hazard Name"
      children={formData.info?.general?.hazard_name}
    />
  );
};
