import axios from 'axios';
import {
  React,
  useEffect,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { getUser } from 'api/UserAPI';
import { getCookie } from 'utils/Cookies';
import { axiosWrapper } from 'utils/Helper';
import { URLS } from './Urls';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState();
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [avatarError, setAvatarError] = useState(false);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const getAvatar = (userId) => {
    setIsAvatarLoading(true);
    axiosWrapper(() => {
      axios
        .get(`${URLS.USERS}${userId}/avatar/`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const base64Data = `data:image/png;base64,${arrayBufferToBase64(
            response.data,
          )}`;
          setAvatar(base64Data);
          setIsAvatarLoading(false);
          setAvatarError(false);
        })
        .catch((error) => {
          console.error('Error with avatar', error);
          setIsAvatarLoading(false);
          setAvatarError(true);
        });
    });
  };

  const getUserInfo = () => {
    if (!getCookie('access_token')) return;
    axiosWrapper(() => {
      getUser()
        .then((res) => {
          setUser(res.data);
          if (res.data.id) {
            getAvatar(res.data.id);
          }
        })
        .catch(() => {
          setUser(null);
          console.log('Error user loading');
        });
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const userValue = useMemo(() => ({
    user,
    setUser,
    getUserInfo,
    avatar,
    isAvatarLoading,
    setAvatar,
    avatarError,
  }), [user, avatar, isAvatarLoading, avatarError]);

  return (
    <UserContext.Provider
      value={userValue}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
