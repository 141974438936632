import React from 'react';
import { SelectComponent } from 'component/Select';

export const HazardTypeFilter = (props) => {
  const options = [
    { value: 'minefield', label: 'Мінне поле | Minefield' },
    { value: 'battlefield ', label: 'Поле бою | BAC' },
  ];
  return (
    <div className="flex-column-group">
      <span>Sort by hazard type</span>
      <SelectComponent
        className="full"
        value={props.hazardTypeFilter}
        options={options}
        handleSelect={props.setHazardTypeFilter}
        isMulti
      />
    </div>
  );
};
