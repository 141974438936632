import React, { useEffect, useState } from 'react';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { convert3857To4326 } from 'utils/Helper';
import { featureCollection, point } from '@turf/helpers';
import { Map } from '../../../component/Map';

export const MapInfo = (props) => {
  const [lmGeojson, setLmGeojson] = useState(props.formData.info.lm.geometry);
  const [bmGeojson, setBmGeojson] = useState(props.formData.info.bm.geometry);

  useEffect(() => {
    if (props.formData.info.lm?.geometry?.coordinates?.length) {
      setLmGeojson(convert3857To4326(featureCollection([
        point(props.formData.info.lm.geometry.coordinates),
      ]), props.formData.file_zone));
    }
  }, [props.formData.info.lm.geometry]);

  useEffect(() => {
    if (props.formData.info.bm?.geometry?.coordinates?.length) {
      setBmGeojson(convert3857To4326(featureCollection([
        point(props.formData.info.bm.geometry.coordinates),
      ]), props.formData.file_zone));
    }
  }, [props.formData.info.bm.geometry]);
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId={props.accordionId || '3'}
            titleUA="Мапа"
            titleEN="Map"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <Map
          map={props.map}
          setMap={props.setMap}
          turnPointsPolygon={props.formData.info.turn_points.geojson}
          evidenceGeojson={props.formData.info.evidences.evidence_geojson}
          lmGeojson={lmGeojson}
          bmGeojson={bmGeojson}
          hazardAreaType={props.formData.info.general.hazard_area_type}
          evidences={props.formData.info.evidences}
          googleWms
        />
      </AccordionItemPanel>
    </>
  );
};
