import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { GeneralInfo } from '../Blocks/Create/GeneralInfo/GeneralInfo';
import { SessionInfo } from '../Blocks/Create/SessionInfo/SessionInfo';
import { FinalIndicatorsInfo } from '../Blocks/Create/FinalIndicators/FinalIndicators';

export const AttributesTab = (props) => {
  const {
    formData,
    setFormData,
    handouts,
    errors,
    zone,
    setZone,
  } = props;

  return (
    <>
      <div className="info_block_header">
        <div className="info_block_header_left">Атрибути</div>
        <span className="info_block_header_line" />
        <div className="info_block_header_right">Attributes</div>
      </div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['general', 'sessionInfo', 'finalIndicatorsInfo']}
        className="custom-accordion"
      >

        <AccordionItem uuid="general">
          <GeneralInfo
            formData={formData}
            setFormData={setFormData}
            errors={[]}
          />
        </AccordionItem>
        <AccordionItem uuid="sessionInfo">
          <SessionInfo
            formData={formData}
            setFormData={setFormData}
            handouts={handouts}
            errors={errors}
            zone={zone}
            setZone={setZone}
          />
        </AccordionItem>
        <AccordionItem uuid="finalIndicatorsInfo">
          <FinalIndicatorsInfo
            formData={formData}
            setFormData={setFormData}
            errors={[]}
          />
        </AccordionItem>
      </Accordion>
    </>
  );
};
