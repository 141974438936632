import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import { SwitherComponent } from 'component/Swither';
import React, { useEffect, useState } from 'react';

export const InternalHazardId = (props) => {
  const { formData, setFormData } = props;
  const [switherState, setSwitherState] = useState(
    !formData.info.general.custom_internal_id,
  );

  const previousId = formData.info.general.internal_id;

  const generatedId = previousId
    ? previousId
    : 'Автоматично згенерований';

  useEffect(() => {
    const id = formData.info.general.custom_internal_id;
    if (id) {
      setSwitherState(!id);
    }
  }, [formData.info.general.custom_internal_id]);

  const handleInternalId = (value) => {
    value = value.toUpperCase();
    if (/^[A-Z0-9-]*$/.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          general: {
            ...prevFormData.info.general,
            custom_internal_id: value,
          },
        },
      }));
    }
  };
  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.1</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Внутрішній ID небезпечної території в організації"
      titleEN="Internal Hazard ID"
      children={(
        <div className="flex-column-group align-right">
          {formData.is_internal_id_editable && (
            <>
              <SwitherComponent
                labelRight={(
                  <>
                    <div>Внести самостійно</div>
                    <div>Enter it yourself</div>
                  </>
                )}
                labelLeft={(
                  <>
                    <div>Автоматично згенерований</div>
                    <div>Automatically generated</div>
                  </>
                )}
                checked={switherState}
                handleSwitcher={(value) => {
                  if (value) {
                    handleInternalId('');
                  }
                  setSwitherState(!switherState);
                }}
              />
              {!switherState
                && (
                <InputComponent
                  id="internal"
                  pattern="^[A-Z0-9-]*$"
                  name="internalId"
                  value={!switherState ? formData.info.general.custom_internal_id : generatedId}
                  handleInput={handleInternalId}
                  disabled={switherState}
                />
                )}
            </>
          )}
          {!formData.is_internal_id_editable
            && (
              <span>
                {formData.info.general.custom_internal_id
                  || formData.info.general.internal_id}
              </span>
            )}
        </div>
      )}
    />
  );
};
