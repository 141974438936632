import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const HazardTypeView = (props) => {
  const { formData } = props;

  const hazardTypeOptions = {
    minefield: 'Мінне поле \\ Minefield',
    battlefield: 'Поле бою \\ BAC',
  };

  const selectedHazardTypes = Object.keys(hazardTypeOptions)
    .filter((option) => formData.info.general?.hazard_type?.includes(option))
    .map((option) => hazardTypeOptions[option]);

  return (
    <AccordionBodyItemView
      accordionSubId="1.11"
      titleUA="Тип небезпеки"
      titleEN="Hazard type"
      children={selectedHazardTypes}
    />
  );
};
