import React from 'react';
import { SwitherComponent } from 'component/Swither';
import { ReactComponent as DroneSvg } from 'assets/images/icons/drone.svg';
import { ReactComponent as FilterSvg } from 'assets/images/icons/filter.svg';
import { ReactComponent as InfoSvg } from 'assets/images/icons/error_24px.svg';

export const FilterCheckboxes = (props) => (
  <div className="upper-filter flex-group">
    <div className="box-map">
      <SwitherComponent
        className="green"
        labelRight="By hazard type"
        checked={props.filter === 'by-hazard_area_type'}
        handleSwitcher={() => {
          props.setFilter('by-hazard_area_type');
        }}
      />
      <SwitherComponent
        className="green"
        labelRight="By team"
        checked={props.filter === 'by-team'}
        handleSwitcher={() => {
          props.setFilter('by-team');
        }}
      />
      <SwitherComponent
        className="green"
        labelRight="By region"
        checked={props.filter === 'by-region'}
        handleSwitcher={() => {
          props.setFilter('by-region');
        }}
      />
      <SwitherComponent
        className="green"
        labelRight="Validation status"
        checked={props.filter === 'by-validation_status'}
        handleSwitcher={() => {
          props.setFilter('by-validation_status');
        }}
      />
    </div>
    <div
      className={`box-map pointer ${props.legendActive === 'drone' ? 'active' : ''}`}
      onClick={() => {
        if (props.legendActive === 'drone') {
          props.setLegendActive(null);
        } else {
          props.setLegendActive('drone');
        }
      }}
    >
      <DroneSvg color="#868686" />
    </div>
    <div
      className={`box-map pointer ${props.legendActive === 'filter' ? 'active' : ''}`}
      onClick={() => {
        if (props.legendActive === 'filter') {
          props.setLegendActive(null);
        } else {
          props.setLegendActive('filter');
        }
      }}
    >
      <FilterSvg color="#868686" />
    </div>
    <div
      className={`box-map pointer ${props.legendActive === 'legend' ? 'active' : ''}`}
      onClick={() => {
        if (props.legendActive === 'legend') {
          props.setLegendActive(null);
        } else {
          props.setLegendActive('legend');
        }
      }}
    >
      <InfoSvg color="#868686" />
    </div>
  </div>
);
