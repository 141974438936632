import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, TableHeader, TableRow } from 'component/Table';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as AddIcon } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as ValidateIcon } from 'assets/images/icons/Done-1.svg';
import { ReactComponent as ExpireIcon } from 'assets/images/icons/cancel_24px.svg';

const ActionWithIcon = ({ action, color, Icon }) => (
  <span className="flex-group action" style={{ color }}>
    <Icon color={color} />
    {action}
  </span>
);

export const UserLabel = ({ user }) => (

  <div className="notification-user">
    <div
      className="role-label"
      style={{
        backgroundColor: user?.role_color || user?.role?.color
          ? `${user.role_color || user?.role?.color}90`
          : '#a700e21a',
      }}
    >
      {user?.short_role_name || user?.role?.short_name}
    </div>
    <div className="info">
      <span>{`${user.name || ''} ${user.surname || ''}`}</span>
      <span>{user.email}</span>
    </div>
  </div>
);

export const NotificationsTable = ({ notifications, handleDeleteNotification, setOpen }) => {
  const addColorAndIconToAction = (action) => {
    switch (action) {
      case 'create':
        return <ActionWithIcon action={action} color="#00BC13" Icon={AddIcon} />;
      case 'edit':
        return <ActionWithIcon action={action} color="#E28800" Icon={EditIcon} />;
      case 'delete':
        return <ActionWithIcon action={action} color="#D26E6E" Icon={DeleteIcon} />;
      case 'validate':
        return <ActionWithIcon action={action} color="#00BC13" Icon={ValidateIcon} />;
      case 'expired':
        return <ActionWithIcon action={action} color="#D26E6E" Icon={ExpireIcon} />;
      default:
        return <span>{action}</span>;
    }
  };

  const generateLinkBasedOnType = (type, objectIdentifier, internal_id) => {
    switch (type) {
      case 'ha_report':
        return (
          <Link
            className="blue-link"
            to={`/view-ha-report/${objectIdentifier?.id}`}
            onClick={() => setOpen(false)}
          >
            {`HA report "${objectIdentifier?.name}"`}
          </Link>
        );
      case 'nts_report':
        return (
          <Link
            className="blue-link"
            to={`/view-nts-report/${objectIdentifier?.id}`}
            onClick={() => setOpen(false)}
          >
            {`NTS report "${objectIdentifier?.name}"`}
          </Link>
        );
      case 'eore_report':
        return (
          <Link
            className="blue-link"
            to={`/reports/eore/view/${objectIdentifier}`}
            onClick={() => setOpen(false)}
          >
            {`EORE report "${internal_id}"`}
          </Link>
        );
      case 'user':
        return <UserLabel user={objectIdentifier} />;
      case 'drone_data':
        return `Drone data "${objectIdentifier?.name}"`;
      case 'validations':
        return (
          <div className="flex-column-group">
            <div>
              Validation (
              {objectIdentifier?.report_type?.replace('_', ' ').toUpperCase()}
              )
            </div>
            <UserLabel user={objectIdentifier?.receiver} />
          </div>
        );
      default:
        return '-';
    }
  };

  return (
    <Table>
      <TableHeader>
        <th style={{ flex: '120px' }}>Time</th>
        <th style={{ flex: '300px' }}>User</th>
        <th>Action</th>
        <th>Link</th>
        <th>Delete</th>
      </TableHeader>
      <tbody>
        {(!notifications || notifications?.length === 0)
          && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
        {notifications?.map((notification) => (
          <TableRow key={notification.id}>
            <td style={{ flex: '120px' }}>
              {moment(notification.created_at).format('DD.MM.YYYY HH:mm')}
            </td>
            <td
              style={{ flex: '300px', justifyContent: 'start' }}
              aria-label="user-role"
            >
              {notification.type !== 'maxar_token' && (
                <UserLabel user={notification.user} />
              )}
              {notification.type === 'maxar_token' && (<div>Maxar</div>)}
            </td>
            <td>{addColorAndIconToAction(notification.action) || '-'}</td>
            <td>{generateLinkBasedOnType(notification.type, notification.object_identifier, notification.internal_id)}</td>
            <td aria-label="delete-icon">
              <DeleteIcon
                color="#D26E6E"
                width="27"
                height="29"
                className="hover-pointer"
                onClick={() => handleDeleteNotification(notification.id)}
              />
            </td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};
