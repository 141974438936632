import { Dialog } from '@mui/material';
import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { InputComponent } from 'component/Input';
import { SelectComponent } from 'component/Select';
import { DatePickerWrapper } from 'component/DataPicker';
import { axiosWrapper } from 'utils/Helper';
import { ReactComponent as DroneIcon } from 'assets/images/icons/drone.svg';
import { ReactComponent as ShedulleIcon } from 'assets/images/icons/Shedulle.svg';
import { FileUploadTiff } from 'component/FileUploadTiff';

export const UploadNewDroneData = (props) => {
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [tiffFile, setTiffFile] = useState(null);
  const [date, setDate] = useState(null);
  const [reportOptions, setReportOptions] = useState([]);
  const [report, setReport] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFileName(null);
    setTiffFile(null);
    setDate(null);
    setReport(null);
  };

  const uploadTiff = (id) => {
    const formData = new FormData();
    formData.append('tiff_file', tiffFile[0]);
    handleClose();
    axiosWrapper(() => {
      axios.post(`/drones/${id}/file/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          toast.success('Drone data created');
          props.setLoader(false);
          props.getDroneInfo(1);
        })
        .catch((err) => {
          console.log(err);
          toast.error('File not uploaded');
          props.setLoader(false);
        });
    });
  };

  const uploadNewDrone = () => {
    if (!tiffFile) {
      toast.warning('You need to upload file');
      return;
    }
    if (!date) {
      toast.warning('You need to set date');
      return;
    }
    props.setLoader(true);
    axiosWrapper(() => {
      axios.post('/drones/', {
        ha_report: report?.value,
        name: fileName,
        date,
      })
        .then((res) => {
          uploadTiff(res.data.id);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Drone data not created');
          props.setLoader(false);
        });
    });
  };

  const getReportOptions = () => {
    axiosWrapper(() => {
      axios.get('/reports/ha/?entire=true')
        .then((res) => {
          const reportNames = res.data.map((item) => ({
            value: item.id,
            label: item.info.general.hazard_name,
          }));
          setReportOptions(reportNames);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    getReportOptions();
  }, []);

  return (
    <div className="drone-new-btn">
      <ButtonComponent
        iconLeft={<DownloadSvg />}
        handleButton={handleClickOpen}
        label="Upload data"
        className=""
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Upload drone data</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="flex-column-group">
            <InputComponent
              id="drone-name"
              name="drone-name"
              label="File name"
              placeholder="Drone name"
              value={fileName}
              handleInput={setFileName}
              iconLeft={<DroneIcon />}
              classNameWrapper="align-left"
              className=""
            />
            <div className="card-wrapper">
              <div className="card tiff-card">
                <FileUploadTiff
                  tiff={tiffFile}
                  setTiff={setTiffFile}
                  types="tif, tiff"
                />
              </div>
            </div>
            <div className="label-input">
              <span>Date</span>
              <DatePickerWrapper
                handleDatePicker={(value) => setDate(moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY'))}
                className="full"
              />
            </div>

            <div className="label-input">
              <span>Report</span>
              <SelectComponent
                id="drone-report"
                name="drone-report"
                label="Report"
                className="full"
                placeholder={(
                  <div>
                    <ShedulleIcon />
                    <span>Select report</span>
                  </div>
                )}
                value={report?.value
                  ? { value: report.value, label: report.label }
                  : null}
                handleSelect={(value, label) => {
                  setReport({ value, label });
                }}
                options={reportOptions}
                isMulti={false}
                isDisabled={false}
              />
            </div>

          </div>
          <div className="buttons-group">
            <ButtonComponent
              className="danger"
              iconLeft={<CancelSvg />}
              handleButton={() => {
                handleClose();
              }}
              label="Close"
            />
            <ButtonComponent
              iconLeft={<DownloadSvg />}
              handleButton={uploadNewDrone}
              label="Upload"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
