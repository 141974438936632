import 'assets/styles/textarea.scss';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { React, useState, useEffect } from 'react';

export const TextareaComponent = (props) => {
  const [textareaValue, setTextareaValue] = useState(props.value);
  const maxLength = props.maxLength ? props.maxLength : 255;

  useEffect(() => {
    setTextareaValue(props.value);
  }, [props.value]);

  return (
    <div className="textarea-wrapper">
      <BlueLink
        blueLink={props.blueLink}
        textareaValue={textareaValue}
        maxLength={maxLength}
      />
      <TextareaAutosize
        id={`textarea-${props.id}`}
        style={props.style}
        className={props.className ? props.className : null}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => {
          if (props.limitChar) {
            if (e.target.value.length <= maxLength) {
              props.handleTextarea(e.target.value);
            }
          } else {
            props.handleTextarea(e.target.value);
          }
        }}
      />
      <label htmlFor={`textarea-${props.id}`}>
        {props.label}
      </label>
    </div>

  );
};

const BlueLink = (props) => {
  if (!props.blueLink) return false;
  if (props.textareaValue) {
    return (
      <div className="blue-link">
        Remaining
        {' '}
        {props.maxLength - props.textareaValue.length}
        {' '}
        characters
      </div>
    );
  }
  return <div className="blue-link">{props.blueLink}</div>;
};
