import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const IMSMAIDView = (props) => {
  const { formData, accordionSubId = '1.2' } = props;
  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Ідентифікаційний номер IMSMA"
      titleEN="IMSMA identification number"
      children={
        formData.info?.general?.imsma_id
        || 'Ідентифікаційний номер звіту генерується автоматично'
      }
    />
  );
};
