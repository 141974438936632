import axios from 'axios';

export const getHAById = async (id, param) => {
  const url = `/reports/ha/${id}/?compact=${param || false}`;
  const response = await axios.get(url);
  return response;
};

export const getHAComments = async (id) => {
  const url = `/validations/ha/${id}`;
  const response = await axios.get(url);
  return response;
};

export const rejectHaReport = async (id, body) => {
  const url = `/validations/ha/${id}/imsma-reject/`;
  const response = await axios.patch(url, body);
  return response;
};
