import { AccordionBodyItemView } from 'component/Accordion';
import React from 'react';
import { typeOfSessionImsmaOptions, typeOfSessionInternalOptions } from '../../Create/SessionInfo/TypeOfSession';

export const TypeOfSessionInternalView = (props) => {
  const { formData } = props;

  const value = typeOfSessionInternalOptions.find((option) => option?.value === formData
    ?.info?.session_information?.eore_type_internal);

  return (
    <>
      <AccordionBodyItemView
        accordionSubId="2.7.1"
        titleUA="Тип проведення"
        titleEN="Type of holding (internal)"
        children={value?.label}
      />
      {formData?.info?.session_information?.eore_type_internal === 'other' && (
        <AccordionBodyItemView
          accordionSubId=""
          titleUA="Інший варіант"
          titleEN="Other"
          children={formData?.info?.session_information?.eore_type_internal_other}
        />
      )}
    </>
  );
};

export const TypeOfSessionImsmaView = (props) => {
  const { formData } = props;

  const value = typeOfSessionImsmaOptions?.find((option) => option?.value === formData
    ?.info?.session_information?.eore_type_imsma);

  return (
    <>
      <AccordionBodyItemView
        accordionSubId="2.7.2"
        titleUA="Тип проведення"
        titleEN="Type of holding (IMSMA)"
        children={value?.label}
      />
      {formData?.info?.session_information?.eore_type_imsma === 'other' && (
        <AccordionBodyItemView
          accordionSubId=""
          titleUA="Інший варіант"
          titleEN="Other"
          children={formData?.info?.session_information?.eore_type_imsma_other}
        />
      )}
    </>
  );
};
