import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const ResidentalAreas = (props) => {
  const handleBeneficiaries = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        landuse_and_beneficiaries: {
          ...prevFormData.info.landuse_and_beneficiaries,
          residental_areas: Number(value),
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>8.1</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Житлові райони"
      titleEN="Residential areas"
      children={(
        <div className="flex-group">
          <input
            placeholder="0"
            id="residental_areas"
            name="residental_areas"
            className="number"
            value={props.formData.info.landuse_and_beneficiaries.residental_areas}
            autoComplete="off"
            options={{
              numericOnly: true,
              stripLeadingZeroes: true,
            }}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              const total = props.checkPercent('residental_areas') + newValue;
              if (total <= 100) {
                handleBeneficiaries(newValue);
              } else {
                e.preventDefault();
              }
            }}
          />
          <span>%</span>
        </div>
      )}
    />
  );
};
