import React from 'react';
import { Oval } from 'react-loader-spinner';
import 'assets/styles/loader.scss';

export const Loader = (props) => (
  <Oval
    height={100}
    width={100}
    color="#00B1B7"
    wrapperStyle={{}}
    wrapperClass="loader"
    visible={props.visible}
    ariaLabel="oval-loading"
    secondaryColor="#00B1B7"
    strokeWidth={4}
    strokeWidthSecondary={2}
  />
);

export const SmallLoader = (props) => (
  <Oval
    height={40}
    width={40}
    color="#00B1B7"
    wrapperStyle={{}}
    wrapperClass="small-loader"
    visible={props.visible}
    ariaLabel="oval-loading"
    secondaryColor="#00B1B7"
    strokeWidth={4}
    strokeWidthSecondary={2}
  />
);
