import 'assets/styles/input.scss';
import { React, useEffect, useState } from 'react';
import { ReactComponent as EyeOnSvg } from 'assets/images/icons/Eye-on.svg';
import { ReactComponent as EyeOffSvg } from 'assets/images/icons/Eye-off.svg';

export const InputComponent = (props) => {
  const [inputValue, setInputValue] = useState(props.value);
  const [type, setType] = useState(props.type);
  const [iconRight, setIconRight] = useState(props.iconRight);
  const maxLength = props.maxLength ? props.maxLength : 255;

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const showHidePassword = () => {
    if (type === 'password') {
      setIconRight(<EyeOnSvg />);
      setType('text');
    } else {
      setIconRight(<EyeOffSvg />);
      setType('password');
    }
  };

  return (
    <div className={`input-wrapper 
        ${props.hasError ? 'error' : ''}
        ${props.classNameWrapper ? props.classNameWrapper : ''}`}
    >
      <div className="labels">
        <label htmlFor={`input-${props.id}`}>
          {props.label}
        </label>
        <BlueLink
          blueLink={props.blueLink}
          inputValue={inputValue}
          maxLength={maxLength}
        />
      </div>
      {props.upperRightText
        && <div className="upper-right-text">{props.upperRightText}</div>}
      <div className={`position-relative ${props.iconRight || props.iconLeft ? 'icon-exist' : ''}`}>
        <input
          type={type}
          id={`input-${props.id}`}
          disabled={props.disabled}
          name={props.name}
          placeholder={props.placeholder}
          className={props.className ? props.className : ''}
          style={props.style}
          value={props.value}
          onChange={(e) => {
            if (props.limitChar) {
              if (e.target.value.length <= maxLength) {
                props.handleInput(e.target.value);
              }
            } else {
              props.handleInput(e.target.value);
            }
          }}
          autoComplete="new-password"
          pattern={props.pattern}
          onBlur={props.onBlur
            ? (e) => props.onBlur(e.target.value)
            : undefined}
        />
        <IconLeft
          iconLeft={props.iconLeft}
          icon={props.iconLeft}
        />
        <IconRight
          iconRight={props.iconRight}
          showHidePassword={() => showHidePassword(type)}
          icon={iconRight}
        />
      </div>

      <Description description={props.description} />

    </div>

  );
};

const Description = (props) => {
  if (!props.description) return false;
  return <div className="input-description">{props.description}</div>;
};

const BlueLink = (props) => {
  if (!props.blueLink) return false;
  if (props.inputValue) {
    return (
      <div className="blue-link">
        Remaining
        {' '}
        {props.maxLength - props.inputValue.length}
        {' '}
        characters
      </div>
    );
  }
  return <div className="blue-link">{props.blueLink}</div>;
};

const IconRight = (props) => {
  if (!props.iconRight) return false;
  return (
    <span
      className="icon icon-right"
      onClick={props.showHidePassword}
    >
      {props.icon}
    </span>
  );
};

const IconLeft = (props) => {
  const {
    iconLeft,
  } = props;

  if (!iconLeft) return false;
  return <span className="icon icon-left">{iconLeft}</span>;
};
