import { toast } from 'react-toastify';
import axios from 'axios';
import {
  getBufferZoneLayer, getFeodalLayer, getImsmaAllLayer, getImsmaFsdLayer,
} from 'api/LayersApi';

const setImagesFromGeojson = (map, imageData, iconName) => {
  if (!imageData) return;
  imageData.images?.forEach((item) => {
    const base64Image = `data:image/png;base64,${item.image_data}`;
    map.loadImage(base64Image, (error, image) => {
      if (error) throw error;
      const imageName = item.feature_property_value ? item.feature_property_value : iconName || 'imsmaImage';
      map.addImage(imageName, image);
    });
  });
};

export const setImsmaAllLayers = async (map, signal) => {
  const layerNames = ['hz_main', 'ev_location', 'hz_polygons', 'hz_polygons_points'];

  // Create an array of Promises for each layer loading operation
  const layerPromises = layerNames.map(async (layerName) => {
    try {
      const res = await getImsmaAllLayer(layerName, signal);

      map.addSource(`imsma-all-${layerName}`, {
        type: 'geojson',
        data: res.data.geojson,
        generateId: true,
      });

      map.addLayer({
        id: `imsma-all-${layerName}-layer`,
        type: 'fill',
        source: `imsma-all-${layerName}`,
        paint: {
          'fill-color': ['get', 'color', ['get', 'color', ['properties']]],
          'fill-opacity': ['get', 'color_alpha', ['get', 'color', ['properties']]],
          'fill-outline-color': ['get', 'outline_color', ['get', 'color', ['properties']]],
        },
        layout: {
          visibility: 'none',
        },
        filter: ['==', '$type', 'Polygon'],
      }, 'lm-layer');

      if (layerName === 'hz_main' || layerName === 'hz_polygons_points') {
        setImagesFromGeojson(map, res.data.image_data);
        map.addLayer({
          id: `imsma-all-point-${layerName}-layer`,
          type: 'symbol',
          source: `imsma-all-${layerName}`,
          layout: {
            'icon-image': ['get', res.data.image_data.feature_property],
            visibility: 'none',
          },
          filter: ['==', '$type', 'Point'],
        }, 'lm-layer');
      }
      if (layerName === 'ev_location') {
        setImagesFromGeojson(map, res.data.image_data);
        map.addLayer({
          id: `imsma-all-point-${layerName}-layer`,
          type: 'symbol',
          source: `imsma-all-${layerName}`,
          layout: {
            'icon-image': 'imsmaImage',
            visibility: 'none',
          },
          filter: ['==', '$type', 'Point'],
        }, 'lm-layer');
      }
      return true;
    } catch (err) {
      if (!signal?.aborted) {
        toast.error('IMSMA ALL layer is not loaded.');
      }
      return false;
    }
  });

  const results = await Promise.all(layerPromises);

  if (results.every((result) => result === true)) {
    console.log('All layers loaded successfully.');
  } else {
    console.log('Some layers failed to load.');
  }
};

export const setImsmaFsdLayers = async (map, signal) => {
  const layerNames = ['hz_main', 'ev_location', 'hz_polygons', 'hz_polygons_points'];

  try {
    await Promise.all(layerNames.map(async (layerName) => {
      const res = await getImsmaFsdLayer(layerName, signal);

      map.addSource(`imsma-fsd-${layerName}`, {
        type: 'geojson',
        data: res.data.geojson,
        generateId: true,
      });

      map.addLayer({
        id: `imsma-fsd-${layerName}-layer`,
        type: 'fill',
        source: `imsma-fsd-${layerName}`,
        paint: {
          'fill-color': ['get', 'color', ['get', 'color', ['properties']]],
          'fill-opacity': ['get', 'color_alpha', ['get', 'color', ['properties']]],
          'fill-outline-color': ['get', 'outline_color', ['get', 'color', ['properties']]],
        },
        layout: {
          visibility: 'none',
        },
        filter: ['==', '$type', 'Polygon'],
      }, 'lm-layer');

      if (layerName === 'hz_main' || layerName === 'hz_polygons_points') {
        setImagesFromGeojson(map, res.data.image_data);
        map.addLayer({
          id: `imsma-fsd-point-${layerName}-layer`,
          type: 'symbol',
          source: `imsma-fsd-${layerName}`,
          layout: {
            'icon-image': ['get', res.data.image_data.feature_property],
            visibility: 'none',
          },
          filter: ['==', '$type', 'Point'],
        }, 'lm-layer');
      }
    }));
    console.log('All IMSMA FSD layers loaded successfully.');
  } catch (error) {
    console.error(error);
    if (!signal?.aborted) {
      toast.error('IMSMA FSD layers failed to load.');
    }
  }
};

export const setBufferZone = async (map, signal) => {
  try {
    const res = await getBufferZoneLayer(signal);

    map.addSource('buffer-zone', {
      type: 'geojson',
      data: res.data,
      generateId: true,
    });

    map.addLayer({
      id: 'buffer-zone-layer',
      type: 'fill',
      source: 'buffer-zone',
      paint: {
        'fill-color': ['get', 'color', ['get', 'color', ['properties']]],
        'fill-opacity': ['get', 'color_alpha', ['get', 'color', ['properties']]],
        'fill-outline-color': ['get', 'outline_color', ['get', 'color', ['properties']]],
      },
      layout: {
        visibility: 'none',
      },
    }, 'lm-layer');

    console.log('Bufferzone layer loaded successfully.');
  } catch (error) {
    console.error(error);
    if (!signal?.aborted) {
      toast.error('Bufferzone layer is not loaded.');
    }
  }
};

export const setFeodalLayer = async (map, signal) => {
  const layerNames = ['Feodal', 'polygons_field', 'ammunition_points_repeat', 'transport_points_repeat'];
  try {
    await Promise.all(layerNames.map(async (layerName, index) => {
      const res = await getFeodalLayer(layerName, signal);

      map.addSource(`feodal-${layerName}`, {
        type: 'geojson',
        data: res.data.geojson,
        generateId: true,
      });

      map.addLayer({
        id: `feodal-polygon-${layerName}-layer`,
        type: 'fill',
        source: `feodal-${layerName}`,
        paint: {
          'fill-color': ['get', 'color', ['get', 'color', ['properties']]],
          'fill-opacity': ['get', 'color_alpha', ['get', 'color', ['properties']]],
          'fill-outline-color': ['get', 'outline_color', ['get', 'color', ['properties']]],
        },
        layout: {
          visibility: 'none',
        },
        filter: ['==', '$type', 'Polygon'],
      }, 'lm-layer');

      if (layerName !== 'polygons_field') {
        setImagesFromGeojson(map, res.data.image_data, `feodal-layer-icon-${index}`);
        map.addLayer({
          id: `feodal-point-${layerName}-layer`,
          type: 'symbol',
          source: `feodal-${layerName}`,
          layout: {
            'icon-image': `feodal-layer-icon-${index}`,
            'icon-size': 0.5,
            visibility: 'none',
          },
          filter: ['==', '$type', 'Point'],
        }, 'lm-layer');
      }
    }));

    console.log('All Feodal layers loaded successfully.');
  } catch (error) {
    console.error(error);
    if (!signal?.aborted) {
      toast.error('Feodal layers failed to load.');
    }
  }
};

const setMaxarLayer = async (map) => {
  let toastDisplayed = false;
  map.addSource('maxar-source', {
    type: 'raster',
    tiles: [`${axios.defaults.baseURL}imsma/maxar-tiles/?coords={bbox-epsg-3857}&width=256&height=256`],
    tileSize: 256,
  });

  map.addLayer({
    id: 'maxar-source-layer',
    type: 'raster',
    source: 'maxar-source',
    layout: {
      visibility: 'none',
    },
  }, 'lm-layer');

  map.on('error', (e) => {
    if (e.sourceId === 'maxar-source') {
      if (e.error?.status === 400 && !toastDisplayed) {
        toast.error('Maxar token is not valid. Please contact the administrator.');
        map.removeLayer('maxar-source-layer');
        map.removeSource('maxar-source');
        toastDisplayed = true;
      }
    }
  });
};

export const setMapboxWMSLayers = async (map, signal) => {
  try {
    await setMaxarLayer(map);
    await setImsmaAllLayers(map, signal);
    await setImsmaFsdLayers(map, signal);
    await setBufferZone(map, signal);
    await setFeodalLayer(map, signal);
  } catch (error) {
    console.error(error);
  }
};
