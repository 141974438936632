import React from 'react';
import { SelectComponent } from 'component/Select';
import { validationStatus } from 'consts/ValidationStatus';

export const ValidationStatusFilter = (props) => {
  const getValidationOptions = () => Object.entries(validationStatus).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <div className="flex-column-group">
      <span>Sort by validation status</span>
      <SelectComponent
        className="full"
        isMulti
        value={props.validStatusFilter}
        options={getValidationOptions()}
        handleSelect={props.setValidStatusFilter}
      />
    </div>
  );
};
