/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import ArrowIcon from 'assets/images/icons/arrow_left.svg';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';

import RadioDisabledIcon from 'assets/images/icons/radio_disabled.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'component/Loader';
import { axiosWrapper } from 'utils/Helper';
import { getNTSById } from 'api/NtsApi';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import NTSPoint from 'assets/images/icons/location.png';
import { ReactComponent as TranslateSvg } from 'assets/images/icons/translate_24px.svg';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  AccordionBodyItemView,
  AccordionHeaderTitle,
} from 'component/Accordion';
import { InputComponent } from 'component/Input';
import { ButtonComponent } from 'component/Button';
import axios from 'axios';
import { NTSFormData } from 'consts/Forms';
import { Map } from 'component/Map';
import { featureCollection, point } from '@turf/helpers';
import { getDonorById } from 'api/DonorsApi';
import { TranslateWrapperComponent } from 'component/Translation';

const ValidationNTSReportPage = () => {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const { reportId } = useParams();

  const [coords3857, setCoords3857] = useState([null, null]);

  const [formData, setFormData] = useState(NTSFormData);

  const [commentForm, setCommentForm] = useState({
    general: '',
    location: '',
    nto_result: '',
  });

  const [savedComment, setSavedComment] = useState({
    general: commentForm.general,
    location: commentForm.location,
    nto_result: commentForm.nto_result,
  });

  const [comments, setComments] = useState([]);

  const [donorName, setDonorName] = useState(null);
  const [translatedFormData, setTranslatedFormData] = useState([]);
  const [translateState, setTranslateState] = useState(false);

  const reasonLabels = {
    planned: 'Заплановане НТО / Planned NTO',
    application: 'По заявці інше / Application other',
    farmer: 'По заявці від фермерів (Feodal) / Farmer application (Feodal)',
    repeated: 'Повторне НТО / Repeated NTO',
  };

  const [commentEntered, setCommentEntered] = useState({
    general: false,
    location: false,
    nto_result: false,
  });

  const selectedReasonLabel = reasonLabels[formData.info.nto_result.nto_reason];
  const [mode, setMode] = useState(null);

  useEffect(() => {
    const savedMode = localStorage.getItem('mode');
    setMode(savedMode);

    localStorage.removeItem('mode');
  }, []);

  const selectedNTSReportId = window.location.pathname.split('/')[2];
  const isValidationMode = mode === 'validation';
  
  useEffect(() => {
    setLoader(true);
    getNTSById(reportId)
      .then((response) => {
        response.data.draft = true;
        setFormData(response.data);
        if (response.data.point) {
          setCoords3857(response.data.point.coordinates);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.detail === 'Not found.') {
          toast.error('Report not found');
          setTimeout(() => navigate('/nts-ha-report'), 2000);
        }
        console.error('Error fetching NTS data:', error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const addMapboxPointLayer = (geojson) => {
    if (!geojson) return;
    if (!map.getSource('nts-point')) {
      map.addSource('nts-point', {
        type: 'geojson',
        data: geojson,
        generateId: true,
      });

      [{ 'nts-point-icon': NTSPoint }].forEach((icon) => {
        map.loadImage(Object.values(icon)[0], (error, image) => {
          if (error) throw error;
          map.addImage(Object.keys(icon)[0], image);
        });
      });

      map.addLayer({
        id: 'nts-point-layer',
        type: 'symbol',
        source: 'nts-point',
        layout: {
          'icon-image': 'nts-point-icon',
          'icon-size': 1,
          'icon-offset': [0, -12],
          'icon-allow-overlap': true,
        },
      });
    } else {
      map.getSource('nts-point').setData(geojson);
    }
  };

  useEffect(() => {
    document.title = `Validation NTS report - ${formData?.info.general.hazard_name || 'Unknow'}`;
  }, [formData]);

  useEffect(() => {
    if (!map) return;
    const prop = formData.info.point?.coordinates;
    if (prop && prop[0] && prop[1]) {
      addMapboxPointLayer(featureCollection([point(prop)]));
      setCoords3857(formData.point?.coordinates);
    } else {
      addMapboxPointLayer(featureCollection([]));
      setCoords3857([null, null]);
    }
  }, [map, formData]);

  const getDonorName = (donorId) => {
    axiosWrapper(() => {
      getDonorById(donorId)
        .then((res) => {
          setDonorName(res.data.name);
        })
        .catch((err) => {
          console.log(err);
          setDonorName(null);
        });
    });
  };

  const PageTitle = (props) => (
    <div className="page-title">
      <img
        src={ArrowIcon}
        alt="Back"
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
      />
      <div>{props.title || '-'}</div>
      <span className="blue-text">
        |
        {props.name || '-'}
      </span>
    </div>
  );
  const handleComments = (block, value) => {
    setCommentForm((prevForm) => ({
      ...prevForm,
      [block]: value,
    }));
  };

  const getAllComments = () => {
    axiosWrapper(() => {
      axios.get(`/validations/nts/${selectedNTSReportId}`).then((data) => {
        const allComments = data.data.iterations;
        setComments(allComments);
      });
    });
  };

  const filteredGeneralComments = comments
    .filter((item) => {
      const commentText = item?.comments?.general;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredLocationComments = comments
    .filter((item) => {
      const commentText = item?.comments?.location;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredNtoResultComments = comments
    .filter((item) => {
      const commentText = item?.comments?.nto_result;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const handleValidationRejectButton = (status, event) => {
    setLoader(true);
    event.preventDefault();
    axiosWrapper(() => {
      axios
        .put(`/validations/nts/${selectedNTSReportId}/`, {
          status,
          comments: savedComment,
        })
        .then((res) => {
          toast.success('Rejected');
          setLoader(false);
          setTimeout(() => {
            navigate('/nts-ha-report');
          }, 1000);
        })
        .catch((err) => {
          console.error('Error rejecting NTS report:', err);
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Please try again');
          }
          toast.error('Error rejecting NTS report. Please try again.');
          setLoader(false);
        });
    });
  };

  const handleValidationAcceptButton = (status, event) => {
    setLoader(true);
    event.preventDefault();
    setCommentForm({
      general: '',
      location: '',
      nto_result: '',
    });

    axiosWrapper(() => {
      axios
        .put(`/validations/nts/${selectedNTSReportId}/`, {
          status,
          comments: savedComment,
        })

        .then((res) => {
          toast.success('Accepted');
          setLoader(false);
          setTimeout(() => {
            navigate('/nts-ha-report');
          }, 1000);
        }).catch((err) => {
          console.error('Error accepting NTS report:', err);
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Please try again');
          }
          toast.error('Error accepting NTS report. Please try again.');
          setLoader(false);
        });
    });
  };

  const onSaveComment = (event, block) => {
    setSavedComment((prevComment) => ({
      ...prevComment,
      [block]: commentForm[block],
    }));

    setCommentForm((prevForm) => ({
      ...prevForm,
      [block]: '',
    }));
    setCommentEntered((prevState) => ({
      ...prevState,
      [block]: true,
    }));
  };

  const onDeleteComment = (event, block) => {
    event.preventDefault();

    const confirmDelete = window.confirm(
      'Are you sure you want to delete this comment?',
    );

    if (confirmDelete) {
      setSavedComment((prevComment) => ({
        ...prevComment,
        [block]: '',
      }));
    }
    setCommentEntered((prevState) => ({
      ...prevState,
      [block]: false,
    }));
  };

  useEffect(() => {
    getAllComments();
    if (selectedNTSReportId) {
      axiosWrapper(() => {
        getNTSById(selectedNTSReportId)
          .then((response) => {
            setFormData(response?.data);
            if (response?.data.donor) {
              getDonorName(response.data.donor);
            }
          })
          .catch((error) => {
            console.error('Error fetching NTS data:', error);
            toast.error('Error fetching NTS data');
          })
          .finally(() => {
            setLoader(false);
          });
      });
    }
  }, [selectedNTSReportId]);

  return (

    <section>
      <PageTitle
        title="Validation"
        name={formData?.info.general.hazard_name || 'Unknown'}
        navigate={navigate}
      />
      <TranslateWrapperComponent
        formData={formData}
        setTranslatedFormData={setTranslatedFormData}
        translationState={translateState}
      >
        <form className="form-wrapper">
          <div
            className="text-hover flex-group flex-right"
            onClick={() => setTranslateState(!translateState)}
          >
            <TranslateSvg />
            <span>Translate</span>
          </div>
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={['general', 'nonTechnical', 'ntoResult']}
            className="custom-accordion"
          >
            <AccordionItem uuid="general" className="custom-accordion">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle
                    accordionId="1"
                    titleUA="Загальна інформація"
                    titleEN="General Information"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="custom-accordion-item-panel">
                <AccordionBodyItemView
                  accordionSubId="1.1"
                  titleUA="Ідентифікаційний номер IMSMA"
                  titleEN="IMSMA identification number"
                  children={
                    formData.info.general.imsma_id
                    || 'Ідентифікаційний номер звіту генерується автоматично'
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="1.1.1"
                  titleUA="Відправляти до IMSMA"
                  titleEN="Send to IMSMA"
                  children={formData.send_to_imsma ? 'Так' : 'Ні'}
                />
                <AccordionBodyItemView
                  accordionSubId="1.2"
                  titleUA="Дата звіту"
                  titleEN="Report date"
                  children={formData.info.general.report_date}
                />
                <AccordionBodyItemView
                  accordionSubId="1.3"
                  titleUA="Організація, що звітує"
                  titleEN="Reporting organization"
                  children={formData?.info.general.reporter_organization}
                />
                <AccordionBodyItemView
                  accordionSubId="1.4"
                  titleUA="Звіт надав"
                  titleEN="Report provided"
                  children={(
                    <span className="no-wrap">
                      {formData.info.general.reporter_name}
                    </span>
                  )}
                />
                <AccordionBodyItemView
                  accordionSubId="1.5"
                  titleUA="Посада особи, що звітує"
                  titleEN="Reporter position"
                  children={(
                    <span className="no-wrap">
                      {translateState && translatedFormData.reporter_occupation}
                      {!translateState && formData.info.general.reporter_occupation}
                    </span>
                  )}
                />
                <AccordionBodyItemView
                  accordionSubId="1.6"
                  titleUA="Дата проведення нетехнічного обстеження"
                  titleEN="Report date"
                  children={formData.info.general.non_tech_examination_date}
                />
                <AccordionBodyItemView
                  accordionSubId={(
                    <div>
                      <span />
                    </div>
                  )}
                  titleUA="Донор"
                  titleEN="Donor"
                  children={(
                    <div className="flex-group no-wrap">
                      {donorName || 'Відсутній'}
                    </div>
                  )}
                />
              </AccordionItemPanel>
              {isValidationMode && (
                <AccordionItemPanel>
                  {filteredGeneralComments?.map((item, index) => {
                    const isLastComment = index === 0;
                    const commentClassName = isLastComment
                      ? 'block-with-comment-green'
                      : 'block-with-comment-gray';

                    return (
                      <div key={index} className={commentClassName}>
                        <div className="icon">
                          <ChatIcon
                            style={{
                              width: '20px',
                              color: '#868686',
                            }}
                          />
                        </div>
                        {item?.comments?.general}
                      </div>
                    );
                  })}
                  <div className="comment-view-block">
                    {savedComment?.general && (
                      <>
                        <div className="block-with-comment">
                          <div className="icon-with-comment">
                            <ChatIcon style={{ width: '20px' }} />
                            <div className="comment">
                              {savedComment?.general}
                            </div>
                          </div>
                          <div className="comment-buttons">
                            <DeleteIcon
                              className="delete-comment-button"
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => onDeleteComment(e, 'general')}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {!commentEntered.general && (
                      <div className="input-with-button">
                        <InputComponent
                          classNameWrapper="w-100"
                          style={{ width: '-webkit-fill-available' }}
                          className="input-view"
                          handleInput={(value) => handleComments('general', value)}
                          onBlur={(e) => onSaveComment(e, 'general')}
                          value={commentForm?.general}
                          placeholder="Add comment"
                        />
                      </div>
                    )}
                  </div>
                </AccordionItemPanel>
              )}
            </AccordionItem>
            <AccordionItem uuid="nonTechnical">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle
                    accordionId="2"
                    titleUA="Місце проведення нетехнічного обстеження"
                    titleEN="Place of non-technical examination"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="custom-accordion-item-panel">
                <AccordionBodyItemView
                  accordionSubId="2.1"
                  titleUA="Область"
                  titleEN="Region"
                  children={formData?.info?.location.region}
                />

                <AccordionBodyItemView
                  accordionSubId="2.2"
                  titleUA="Район"
                  titleEN="District"
                  children={formData?.info?.location.district}
                />
                <AccordionBodyItemView
                  accordionSubId="2.3"
                  titleUA="Територіальна громада"
                  titleEN="Local community"
                  children={formData?.info?.location.local_community}
                />

                <AccordionBodyItemView
                  accordionSubId="2.4"
                  titleUA="Населений пункт"
                  titleEN="Locality"
                  children={formData?.info?.location.settlement_name}
                />

                <AccordionBodyItemView
                  accordionSubId="2.5"
                  titleUA="Опис місця проведення нетехнічного обстеження"
                  titleEN="Description of the location of the non-technical inspection"
                  children={(
                    <>
                      {!translateState && formData?.info?.location.description}
                      {translateState && translatedFormData.location_description}
                    </>
                  )}
                />
              </AccordionItemPanel>
              <div className="flex-column-group no-y-margin w-100">
                <Map
                  map={map}
                  setMap={setMap}
                />
                <div className="flex-group">
                  <div className="double-text">
                    <span>Координати</span>
                    <span>Coordinates</span>
                  </div>
                  <div className="line" />
                  <div className="flex-group text flex-wrap">
                    <span>
                      Long:
                      {coords3857[0] ? coords3857[0].toFixed(0) : 0}
                    </span>
                    <span>
                      Lat:
                      {coords3857[1] ? coords3857[1].toFixed(0) : 0}
                    </span>
                  </div>
                </div>
              </div>
              {isValidationMode && (
                <AccordionItemPanel>
                  {filteredLocationComments?.map((item, index) => {
                    const isLastComment = index === 0;
                    const commentClassName = isLastComment
                      ? 'block-with-comment-green'
                      : 'block-with-comment-gray';

                    return (
                      <div key={index} className={commentClassName}>
                        <div className="icon">
                          <ChatIcon
                            style={{
                              width: '20px',
                              color: '#868686',
                            }}
                          />
                        </div>
                        {item?.comments?.location}
                      </div>
                    );
                  })}
                  <div className="comment-view-block">
                    {savedComment?.location && (
                      <div className="block-with-comment">
                        <div className="icon-with-comment">
                          <ChatIcon style={{ width: '20px' }} />
                          <div className="comment">
                            {savedComment?.location}
                          </div>
                        </div>
                        <div className="comment-buttons">
                          <DeleteIcon
                            className="delete-comment-button"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => onDeleteComment(e, 'location')}
                          />
                        </div>
                      </div>
                    )}
                    {!commentEntered.location && (
                      <div className="input-with-button">
                        <InputComponent
                          classNameWrapper="w-100"
                          style={{ width: '-webkit-fill-available' }}
                          className="input-view"
                          handleInput={(value) => handleComments('location', value)}
                          onBlur={(e) => onSaveComment(e, 'location')}
                          value={commentForm?.location}
                          placeholder="Add comment"
                        />
                      </div>
                    )}
                  </div>
                </AccordionItemPanel>
              )}
            </AccordionItem>
            <AccordionItem uuid="ntoResult">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle
                    accordionId="3"
                    titleUA="Результат НТО"
                    titleEN="Result of NTO"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="custom-accordion-item-panel">
                <AccordionBodyItemView
                  accordionSubId="3.0"
                  titleUA="Village by village"
                  titleEN="Village by village"
                  children={
                    formData?.info?.nto_result.village_by_village ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.1"
                  titleUA="Причини проведення HTO"
                  titleEN="Reasons for performing NTS"
                  children={selectedReasonLabel}
                />
                <AccordionBodyItemView
                  accordionSubId="3.2"
                  titleUA="Чи виявлені будь-які докази?"
                  titleEN="Have any evidence been found?"
                  children={
                    formData?.info?.nto_result.evidences ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.3"
                  titleUA="Чи має бути створено звіт про виявлення одиничного боєприпасу?"
                  titleEN="Should a report be created for the detection of a single ordnance?"
                  children={
                    formData?.info?.nto_result?.single_ammo_report_needed ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.4"
                  titleUA="Чи має бути створено нову небезпечну територію?"
                  titleEN="Should a new hazardous area be established?"
                  children={
                    formData?.info?.nto_result.new_hazard_area_report_needed ? (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Так / Yes
                      </div>
                    ) : (
                      <div className="accordion-view-disabled-icon">
                        <img src={RadioDisabledIcon} alt="" />
                        {' '}
                        Ні / No
                      </div>
                    )
                  }
                />
                <AccordionBodyItemView
                  accordionSubId="3.5"
                  titleUA="Коментар"
                  titleEN="Comment"
                  children={(
                    <>
                      {!translateState && formData.info.nto_result?.comment}
                      {translateState && translatedFormData.nto_result_comment}
                    </>
                  )}
                />
              </AccordionItemPanel>

              {isValidationMode && (
                <AccordionItemPanel>
                  {filteredNtoResultComments?.map((item, index) => {
                    const isLastComment = index === 0;
                    const commentClassName = isLastComment
                      ? 'block-with-comment-green'
                      : 'block-with-comment-gray';

                    return (
                      <div key={index} className={commentClassName}>
                        <div className="icon">
                          <ChatIcon
                            style={{
                              width: '20px',
                              color: '#868686',
                            }}
                          />
                        </div>
                        {item?.comments?.nto_result}
                      </div>
                    );
                  })}
                  <div className="comment-view-block">
                    {savedComment?.nto_result && (
                      <div className="block-with-comment">
                        <div className="icon-with-comment">
                          <ChatIcon style={{ width: '20px' }} />
                          <div className="comment">
                            {savedComment?.nto_result}
                          </div>
                        </div>
                        <div className="comment-buttons">
                          <DeleteIcon
                            className="delete-comment-button"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => onDeleteComment(e, 'nto_result')}
                          />
                        </div>
                      </div>
                    )}
                    {!commentEntered.nto_result && (
                      <div className="input-with-button">
                        <InputComponent
                          classNameWrapper="w-100"
                          style={{ width: '-webkit-fill-available' }}
                          className="input-view"
                          handleInput={(value) => handleComments('nto_result', value)}
                          onBlur={(e) => onSaveComment(e, 'nto_result')}
                          value={commentForm?.nto_result}
                          placeholder="Add comment"
                        />
                      </div>
                    )}
                  </div>
                </AccordionItemPanel>
              )}
            </AccordionItem>
          </Accordion>
          {isValidationMode && (
            <div className="form-buttons buttons-group py-10">
              <ButtonComponent
                handleButton={(event) => handleValidationRejectButton('rejected', event)}
                label="Reject"
                disabled={false}
                className="danger"
                type="button"
              />
              <ButtonComponent
                handleButton={(event) => handleValidationAcceptButton('accepted', event)}
                label="Accept"
                disabled={false}
                type="button"
              />
            </div>
          )}
        </form>
      </TranslateWrapperComponent>

      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export default ValidationNTSReportPage;
