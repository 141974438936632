import React from 'react';
import { AccordionHeaderTitle } from 'component/Accordion';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { Informator } from './Informators/Informator';

export const InformatorsInfo = (props) => {
  const evidenceInformators = props.formData.info.evidences?.indirect_evidences.informators;
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="9"
            titleUA="Інформатори"
            titleEN="Informants"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {evidenceInformators.map((_, index) => (
          <Informator
            key={index}
            formData={props.formData}
            setFormData={props.setFormData}
            informatorId={index}
            errors={props.errors}
          />
        ))}
      </AccordionItemPanel>
    </>
  );
};
