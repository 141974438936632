import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { removeLeadingZeros } from '../../../../utils/Helper';

export const AccessView = (props) => {
  const { formData } = props;
  const landuse = formData.info.landuse_and_beneficiaries;

  return (
    <AccordionBodyItemView
      accordionSubId="8.4"
      titleUA="Доступи (дороги, залізниці, мости)"
      titleEN="Access (roads, railways, bridges)"
      children={(
        <div className="flex-group flex-wrap">
          {removeLeadingZeros(landuse.roads_railways_bridges)}
        </div>
      )}
    />
  );
};
