import React from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as MobileSvg } from 'assets/images/icons/mobile.svg';

export const UserPhoneNumber = (props) => {
  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const formattedNumber = number.replace(/[^0-9]/g, '');
    return `+${formattedNumber}`;
  };

  const handlePhoneNumber = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      phone_number: value ? formatPhoneNumber(value) : prev.phone_number,
    }));
  };

  return (
    <InputComponent
      type="text"
      upperRightText="Phone number"
      id="user-phone-number"
      name="phone-number"
      placeholder="+38(099) 999 99 99"
      value={props.phoneNumber}
      handleInput={handlePhoneNumber}
      iconLeft={<MobileSvg />}
      autoComplete="off"
    />
  );
};

export const PhoneNumberView = (props) => (
  <div className="double-text">
    <span>Phone number</span>
    <span>{props.phoneNumber || '-'}</span>
  </div>
);
