import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { SwitherComponent } from 'component/Swither';

export const IMSMASwither = (props) => {
  const updateSendToImsma = (newValue) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      send_to_imsma: newValue,
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{props.formType === 'ha' ? '1.2.1' : '1.1.1'}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Відправляти до IMSMA"
      titleEN="Send to IMSMA"
      children={(
        <SwitherComponent
          labelRight="No"
          labelLeft="Yes"
          checked={props.formData.send_to_imsma}
          handleSwitcher={updateSendToImsma}
        />
      )}
    />
  );
};
