import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { InternalHazardIdView } from './InternalHazardId';
import { IMSMAIDView } from './IMSMAID';
import { IMSMASwitcherView } from './IMSMASwitcher';
import { HazardNameView } from './HazardName';
import { ReportDateView } from './ReportDate';
import { ReporterOrganizationView } from './ReporterOrganization';
import { ReportProviderView } from './ReportProvider';
import { TeamIdView } from './TeamId';
import { ReporterOccupationView } from './ReporterOccupation';
import { ExaminationDateView } from './ExaminationDate';
import { HazardAreaTypeView } from './HazardAreaType';
import { HazardTypeView } from './HazardType';

import { DonorView } from './Donor';
import { CommentsView } from '../Comments';

export const GeneralInfoView = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;

  return (
    <AccordionItem uuid="general">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="1"
            titleUA="Загальна інформація"
            titleEN="General Information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <InternalHazardIdView formData={formData} />
        <IMSMAIDView formData={formData} />
        <IMSMASwitcherView formData={formData} />
        <HazardNameView formData={formData} />
        <ReportDateView formData={formData} />
        <ReporterOrganizationView formData={formData} />
        <ReportProviderView formData={formData} />
        <TeamIdView formData={formData} />
        <ReporterOccupationView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <ExaminationDateView formData={formData} />
        <HazardAreaTypeView formData={formData} />
        <HazardTypeView formData={formData} />
        <DonorView formData={formData} />

        <CommentsView
          comments={comments}
          block="general"
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
