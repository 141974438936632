import React from 'react';
import proj4 from 'proj4';

export const LocationIcon = (props) => {
  const flyToCoords = (coords) => {
    proj4.defs('EPSG:32637', `+proj=utm +zone=${props.zone} +datum=WGS84 +units=m +no_defs +type=crs`);
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
    const convertedCoordinates = proj4('EPSG:32637', 'EPSG:4326', coords);
    if (props.map && coords[0] !== 0 && coords[1] !== 0) {
      props.map.flyTo({ center: convertedCoordinates, zoom: 15 });
    }
  };

  const higlightText = (featureId) => {
    props.map.setPaintProperty(`${props.source}-text-layer`, 'text-color', [
      'case',
      ['==', ['id'], featureId],
      '#00b1b7',
      '#000000',
    ]);
  };

  return (
    <div
      className="text-hover"
      onClick={() => {
        higlightText(props.id);
        flyToCoords(props.coords);
      }}
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9 14C11.7614 14 14 11.7614 14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14ZM9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z" fill="#868686" />
        <path d="M8 1C8 0.447715 8.44772 0 9 0C9.55228 0 10 0.447715 10 1V5C10 5.55228 9.55228 6 9 6C8.44772 6 8 5.55228 8 5V1Z" fill="#868686" />
        <path d="M8 13C8 12.4477 8.44772 12 9 12C9.55228 12 10 12.4477 10 13V17C10 17.5523 9.55228 18 9 18C8.44772 18 8 17.5523 8 17V13Z" fill="#868686" />
        <path d="M0 9C0 8.44772 0.447715 8 1 8H5C5.55228 8 6 8.44772 6 9C6 9.55228 5.55228 10 5 10H1C0.447715 10 0 9.55228 0 9Z" fill="#868686" />
        <path d="M12 9C12 8.44772 12.4477 8 13 8H17C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H13C12.4477 10 12 9.55228 12 9Z" fill="#868686" />
        <path d="M10 9C10 9.55229 9.55229 10 9 10C8.44771 10 8 9.55229 8 9C8 8.44771 8.44771 8 9 8C9.55229 8 10 8.44771 10 9Z" fill="#868686" />
      </svg>
    </div>
  );
};
