import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { Table, TableHeader, TableRow } from 'component/Table';
import { InfoItem } from 'component/InfoItem';

export const IndirectBeneficiariesView = (props) => {
  const { formData } = props;
  const beneficiaries = formData.info.landuse_and_beneficiaries;

  return (
    <>
      <div className="flex-column-group">
        <AccordionBodyItem
          accordionSubId=""
          titleUA="Кількість непрямих бенефіціарів"
          titleEN="Number of indirect beneficiaries"
        />
        <Table>
          <TableHeader>
            <th>
              <div>8.12 Чоловіків (18 та старше)</div>
              <div>Men (18+)</div>
            </th>
            <th>
              <div>8.13 Жінок (18 та старше)</div>
              <div>Women (18+)</div>
            </th>
            <th>
              <div>8.14 Хлопчиків (молодше 18)</div>
              <div>Boys</div>
            </th>
            <th>
              <div>8.15 Дівчаток (молодше 18)</div>
              <div>Girls</div>
            </th>
          </TableHeader>
          <tbody>
            <TableRow>
              <td>{beneficiaries.number_of_indirect_beneficiaries?.men || 0}</td>
              <td>{beneficiaries.number_of_indirect_beneficiaries?.women || 0}</td>
              <td>{beneficiaries.number_of_indirect_beneficiaries?.boys || 0}</td>
              <td>{beneficiaries.number_of_indirect_beneficiaries?.girls || 0}</td>
            </TableRow>
          </tbody>
        </Table>
      </div>
      <div
        className="flex-right"
        style={{ marginTop: '10px', paddingBottom: '10px' }}
      >
        <InfoItem
          titleEN="Total number of direct beneficiaries"
          titleUA="8.16 Загальна кількість непрямих бенефіціарів"
          data={beneficiaries.number_of_indirect_beneficiaries
            ? Object.values(beneficiaries.number_of_indirect_beneficiaries)
              .reduce((acc, item) => Number(acc) + Number(item))
            : 0}
        />
      </div>
    </>
  );
};
