import {
  React,
  createContext,
  useState,
  useContext,
  useMemo,
} from 'react';
import { login } from 'api/UserAPI';
import { getCookie, removeCookie } from 'utils/Cookies';
import { setAxiosInterceptors, setTokens } from 'utils/Helper';
import { useMapbox } from 'utils/MapboxContext';
import { useDeepL } from 'utils/DeepLContext';
import { useUser } from './UserContext';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userCookies, setUserCookies] = useState(getCookie('access_token') || '');
  const { getMapboxToken, setToken } = useMapbox();
  const { setUser, getUserInfo, setAvatar } = useUser();
  const { setDeepLToken, getDeepLToken } = useDeepL();

  const loginUser = (userData) => {
    const dataPromise = login(userData).then((res) => {
      setTokens(res.data.access, res.data.refresh);
      setUserCookies(res.data.access);
      setAxiosInterceptors();
      getUserInfo();
      getMapboxToken();
      getDeepLToken();
      return res;
    });
    return dataPromise;
  };

  const logoutUser = async () => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    setUserCookies(null);
    setUser(null);
    setAvatar(null);
    setToken(null);
    setDeepLToken(null);
  };

  const authValue = useMemo(() => (
    { userCookies, loginUser, logoutUser }), [userCookies, loginUser, logoutUser]);

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
};
