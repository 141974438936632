import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import { sex } from 'consts/Sex';

export const Sex = (props) => {
  const options = [{
    value: 'male',
    label: 'Чоловіча / Male',
  }, {
    value: 'female',
    label: 'Жіноча / Female',
  }, {
    value: 'undefined',
    label: 'Невизначено / Undefined',
  },

  ];
  return (
    <AccordionBodyItem
      accordionSubId={`9.${props.id}`}
      titleUA="Стать"
      titleEN="Sex"
      children={(
        <SelectComponent
          options={options}
          value={{
            label: sex[props.formData.info.informators[props.index].sex],
            value: props.formData.info.informators[props.index].sex,
          }}
          handleSelect={(value) => props.handleInformator('sex', value)}
        />
      )}
    />
  );
};
