import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const AppropriateOperationView = (props) => {
  const { formData } = props;
  const appropriateOperation = formData.info.next_landrelease_activity;

  const nextOperationOptions = {
    resurvey: 'Повторне проведення \\ НТО  Re-survey',
    techsurvey: 'Технічне обстеження \\ Technical survey',
    clearance: 'Розмінування \\ Clearance',
    cancellation: 'Відміна \\ Cancellation',
  };

  const selectedNextOperationsWithBackslashes = Object.keys(nextOperationOptions)
    .filter((option) => appropriateOperation.next_most_appropriate_operation.includes(option))
    .map((option) => nextOperationOptions[option]);

  return (
    <AccordionBodyItem
      accordionSubId="7.2"
      titleUA="Рекомендований подальший етап розблокування"
      titleEN="Most appropriate operation to conduct next"
      rightContentClassName="w-80"
      children={(
        <div className="flex-group flex-wrap">
          {selectedNextOperationsWithBackslashes.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon
                style={{ width: '20px', height: '20px' }}
              />
              {item}
            </div>
          ))}
        </div>
      )}
    />
  );
};
