import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import 'assets/styles/pages/lm-bm.scss';
import { Map } from 'component/Map';
import { React, useState, useEffect } from 'react';
import { convert3857To4326 } from 'utils/Helper';
import { featureCollection, point } from '@turf/helpers';
import { TableFileInfo } from './FileInformation/TableInfo';
import { LmBmInfo } from './FileInformation/LmBmInfo';
import { HazardAreaInfo } from './FileInformation/HazardAreaInfo';

export const FileInformation = (props) => {
  const [lmGeojson, setLmGeojson] = useState(props.formData.info.lm.geometry);
  const [bmGeojson, setBmGeojson] = useState(props.formData.info.bm.geometry);

  useEffect(() => {
    if (props.formData.info.lm.geometry.coordinates.length) {
      setLmGeojson(convert3857To4326(featureCollection([
        point(props.formData.info.lm.geometry.coordinates),
      ]), props.formData.file_zone));
    }
  }, [props.formData.info.lm.geometry]);

  useEffect(() => {
    if (props.formData.info.bm.geometry.coordinates.length) {
      setBmGeojson(convert3857To4326(featureCollection([
        point(props.formData.info.bm.geometry.coordinates),
      ]), props.formData.file_zone));
    }
  }, [props.formData.info.bm.geometry]);
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId={props.accordionId}
            validationForm={props.validationForm}
            block="file_info"
            titleUA={(
              <div className="flex-group">
                <span>Інформація про полігон</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="File information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className="flex-column-group">
          {props.tab !== 'gis'
            && (
              <HazardAreaInfo
                subAccordionId={5.1}
                formData={props.formData}
                setFormData={props.setFormData}
              />
            )}
          {props.tab !== 'gis'
            && (
              <LmBmInfo
                formData={props.formData}
                setFormData={props.setFormData}
                handleTabChange={props.handleTabChange}
                photos={props.photos}
                setPhotos={props.setPhotos}
                errors={props.errors}
                setLoaderPhotos={props.setLoaderPhotos}
              />
            )}
          <TableFileInfo
            formData={props.formData}
          />
          {props.tab !== 'gis'
            && (
              <Map
                map={props.map1}
                setMap={props.setMap1}
                turnPointsPolygon={props.formData.info.turn_points.geojson}
                hazardAreaType={props.formData.info.general.hazard_area_type}
                lmGeojson={lmGeojson}
                bmGeojson={bmGeojson}
                id="turnPoints-map"
                googleWms
              />
            )}
        </div>

      </AccordionItemPanel>
    </>
  );
};
