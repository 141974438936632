import React from 'react';
import { InfoItem } from 'component/InfoItem';
import { Table, TableHeader, TableRow } from 'component/Table';

export const TableFileInfo = (props) => {
  const turnPoints = props.formData.info.turn_points;

  return (
    <div className="flex-column-group">
      <Table>
        <TableHeader>
          <th>
            <div>Від</div>
            <div>From</div>
          </th>
          <th>
            <div>До</div>
            <div>To</div>
          </th>
          <th>
            <div>Азимут (Магнітний)</div>
            <div>Magnetic bearing</div>
          </th>
          <th>
            <div>Азимут (Істинний)</div>
            <div>True bearing</div>
          </th>
          <th>
            <div>Відстань (м)</div>
            <div>Distance</div>
          </th>
          <th>
            <div>Long/UT</div>
            <div>M X</div>
          </th>
          <th>
            <div>Lat/UT</div>
            <div>M У</div>
          </th>
        </TableHeader>
        <tbody>
          {!turnPoints?.points?.length
            && (
            <TableRow>
              <td colSpan={7}>Empty</td>
            </TableRow>
            )}
          {turnPoints && turnPoints?.display_points?.map((point, index) => (
            <TableRow key={index}>
              <td>{point.from}</td>
              <td>{point.to}</td>
              <td>{point.magnetic_azimuth?.toFixed(0) || 0}</td>
              <td>{point.azimuth?.toFixed(0) || 0}</td>
              <td>{point.distance_meters?.toFixed(0) || 0}</td>
              <td>{point.longitude?.toFixed(0) || 0}</td>
              <td>{point.latitude?.toFixed(0) || 0}</td>
            </TableRow>
          ))}
        </tbody>
      </Table>

      <div className="flex-between">
        <InfoItem
          titleUA="Магнітне відхилення (°)"
          titleEN="Magnetic declination"
          data={`+${turnPoints?.magnetic_declination}` || 0}
        />
        <InfoItem
          titleUA="Загальна обчислена площа (всі полігони) (кв.м)"
          titleEN="Total area (all polygons) (sqm)"
          data={Math.round(turnPoints?.total_area_meters) || 0}
        />
      </div>
    </div>
  );
};
