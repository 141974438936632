import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const ClusterMunition = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.5"
      titleUA="Касетні боєприпаси"
      titleEN="Cluster Munition"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="clusterMunition"
          name="cluster_munition"
          value="cluster_munition"
          checked={hazardType.cluster_munition}
          handleInput={props.handleContamination}
          disable="false"
        />
      )}
    />
  );
};
