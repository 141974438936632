import React, { useEffect, useState } from 'react';
import { ButtonComponent } from 'component/Button';
import { InputComponent } from 'component/Input';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/Download.svg';
import { axiosWrapper } from 'utils/Helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getSettings } from 'api/SettingsApi';
import { DatePickerWrapper } from 'component/DataPicker';
import { downloadNotificationsLogs } from 'api/NotificationsApi';
import { ClipLoader } from 'react-spinners';
import { Accordion } from 'react-accessible-accordion';
import { getAllUsersForTokens } from 'api/UserAPI';
import { CreateTokenDialog } from 'component/Dialog';
import { useUser } from 'routes/UserContext';
import IMSMARights from './IMSMARights';

const Tokens = ({ setLoader }) => {
  const [imsmaUserName, setImsmaUserName] = useState(null);
  const [imsmaPassword, setImsmaPassword] = useState(null);
  const [imsmaToken, setImsmaToken] = useState(null);
  const [mapboxToken, setMapboxToken] = useState(null);
  const [fmsLogin, setFmsLogin] = useState(null);
  const [fmsPassword, setFmsPassword] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoaderToNotification, setIsLoaderToNotification] = useState(false);
  const [users, setUsers] = useState([]);
  const { user } = useUser();

  const getUsersSettings = async () => {
    try {
      const response = await getAllUsersForTokens(true);
      setUsers(response.data);
    } catch (error) {
      console.log('Error fetching users', error);
    }
  };

  const setOpsSettings = () => {
    axiosWrapper(() => {
      getSettings()
        .then((res) => {
          setImsmaUserName(res.data.username);
          setImsmaPassword(res.data.password);
          setFmsLogin(res.data.fms_email);
          setFmsPassword(res.data.fms_password);
          setImsmaToken(res.data.imsma_token);
          setMapboxToken(res.data.mapbox_token);
        })
        .catch(() => {
          toast.error('Error!');
        })
        .finally(() => {
          setLoader(false);
        });
    });
  };

  const updateOpsSettings = () => {
    axiosWrapper(() => {
      axios.patch('/core/settings/0/', {
        username: imsmaUserName,
        password: imsmaPassword,
        imsma_token: imsmaToken,
        mapbox_token: mapboxToken,
        fms_email: fmsLogin,
        fms_password: fmsPassword,
      }).then(() => {
        toast.success('Settings updated successfully');
      }).catch(() => {
        toast.error('Settings update failed!');
      });
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    setLoader(true);
    setOpsSettings();
    getUsersSettings().finally(() => setLoader(false));
  }, []);

  const getNotificationsLogs = (startDateNotification, endDateNotification) => {
    axiosWrapper(() => {
      setIsLoaderToNotification(true);
      downloadNotificationsLogs(startDateNotification, endDateNotification)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'logs.xlsx');
          document.body.appendChild(link);
          link.click();
          setIsLoaderToNotification(false);
          toast.success('Notification logs downloaded successfully.');
        })
        .catch((error) => {
          console.error('Error', error);
          setIsLoaderToNotification(false);
          toast.error('Notification logs download failed.');
        })
        .finally(() => {
          setIsLoaderToNotification(false);
        });
    });
  };

  return (
    <>
      <div className="tokens-container">
        {user?.permissions?.view_logs
          && (
            <div className="date-pickers-section">
              <div className="date-picker-from">
                <span style={{ color: '#939393' }}>From</span>
                <DatePickerWrapper
                  value={startDate}
                  handleDatePicker={handleStartDateChange}
                />
              </div>
              <div className="date-picker-to">
                <span style={{ color: '#939393' }}>To</span>
                <DatePickerWrapper
                  value={endDate}
                  handleDatePicker={handleEndDateChange}
                />
              </div>
              <ButtonComponent
                iconRight={isLoaderToNotification ? (
                  <ClipLoader
                    color="currentColor"
                    size={24}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : <DownloadIcon />}
                label="Download logs"
                handleButton={() => { getNotificationsLogs(startDate?.format('DD.MM.YYYY'), endDate?.format('DD.MM.YYYY')); }}
              />
            </div>
          )}
        {user?.permissions?.change_settings && (
          <>
            <InputComponent
              classNameWrapper="w-100"
              style={{ width: '-webkit-fill-available' }}
              id="mapbox-token"
              name="mapbox-token"
              handleInput={setMapboxToken}
              value={mapboxToken}
              label="Mapbox Token"
            />
            <div
              className="inputs-section"
            >
              <div
                className="bottom-inputs"
              >
                <InputComponent
                  id="fms-login"
                  style={{ width: '-webkit-fill-available' }}
                  name="fms-login"
                  handleInput={setFmsLogin}
                  value={fmsLogin}
                  label="FMS login"
                  classNameWrapper="w-100"
                />
                <InputComponent
                  id="fms-password"
                  style={{ width: '-webkit-fill-available' }}
                  name="fms-password"
                  handleInput={setFmsPassword}
                  value={fmsPassword}
                  label="FMS password"
                  classNameWrapper="w-100"
                />
              </div>
              <span className="line" />
              <div className="buttons-group">
                <ButtonComponent
                  iconLeft={<SaveIcon />}
                  label="Save all"
                  handleButton={updateOpsSettings}
                />
              </div>
            </div>
          </>
        )}
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          className="custom-accordion w-100"
          preExpanded={[]}
        >
          <IMSMARights users={users} onUpdate={getUsersSettings} />
        </Accordion>
      </div>
      {user?.permissions?.add_settings && (
        <div className="buttons-group-tokens">
          <CreateTokenDialog onUpdate={getUsersSettings} />
        </div>
      )}
    </>
  );
};

export default Tokens;
