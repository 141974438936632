import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';
import { InputComponent } from 'component/Input';
import { React, useEffect, useState } from 'react';

export const Vegetation = (props) => {
  const geographicDescription = props.formData.info.geographic_and_terrain_description;
  const [otherCheckbox, setOtherCheckbox] = useState(false);

  useEffect(() => {
    setOtherCheckbox(geographicDescription.vegetation.includes('other'));
  }, [geographicDescription]);

  const handleGeographicDescription = (value, isChecked) => {
    const vegetation = [...geographicDescription.vegetation];

    if (isChecked) {
      vegetation.push(value);
    } else {
      const index = vegetation.indexOf(value);
      if (index !== -1) {
        vegetation.splice(index, 1);
      }
    }

    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          vegetation,
        },
      },
    }));
  };

  const handleOtherDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          vegetation_other: value,
        },
      },
    }));
  };

  return (
    <>
      {props.errors?.geographic_and_terrain_description?.vegetation
                && <span className="error-text">Потрібно обрати хоча би один з варіантів.</span>}
      <AccordionBodyItem
        accordionSubId={(
          <div>
            <span>6.1</span>
            <span className="star">*</span>
          </div>
)}
        titleUA="Рослинність"
        titleEN="Vegetation"
        className={props.errors?.geographic_and_terrain_description?.vegetation ? 'error' : ''}
        rightContentClassName="w-60"
        children={(
          <>
            <div className="flex-group flex-wrap">
              <CheckboxInput
                id="vegetation"
                name="vegetation"
                value="grass"
                label="Трава \ Grass"
                checked={geographicDescription.vegetation.includes('grass')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="bulrush"
                name="vegetation"
                value="bulrush"
                label="Очерет \ Bulrush"
                checked={geographicDescription.vegetation.includes('bulrush')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="bushes"
                name="vegetation"
                value="bushes"
                label="Кущі \ Bushes"
                checked={geographicDescription.vegetation.includes('bushes')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="vegetation_none"
                name="vegetation"
                value="none"
                label="Відсутня \ None"
                checked={geographicDescription.vegetation.includes('none')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="vegetation_unknown"
                name="vegetation"
                value="unknown"
                label="Невідомо \ Unknown"
                checked={geographicDescription.vegetation.includes('unknown')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="trees"
                name="vegetation"
                value="trees"
                label="Дерева \ Trees"
                checked={geographicDescription.vegetation.includes('trees')}
                handleInput={handleGeographicDescription}
                disable="false"
              />
              <CheckboxInput
                id="vegetation_other"
                name="vegetation"
                value="other"
                label="Інше \ Other"
                checked={geographicDescription.vegetation.includes('other')}
                handleInput={(value, checked) => {
                  setOtherCheckbox(checked);
                  handleGeographicDescription(value, checked);
                }}
                disable="false"
              />
            </div>
            {otherCheckbox && (
              <InputComponent
                id="vegetation_other"
                style={{ width: 'calc(100% - 40px)' }}
                handleInput={handleOtherDescription}
                value={geographicDescription.vegetation_other || ''}
                blueLink
                limitChar
              />
            )}
          </>
)}
      />
    </>

  );
};
