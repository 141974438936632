import { Dialog } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/cancel_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { createUser, updateUser } from 'api/UserAPI';
import AccessRightsLists from 'component/AccessRightsLists';
import { URLS } from 'routes/Urls';
import { useUser } from 'routes/UserContext';
import { Avatar } from './Avatar';
import { UserName } from './Name';
import { UserMiddleName } from './MiddleName';
import { UserFunction } from './Function';
import { UserSurName } from './SurName';
import { UserEmail } from './Email';
import { UserPhoneNumber } from './PhoneNumber';
import { UserPassword } from './Password';
import { UserTeamId } from './TeamId';

import { UserPosition } from './Position';
import { GeneratePasswordCheckbox } from './GeneratePassword';
import { UserDirectors } from './Directors';
import { UserAccessLevel } from './AccessLevel';
import { handleAddUser, handleUpdateUser, handleUserErrors } from '../ValidateUser';

export const UserPopup = ({
  mode,
  setLoader,
  open,
  setOpen,
  setInfoOpen,
  userInfo,
  setUserInfo,
  setUserId,
  setAfterCreate,
  permissions,
  setPermissions,
  setDirectors,
  directors,
  setRole,
  role,
  avatarImage,
  setAvatarImage,
}) => {
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const [avatarFile, setAvatarFile] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    setUserInfo((prev) => ({
      ...prev,
      permissions: Object.keys(permissions),
    }));
  }, [permissions]);

  const updateAvatar = (userId) => {
    if (avatarFile) {
      const formData = new FormData();
      formData.append('avatar', avatarFile);

      return axios.patch(`${URLS.USERS}${userId}/avatar/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
    return Promise.resolve();
  };

  const successUserSave = (id) => {
    updateAvatar(id)
      .then(() => {
        setAfterCreate(true);
        setInfoOpen(true);
        toast.success('User saved successfully');
        setLoader(false);
        setOpen(false);
        setPasswordMatch(true);
      }).catch((err) => {
        console.log(err);
        setLoader(false);
        toast.error('Error saving user');
      });
  };

  const errorUserSave = (err) => {
    console.log(err);
    handleUserErrors(err.response?.data);
    toast.error('Error saving user');
    setLoader(false);
  };

  const saveUserInfo = () => {
    if (mode === 'create') {
      if (!handleAddUser(userInfo)) return false;
      setLoader(true);
      createUser(userInfo)
        .then((res) => {
          if (res.status === 201) {
            successUserSave(res.data.id);
            setUserId(res.data.id);
          }
        })
        .catch((err) => {
          errorUserSave(err);
        });
    }
    if (mode === 'edit') {
      if (userInfo.password === null) {
        delete userInfo.password;
      }
      if (!handleUpdateUser(userInfo)) return false;
      setLoader(true);
      updateUser(userInfo.id, userInfo)
        .then((res) => {
          successUserSave(res.data.id);
        })
        .catch((err) => {
          errorUserSave(err);
        });
    }
    return false;
  };

  const [avatarImageLoading, setAvatarImageLoading] = useState(false);

  const handleDeleteAvatar = () => {
    setAvatarImageLoading(false);
    setAvatarImage(null);
  };

  useEffect(() => {
    if (userInfo.password !== null) {
      setPasswordMatch(userInfo.password === confirmPassword);
    }
  }, [userInfo.password, confirmPassword]);

  const handleDlgClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;

    const confirmed = window.confirm(
      'Are you sure you want to close without saving changes?',
    );
    if (confirmed) {
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        open={open}
        onClose={handleDlgClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirm-user-popup-wrapper"
      >
        <div className="confirm-user-popup">
          <div className="header">
            <span className="title">
              {mode === 'create' ? 'Add user' : 'Edit user'}
            </span>
            <span
              className="closeBtn"
              onClick={() => {
                handleDlgClose();
              }}
            />
          </div>
          <div className="body-section">
            <div className="first-section">
              <div className="column flex-column-group">
                <Avatar
                  avatarImage={avatarImage}
                  setAvatarFile={setAvatarFile}
                  setAvatarImage={setAvatarImage}
                  avatarImageLoading={avatarImageLoading}
                  handleDeleteAvatar={handleDeleteAvatar}
                />
                <GeneratePasswordCheckbox
                  setUserInfo={setUserInfo}
                  setConfirmPassword={setConfirmPassword}
                />
              </div>
              <div className="column">
                <UserName
                  name={userInfo.name}
                  setUserInfo={setUserInfo}
                />
                <UserMiddleName
                  middlename={userInfo.middle_name}
                  setUserInfo={setUserInfo}
                />
                <UserFunction
                  function={userInfo.function}
                  setUserInfo={setUserInfo}
                />
              </div>
              <div className="column">
                <UserSurName
                  surname={userInfo.surname}
                  setUserInfo={setUserInfo}
                />
                <UserEmail
                  email={userInfo.email}
                  setUserInfo={setUserInfo}
                />
                <UserPhoneNumber
                  phoneNumber={userInfo.phone_number}
                  setUserInfo={setUserInfo}
                />
              </div>
            </div>
            {passwordMatch
              ? null
              : <div className="error-text">Passwords do not match</div>}
            <div className="second-section">
              <div className="column">
                <UserPassword
                  placeholder="New password"
                  password={userInfo.password}
                  setUserInfo={setUserInfo}
                />
                <UserTeamId
                  teamId={userInfo.team_id}
                  setUserInfo={setUserInfo}
                  selectedRole={userInfo.role}
                />
              </div>
              <div className="column">
                <UserPassword
                  placeholder="Confirm password"
                  password={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                />
                <UserDirectors
                  directors={userInfo.directors}
                  setUserInfo={setUserInfo}
                  directorsOption={directors}
                  setDirectors={setDirectors}
                  hasEditPermission={user?.permissions?.change_user}
                />
              </div>
              <div className="column">
                <UserPosition
                  reporterPosition={userInfo.reporter_position}
                  setUserInfo={setUserInfo}
                />
                <UserAccessLevel
                  role={userInfo.role}
                  setUserInfo={setUserInfo}
                  setRole={setRole}
                  setPermissions={setPermissions}
                  roleOption={role}
                  mode={mode}
                  hasEditPermission={user?.permissions?.change_user}
                />
              </div>
            </div>

          </div>
          <div
            className="role-popup"
            style={{ padding: '0' }}
          >
            <AccessRightsLists
              accessList={permissions}
              setAccessList={setPermissions}
            />
          </div>

          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={() => {
                handleDlgClose();
              }}
              label="Cancel"
            />
            <ButtonComponent
              iconLeft={<SendSvg />}
              handleButton={() => {
                saveUserInfo(mode);
              }}
              label="Save"
            />
          </div>
        </div>
      </Dialog>
      <ToastContainer />
    </>
  );
};
