import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import 'assets/styles/pages/validation.scss';
import ReportRow from './ReportRow';

const AccordionValidation = ({ setMode, handleActionClick }) => (
  <Accordion
    allowMultipleExpanded
    allowZeroExpanded
    className="custom-accordion"
    preExpanded={[]}
  >
    <AccordionItem uuid="reportsList">
      <ReportRow
        setMode={setMode}
        handleActionClick={handleActionClick}
      />
    </AccordionItem>
  </Accordion>
);

export default AccordionValidation;
