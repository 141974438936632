import React from 'react';
import { SelectComponent } from 'component/Select';

export const UserPosition = (props) => {
  const reporterPositions = [
    'Керівник команди (розрахунку, групи) EORE',
    'Керівник команди (розрахунку, групи) НТО',
    'Керівник команди (розрахунку, групи) розмінування',
    'Керівник операцій',
    'Супервайзер',
    'Кординатор проекту',
    'Відповідальний по вивільненню земель',
    'Відповідальний по гарантії якості',
    'Відповідальний по управлінню інформації',
    'Волонтер',
    'Оператор',
    'Оператор-водій',
  ];

  const handleSelectedPosition = (selectedOptions) => {
    props.setUserInfo((prev) => ({
      ...prev,
      reporter_position: selectedOptions,
    }));
  };

  return (
    <div className="select-wrapper w-100" style={{ gap: '0' }}>
      <span className="label" style={{ marginTop: '10px' }}>
        Reporter position
      </span>
      <SelectComponent
        className="w-100"
        placeholder="Select..."
        options={reporterPositions.map((position) => ({
          value: position,
          label: position,
        }))}
        value={{
          value: props.reporterPosition,
          label: props.reporterPosition,
        }}
        handleSelect={handleSelectedPosition}
      />
    </div>
  );
};
