import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';

export const ReasonAndComments = (props) => {
  const landreleaseActivity = props.formData.info.next_landrelease_activity;

  const handleReasonAndComments = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          reasons_and_comments_on_71_72: value,
        },
      },
    }));
  };

  const handleReasonAndCommentsExtended = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          reasons_and_comments_on_71_72_extended: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>7.3</span>
        </div>
)}
      titleUA="Причини та коментарі по 7.1 та 7.2"
      titleEN="Reasons and comments on 7.1 and 7.2"
      children={(
        <div className="flex-group flex-wrap flex-right">
          <TextareaComponent
            id="reasons_and_comments_on_71_72"
            name="reasons_and_comments_on_71_72"
            value={landreleaseActivity.reasons_and_comments_on_71_72}
            handleTextarea={handleReasonAndComments}
            blueLink
            disable="false"
            limitChar
          />
          <TextareaComponent
            id="reasons_and_comments_on_71_72_extended"
            name="reasons_and_comments_on_71_72_extended"
            value={landreleaseActivity.reasons_and_comments_on_71_72_extended}
            handleTextarea={handleReasonAndCommentsExtended}
            blueLink={false}
            disable="false"
            limitChar={false}
          />

        </div>
)}
    />
  );
};
