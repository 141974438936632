import React from 'react';

export const HandoutsView = (props) => {
  const { formData, handouts, type } = props;

  return (
    <>
      <div className="double-text">
        <span>Матеріали для розповсюдження</span>
        <span>Materials for distribution</span>
      </div>
      <div className="flex-group handouts flex-wrap">
        {!handouts?.length && (
          <span className="text-center">
            Немає матеріалів для розповсюдження
          </span>
        )}
        {handouts?.length !== 0 && handouts?.map((handout) => (
          <HandoutView
            key={handout.handout_id}
            handout={handout}
            formData={formData}
            type={type}
          />
        ))}
      </div>
    </>
  );
};

export const HandoutView = (props) => {
  const { handout, formData, type } = props;

  const handouts = formData.info?.session_information[type]?.handouts || [];

  const filteredHandouts = handouts?.filter((handoutItem) => handoutItem?.count !== 0);

  const currentHandout = filteredHandouts
    ?.find((handoutItem) => handoutItem?.handout_id === handout?.handout_id);

  if (!currentHandout) return null;
  return (
    <div className="flex-column-group">
      <span>{currentHandout?.name}</span>
      <span>{currentHandout?.count}</span>
    </div>
  );
};
