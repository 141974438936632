import React from 'react';
import Cleave from 'cleave.js/react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';

export const ClearanceDepth = (props) => {
  const landreleaseActivity = props.formData.info.next_landrelease_activity;

  const handleClearanceDepth = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          recommended_clearance_depth: Number(value),
        },
      },
    }));
  };

  const handleClearanceDepthDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          recommended_clearance_depth_description: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>7.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Рекомендована глибина розмінування (см):"
      titleEN="Recommended clearance depth, sm"
      className={props.errors?.next_landrelease_activity?.recommended_clearance_depth ? 'error' : ''}
      children={(
        <div className="flex-group flex-wrap flex-right">
          <div className="flex-column-group align-right">
            <Cleave
              id="recommended_clearance_depth"
              className="number"
              name="recommended_clearance_depth"
              options={{
                numericOnly: true,
                stripLeadingZeroes: true,
              }}
              value={landreleaseActivity.recommended_clearance_depth}
              autoComplete="off"
              onChange={(e) => handleClearanceDepth(e.target.value)}
              disable="false"
            />
            <TextareaComponent
              value={landreleaseActivity.recommended_clearance_depth_description}
              handleTextarea={handleClearanceDepthDescription}
              blueLink
              limitChar
            />
          </div>

        </div>
      )}
    />
  );
};
