import React from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/User.svg';

export const UserFunction = (props) => {
  const handleFunction = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      function: value,
    }));
  };

  return (
    <InputComponent
      type="text"
      upperRightText="Function"
      id="user-function"
      name="user-function"
      placeholder="Function"
      value={props.function}
      handleInput={handleFunction}
      iconLeft={<PeopleSvg />}
      autoComplete="off"
    />
  );
};

export const FunctionView = (props) => (
  <div className="double-text">
    <span>Function</span>
    <span>{props.function || '-'}</span>
  </div>
);
