import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { RegionView } from './Region';
import { DistrictView } from './District';
import { LocalCommunityView } from './LocalCommunity';
import { LocalityView } from './Locality';
import { SettlementIdView } from './SettlementId';
import { ClosestSettlementView } from './ClosestSettlement';
import { CommentsView } from '../Comments';

export const LocationView = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;

  return (
    <AccordionItem uuid="location">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            titleUA="Місцезнаходження"
            titleEN="Location"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <RegionView formData={formData} />
        <DistrictView formData={formData} />
        <LocalCommunityView formData={formData} />
        <LocalityView formData={formData} />
        <SettlementIdView formData={formData} />
        <ClosestSettlementView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <CommentsView
          comments={comments}
          block="location"
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
