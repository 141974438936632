import { React, useState } from 'react';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as AttachSvg } from 'assets/images/icons/attach_file_24px.svg';
import { UploadButton } from 'component/UploadButton';
import { ButtonComponent } from 'component/Button';
import 'assets/styles/card.scss';

export const UploadEoreTemplateFile = (props) => {
  const {
    file,
    setFile,
    types,
  } = props;

  const [key, setKey] = useState(Date.now());

  const handleFileChange = (e) => {
    if (!e.target.files) {
      setFile(null);
    } else {
      setFile(e.target.files);
    }
  };

  return (
    <div className="flex-column-group justify-center align-center">
      {!file && (
        <div className="icon-wrapper">
          <AttachSvg
            width={40}
            height={40}
          />
        </div>
      )}
      {
        file && (
          <div className="tiff-file">
            {[...file][0].name}
          </div>
        )
      }
      <div className="buttons-group">
        <UploadButton
          key={key}
          id="eore-template"
          handleUploadButton={handleFileChange}
          accept={types}
        />
        {file && (
          <DeleteFile
            handleDeleteButton={handleFileChange}
            setKey={setKey}
          />
        )}
      </div>

    </div>
  );
};

const DeleteFile = (props) => {
  const {
    handleDeleteButton,
    setKey,
  } = props;
  return (
    <ButtonComponent
      className="danger"
      label={
        <DeleteSvg />
      }
      handleButton={(e) => {
        handleDeleteButton(e);
        setKey(Date.now());
      }}
    />
  );
};
