import React from 'react';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionBodyItem } from 'component/Accordion';

export const PhotosView = (props) => {
  const { photos } = props;
  return (
    photos?.sort((a, b) => {
      const order = {
        lm: 1,
        bm: 2,
        direct_evidence: 3,
        indirect_evidence: 4,
        general: 5,
      };

      return order[a.dependency] - order[b.dependency];
    })
      ?.map((photo, index) => {
        if (photo.dependency === 'general') {
          return (
            <AccordionItemPanel key={index}>
              <AccordionBodyItem
                titleUA="Загальні"
                titleEN="General"
              />
              <div className="photo-block">
                <img src={photo?.data_url} alt="" />
              </div>
            </AccordionItemPanel>
          );
        }
        if (photo.dependency === 'direct_evidence') {
          return (
            <AccordionItemPanel key={index}>
              <AccordionBodyItem
                titleUA="Прямі докази"
                titleEN="Direct evidence"
              />
              <div className="photo-block">
                <img src={photo?.data_url} alt="" />
              </div>
            </AccordionItemPanel>
          );
        }
        if (photo?.dependency === 'indirect_evidence') {
          return (
            <AccordionItemPanel key={index}>
              <AccordionBodyItem
                titleUA="Непрямі докази"
                titleEN="Indirect evidence"
              />
              <div className="photo-block">
                <img src={photo?.data_url} alt="" />
              </div>
            </AccordionItemPanel>
          );
        }
        if (photo?.dependency === 'lm') {
          return (
            <AccordionItemPanel key={index}>
              <AccordionBodyItem
                titleUA="Фіксований орієнтир"
                titleEN="Landmark (LM)"
              />
              <div className="photo-block">
                <img src={photo?.data_url} alt="" />
              </div>
            </AccordionItemPanel>
          );
        }
        if (photo?.dependency === 'bm') {
          return (
            <AccordionItemPanel key={index}>
              <AccordionBodyItem
                titleUA="Опорна точка"
                titleEN="Benchmark (BM)"
              />
              <div className="photo-block">
                <img src={photo?.data_url} alt="" />
              </div>
            </AccordionItemPanel>
          );
        }
        return null;
      })
  );
};
