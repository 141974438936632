import React from 'react';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/file_download.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/Download.svg';
import { toast } from 'react-toastify';
import { getEoreTemplateFile } from 'api/EOREApi';
import { ButtonComponent } from 'component/Button';

export const DownloadEORETemplate = (props) => {
  const getFile = () => {
    props.setLoader(true);

    getEoreTemplateFile(props.id)
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', props.fileName || 'downloaded_file');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        props.setLoader(false);
      })
      .catch((error) => {
        console.error('Error downloading file', error);
        props.setLoader(false);
        toast.error('Error downloading file');
      });
  };

  return (
    <>
      {props.iconOnly && (
        <DownloadIcon
          className="upload-icon text-hover"
          onClick={getFile}
        />
      )}
      {!props.iconOnly && (
        <ButtonComponent
          handleButton={getFile}
          label="Current template"
          iconLeft={<DownloadSvg />}
          type="button"
          className="warning"
        />
      )}
    </>
  );
};
