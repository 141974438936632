import proj4 from 'proj4';
import { React } from 'react';

export const SwapEvidenceIcon = (props) => {
  proj4.defs('EPSG:32637', `+proj=utm +zone=${props.zone} +datum=WGS84 +units=m +no_defs +type=crs`);
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

  const getEvidenceGeojson = (items) => items.map((item) => {
    const long = item.long ? Number(item.long) : 0;
    const lat = item.lat ? Number(item.lat) : 0;
    const convertedCoordinates = proj4('EPSG:32637', 'EPSG:4326', [long, lat]);
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          Number(convertedCoordinates[0]),
          Number(convertedCoordinates[1]),
        ],
      },
      properties: {},
    };
  });

  const updateFormData = () => {
    props.setFormData((prevFormData) => {
      const itemToMove = prevFormData.info.evidences[props.evidenceTypeFrom]
        .items[props.evidenceIndex];
      const updatedFromItems = [...prevFormData.info.evidences[props.evidenceTypeFrom].items];
      const updatedToItems = [...prevFormData.info.evidences[props.evidenceTypeTo].items];
      if (itemToMove) {
        updatedFromItems.splice(props.evidenceIndex, 1);
        updatedToItems.push(itemToMove);
      }
      const getFeatures = () => {
        if (props.evidenceTypeFrom === 'direct_evidences') {
          return getEvidenceGeojson(updatedFromItems);
        }
        if (props.evidenceTypeTo === 'direct_evidences') {
          return getEvidenceGeojson(updatedToItems);
        }
        return false;
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            evidence_geojson: {
              ...prevFormData.info.evidences.evidence_geojson,
              features: getFeatures(),
            },
            [props.evidenceTypeFrom]: {
              ...prevFormData.info.evidences[props.evidenceTypeFrom],
              items: updatedFromItems,
            },
            [props.evidenceTypeTo]: {
              ...prevFormData.info.evidences[props.evidenceTypeTo],
              items: updatedToItems,
            },
          },
        },
      };
    });
  };

  const updatePhotos = () => {
    const photoIndex = props.photos.findIndex((photo) => photo.dependency === props.evidenceTypeFrom.split('s')[0]
      && photo.item === props.evidenceIndex + 1);

    const newItemIndex = props.formData.info.evidences[props.evidenceTypeTo].items?.length;

    if (photoIndex !== -1) {
      props.setPhotos((prevPhotos) => prevPhotos.map((photo, index) => {
        if (index === photoIndex) {
          const updatedPhoto = {
            ...photo,
            dependency: props.evidenceTypeTo.split('s')[0],
            item: newItemIndex + 1,
          };
          return updatedPhoto;
        } if (photo.dependency === props.evidenceTypeFrom.split('s')[0]
          && photo.item > newItemIndex + 1) {
          const updatedPhoto = {
            ...photo,
            item: photo.item - 1,
          };
          return updatedPhoto;
        }
        return photo;
      }));
    }
  };

  const swapEvidence = () => {
    updateFormData();
    updatePhotos();
  };

  return (
    <div
      className="text-hover"
      onClick={() => swapEvidence()}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="swap_horiz_24px">
          <path
            id="icon/action/swap_horiz_24px"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.01 5L21 9L17.01 13V10H10V8H17.01V5ZM3 15L6.99 11V14H14V16H6.99V19L3 15Z"
            fill="#868686"
          />
        </g>
      </svg>
    </div>
  );
};
