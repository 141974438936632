import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { removeLeadingZeros } from '../../../../utils/Helper';

export const AgriculturalView = (props) => {
  const { formData } = props;
  const landuse = formData.info.landuse_and_beneficiaries;

  return (
    <AccordionBodyItemView
      accordionSubId="8.5"
      titleUA="Сільське господарство"
      titleEN="Agricultural"
      children={(
        <div className="flex-group flex-wrap">
          {removeLeadingZeros(landuse.agricultural)}
        </div>
      )}
    />
  );
};
