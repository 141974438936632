import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';

export const DescriptionPlace = (props) => {
  const { formData, setFormData } = props;

  const handleDescriptionPlace = (value) => {
    setFormData({
      ...formData,
      info: {
        ...formData.info,
        session_information: {
          ...formData.info.session_information,
          description_of_place_type: value,
        },
      },
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.9</span>
        </div>
      )}
      titleUA="Опис типу місця"
      titleEN="Description of place type"
      children={(
        <TextareaComponent
          id="description_of_place_type"
          name="description_of_place_type"
          value={formData.info.session_information.description_of_place_type}
          handleTextarea={(value) => handleDescriptionPlace(value)}
        />
      )}
    />
  );
};
