import React from 'react';

export const DirectBeneficiariesView = (props) => {
  const { formData } = props;
  const totalDirectBeneficiaries =
    formData.info?.session_information?.direct_beneficiaries?.male_adult_direct_count +
    formData.info?.session_information?.direct_beneficiaries?.female_adult_direct_count +
    formData.info?.session_information?.direct_beneficiaries?.male_child_direct_count +
    formData.info?.session_information?.direct_beneficiaries?.female_child_direct_count;

  return (
    <div className='total-beneficiaries-container'>
      <GCAMaleView formData={formData} />
      <GCAFemaleView formData={formData} />
      <GCABoysView formData={formData} />
      <GCAGirlsView formData={formData} />
      <div className='total-beneficiaries'>
        <div>
          Total:
        </div>
        <div>
          {totalDirectBeneficiaries}
        </div>
      </div>
    </div>
  );
};

export const GCAMaleView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>GCA Чоловік</span>
        <span>GCA Male</span>
      </div>
      {formData.info.session_information.direct_beneficiaries.male_adult_direct_count}
    </div>
  );
};

export const GCAFemaleView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>GCA Жінка</span>
        <span>GCA Female</span>
      </div>
      {formData.info.session_information.direct_beneficiaries.female_adult_direct_count}
    </div>
  );
};

export const GCABoysView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>GCA Хлопці</span>
        <span>GCA Boys</span>
      </div>
      {formData.info.session_information.direct_beneficiaries.male_child_direct_count}
    </div>
  );
};

export const GCAGirlsView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>GCA Дівчата</span>
        <span>GCA Girls</span>
      </div>
      {formData.info.session_information.direct_beneficiaries.female_child_direct_count}
    </div>
  );
};
