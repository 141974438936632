import React from 'react';
import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { alphaVal } from 'utils/Helper';

export const InformantView = (props) => {
  const {
    informator,
    index,
    translatedFormData,
    translateState,
  } = props;
  return (
    <>
      <AccordionBodyItem
        titleUA={`Інформатор ${alphaVal(index + 1)}`}
        titleEN={`Informant ${alphaVal(index + 1)}`}
      />
      <AccordionItemPanel className="custom-accordion-item-panel">
        <AccordionBodyItemView
          accordionSubId="9.1"
          titleUA="ПІБ"
          titleEN="Name"
        >
          {informator.name}
        </AccordionBodyItemView>
        <AccordionBodyItemView
          accordionSubId="9.2"
          titleUA="Посада/вид діяльності"
          titleEN="Position / activity"
        >
          {translateState && translatedFormData[`informator_position_${index}`]}
          {!translateState && informator.position}
        </AccordionBodyItemView>
        <AccordionBodyItemView
          accordionSubId="9.3"
          titleUA="Вік"
          titleEN="Age"
        >
          {informator.age}
        </AccordionBodyItemView>
        <AccordionBodyItemView
          accordionSubId="9.4"
          titleUA="Стать"
          titleEN="Sex"
        >
          {informator.sex === 'male' && 'Чоловіча'}
          {informator.sex === 'female' && 'Жіноча'}
          {informator.sex === 'undefined' && 'Невизначено'}
        </AccordionBodyItemView>

        <AccordionBodyItemView
          accordionSubId="9.5"
          titleUA="Телефон"
          titleEN="Phone"
        >
          {informator.phone}
        </AccordionBodyItemView>
        <AccordionBodyItemView
          accordionSubId="9.6"
          titleUA="Адреса"
          titleEN="Address"
        >
          {translateState && translatedFormData[`informator_address_${index}`]}
          {!translateState && informator.address}
        </AccordionBodyItemView>
      </AccordionItemPanel>
    </>
  );
};
