import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const ReporterOrganizationView = (props) => {
  const { formData, accordionSubId = '1.5' } = props;
  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Організація, що звітує"
      titleEN="Reporter organization"
      children={formData.info?.general?.reporter_organization}
    />
  );
};
