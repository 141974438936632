import React from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as MailSvg } from 'assets/images/icons/mail_outline.svg';

export const UserEmail = (props) => {
  const handleEmail = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      email: value,
    }));
  };

  return (
    <InputComponent
      upperRightText="Email"
      type="text"
      id="user-email"
      name="user-email"
      placeholder="sample@gmail.com"
      value={props.email}
      handleInput={handleEmail}
      iconLeft={<MailSvg />}
      autoComplete="off"
    />
  );
};

export const EmailView = (props) => (
  <div className="double-text">
    <span>Email</span>
    <span>{props.email || '-'}</span>
  </div>
);
