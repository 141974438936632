import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { React, useState } from 'react';
import { ReactComponent as ImportIcon } from 'assets/images/icons/import-icon.svg';
import { getHAById } from 'api/HaApi';

export const UploadPdfBtn = (props) => {
  const [statusOk, setStatusOk] = useState('');

  const toggleImportDropdown = (reportId) => {
    props.setIsImportDropdownOpen((prevState) => ({
      ...prevState,
      [reportId]: !prevState[reportId],
    }));
  };

  const handleDownload = async (id, fileName) => {
    props.setIsFileUploadingPdf(true);
    props.setFileUploadingIdPdf(id);
    try {
      const response = await axios.get(`/reports/ha/${id}/signed-document/`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      toast.success('File downloaded successfully!');
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Download error!');
    } finally {
      props.setIsFileUploadingPdf(false);
      props.setFileUploadingIdPdf(null);
    }
  };

  const handleUpload = async (id, file) => {
    try {
      if (!file) {
        console.error('No file selected');
        return;
      }
      props.setIsFileUploadingPdf(true);
      props.setFileUploadingIdPdf(id);
      const formData = new FormData();
      formData.append('document', file);
      const response = await axios.patch(
        `/reports/ha/${id}/signed-document/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { status } = response.data;
      if (status === 'ok') {
        const updatedItem = await getHAById(id, true);
        const updatedList = props?.reportsList
          ?.map((report) => (report?.id === id ? updatedItem?.data : report));
        props.onUpdateReportsList(updatedList);
        toast.success('File uploaded successfully!');
        setStatusOk(status);
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Upload error!');
    } finally {
      props.setIsFileUploadingPdf(false);
      props.setFileUploadingIdPdf(null);
    }
  };

  const handleFileChange = (file, id) => {
    handleUpload(id, file);
  };

  const handleUploadButtonClick = (itemId) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      handleFileChange(file, itemId);
    };
    fileInput.click();
  };

  return (
    <>
      {!props.isFileUploadingPdf
        && (
          <ImportIcon
            className={(props.item?.info?.signed_document_file_name || statusOk === 'ok')
              ? 'import-icon'
              : 'import-icon-red'}
            onClick={() => toggleImportDropdown(props.item?.id)}
          />
        )}
      {props.isFileUploadingPdf && props.fileUploadingIdPdf === props.item?.id
        ? (
          <ClipLoader
            color="rgba(0, 177, 183, 1)"
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )
        : (props.isImportDropdownOpen[props.item?.id]
          && (
            <div
              className="import-dropdown-content"
              onClick={() => props.setIsImportDropdownOpen(false)}
            >
              <p
                style={{ cursor: 'pointer' }}
                onClick={() => handleUploadButtonClick(props.item?.id)}
              >
                Upload
              </p>
              {(props.item?.info?.signed_document_file_name || statusOk === 'ok') && (
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownload(
                    props.item?.id,
                    props.item?.info?.signed_document_file_name,
                  )}
                >
                  Download
                </p>
              )}
            </div>
          )
        )}
      {props.isFileUploadingPdf
        && props.fileUploadingIdPdf !== props.item?.id
        && (
          <ImportIcon
            className="import-icon-disabled"
          />
        )}
    </>
  );
};
