import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const ReportProvider = (props) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>{props.formType === 'ha' ? '1.6' : '1.4'}</span>
        <span className="star">*</span>
      </div>
    )}
    titleUA="Звіт надав"
    titleEN="Report provided"
    children={(
      <span className="no-wrap">
        {props.formData.info.general.reporter_name}
      </span>
    )}
  />
);
