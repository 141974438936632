import React from 'react';
import { AccordionItemPanel } from 'react-accessible-accordion';

export const AnnexMapView = (props) => (
  <AccordionItemPanel>
    <div>
      {props?.annexImages?.sort((a, b) => ((a.is_main === b.is_main) ? 0 : a.is_main ? -1 : 1))
        .map((img, index) => (
          <div className="annex-block" key={index}>
            <a
              href={img?.data_url}
              target="_blank"
              rel="noreferrer"
              aria-label="image"
            >
              <img src={img?.data_url} className="preview-image" alt="" />
            </a>
          </div>
        ))}
    </div>
  </AccordionItemPanel>
);
