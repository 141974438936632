import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const AntiVehiceMines = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.2"
      titleUA="Протитранспортні міни"
      titleEN="Anti-vehicle mine"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="AntiVehicleMine"
          name="anti_transport_mines"
          value="anti_transport_mines"
          checked={hazardType.anti_transport_mines}
          handleInput={props.handleContamination}
          disabled={props.formData.info.general.hazard_type.includes('battlefield')}
        />
      )}
    />
  );
};
