import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';

export const SlopeGradient = (props) => {
  const geographicDescription = props.formData.info.geographic_and_terrain_description;

  const handleGeographicDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          slope_gradient: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>6.3</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Градус нахилу"
      titleEN="Slope gradient"
      className={props.errors?.geographic_and_terrain_description?.slope_gradient ? 'error' : ''}
      rightContentClassName="w-60"
      children={(
        <div className="flex-group flex-wrap">
          <RadioButton
            id="low_0_10"
            name="slope_gradient"
            value="low_0_10"
            label="Низький (0-10%) \ Low (0-10%)"
            checked={geographicDescription.slope_gradient.includes('low_0_10')}
            handleInput={handleGeographicDescription}
            disable="false"
          />
          <RadioButton
            id="medium_10_30"
            name="slope_gradient"
            value="medium_10_30"
            label="Середній (10-30%) \ Medium (10-30%)"
            checked={geographicDescription.slope_gradient.includes('medium_10_30')}
            handleInput={handleGeographicDescription}
            disable="false"
          />
          <RadioButton
            id="high_30"
            name="slope_gradient"
            value="high_30"
            label="Високий (30+%) \ High (30+%)"
            checked={geographicDescription.slope_gradient.includes('high_30')}
            handleInput={handleGeographicDescription}
            disable="false"
          />
        </div>
      )}
    />
  );
};
