import { AccordionBodyItem } from 'component/Accordion';
import React from 'react';

export const ReporterPosition = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.8</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Посада особи, що звітує"
      titleEN="Reporter position"
      children={(
        <span className="no-wrap">
          {formData?.info?.general?.reporter_position}
        </span>
      )}
    />
  );
};
