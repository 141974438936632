import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { toast } from 'react-toastify';
import { ReactComponent as DoneIcon } from 'assets/images/icons/Done-1.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as ForwardIcon } from 'assets/images/icons/forward.svg';
import { axiosWrapper } from 'utils/Helper';
import { getUserPermissions } from 'api/UserAPI';
import { Loader } from './Loader';
import { CheckboxInput } from './Checkbox';
import { ButtonComponent } from './Button';
import { AccordionHeaderTitle } from './Accordion';

function AccessRightsLists({ accessList, setAccessList }) {
  const [permissions, setPermissions] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const getPermissions = () => {
    setLoader(true);
    axiosWrapper(() => {
      getUserPermissions()
        .then((res) => {
          setPermissions(res.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Permissions are not loaded');
          setLoader(false);
        })
        .finally(() => {
          setLoader(false);
        });
    });
  };

  const addToAccessList = () => {
    setAccessList((prevList) => ({
      ...prevList,
      ...selectedPermissions,
    }));

    setSelectedPermissions({});
  };

  const handleAccessCheckbox = (key) => {
    setSelectedPermissions((prevState) => {
      const updatedPermissions = { ...prevState };

      if (!updatedPermissions[key]) {
        updatedPermissions[key] = permissions[key];
      } else {
        delete updatedPermissions[key];
      }

      return updatedPermissions;
    });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const removeFromAccessList = (id) => {
    const updatedAccessList = { ...accessList };
    delete updatedAccessList[id];

    if (!Object.values(permissions).includes(accessList[id])) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [id]: accessList[id],
      }));
    }

    setAccessList(updatedAccessList);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      className="custom-accordion"
      preExpanded={['accessList']}
    >
      {' '}
      <AccordionItem uuid="accessList">
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderTitle
              accordionId={<DoneIcon style={{ marginTop: '5px' }} />}
              titleEN={(
                <div className="flex-group" style={{ marginBottom: '5px' }}>
                  <span>Access lists</span>
                  <span />
                </div>
              )}
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div style={{
            display: 'flex',
          }}
          >
            <div className="access-all-list-title">
              All access list
            </div>
            <div className="access-user-list-title">
              User access list
            </div>
          </div>
          <div style={{
            display: 'flex',
            gap: '20px',
            maxHeight: '150px',
            overflow: 'auto',
            justifyContent: 'space-between',
          }}
          >
            <div className="user-access-list">
              <AccordionItemPanel>
                {loader ? <Loader />
                  : Object.entries(permissions).map(([key, value]) => (
                    <div key={key}>
                      {!Object.values(accessList).includes(value)
                        && (
                          <CheckboxInput
                            id={key}
                            value={key}
                            label={value}
                            name={value}
                            handleInput={() => { handleAccessCheckbox(key); }}
                          />
                        )}
                    </div>
                  ))}
              </AccordionItemPanel>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <ButtonComponent
                className="add-access-btn"
                iconRight={<ForwardIcon />}
                handleButton={() => addToAccessList()}
                label="Add"
              />
            </div>
            <div className="all-access-list">
              <AccordionItemPanel>
                {Object.keys(accessList).length > 0
                  ? Object.entries(accessList).map(([key, value]) => (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '95%',
                        height: '35px',
                      }}
                    >
                      <p style={{ color: '#868686', fontSize: '14px' }}>{value}</p>
                      <DeleteIcon
                        className="delete-icon"
                        onClick={() => removeFromAccessList(key)}
                      />
                    </div>
                  )) : (
                    <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                      <p style={{ color: '#868686', fontSize: '14px' }}>
                        Дані відсутні
                      </p>
                    </div>
                  )}
              </AccordionItemPanel>
            </div>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default AccessRightsLists;
