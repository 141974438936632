import { Dialog } from '@mui/material';
import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as ViewIcon } from 'assets/images/icons/Eye-on.svg';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { Map } from 'component/Map';

export const ViewDroneData = (props) => {
  const [open, setOpen] = useState(false);
  const [map, setMap] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setDroneLayer = (droneDataId) => {
    map.addSource('drone-source', {
      type: 'raster',
      tiles: [`${axios.defaults.baseURL}drones/${droneDataId}/tiff/details/{bbox-epsg-3857}`],
      tileSize: 256,
    });

    map.addLayer({
      id: 'drone-source-layer',
      type: 'raster',
      source: 'drone-source',
    });

    if (props.centroid) {
      map.flyTo({
        center: props.centroid,
        essential: true,
        zoom: 12,
      });
    }
  };

  useEffect(() => {
    if (!map) return;
    setDroneLayer(props.id);
  }, [map]);

  return (
    <>
      <ViewIcon
        className="view-icon"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">View drone data</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="message flex-column-group">
            <div className="double-text">
              <span>File name</span>
              <span>{props.fileName}</span>
            </div>
            <Map
              map={map}
              setMap={setMap}
              width="width-500"
            />
            <div className="flex-between">
              <div className="double-text">
                <span>Date</span>
                <span>{props.date}</span>
              </div>
              <div className="double-text align-right">
                <span>Report</span>
                <span>{props.reportName}</span>
              </div>
            </div>
          </div>
          <div className="buttons-group">
            <ButtonComponent
              className="danger"
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Close"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
