import { React, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './index.scss';

import axios from 'axios';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { axiosWrapper } from 'utils/Helper';
import { URLS } from 'routes/Urls';

import { Table, TableHeader, TableRow } from 'component/Table';
import { Tab } from 'component/Tab';
import { Loader } from 'component/Loader';

import { ReactComponent as ResetIcon } from 'assets/images/icons/update2.svg';
import {
  CreateReportDialog,
  DeleteReportDialog,
} from 'component/Dialog';
import { DatePickerWrapper } from 'component/DataPicker';
import { useUser } from 'routes/UserContext';
import { ReactComponent as EditIcon } from '../../assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as CommentIcon } from '../../assets/images/icons/chat.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/icons/Eye-on.svg';
import { ReactComponent as SortByIcon } from '../../assets/images/icons/Sort-by2.svg';
import { ReactComponent as DoneIcon } from '../../assets/images/icons/Done-1.svg';
import { FilterByStatusDialog } from './Filters/FilterReportList';
import ReportsList from './ReportsList';

export const NTS_HA_REPORT = () => {
  const { user } = useUser();
  const location = useLocation();
  const [ntsData, setNtsData] = useState([]);
  const [haData, setHaData] = useState([]);
  const [ntsHaData, setNtsHaData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [resetFiltersStatus, setResetFiltersStatus] = useState(false);
  const [isNtsNextProperty, setIsNtsNextProperty] = useState('');
  const [isHaNextProperty, setIsHaNextProperty] = useState('');
  const [isNtsHaNextProperty, setIsNtsHaNextProperty] = useState('');
  const [rotations, setRotations] = useState({
    report_date: 0,
    settlement_code: 0,
    report_name: 0,
    report_area: 0,
    imsma_id: 0,
  });
  const [lastClickedIcon, setLastClickedIcon] = useState(null);

  const [startDate, setStartDate] = useState(location.state?.startDate || null);
  const [endDate, setEndDate] = useState(location.state?.endDate || null);   
  const formatDate = (dateString) => {
    if (!dateString) {
      return '-';
    }
    const date = moment(dateString, 'DD.MM.YYYY');
    if (!date.isValid()) {
      return '-';
    }
    return `${date.format('L')}`;
  };

  const initParams = {
    validation_status: [],
    hazard_area_type: [],
    dateFrom: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
    dateTo: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
    page: 1,
    ordering: [],
  };
  const [ntsParams, setNtsParams] = useState(initParams);
  const [haParams, setHaParams] = useState(initParams);
  const [ntsHaParams, setNtsHaParams] = useState(initParams);
  const [sortDirectionNts, setSortDirectionNts] = useState(true);
  const [sortDirectionHa, setSortDirectionHa] = useState(true);
  
  const handleContextMenu = (e) => {
    e.preventDefault(); 
   }

  const generateUrl = (params, reportType) => {
    let url = `/reports/${reportType}`;

    const queryParams = [];

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        if (Array.isArray(params[key]) && params[key].length > 0) {
          queryParams.push(
            params[key].map((value) => `${key}=${value}`).join('&'),
          );
        } else if (params[key] instanceof Date) {
          queryParams.push(`${key}=${params[key].toISOString()}`);
        }
      }
    });

    if (params.page) {
      queryParams.push(`page=${params.page}`);
    }
    if (params.ordering && params.ordering.length > 0) {
      queryParams.push(`ordering=${params.ordering}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return url;
  };

  const fetchDataWithSortingAndFilter = (
    params,
    setData,
    setIsNextProperty,
    reportType,
    dateFrom,
    dateTo,
  ) => {
    setLoader(true);
    const url = generateUrl(params, reportType);

    axiosWrapper(() => {
      axios
        .get(url, {
          params: {
            date_from: dateFrom || null,
            date_to: dateTo || null,
          },
        })
        .then((res) => {
          setData((prev) => [...prev, ...res.data.results]);
          setIsNextProperty(res.data.next);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    });
  };

  useEffect(() => {
    fetchDataWithSortingAndFilter(
      ntsParams,
      setNtsData,
      setIsNtsNextProperty,
      'nts/',
      ntsParams?.dateFrom,
      ntsParams?.dateTo,
    );
  }, [ntsParams]);

  useEffect(() => {
    fetchDataWithSortingAndFilter(
      haParams,
      setHaData,
      setIsHaNextProperty,
      'ha/',
      haParams?.dateFrom,
      haParams?.dateTo,
    );
  }, [haParams]);

  useEffect(() => {
    fetchDataWithSortingAndFilter(
      ntsHaParams,
      setNtsHaData,
      setIsNtsHaNextProperty,
      'nts-ha/',
      ntsHaParams?.dateFrom,
      ntsHaParams?.dateTo,
    );
  }, [ntsHaParams]);

  const loadMoreData = (type) => {
    switch (type) {
      case 'nts':
        setNtsParams((prevParams) => ({
          ...prevParams,
          page: prevParams.page + 1,
        }));
        break;
      case 'ha':
        setHaParams((prevParams) => ({
          ...prevParams,
          page: prevParams.page + 1,
        }));
        break;
      case 'ntsHa':
        setNtsHaParams((prevParams) => ({
          ...prevParams,
          page: prevParams.page + 1,
        }));
        break;
      default:
        break;
    }
  };

  const handleSelectedStatusChange = (
    selectedNtsStatus,
    selectedHaStatus,
    selectedAreaType,
  ) => {
    if (!Array.isArray(selectedNtsStatus || selectedHaStatus)) {
      console.error('selectedStatus is not an array');
      return;
    }

    if (activeTabIndex === 0) {
      setResetFiltersStatus(false);
      setNtsData([]);
      setNtsParams((prevParams) => ({
        ...prevParams,
        page: 1,
        validation_status: selectedNtsStatus.map((status) => status.value),
      }));
    } else if (activeTabIndex === 1) {
      setResetFiltersStatus(false);
      setHaData([]);
      setHaParams((prevParams) => ({
        ...prevParams,
        page: 1,
        hazard_area_type: selectedAreaType.map((areaType) => areaType.value),
        validation_status: selectedHaStatus.map((status) => status.value),
      }));
    }
  };

  const navigate = useNavigate();

  const handleSortIconClick = (ordering) => {
    switch (activeTabIndex) {
      case 0:
        setNtsData([]);

        setNtsParams((prevParams) => ({
          ...prevParams,
          page: 1,
          ordering: sortDirectionNts === true ? `-${ordering}` : ordering,
        }));

        setSortDirectionNts((prevDirection) => (prevDirection === true ? false : true));

        break;
      case 1:
        setHaData([]);

        setHaParams((prevParams) => ({
          ...prevParams,
          page: 1,
          ordering: sortDirectionHa === true ? `-${ordering}` : ordering,
        }));

        setSortDirectionHa((prevDirection) => (prevDirection === true ? false : true));

        break;
      default:
        break;
    }
    const currentRotation = rotations[ordering] || 0;
    const newRotation = currentRotation + 180;

    if (lastClickedIcon && lastClickedIcon !== ordering) {
      setRotations((prevRotations) => ({
        ...prevRotations,
        [lastClickedIcon]: 0,
      }));
    }
    setRotations((prevRotations) => ({
      ...prevRotations,
      [ordering]: newRotation,
    }));
    setLastClickedIcon(ordering);
  };

  const handleStartDateChange = (date) => {
    navigate(location.state, {});

    setStartDate(date.format('DD.MM.YYYY'));
    setNtsParams((prevParams) => ({
      ...prevParams,
      dateFrom: date.format('DD.MM.YYYY'),
      page: 1,
    }));
    setHaParams((prevParams) => ({
      ...prevParams,
      dateFrom: date.format('DD.MM.YYYY'),
      page: 1,
    }));
    setNtsHaParams((prevParams) => ({
      ...prevParams,
      dateFrom: date.format('DD.MM.YYYY'),
      page: 1,
    }));
    setNtsData([]);
    setHaData([]);
    setNtsHaData([]);
  };

  const handleEndDateChange = (date) => {
    navigate(location.state, {});

    setEndDate(date.format('DD.MM.YYYY'));
    setNtsParams((prevParams) => ({
      ...prevParams,
      dateTo: date.format('DD.MM.YYYY'),
      page: 1,
    }));

    setHaParams((prevParams) => ({
      ...prevParams,
      dateTo: date.format('DD.MM.YYYY'),
      page: 1,
    }));

    setNtsHaParams((prevParams) => ({
      ...prevParams,
      dateTo: date.format('DD.MM.YYYY'),
      page: 1,
    }));
    setNtsData([]);
    setHaData([]);
    setNtsHaData([]);
  };

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  const confirmPopup = () => {
    const reportName = localStorage.getItem('reportName' || '');
    if (reportName) {
      navigate(URLS.CREATE_NTS_REPORT_PAGE, {
        state: { name: reportName },
      });
    }
  };

  const handleResetClick = () => {
    setResetFiltersStatus(true);
    setHaData([]);
    setNtsData([]);
    setNtsHaData([]);
    setStartDate(null);
    setEndDate(null);

    setNtsParams((prevParams) => ({
      ...prevParams,
      dateFrom: null,
      dateTo: null,
      validation_status: [],
      hazard_area_type: [],
      page: 1,
    }));
    setHaParams((prevParams) => ({
      ...prevParams,
      dateFrom: null,
      dateTo: null,
      validation_status: [],
      hazard_area_type: [],
      page: 1,
    }));
    setNtsHaParams((prevParams) => ({
      ...prevParams,
      dateFrom: null,
      dateTo: null,
      page: 1,
    }));
    navigate(location.state, {});
  };

  const deleteNtsHaReport = (reportId, reportType) => {
    if (reportType === 'nts_report') {
      axiosWrapper(() => {
        axios
          .delete(`/reports/nts/${reportId}`)
          .then(() => {
            setNtsData((prevData) => prevData.filter((item) => item.id !== reportId));
            setNtsHaData((prevData) => prevData.filter((item) => item.id !== reportId));
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else if (reportType === 'ha_report' || reportType === 'ha') {
      axiosWrapper(() => {
        axios
          .delete(`/reports/ha/${reportId}`)
          .then(() => {
            setHaData((prevData) => prevData.filter((item) => item.id !== reportId));
            setNtsHaData((prevData) => prevData.filter((item) => item.id !== reportId));
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  const deleteNtsReport = (selectedNtsReportId) => {
    if (activeTabIndex === 0) {
      axiosWrapper(() => {
        axios
          .delete(`/reports/nts/${selectedNtsReportId}`)
          .then(() => {
            setNtsData((prevData) => prevData.filter((item) => item.id !== selectedNtsReportId));
            setNtsData([]);

            setNtsParams({
              dateFrom: null,
              dateTo: null,
              validation_status: [],
              hazard_area_type: [],
              page: 1,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  const getEditReportUrl = (reportType, id) => {
    if (reportType === 'ha_report' || reportType === 'ha') {
      return `/edit-ha-report/${id}`;
    } else if (reportType === 'nts_report') {
      return `/edit-nts-report/${id}`;
    }
    return '';
  };
  const [disabledDeleteDialogs, setDisabledDeleteDialogs] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});
  
  const handleAuxClick = (event, id) => {
    
    if (event.button === 1 ) {
      openIntoNewTab(id); 
    }
  };

  const openIntoNewTab = (id) => {
    const mode = 'validation';
    localStorage.setItem('mode', mode);
    setDisabledButtons(prevState => ({
      ...prevState,
      [id]: { validation: true, edit: true, delete: true  }
    }));
  
    setDisabledDeleteDialogs(prevState => ({
      ...prevState,
      [id]: true
    }));
  };

  const EditReportLink = ({ reportType, id }) => {

    const url = getEditReportUrl(reportType, id);
      
    if (!url) return null;
  const isEditDisabled = disabledButtons[id]?.edit;

    return (
      <Link to={url} state={{ id }} target="_blank" rel="noopener noreferrer" style={{ color: isEditDisabled ? '#d3d3d3' : '#868686', pointerEvents: isEditDisabled ? 'none' : 'auto' }}
      onClick={() => openIntoNewTab(id)}
      onAuxClick={(event)=> handleAuxClick(event,id)}
      >
        <EditIcon className="edit-icon"/>
      </Link>
    );
  };

  const getViewReportUrl = (reportType, id) => {
    if (reportType === 'ha_report' || reportType === 'ha') {
      return `/view-ha-report/${id}`;
    } else if (reportType === 'nts_report') {
      return `/view-nts-report/${id}`;
    }
    return '';
  };

  const ViewReportLink = ({ reportType, id }) => {
    const url = getViewReportUrl(reportType, id);
    if (!url) return null;

    return (
      <Link to={url} state={{ id }} target="_blank" rel="noopener noreferrer" style={{ color: '#868686' }}>
        <ViewIcon className="view-icon" />
      </Link>
    );
  };


  const getValidationReportUrl = (reportType, id) => {
    if (reportType === 'ha_report' || reportType === 'ha') {
      return `/validation-ha-report/${id}`;
    } else if (reportType === 'nts_report') {
      return `/validation-nts-report/${id}`;
    }
    return '';
  };

  const ValidationReportLink = ({ reportType, id }) => {
    const url = getValidationReportUrl(reportType, id);
    if (!url) return null;
    const isValidationDisabled = disabledButtons[id]?.validation;

    return (
      <Link to={url} target="_blank" rel="noopener noreferrer" className="view-icon" style={{ color: isValidationDisabled ? '#d3d3d3' : '#868686', pointerEvents: isValidationDisabled ? 'none' : 'auto' }}
      onClick={() => openIntoNewTab(id)}
      onAuxClick={(event)=> handleAuxClick(event,id)}
      >
        <CommentIcon className="view-icon" />
      </Link >
    );
  };


  const deleteHaReport = (selectedHaReportId) => {
    if (activeTabIndex === 1) {
      axiosWrapper(() => {
        axios
          .delete(`/reports/ha/${selectedHaReportId}`)
          .then(() => {
            setHaData((prevData) => prevData.filter((item) => item.id !== selectedHaReportId));
            setHaData([]);
            setHaParams({
              dateFrom: null,
              dateTo: null,
              validation_status: [],
              hazard_area_type: [],
              page: 1,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    }
  };

  const getActionsByRoleAndStatus = (item) => {
    const draftOrRejectedStatus = item?.validation_status?.value === 'draft'
      || item?.validation_status?.value === 'draft_rejected';
    const validationStatus = item?.validation_status?.value?.startsWith('val_')
      || item?.validation_status?.value === 'imsma_rejected';
    return (
      <div onContextMenu={handleContextMenu}>
        {user?.permissions?.change_nto_report
          && draftOrRejectedStatus && (
            <EditReportLink reportType={item.report_type} id={item.id}  />
          )}
        {user?.permissions?.view_nto_report && (
          <ViewReportLink reportType={item.report_type} id={item.id}  />
        )}
        {user?.permissions?.delete_nto_report
          && draftOrRejectedStatus && (
            <DeleteReportDialog
              deleteNtsReport={
                activeTabIndex === 0 ? () => deleteNtsReport(item.id) : null
              }
              deleteHaReport={
                activeTabIndex === 1 ? () => deleteHaReport(item.id) : null
              }
              deleteNtsHaFunction={
                activeTabIndex === 2
                  ? () => deleteNtsHaReport(item.id, item.report_type)
                  : null
              }
              isDisabled={disabledDeleteDialogs[item.id]}
            />
          )}
        {user?.permissions?.validate_nto_report
          && item?.is_current_validation_role
          && validationStatus
          && (
            <ValidationReportLink reportType={item.report_type} id={item.id} />
          )}
      </div>
    );
  };

  useEffect(() => {
    document.title = 'NTS and HA reports';
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  return (
    <section>
      <div className="nts-header">
        <div className="nts-title">NTS / HA report</div>
        <div style={{ display: 'flex', gap: '50px' }}>
          <div style={{ display: 'flex', gap: '14px' }}>
            {activeTabIndex !== 2 && (
              <FilterByStatusDialog
                activeTabIndex={activeTabIndex}
                handleSelectedStatusChange={handleSelectedStatusChange}
                resetFiltersStatus={resetFiltersStatus}
              />
            )}
            <div
              style={{
                display: 'flex',
                gap: '5px',
              }}
            >
              <div
                className="flex-group flex-between"
                style={{ width: '150px' }}
              >
                <DatePickerWrapper
                  value={startDate ? moment(startDate, 'DD.MM.YYYY') : null}
                  handleDatePicker={handleStartDateChange}
                />
              </div>
              <div
                className="flex-group flex-between"
                style={{ width: '150px', paddingRight: '10px' }}
              >
                <DatePickerWrapper
                  value={endDate ? moment(endDate, 'DD.MM.YYYY') : null}
                  handleDatePicker={handleEndDateChange}
                />
              </div>
              <div className="text-hover p-1" onClick={handleResetClick}>
                <ResetIcon color="#868686" />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '10px' }}>
            <Tab>
              <div
                className={`tab-item-custom ${activeTabIndex === 0 ? 'active' : ''
                  }`}
                onClick={() => handleTabClick(0)}
              >
                NTS
              </div>
              <div
                className={`tab-item-custom ${activeTabIndex === 1 ? 'active' : ''
                  }`}
                onClick={() => handleTabClick(1)}
              >
                HA
              </div>
              <div
                className={`tab-item-custom ${activeTabIndex === 2 ? 'active' : ''
                  }`}
                onClick={() => handleTabClick(2)}
              >
                <DoneIcon className="done-icon" />
                All
              </div>
            </Tab>
          </div>
        </div>
      </div>
      <div className="nts">
        <div className="nts-table">
          <Table>
            <TableHeader>
              <th className="text-with-icon">
                {activeTabIndex !== 2 && (
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.report_date}deg)`,
                    }}
                    onClick={() => handleSortIconClick('report_date')}
                  />
                )}
                Date
              </th>
              <th className="text-with-icon" style={{ flex: '100px' }}>
                {activeTabIndex !== 2 && (
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.settlement_code}deg)`,
                    }}
                    onClick={() => handleSortIconClick('settlement_code')}
                  />
                )}
                KOTTG
              </th>
              <th>Type</th>
              <th className="text-with-icon">
                {activeTabIndex !== 2 && (
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.report_name}deg)`,
                    }}
                    onClick={() => handleSortIconClick('report_name')}
                  />
                )}
                Name
              </th>
              <th className="text-with-icon" style={{ flex: '200px' }}>
                {activeTabIndex === 1 && (
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.report_area}deg)`,
                    }}
                    onClick={() => handleSortIconClick('report_area')}
                  />
                )}
                Area
              </th>
              <th className="text-with-icon" style={{ flex: '200px' }}>
                {activeTabIndex !== 2 && (
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.imsma_id}deg)`,
                    }}
                    onClick={() => handleSortIconClick('imsma_id')}
                  />
                )}
                IMSMA ID
              </th>
              <th>Status</th>
              <th>Action</th>
            </TableHeader>
            <tbody>
              {activeTabIndex === 0
                && (ntsData && ntsData.length === 0 ? (
                  <TableRow>
                    <td colSpan="7">Дані відсутні</td>
                  </TableRow>
                ) : (
                  <ReportsList
                    data={ntsData}
                    activeTabIndex={activeTabIndex}
                    formatDate={formatDate}
                    getActionsByRoleAndStatus={getActionsByRoleAndStatus}
                  />
                ))}
              {activeTabIndex === 1
                && (haData && haData.length === 0 ? (
                  <TableRow>
                    <td colSpan="7">Дані відсутні</td>
                  </TableRow>
                ) : (
                  <ReportsList
                    data={haData}
                    setData={setHaData}
                    activeTabIndex={activeTabIndex}
                    formatDate={formatDate}
                    getActionsByRoleAndStatus={getActionsByRoleAndStatus}
                  />
                ))}
              {activeTabIndex === 2
                && (ntsHaData && ntsHaData.length === 0 ? (
                  <TableRow>
                    <td colSpan="7">Дані відсутні</td>
                  </TableRow>
                ) : (
                  <ReportsList
                    data={ntsHaData}
                    setData={setNtsHaData}
                    activeTabIndex={activeTabIndex}
                    formatDate={formatDate}
                    getActionsByRoleAndStatus={getActionsByRoleAndStatus}
                  />
                ))}
            </tbody>
          </Table>
        </div>
        <div className="loadMore-div">
          {activeTabIndex === 2 && isNtsHaNextProperty !== null && (
            <button
              className="loadMore-button"
              onClick={() => {
                loadMoreData('ntsHa');
              }}
            >
              Load more...
            </button>
          )}
        </div>
        <div className="loadMore-div">
          {activeTabIndex === 1 && isHaNextProperty !== null && (
            <button
              className="loadMore-button"
              onClick={() => {
                loadMoreData('ha');
              }}
            >
              Load more...
            </button>
          )}
        </div>
        <div className="loadMore-div">
          {activeTabIndex === 0 && isNtsNextProperty !== null && (
            <button
              className="loadMore-button"
              onClick={() => {
                loadMoreData('nts');
              }}
            >
              Load more...
            </button>
          )}
        </div>
        {user?.permissions?.add_nto_report && (
          <>
            <hr className="nts-line" />
            <div className="nts-footer">
              <CreateReportDialog confirmPopup={confirmPopup} />
            </div>
          </>
        )}
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
