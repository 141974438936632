import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';

export const ReportDate = (props) => {
  const updateReportDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          report_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{props.formType === 'ha' ? '1.4' : '1.2'}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата звіту"
      titleEN="Report date"
      className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <DatePickerWrapper
          value={moment(props.formData.info.general.report_date, 'DD.MM.YYYY')}
          handleDatePicker={updateReportDate}
          disableDateBefore={props?.formData?.info?.general?.examination_date?.to}
        />
      )}
    />
  );
};
