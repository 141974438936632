import { React, useState } from 'react';
import { Dialog } from '@mui/material';
import { toast } from 'react-toastify';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete_24px.svg';
import { axiosWrapper } from 'utils/Helper';
import { deleteTemplate } from 'api/TemplatesApi';

export const DeleteTemplate = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTemplateAction = (id) => {
    props.setLoader(true);
    axiosWrapper(() => {
      deleteTemplate(id)
        .then(() => {
          handleClose();
          props.setLoader(false);
          toast.success('Template was deleted');
          props.getTemplates(1);
        })
        .catch((err) => {
          console.log(err);
          props.setLoader(false);
          toast.error('Error delete template!');
        });
    });
  };

  return (
    <div className="drone-new-btn">
      <DeleteSvg
        className="delete-icon"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        className="templates-modal"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Template delete</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="flex-column-group">
            Are you sure?
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={() => {
                handleClose();
              }}
              label="Cancel"
            />
            <ButtonComponent
              className="danger"
              iconLeft={<DeleteSvg />}
              handleButton={() => deleteTemplateAction(props.id)}
              label="Delete"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
