import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const AntiPersonnelMineView = (props) => {
  const { formData } = props;
  return (
    <AccordionBodyItemView
      accordionSubId="3.1"
      titleUA="Протипіхотні міни"
      titleEN="Anti-personnel mine"
      children={
        formData.info.hazard_type.anti_personnel_mines && (
          <CheckBoxIcon />
        )
      }
    />
  );
};
