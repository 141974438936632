import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import { SwitherComponent } from 'component/Swither';
import { React, useEffect, useState } from 'react';

export const InternalHazardArea = (props) => {
  const [switherState, setSwitherState] = useState(
    !props.formData.info.general.custom_internal_id,
  );

  const previousId = props.formData.info.general.internal_id;

  useEffect(() => {
    const id = props.formData.info.general.custom_internal_id;
    if (id) {
      setSwitherState(!id);
    }
  }, [props.formData.info.general.custom_internal_id]);

  const handleInternalId = (value) => {
    value = value.toUpperCase();
    if (/^[A-Z0-9-]*$/.test(value)) {
      props.setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          general: {
            ...prevFormData.info.general,
            custom_internal_id: value,
          },
        },
      }));
    }
  };

  if (props.formType !== 'ha') return null;
  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.1</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Внутрішній ID небезпечної території в організації"
      titleEN="Internal Hazard ID"
      children={(
        <div className="flex-column-group align-right">
          {props.formData.is_internal_id_editable && (
            <>
              <SwitherComponent
                labelRight={(
                  <>
                    <div>Внести самостійно</div>
                    <div>Enter it yourself</div>
                  </>
                )}
                labelLeft={(
                  <>
                    <div>Автоматично згенерований</div>
                    <div>Automatically generated</div>
                  </>
                )}
                checked={switherState}
                handleSwitcher={(value) => {
                  if (value) {
                    handleInternalId('');
                  }
                  setSwitherState(!switherState);
                }}
              />

              <InputComponent
                id="internal"
                pattern="^[A-Z0-9-]*$"
                name="internalId"
                value={!switherState ? props.formData.info.general.custom_internal_id : previousId}
                handleInput={handleInternalId}
                disabled={switherState}
              />
            </>
          )}
          {!props.formData.is_internal_id_editable
            && (
              <span>
                {props.formData.info.general.custom_internal_id
                  || props.formData.info.general.internal_id}
              </span>
            )}
        </div>
      )}
    />
  );
};
