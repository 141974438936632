import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const DistrictView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.2"
      titleUA="Район"
      titleEN="District"
      children={formData.info.location.district}
    />
  );
};
