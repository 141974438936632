import axios from 'axios';
import { getCookie } from './Cookies';

export const getHeaders = () => {
  const token = getCookie('access_token');
  if (!token) return false;
  return `Bearer ${token}`;
};

export const setHeaders = () => {
  axios.defaults.headers.common.Authorization = getHeaders();
};
