import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const AbandonedView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="3.4"
      titleUA="Залишені ВНП (I категорія)"
      titleEN="Abandoned Explosive Ordnance"
      children={
        formData.info.hazard_type.abandoned_explosive_ordnance && (
          <CheckBoxIcon />
        )
      }
    />
  );
};
