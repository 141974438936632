import React from 'react';
import { AccordionHeaderTitle } from 'component/Accordion';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { ReactComponent as MapIcon } from 'assets/images/icons/map1.svg';
import { AnnexMapBlock } from './AnnexMapGallery';

export const AnnexMap = (props) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          titleUA="Annex maps"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <div className="card-wrapper flex-column-group">
        {props.errors?.annex && (
          <span className="error-text">
            Потрібно завантажити хоча б одне фото
          </span>
        )}
        <div className={`card annex-card ${props.errors?.annex ? 'error' : ''}`}>
          <div className="header">
            <MapIcon color="#868686" />
            <span>Map upload</span>
          </div>
          <AnnexMapBlock
            setAnnexImages={props.setAnnexImages}
            annexImages={props.annexImages}
            formData={props.formData}
            setFormData={props.setFormData}
            setLoaderPhotos={props.setLoaderPhotos}
          />

        </div>
      </div>

    </AccordionItemPanel>
  </>
);
