import { featureCollection, point } from '@turf/helpers';
import { Map } from 'component/Map';
import { React, useEffect, useState } from 'react';
import NTSPoint from 'assets/images/icons/location.png';
import { convertCoords3857To4326, convertCoords4326To3857 } from 'utils/Helper';

export const NTSmap = (props) => {
  const [NTSgeojson, setNTSgeojson] = useState(featureCollection([]));

  const [coords4326, setCoords4326] = useState(props.formData.info
    .point?.geometry?.coordinates || null);

  useEffect(() => {
    setCoords4326(props.formData.info.point?.geometry?.coordinates);
  }, [props.formData.info.point?.geometry?.coordinates]);

  const handleLong = (value) => {
    props.setCoords3857((prevCoords) => {
      const updatedCoords = [...prevCoords];
      updatedCoords[0] = Number(value);
      return updatedCoords;
    });
  };

  const handleLat = (value) => {
    props.setCoords3857((prevCoords) => {
      const updatedCoords = [...prevCoords];
      updatedCoords[1] = Number(value);
      return updatedCoords;
    });
  };

  useEffect(() => {
    if (props.mode === 'edit') return;
    setNTSgeojson(featureCollection([]));
    setCoords4326(null);
    handleLat(null);
    handleLong(null);
  }, [props.formData.info.general.hazard_name]);

  const updateFormDataWithCoords = (coords) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        point: {
          type: 'Point',
          coordinates: coords,
        },
      },
    }));
  };

  const updateGeojson = (coords) => {
    if (!coords && coords4326 && coords4326[0] && coords4326[1]) {
      setNTSgeojson(featureCollection([point(coords4326)]));
    } else if (coords && coords[0] && coords[1]) {
      setNTSgeojson(featureCollection([point(coords)]));
    } else {
      setNTSgeojson(featureCollection([]));
    }
  };

  const addMapboxPointLayer = () => {
    if (!props.map.getSource('nts-point')) {
      props.map.addSource('nts-point', {
        type: 'geojson',
        data: NTSgeojson,
        generateId: true,
      });

      [{ 'nts-point-icon': NTSPoint }].forEach((icon) => {
        props.map.loadImage(Object.values(icon)[0], (error, image) => {
          if (error) throw error;
          props.map.addImage(Object.keys(icon)[0], image);
        });
      });

      props.map.addLayer({
        id: 'nts-point-layer',
        type: 'symbol',
        source: 'nts-point',
        layout: {
          'icon-image': 'nts-point-icon',
          'icon-size': 1,
          'icon-offset': [0, -12],
          'icon-allow-overlap': true,
        },
      });
    } else {
      props.map.getSource('nts-point').setData(NTSgeojson);
    }
  };

  useEffect(() => {
    if (props.coords3857) {
      setCoords4326(convertCoords3857To4326(props.coords3857, props.zone));
    }
  }, [props.coords3857]);

  useEffect(() => {
    updateGeojson();
    updateFormDataWithCoords(coords4326);
  }, [coords4326]);

  useEffect(() => {
    if (!props.map) return;

    props.map.on('click', (e) => {
      if (!props.zone) return;
      props.setCoords3857(convertCoords4326To3857([
        Number(e.lngLat.lng), Number(e.lngLat.lat),
      ], props.zone));
      setCoords4326([e.lngLat.lng, e.lngLat.lat]);
    });

    updateGeojson();
  }, [props.map, props.zone]);

  useEffect(() => {
    if (!props.map) return;
    addMapboxPointLayer();
  }, [NTSgeojson]);

  return (
    <div className="flex-column-group no-y-margin">
      <Map
        map={props.map}
        setMap={props.setMap}
      />
      <div className="flex-group">
        <div className={`double-text ${props.errors?.point?.coordinates ? 'error' : ''}`}>
          <span>Координати</span>
          <span>Coordinates</span>
        </div>
        <div className="line" />
        <div className="flex-group">
          <input
            placeholder="440571"
            id="nts-map-long"
            className="text-center"
            value={props.coords3857 ? props.coords3857[0]?.toFixed() : null}
            autoComplete="off"
            onChange={(e) => {
              handleLong(e.target.value);
            }}
          />
          <input
            placeholder="440571"
            id="nts-map-lat"
            className="text-center"
            value={props.coords3857 ? props.coords3857[1]?.toFixed() : null}
            autoComplete="off"
            onChange={(e) => {
              handleLat(e.target.value);
            }}
          />
        </div>
      </div>
    </div>

  );
};
