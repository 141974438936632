/* eslint-disable consistent-return */
import translate from 'deepl';
import { useEffect } from 'react';
import {
  HAFieldsToDeepLTranslate,
  NTSFieldsToDeepLTranslate,
} from 'consts/FieldsToDeepLTranslate';
import { useDeepL } from 'utils/DeepLContext';

export const translateText = async (text, token) => {
  try {
    const response = await translate({
      free_api: true,
      text,
      target_lang: 'EN',
      auth_key: token,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const TranslateWrapperComponent = ({
  formData,
  setTranslatedFormData,
  translationState,
  children,
}) => {
  const { token } = useDeepL();

  const fieldsToTranslate = formData.report_type === 'nts_report'
    ? NTSFieldsToDeepLTranslate(formData)
    : HAFieldsToDeepLTranslate(formData);

  const transformTranslatedData = (translatedData) => {
    const transformedData = {};
    Object.keys(fieldsToTranslate).forEach((key, index) => {
      transformedData[key] = translatedData[index].text;
    });
    return transformedData;
  };

  useEffect(() => {
    if (!translationState) return;

    translateText(Object.values(fieldsToTranslate), token)
      .then((response) => {
        setTranslatedFormData(transformTranslatedData(response.data.translations));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [translationState]);

  return children;
};
