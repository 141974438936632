import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { AntiPersonnelMines } from './Contamination/AntiPersonnelMine';
import { AntiVehiceMines } from './Contamination/AntiVehiceMine';
import { UnexplodedOrdnance } from './Contamination/UnexplodedOrdnance';
import { AbandonedExplosiveOrdnance } from './Contamination/AbandonedExplosiveOrdnance';
import { ClusterMunition } from './Contamination/ClusterMunition';
import { ArmsAmmunition } from './Contamination/ArmsAmmunition';
import { ImprovisedExplosiveDevice } from './Contamination/ImprovisedExplosiveDevice';
import { Explosives } from './Contamination/Explosives';
import { Description } from './Contamination/Description';

export const ContaminationInfo = (props) => {
  const handleContamination = (value, isChecked) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        hazard_type: {
          ...prevFormData.info.hazard_type,
          [value]: isChecked,
        },
      },
    }));
  };

  const handleContaminationDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        hazard_type: {
          ...prevFormData.info.hazard_type,
          description: value,
        },
      },
    }));
  };

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            validationForm={props.validationForm}
            block="hazard_type"
            titleUA={(
              <div className="flex-group">
                <span>Тип забруднення</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="Type of contamination"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {props.errors.hazard_type
          && <span className="error-text">Потрібно обрати хоча би один з варіантів.</span>}

        <AntiPersonnelMines
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <AntiVehiceMines
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <UnexplodedOrdnance
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <AbandonedExplosiveOrdnance
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <ClusterMunition
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <ImprovisedExplosiveDevice
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <ArmsAmmunition
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <Explosives
          formData={props.formData}
          setFormData={props.setFormData}
          handleContamination={handleContamination}
          errors={props.errors}
        />
        <Description
          formData={props.formData}
          setFormData={props.setFormData}
          handleContaminationDescription={handleContaminationDescription}
        />
      </AccordionItemPanel>
    </>
  );
};
