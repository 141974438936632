/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
import { ButtonComponent } from 'component/Button';
import { InputComponent } from 'component/Input';
import { React, useEffect, useState } from 'react';
import moment from 'moment';
import { CheckboxInput } from 'component/Checkbox';
import { SelectComponent } from 'component/Select';
import { toast } from 'react-toastify';
import { DatePickerWrapper } from 'component/DataPicker';
import { ImageDeleteDialog } from 'component/Dialog';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as TurnIcon } from 'assets/images/icons/update2.svg';
import { ReactComponent as ImageIcon } from 'assets/images/icons/Photo.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/Calendar.svg';
import { ReactComponent as MapIcon } from 'assets/images/icons/map3.svg';
import { ReactComponent as PencilIcon } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as TranslateSvg } from 'assets/images/icons/translate_24px.svg';
import { createOrUpdateFile, getBase64, sortedPhotos } from 'utils/Helper';
import { translateText } from 'component/Translation';
import { TextareaComponent } from 'component/Textarea';
import { useDeepL } from 'utils/DeepLContext';

export const ImageDetailInfo = (props) => {
  const photo = props.photos[props.currentImageIndex];
  const [details, setDetails] = useState(props.photos[props.currentImageIndex]);
  const [editDateAction, setEditDateAction] = useState(false);
  const [editLatLongAction, setEditLatLongAction] = useState(false);
  const [translateState, setTranslateState] = useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const { token } = useDeepL();

  useEffect(() => {
    if (!translateState) return;
    translateText(details.description, token)
      .then((res) => {
        setTranslatedText(res.data.translations[0].text);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [translateState]);

  const changeObjectIndex = (array, currentIndex, newIndex) => {
    const removedObject = array.splice(currentIndex, 1)[0];
    array.splice(newIndex, 0, removedObject);
    return array;
  };

  const getEvidenceAppendixObjects = (evidenceType) => {
    const updatedEvidence = {};
    props.formData.info.evidences[`${evidenceType}s`].items.forEach((item, index) => {
      const filteredPhotos = props.photos
        .filter((photoObj) => photoObj.dependency === evidenceType && photoObj.item === index + 1);
      updatedEvidence[index + 1] = filteredPhotos.map((_, indexFilteredPhotos) => ({
        value: indexFilteredPhotos + 1,
        label: `№ ${indexFilteredPhotos + 1}`,
      }));
    });
    return updatedEvidence;
  };

  function createAppendixOptions(dependency) {
    return props.photos
      .filter((photoObj) => photoObj.dependency === dependency)
      .map((_, index) => ({
        value: index + 1,
        label: `№ ${index + 1}`,
      }));
  }

  const baseAppendixOptions = {
    lm: createAppendixOptions('lm'),
    bm: createAppendixOptions('bm'),
    direct_evidence: getEvidenceAppendixObjects('direct_evidence'),
    indirect_evidence: getEvidenceAppendixObjects('indirect_evidence'),
    general: createAppendixOptions('general'),
  };

  const [appendixOptions, setAppendixOptions] = useState(baseAppendixOptions);

  useEffect(() => {
    setDetails(props.photos[props.currentImageIndex]);
    setAppendixOptions(baseAppendixOptions);
  }, [props.currentImageIndex]);

  const handlePhotoDate = (value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      date: moment(value, 'YYYY:MM:DD HH:mm').format('YYYY:MM:DD HH:mm'),
    }));
  };

  const handleAppendix = (value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      order: value,
    }));

    props.setPhotos((prevPhotos) => {
      let photosWithDependency = prevPhotos
        .filter((photoObj) => photoObj.dependency === details.dependency);
      photosWithDependency = changeObjectIndex(
        photosWithDependency,
        photosWithDependency
          .findIndex((filteredPhotoObj) => filteredPhotoObj.file.name === details.file.name),
        value - 1,
      );
      // eslint-disable-next-line no-return-assign
      photosWithDependency?.forEach((photoObj, index) => {
        if (photoObj) photoObj.order = index + 1;
      });
      return sortedPhotos(prevPhotos);
    });
  };

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  const DateInfo = (props) => {
    if (!editDateAction) {
      if (props.date) {
        const day = moment(props.date, 'YYYY:MM:DD').format('LL') || 'Unknow';
        const time = moment(props.date, 'YYYY:MM:DD HH:mm').format('HH:mm');
        const dayOfWeek = moment(props.date, 'YYYY:MM:DD').format('dddd');
        return (
          <>
            <div>{day}</div>
            <div>
              {dayOfWeek}
              ,
              {' '}
              {time}
            </div>
          </>
        );
      }
      return 'Unknow date';
    }
    return (
      <DatePickerWrapper
        value={moment(details.date, 'YYYY:MM:DD')}
        handleDatePicker={(value) => { handlePhotoDate(value); }}
      />
    );
  };

  // eslint-disable-next-line no-shadow
  const GpsInfo = (props) => {
    if (editLatLongAction) return;
    if (props.lat && props.long) {
      return `${props.lat}, ${props.long}`;
    }
    return 'Unknow location';
  };

  const directEvidenceOptions = props.formData?.info.evidences?.direct_evidences.items
    .map((_, index) => ({
      value: `4.2.${index + 1}`,
      label: `4.2.${index + 1}`,
    }));

  const indirectEvidenceOptions = props.formData?.info.evidences?.indirect_evidences.items
    .map((_, index) => ({
      value: `4.5.${index + 1}`,
      label: `4.5.${index + 1}`,
    }));

  const lmBmOptions = [{
    value: 'lm',
    label: 'LM',
  }, {
    value: 'bm',
    label: 'BM',
  }];

  const getOptionValue = (selectName) => {
    if (!details) {
      return {
        value: null,
        label: null,
      };
    }
    if (details.dependency === 'direct_evidence'
      && selectName === 'evidences'
      && details.item) {
      return {
        value: `4.2.${details.item}`,
        label: `4.2.${details.item}`,
      };
    } if (details.dependency === 'indirect_evidence'
      && selectName === 'evidences'
      && details.item) {
      return {
        value: `4.5.${details.item}`,
        label: `4.5.${details.item}`,
      };
    } if (details.dependency === 'lm'
      && selectName === 'lmBm') {
      return {
        value: 'lm',
        label: 'LM',
      };
    } if (details.dependency === 'bm'
      && selectName === 'lmBm') {
      return {
        value: 'bm',
        label: 'BM',
      };
    }
    return {
      value: null,
      label: null,
    };
  };

  const setPhotoDependency = (value, type) => {
    if (type === 'evidences') {
      const dependencyNumber = Number(value.split('.')[1]);
      const item = Number(value.split('.')[2]);

      const dependency = dependencyNumber === 2
        ? 'direct_evidence'
        : 'indirect_evidence';

      setDetails((prevDetails) => ({
        ...prevDetails,
        dependency,
        item,
        order: appendixOptions[dependency][item]?.length + 1 || 1,
      }));

      setAppendixOptions((prevOptions) => ({
        ...prevOptions,
        [dependency]: {
          ...prevOptions[dependency],
          [item]: [...prevOptions[dependency][item], {
            value: prevOptions[dependency][item]?.length + 1 || 1,
            label: `№ ${prevOptions[dependency][item]?.length + 1 || 1}`,
          }],
        },
      }));
    }
    if (type === 'lmBm') {
      setDetails((prevDetails) => ({
        ...prevDetails,
        dependency: value,
        item: null,
        order: appendixOptions[value].length + 1,
      }));

      setAppendixOptions((prevOptions) => ({
        ...prevOptions,
        [value]: [...prevOptions[value], {
          value: prevOptions[value].length + 1,
          label: `№ ${prevOptions[value].length + 1}`,
        }],
      }));
    }
  };

  const handlePhotoDescription = (value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      description: value,
    }));
  };

  const handleLat = (value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      lat: value,
    }));
  };

  const handleLong = (value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      long: value,
    }));
  };

  const handleRotate = (value) => {
    const updatedFile = createOrUpdateFile(value, details.file.name);
    setDetails((prevDetails) => ({
      ...prevDetails,
      data_url: value,
      file: updatedFile,
    }));
    props.setPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos[props.currentImageIndex].data_url = value;
      updatedPhotos[props.currentImageIndex].file = updatedFile;
      return updatedPhotos;
    });
  };

  const savePhoto = () => {
    if ((details.dependency === 'direct_evidence'
      || details.dependency === 'indirect_evidence')
      && !details.item) {
      toast.error('You need to select evidence or LM/BM');
      return;
    }

    props.setPhotos((prevPhotos) => {
      const updatedPhotos = prevPhotos.map((photoObj) => {
        if (photoObj.file.name === details.file.name) {
          return {
            ...details,
          };
        }
        return photoObj;
      });
      return sortedPhotos(updatedPhotos);
    });
    toast.success('Photo was updated');
  };

  return (
    <div className="file-info">
      <div className="header">
        <span>Image information</span>
        <div onClick={() => props.closeModal()}>
          <CloseIcon />
        </div>
      </div>
      <div className="body">
        <div className="flex-between">
          <div className="flex-group">
            <CalendarIcon />
            <div className="double-text">
              <DateInfo
                date={photo?.date}
              />
            </div>
          </div>
          <div
            className="icon text-hover"
            onClick={(e) => {
              e.preventDefault();
              setEditDateAction(true);
            }}
          >
            <PencilIcon />
          </div>
        </div>
        <div className="flex-between">
          <div className="flex-group">
            <MapIcon />
            <GpsInfo
              lat={photo?.lat}
              long={photo?.long}
            />
            {editLatLongAction
              && (
                <div className="flex-column-group">
                  <InputComponent
                    value={details.lat}
                    placeholder="Add lat"
                    handleInput={(value) => {
                      handleLat(value);
                    }}
                  />
                  <InputComponent
                    value={details.long}
                    placeholder="Add long"
                    handleInput={(value) => {
                      handleLong(value);
                    }}
                  />
                </div>
              )}
          </div>
          <div
            className="icon text-hover"
            onClick={(e) => {
              e.preventDefault();
              setEditLatLongAction(true);
            }}
          >
            <PencilIcon />
          </div>
        </div>
        <div className="flex-between">
          <div className="flex-group">
            <ImageIcon className="text-hover" />
            <span>Rotate photo</span>
          </div>
          <div className="icon text-hover">
            <TurnIcon
              onClick={() => {
                props.setLoaderPhotos(true);
                getBase64(details.data_url)
                  .then((res) => {
                    handleRotate(res);
                    props.setLoaderPhotos(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    props.setLoaderPhotos(false);
                  });
              }}
            />
          </div>
        </div>

        <div className="flex-group-column">
          <TextareaComponent
            id="photo-description"
            name="photo-description"
            value={translateState
              ? translatedText
              : details?.description}
            handleTextarea={(value) => { handlePhotoDescription(value); }}
            blueLink={false}
            disabled={translateState}
            limitChar={false}
            style={{ width: '278px' }}
            placeholder="Add description"
          />
          <div
            className="text-hover"
            onClick={() => {
              setTranslateState(!translateState);
            }}
          >
            <TranslateSvg />
            Translate
          </div>

          <CheckboxInput
            id="addToReport"
            value="addToReport"
            label="Add to report"
            name="addToReport"
            margin="no-y-margin"
            checked={details?.dependency !== 'general'}
            handleInput={(value, isChecked) => {
              const dependency = isChecked ? 'direct_evidence' : 'general';
              setDetails((prevDetails) => ({
                ...prevDetails,
                dependency,
                item: null,
              }));
            }}
          />
          {details?.dependency !== 'general' && (
            <div className="flex-column-group">
              <div className="select-wrapper w-100">
                <span className="label">Evidence</span>
                <SelectComponent
                  options={[...directEvidenceOptions, ...indirectEvidenceOptions]}
                  placeholder="Select..."
                  value={getOptionValue('evidences')}
                  handleSelect={(value) => {
                    setPhotoDependency(value, 'evidences');
                  }}
                />
              </div>
              {details.dependency === 'direct_evidence'
                && (
                  <CheckboxInput
                    id="sendToImsma"
                    value="sendToImsma"
                    label="Send to IMSMA"
                    name="sendToImsma"
                    margin="no-y-margin"
                    checked={details?.send_to_imsma}
                    handleInput={(value, isChecked) => {
                      setDetails((prevDetails) => ({
                        ...prevDetails,
                        send_to_imsma: isChecked,
                      }));
                    }}
                  />
                )}
              <div className="select-wrapper w-100">
                <span className="label">LM/BM</span>
                <SelectComponent
                  options={lmBmOptions}
                  value={getOptionValue('lmBm')}
                  placeholder="Select..."
                  handleSelect={(value) => {
                    setPhotoDependency(value, 'lmBm');
                  }}
                />
              </div>
            </div>
          )}
          <div className="select-wrapper w-100">
            <span className="text error">*Рекомендуємо проставляти порядок фотографій після завантаження усіх.</span>
            <span className="text error">*We recommend that you put the order of the photos after uploading them all.</span>
            <span className="label">Appendix numeration</span>

            <SelectComponent
              options={details.dependency === 'direct_evidence' || details.dependency === 'indirect_evidence'
                ? appendixOptions[details.dependency][details.item]
                : appendixOptions[details.dependency]}
              value={{
                value: details.order,
                label: `№ ${details.order}`,
              }}
              placeholder="Select..."
              handleSelect={(value) => {
                handleAppendix(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <ImageDeleteDialog
          svgOnly={false}
          deleteImage={props.deletePhoto}
          closeModal={props.closeModal}
        />
        <div className="line" />
        <div className="flex-group flex-center">
          <ButtonComponent
            handleButton={(e) => {
              savePhoto(e);
              props.closeModal();
            }}
            className="outline"
            label={(
              <div className="flex-group">
                <SaveIcon />
                <span>Save</span>
              </div>
            )}
          />
          <ButtonComponent
            handleButton={props.closeModal}
            className="danger outline"
            label={(
              <div className="flex-group">
                <CloseIcon />
                <span>Cancel</span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
