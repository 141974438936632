import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { PriorityLevel } from './LandreleaseActivity/PriorityLevel';
import { AppropriateOperation } from './LandreleaseActivity/AppropriateOperation';
import { ReasonAndComments } from './LandreleaseActivity/ReasonsAndComments';
import { ClearanceDepth } from './LandreleaseActivity/ClearanceDepth';
import { AdditionalComments } from './LandreleaseActivity/AdditionalComments';

export const LandreleaseActivityInfo = (props) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          accordionId="7"
          validationForm={props.validationForm}
          block="next_landrelease_activity"
          titleUA={(
            <div className="flex-group">
              <span>Зауваження щодо подальшого розблокування ділянки</span>
              <span className="star">*</span>
            </div>
          )}
          titleEN="Information about next landrelease activity"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <PriorityLevel
        formData={props.formData}
        setFormData={props.setFormData}
      />
      <AppropriateOperation
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
      <ReasonAndComments
        formData={props.formData}
        setFormData={props.setFormData}
      />
      <ClearanceDepth
        formData={props.formData}
        setFormData={props.setFormData}
        errors={props.errors}
      />
      <AdditionalComments
        formData={props.formData}
        setFormData={props.setFormData}
      />
    </AccordionItemPanel>
  </>
);
