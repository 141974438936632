import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';
import { React, useState } from 'react';
import { InputComponent } from '../../../../component/Input';

export const ClosestSettlement = (props) => {
  const [inputVisible, setInputVisible] = useState(true);

  const closestSettlementNameObj = props.formData.info.location.nearest_settlement_name;

  const updateClosestSettlement = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        location: {
          ...prevFormData.info.location,
          nearest_settlement_name: {
            ...prevFormData.info.location.nearest_settlement_name,
            settlement_name: value,
          },
        },
      },
    }));
  };

  const handleLocationMatch = (value) => {
    setInputVisible(value === 'true' ? false : true);
    if (value === 'true') {
      updateClosestSettlement('');
    }
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        location: {
          ...prevFormData.info.location,
          nearest_settlement_name: {
            ...prevFormData.info.location.nearest_settlement_name,
            matches: value === 'true' ? true : false,
          },
        },
      },
    }));
  };

  const handleClosestSettlement = (value) => {
    updateClosestSettlement(value);
  };

  return (
    <AccordionBodyItem
      accordionSubId="2.6"
      titleUA="Найближчий населений пункт"
      titleEN="Closest settlement"
      children={(
        <div className="flex-column-group text-left flex-wrap">
          <RadioButton
            id="matches"
            name="match"
            value
            handleInput={handleLocationMatch}
            checked={closestSettlementNameObj.matches}
            disabled={false}
            label="Співпадає з 2.5 \ Matches 2.5"
          />
          <RadioButton
            id="noMatches"
            name="match"
            value={false}
            handleInput={handleLocationMatch}
            checked={!closestSettlementNameObj.matches}
            disabled={false}
            label={(
              <>
                <div>Не співпадає з 2.5 (вказати нижче ТГ, населений пункт) /</div>
                <div>Doesn’t match 2.5 (specify below council, settlement)</div>
              </>
            )}
          />

          {inputVisible
            && (
              <InputComponent
                name="closestSettlementName"
                value={closestSettlementNameObj.settlement_name}
                handleInput={handleClosestSettlement}
                placeholder="Settlement name"
                blueLink
                limitChar
              />
            )}
        </div>
      )}
    />
  );
};
