import ReactDOM from 'react-dom/client';
import { React, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { featureCollection, point } from '@turf/helpers';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import FMSMachineryOnlineIcon from 'assets/images/icons/traktor.png';
import FMSMachineryOfflineIcon from 'assets/images/icons/traktor-offline.png';
import ParkingIcon from 'assets/images/icons/Parking.png';
import { axiosWrapper } from 'utils/Helper';
import { Map } from 'component/Map';
import { Loader } from 'component/Loader';
import { getFMSMachineryLocation, getFMSMachineryTracks } from 'api/MachineryApi';
import { FilterDate } from './FilterDate';
import { MachineryPopup } from './MachineryTracksPopup';

export const MachineryTrackingPage = () => {
  const [loader, setLoader] = useState(true);
  const [map, setMap] = useState(null);
  const [fromDate, setFromDate] = useState(moment(new Date().getDate() - 1, 'DD.MM.YYYY'));
  const [toDate, setToDate] = useState(moment(new Date(), 'DD.MM.YYYY'));
  const [fmsMachineryTrackIndexes, setFmsMachineryTrackIndexes] = useState([]);

  useEffect(() => {
    document.title = 'Machinery tracking';
  }, []);

  const transformMachineryTracksGeojson = (data) => {
    if (!data.length) return [];
    return data.map((machine) => featureCollection(machine.tracks));
  };

  const transformMachineryLocationGeojson = (data) => {
    if (!data.length) return [];
    return data.map((machine) => {
      const pointFeature = point(machine.location.coordinates, {
        name: machine.name,
        state: machine.state,
        speed: machine.speed,
      });
      return featureCollection([pointFeature]);
    });
  };

  const setFmsMachineryLocation = (data) => {
    data.forEach((geojson, index) => {
      if (map) {
        [{ 'fms-machinery-online-icon': FMSMachineryOnlineIcon },
          { 'fms-machinery-offline-icon': FMSMachineryOfflineIcon }].forEach((icon) => {
          map.loadImage(Object.values(icon)[0], (error, image) => {
            if (error) throw error;
            map.addImage(Object.keys(icon)[0], image);
          });
        });
        map.addSource(`fms-machinery-location-${index}`, {
          type: 'geojson',
          data: geojson,
          generateId: true,
        });

        map.addLayer({
          id: `fms-machinery-location-${index}-point-layer`,
          type: 'symbol',
          source: `fms-machinery-location-${index}`,
          layout: {
            'icon-image': [
              'match',
              ['get', 'state'],
              'STOP', 'fms-machinery-offline-icon',
              'MOVE', 'fms-machinery-online-icon',
              'fms-machinery-offline-icon',
            ],
            'icon-size': 1,
            'icon-offset': [0, 0],
            'icon-allow-overlap': true,
            'text-field': ['get', 'name'],
            'text-size': 16,
            'text-offset': [0, 1.5],
          },
          paint: {
            'text-color': '#747474',
          },
          filter: ['==', '$type', 'Point'],
        });
      }
    });
  };

  const setFMSMachinery = (data) => {
    fmsMachineryTrackIndexes.forEach((index) => {
      if (map && map?.getSource(`fms-machinery-period-${index}`)) {
        map.removeLayer(`fms-machinery-period-${index}-line-layer`);
        map.removeLayer(`fms-machinery-period-${index}-point-layer`);
        map.removeSource(`fms-machinery-period-${index}`);
      }
      setFmsMachineryTrackIndexes([]);
    });
    data.forEach((geojson, index) => {
      if (map) {
        [{ 'fms-parking-icon': ParkingIcon }].forEach((icon) => {
          map.loadImage(Object.values(icon)[0], (error, image) => {
            if (error) throw error;
            map.addImage(Object.keys(icon)[0], image);
          });
        });
        map.addSource(`fms-machinery-period-${index}`, {
          type: 'geojson',
          data: geojson,
          generateId: true,
        });

        setFmsMachineryTrackIndexes((prev) => [...prev, index]);

        map.addLayer({
          id: `fms-machinery-period-${index}-line-layer`,
          type: 'line',
          source: `fms-machinery-period-${index}`,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#ff0000',
            'line-width': 2,
          },
          filter: ['==', '$type', 'LineString'],
        });

        map.addLayer({
          id: `fms-machinery-period-${index}-point-layer`,
          type: 'symbol',
          source: `fms-machinery-period-${index}`,
          layout: {
            'icon-image': 'fms-parking-icon',
            'icon-size': 0.015,
            'icon-offset': [0, 0],
            'icon-allow-overlap': true,
            'icon-rotate': 180,
          },
          filter: ['==', '$type', 'Point'],
        });

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.on('mouseenter', [`fms-machinery-period-${index}-point-layer`], (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const container = document.createElement('div');

          const root = ReactDOM.createRoot(container);
          root.render(<MachineryPopup properties={e.features[0].properties} />);

          popup
            .setLngLat(coordinates)
            .setDOMContent(container)
            .addTo(map);
        });

        map.on('mouseleave', `fms-machinery-period-${index}-point-layer`, () => {
          popup.remove();
        });
      }
    });
    map.moveLayer('fms-machinery-location-0-point-layer');
    map.moveLayer('fms-machinery-location-1-point-layer');
  };

  const getMachineryLocation = () => {
    axiosWrapper(() => {
      getFMSMachineryLocation(new Date())
        .then((res) => {
          setFmsMachineryLocation(transformMachineryLocationGeojson(res.data));
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toast.error('Error loading machinery location.');
          console.log(err);
        });
    });
  };

  const getMachineryTracks = () => {
    setLoader(true);
    axiosWrapper(() => {
      getFMSMachineryTracks(fromDate, toDate)
        .then((res) => {
          setFMSMachinery(transformMachineryTracksGeojson(res.data));
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toast.error('Error.');
          console.log(err);
        });
    });
  };

  useEffect(() => {
    if (!map) return;
    getMachineryLocation();
    if (!fromDate || !toDate) return;
    getMachineryTracks();
  }, [map]);

  return (
    <section className="no-padding">
      <Map
        map={map}
        setMap={setMap}
        height="full"
        googleWms
      />
      <FilterDate
        fromDate={fromDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        toDate={toDate}
        getMachineryTracks={getMachineryTracks}
      />
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
