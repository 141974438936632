import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as AttachmentSvg } from 'assets/images/icons/attachment.svg';
import { ReactComponent as PlusSvg } from 'assets/images/icons/add_24px.svg';

import 'assets/styles/card.scss';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { UploadButton } from './UploadButton';
import { ButtonComponent } from './Button';

export const FileUploadAttachments = (props) => {
  const {
    attachments,
    setAttachments,
    formData,
  } = props;

  const [fileList, setFileList] = useState(attachments || []);

  useEffect(() => {
    if (fileList.length === 0) {
      setFileList(attachments);
    }
  }, [attachments])

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const duplicateFiles = newFiles.filter((newFile) => attachments
      ?.some((att) => att.file?.name === newFile.name));

    if (duplicateFiles.length > 0) {
      toast.warning('You cannot upload attachments with the same name');
    } else {
      setFileList((prevList) => [...prevList, ...newFiles]);
    }
  };

  const handleUploadClick = () => {
    if (fileList.length > 0) {
      setAttachments(() => [...fileList]);
    }
  };

  useEffect(() => {
    handleUploadClick();
  }, [fileList]);

  const files = fileList ? [...fileList] : [];

  return (
    <div className="card-wrapper flex-group flex-wrap">
      {files.map((file, i) => (
        <div className="card attachment-card" key={i}>
          <AnnexFilePreview file={file} />
          <div className="flex-group annex-text">
            <DownloadAttachment
              name={file.name}
              dataUrl={URL.createObjectURL(file)}
            />
            <DeleteAttachment
              handleDelete={() => {
                setFileList((prevList) => prevList.filter((_, index) => index !== i));
                setAttachments((prevAttachments) => prevAttachments
                  .filter((_, index) => index !== i));
              }}
            />
          </div>
        </div>
      ))}
      <div className="no-attachment-file card attachment-card">
        <span className="rounded-bg-grey">
          <AttachmentSvg />
        </span>
        <UploadButton
          id="upload-attachments"
          handleUploadButton={handleFileChange}
          label={
            <><PlusSvg /><span>Add file</span></>
          }
        />
      </div>
    </div>
  );
};

const AnnexFilePreview = ({ file }) => (
  <span className="cut-text text file-name">{file.name}</span>
);

const DownloadAttachment = ({ name, dataUrl }) => (
  <a
    className="download-link flex-group"
    href={dataUrl}
    target="_blank"
    rel="noreferrer"
    download={name}
  >
    <DownloadSvg />
    Download file
  </a>
);

const DeleteAttachment = ({ handleDelete }) => (
  <ButtonComponent
    handleButton={handleDelete}
    className="danger delete-btn"
    label={<DeleteSvg />}
    type="button"
  />
);
