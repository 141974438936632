import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import React from 'react';

export const ReporterOrganization = (props) => {
  const { formData } = props;
  const options = [
    { value: 'FSD', label: 'FSD' },
  ];

  const updateReporterOrganization = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          reporter_organization: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.5</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Організація, що звітує"
      titleEN="Reporting organization"
      className={props.errors?.general?.reporter_organization ? 'error' : ''}
      children={(
        <SelectComponent
          options={options}
          value={formData?.info?.general?.reporter_organization
            ? {
              label: formData?.info?.general?.reporter_organization,
              value: formData?.info?.general?.reporter_organization,
            }
            : options[0]}
          handleSelect={updateReporterOrganization}
        />
      )}
    />
  );
};
