import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';

export const AppropriateOperation = (props) => {
  const lanreleaseActivity = props.formData.info.next_landrelease_activity;

  const handleLandrealiseActivity = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          next_most_appropriate_operation: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>7.2</span>
          <span className="star">*</span>
        </div>
)}
      titleUA="Рекомендований подальший етап розблокування"
      titleEN="Most appropriate operation to conduct next"
      className={props.errors?.next_landrelease_activity?.next_most_appropriate_operation ? 'error' : ''}
      rightContentClassName="w-60"
      children={(
        <div className="flex-group flex-wrap">
          <RadioButton
            id="resurvey"
            name="next_most_appropriate_operation"
            value="resurvey"
            label="Повторне проведення НТО \ Re-survey"
            checked={lanreleaseActivity.next_most_appropriate_operation.includes('resurvey')}
            handleInput={handleLandrealiseActivity}
            disable="false"
          />
          <RadioButton
            id="techsurvey"
            name="next_most_appropriate_operation"
            value="techsurvey"
            label="Технічне обстеження \ Technical survey"
            checked={lanreleaseActivity.next_most_appropriate_operation.includes('techsurvey')}
            handleInput={handleLandrealiseActivity}
            disable="false"
          />
          <RadioButton
            id="clearance"
            name="next_most_appropriate_operation"
            value="clearance"
            label="Розмінування \ Clearance"
            checked={lanreleaseActivity.next_most_appropriate_operation.includes('clearance')}
            handleInput={handleLandrealiseActivity}
            disable="false"
          />
          <RadioButton
            id="cancellation"
            name="next_most_appropriate_operation"
            value="cancellation"
            label="Відміна \ Cancellation"
            checked={lanreleaseActivity.next_most_appropriate_operation.includes('cancellation')}
            handleInput={handleLandrealiseActivity}
            disable="false"
          />
        </div>
)}
    />
  );
};
