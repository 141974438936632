import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const ReportProviderView = (props) => {
  const { formData, accordionSubId = '1.6' } = props;
  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Звіт надав"
      titleEN="Report provider"
      children={formData.info?.general?.reporter_name}
    />
  );
};
