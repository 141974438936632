/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ReactComponent as CheckSvg } from 'assets/images/icons/Done-1.svg';

export const AccordionHeaderTitle = (props) => {
  const {
    block,
    validationForm,
    accordionId,
    titleUA,
    titleEN,
  } = props;

  let svgClass = '';

  if (block && validationForm) {
    const blocks = Object.values(validationForm[block]);

    if (block === 'hazard_type') {
      svgClass = blocks.some((item) => item === true) ? 'check-full' : 'check-empty';
    } else {
      svgClass = blocks.every((item) => item === true)
        ? 'check-full'
        : blocks.every((item) => item === false)
          ? 'check-empty'
          : 'check-not-full';
    }
  }

  return (
    <div className="accordion-header-wrapper">
      <span className="numberId">{accordionId}</span>
      {validationForm
        && block
        && <div><CheckSvg class={svgClass} /></div>}
      <div className="accordion-title">
        <span>{titleUA}</span>
        <span>{titleEN}</span>
      </div>
      <span className="line" />
    </div>
  );
};

export const AccordionHeaderUserSettings = (props) => (
  <div className="accordion-header-wrapper">
    <div className="accordion-title-validation" style={{ backgroundColor: `${props.backgroundColor}` }}>
      {props.title}
    </div>
    <span className="line" style={{ flex: '1' }} />
    <div
      className="accordion-user-properties"
    >
      {props.userName}
    </div>
    <span className="line" style={{ flex: '1' }} />
    <div
      className="accordion-user-properties"
    >
      {props.email}
    </div>
    <span className="line" style={{ flex: '1' }} />
  </div>
);

export const AccordionBodyItem = (props) => {
  const {
    align,
    rightContentClassName,
    accordionSubId,
    titleUA,
    titleEN,
    bgStyle,
    className,
    children,
  } = props;

  const accordionWrapperClass = align ? align : '';
  const rightContentClass = rightContentClassName
    ? rightContentClassName
    : '';
  const bgStyleBody = bgStyle ? bgStyle : '';
  const classNameBody = className ? className : '';

  return (
    <div
      className={`accordion-body-wrapper ${accordionWrapperClass} ${bgStyleBody} ${classNameBody}`}
    >
      <span className="numberId">{accordionSubId}</span>
      <div className="accordion-title">
        <span>{titleUA}</span>
        <span>{titleEN}</span>
      </div>
      <span className="line" />
      <span className={`right-content ${rightContentClass}`}>
        {children}
      </span>
    </div>
  );
};

export const AccordionBodyItemView = (props) => {
  const {
    align,
    accordionSubId,
    titleUA,
    titleEN,
    bgStyle,
    children,
  } = props;

  const accordionWrapperClass = align ? align : '';

  return (
    <div
      className={`accordion-body-wrapper-view ${accordionWrapperClass} ${bgStyle}`}
    >
      <span className="numberId">{accordionSubId}</span>
      <div className="accordion-right-part">
        <div className="accordion-title">
          <span>{titleUA}</span>
          <span>{titleEN}</span>
        </div>
        <span className="accordion-last-items">{children}</span>
      </div>
    </div>
  );
};

export const AccordionBodyItemView2 = (props) => {
  const {
    align,
    accordionSubId,
    titleUA,
    titleEN,
    bgStyle,
    children,
  } = props;

  const accordionWrapperClass = align ? align : '';

  return (
    <div
      className={`accordion-body-wrapper-view2 ${accordionWrapperClass} ${bgStyle}`}
    >
      <span className="numberId">{accordionSubId}</span>
      <div className="accordion-right-part">
        <div className="accordion-title">
          <span>{titleUA}</span>
          <span>{titleEN}</span>
        </div>
        <span className="accordion-last-items">{children}</span>
      </div>
    </div>
  );
};
