import { React, useState } from 'react';
import { SmallLoader } from 'component/Loader';
import { SwitherComponent } from 'component/Swither';

export const MapLayers = (props) => {
  const [imsmaAll, setImsmaAll] = useState(false);
  const [imsmaFsd, setImsmaFsd] = useState(false);
  const [bufferZone, setBufferZone] = useState(false);
  const [feodal, setFeodal] = useState(false);
  const [maxar, setMaxar] = useState(false);

  const setLayerVisibility = (layer, bool) => {
    if (!props.map.getLayer(layer)) return;
    const visibility = bool ? 'visible' : 'none';
    props.map.setLayoutProperty(layer, 'visibility', visibility);
  };

  return (
    <div className="absolute-center bottom">
      {props.mapLayersLoads ? '' : <SmallLoader />}
      <div className="box-map">
        <SwitherComponent
          className="green"
          labelRight="IMSMA All"
          checked={imsmaAll}
          handleSwitcher={(bool) => {
            setLayerVisibility('imsma-all-point-hz_main-layer', bool);
            setLayerVisibility('imsma-all-point-ev_location-layer', bool);
            setLayerVisibility('imsma-all-hz_polygons-layer', bool);
            setLayerVisibility('imsma-all-point-hz_polygons_points-layer', bool);
            setImsmaAll(bool);
          }}
        />
        <SwitherComponent
          className="green"
          labelRight="IMSMA FSD"
          checked={imsmaFsd}
          handleSwitcher={(bool) => {
            setLayerVisibility('imsma-fsd-point-hz_main-layer', bool);
            setLayerVisibility('imsma-fsd-point-ev_location-layer', bool);
            setLayerVisibility('imsma-fsd-hz_polygons-layer', bool);
            setLayerVisibility('imsma-fsd-point-hz_polygons_points-layer', bool);
            setImsmaFsd(bool);
          }}
        />
        <SwitherComponent
          className="green"
          labelRight="Bufferzone"
          checked={bufferZone}
          handleSwitcher={(bool) => {
            setLayerVisibility('buffer-zone-layer', bool);
            setBufferZone(bool);
          }}
        />
        <SwitherComponent
          className="green"
          labelRight="Feodal"
          checked={feodal}
          handleSwitcher={(bool) => {
            setLayerVisibility('feodal-point-Feodal-layer', bool);
            setLayerVisibility('feodal-polygon-polygons_field-layer', bool);
            setLayerVisibility('feodal-point-ammunition_points_repeat-layer', bool);
            setLayerVisibility('feodal-point-transport_points_repeat-layer', bool);
            setFeodal(bool);
          }}
        />
        <SwitherComponent
          className="green"
          labelRight="Maxar"
          checked={maxar}
          handleSwitcher={(bool) => {
            setLayerVisibility('maxar-source-layer', bool);
            setMaxar(bool);
          }}
        />
      </div>
    </div>
  );
};
