import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';

export const Handouts = (props) => {
  const {
    formData,
    setFormData,
    type,
    handouts,
  } = props;

  const handleHandout = (id, value) => {
    if (/[^0-9]/.test(value)) return;

    const updatedHandouts = formData.info.session_information[type].handouts?.map((handout) => {
      if (handout.handout_id === id) {
        const count = Number(value) || 0;
        return {
          ...handout,
          count: count > 0 ? count : handout.count,
        };
      }
      return handout;
    })

    if (updatedHandouts.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          session_information: {
            ...prevFormData.info.session_information,
            [type]: {
              ...prevFormData.info.session_information[type],
              handouts: updatedHandouts,
            },
          },
        },
      }));
    }
  };

  return (
    <>
      <AccordionBodyItem
        titleUA="Матеріали для розповсюдження"
        titleEN="Materials for distribution"
      />
      <div className="flex-group handouts flex-wrap">
        {!handouts?.length && (
          <span className="text-center">
            Немає матеріалів для розповсюдження
          </span>
        )}
        {handouts?.length !== 0 && handouts?.map((handout) => (
          <Handout
            key={handout.handout_id}
            handout={handout}
            formData={formData}
            type={type}
            handleHandout={handleHandout}
          />
        ))}
      </div>
    </>
  );
};

export const Handout = (props) => {
  const {
    handout,
    handleHandout,
    formData,
    type,
  } = props;
  if (!handout.name) return null;

  return (
    <div className="handout-item">
      <span className="text">{handout.name}</span>
      <InputComponent
        type="text"
        placeholder="0"
        id={`${handout.handout_id}-count`}
        name={`${handout.handout_id}-count`}
        className="number"
        value={formData.info.session_information[type].handouts
          ?.find((item) => item.handout_id === handout.handout_id)?.count || 0}
        autoComplete="off"
        handleInput={(value) => handleHandout(handout.handout_id, value.replace(/^0+/, ''))}
      />
    </div>
  );
};
