import { React, useEffect, useState } from 'react';
import { getSettlement } from 'api/LocationAPI';
import { SelectComponent } from 'component/Select';

export const SettlementFilter = (props) => {
  const [options, setOptions] = useState([]);

  const getSettlementOptions = () => {
    getSettlement(props.regionFilter, props.districtFilter, props.localCommunityFilter)
      .then((res) => {
        const resOptions = res.data.map((item) => ({
          value: `${item.name} / ${item.eng_name}`,
          label: `${item.name} / ${item.eng_name}`,
        }));

        setOptions(resOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.localCommunityFilter) {
      getSettlementOptions();
    }
  }, [props.localCommunityFilter]);

  return (
    <div className="flex-column-group">
      <span>Sort by settlement name</span>
      <SelectComponent
        className="full"
        value={props.settlementFilter
          ? { value: props.settlementFilter, label: props.settlementFilter }
          : null}
        options={options}
        handleSelect={(e) => {
          props.setSettlementFilter(e);
        }}
        isMulti={false}
        isDisabled={!props.localCommunityFilter}
      />
    </div>
  );
};
