import { React, useState } from 'react';
import { Dialog } from '@mui/material';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import 'assets/styles/pages/templates.scss';
import { ReactComponent as ViewIcon } from 'assets/images/icons/Eye-on.svg';
import { NTSAttributesBlock } from './NTSAttributes';
import { HAAttributesBlock } from './HAAtrributes';

const findKey = (obj, targetKey) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === targetKey) {
        return obj[key];
      } if (typeof obj[key] === 'object') {
        const result = findKey(obj[key], targetKey);
        if (result !== undefined) {
          return result;
        }
      }
    }
  }
  return undefined;
};

function hasTrueChildProperty(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (hasTrueChildProperty(obj[key])) {
        return true;
      }
    } else if (obj[key] === true) {
      return true;
    }
  }
  return false;
}

const ViewAttributes = (props) => {
  const selectedNtsBlocks = {
    general: hasTrueChildProperty(props.fields.general),
    location: hasTrueChildProperty(props.fields.location),
    nto_result: hasTrueChildProperty(props.fields.nto_result),
  };
  const selectedHaBlocks = {
    general: hasTrueChildProperty(props.fields.general),
    examination_date: hasTrueChildProperty(props.fields.general?.examination_date),
    location: hasTrueChildProperty(props.fields.location),
    hazard_type: hasTrueChildProperty(props.fields.hazard_type),
    evidences: hasTrueChildProperty(props.fields.evidences),
    direct_evidences: hasTrueChildProperty(props.fields.evidences?.direct_evidences),
    indirect_evidences: hasTrueChildProperty(props.fields.evidences?.indirect_evidences),
    polygon_info: hasTrueChildProperty(props.fields.polygon_info),
    geographic_and_terrain_description: hasTrueChildProperty(props.fields
      .geographic_and_terrain_description),
    next_landrelease_activity: hasTrueChildProperty(props.fields.next_landrelease_activity),
    landuse_and_beneficiaries: hasTrueChildProperty(props.fields.landuse_and_beneficiaries),
    number_of_direct_beneficiaries: hasTrueChildProperty(props.fields
      .number_of_direct_beneficiaries),
    number_of_indirect_beneficiaries: hasTrueChildProperty(props.fields
      .number_of_indirect_beneficiaries),
    informators: hasTrueChildProperty(props.fields.informators),

  };
  return (
    <>
      {props.type === 'nts'
        && (
          <NTSAttributesBlock
            reportType={props.type}
            selectedNtsBlocks={selectedNtsBlocks}
            findKey={findKey}
            selectedNtsAttr={props.fields}
            NTSAttributes={props.NTSAttributes}
            viewMode
          />
        )}
      {props.type === 'ha'
        && (
          <HAAttributesBlock
            reportType={props.type}
            selectedHaBlocks={selectedHaBlocks}
            findKey={findKey}
            selectedHaAttr={props.fields}
            HAAttributes={props.HAAttributes}
            viewMode
          />
        )}
    </>
  );
};

export const ViewTemplate = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="drone-new-btn">
      <ViewIcon
        className="view-icon"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        className="templates-modal"
      >
        <div className="confirm-popup popup">
          <div className="header">
            <span className="title">Template view</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="flex-column-group">
            <div className="flex-group flex-between">
              <div className="double-text">
                <span>Template name</span>
                <span>{props.template.name}</span>
              </div>

            </div>
            <div className="flex-group">
              <div className="double-text">
                <span>Template type</span>
                <span>{props.template.type.toUpperCase()}</span>
              </div>
            </div>
            <div className="flex-group">
              <div className="double-text">
                <span>Attributes</span>
                <div className="attributes">
                  <ViewAttributes
                    fields={props.template.fields}
                    type={props.template.type}
                    HAAttributes={props.HAAttributes}
                    NTSAttributes={props.NTSAttributes}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttons-group">
            <ButtonComponent
              className="danger"
              iconLeft={<CancelSvg />}
              handleButton={() => {
                handleClose();
              }}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
