import { React, useEffect, useState } from 'react';
import { CheckboxInput } from 'component/Checkbox';
import generatePassword from 'utils/PasswordGenerate';

export const GeneratePasswordCheckbox = (props) => {
  const [checked, setChecked] = useState(false);

  const handleGeneratePassword = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (checked) {
      const newPassword = generatePassword();
      props.setUserInfo((prev) => ({
        ...prev,
        password: newPassword,
      }));
      props.setConfirmPassword(newPassword);
    }
  }, [checked]);

  return (
    <CheckboxInput
      id="generate-password"
      name="generate-password"
      label="Generate password"
      checked={checked}
      handleInput={handleGeneratePassword}
    />
  );
};
