import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';

export const AreaType = (props) => {
  if (props.formType !== 'ha') return null;

  const updateHazardAreaType = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          hazard_area_type: value,
        },
      },
    }));
  };

  const updatePolygonColor = (value) => {
    const colorPolygon = value === 'I3T|SHA' ? '#ff0000' : '#ffa500';
    const colorPoints = value === 'I3T|SHA' ? '#B42222' : '#bf7c00';
    props.map.setPaintProperty('turnPointsPolygon-layer', 'fill-color', colorPolygon);
    props.map.setPaintProperty('turnPoints-layer', 'circle-color', colorPoints);
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.10</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Тип небезпечної території"
      titleEN="Area type"
      children={(
        <div className="flex-group no-wrap">
          <RadioButton
            id="I3T|SHA"
            name="hazard_area_type"
            value="I3T|SHA"
            label="I3T|SHA"
            checked={props.formData.info.general.hazard_area_type.includes('I3T|SHA')}
            handleInput={(value) => {
              updatePolygonColor(value);
              updateHazardAreaType(value);
            }}
            disable="false"
          />
          <RadioButton
            id="3T|CHA"
            name="hazard_area_type"
            value="3T|CHA"
            label="3T|CHA"
            checked={props.formData.info.general.hazard_area_type.includes('3T|CHA')}
            handleInput={(value) => {
              updatePolygonColor(value);
              updateHazardAreaType(value);
            }}
            disable="false"
          />
        </div>
      )}
    />
  );
};
