import React from 'react';
import 'assets/styles/switcher.scss';

const LabelLeft = (props) => {
  if (!props.labelLeft) return false;
  return (
    <span>
      {props.labelLeft}
    </span>
  );
};

const LabelRight = (props) => {
  if (!props.labelRight) return false;
  return (
    <span>
      {props.labelRight}
    </span>
  );
};

export const SwitherComponent = (props) => (
  <div className="swither-wrapper">
    <LabelLeft labelLeft={props.labelLeft} />
    <input
      type="checkbox"
      className={`flipswitch ${props.className} ? ${props.className} : ''`}
      checked={props.checked}
      onChange={() => props.handleSwitcher(!props.checked)}
    />
    <LabelRight labelRight={props.labelRight} />
  </div>
);
