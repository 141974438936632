import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { DownloadAllAttachments } from '../../Attachments';

const AttachmentsBlockView = ({ attachments, setLoader, formData }) => (
  <Accordion
    allowMultipleExpanded
    allowZeroExpanded
    preExpanded={['attachments']}
  >
    <AccordionItem uuid="attachments">

      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            titleUA="Додаткові документи"
            titleEN="Attached documents"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div
          className="attachments-cards"
        >
          <div
            className="attachments-header"
          >
            <p
              className="attachments-header-text"
            >
              Кількість файлів:
              {' '}
              {attachments?.length}
            </p>
            {attachments?.length > 0 && (
              <div
                className="download-all-attachments-button"
              >
                <DownloadAllAttachments
                  setLoader={setLoader}
                  id={formData?.id}
                />
              </div>
            )}
          </div>
          {attachments?.map((attachment) => (
            <div key={attachment?.id}>
              <div>{attachment?.name}</div>
            </div>
          ))}
        </div>

      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
);

export default AttachmentsBlockView;
