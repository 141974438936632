import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const TeamIdView = (props) => {
  const { formData, accordionSubId = '1.7' } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={accordionSubId}
      titleUA="Ідентифікаційний номер групи/команди НТО"
      titleEN="Team"
      children={formData.info?.general?.team_id}
    />
  );
};
