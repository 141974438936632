import { Dialog } from '@mui/material';
import { ButtonComponent } from 'component/Button';
import React, { useState } from 'react';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as UserSvg } from 'assets/images/icons/User.svg';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { InputComponent } from 'component/Input';
import { ReactComponent as EditIcon } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import AccessRightsLists from 'component/AccessRightsLists';
import { createRole, updateRole } from 'api/UserAPI';
import { axiosWrapper } from 'utils/Helper';
import { toast } from 'react-toastify';
import { Loader } from 'component/Loader';
import { useUser } from 'routes/UserContext';

const RolePopup = ({ mode, role, getRolesList }) => {
  const [roleName, setRoleName] = useState(role?.name || '');
  const [shortRoleName, setShortRoleName] = useState(role?.short_name || '');
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [accessList, setAccessList] = useState(role?.permissions || {});
  const { user } = useUser();

  const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const isColorUnique = (color) => {
    const black = '#000000';
    const white = '#FFFFFF';
    const red = '#FF0000';

    return color !== black && color !== white && color !== red;
  };

  const setRandomColorForUserRole = () => {
    let color = getRandomColor();

    while (!isColorUnique(color)) {
      color = getRandomColor();
    }

    return color;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoleName = (value) => {
    setRoleName(value);
  };

  const handleShortRoleName = (value) => {
    let input = value.trim();
    input = input.replace(/[^A-Za-z]/g, '');
    input = input.slice(0, 3);
    setShortRoleName(input.toUpperCase());
  };

  const createUserRole = () => {
    axiosWrapper(() => {
      setLoader(true);
      const roleData = {
        name: roleName,
        short_name: shortRoleName,
        color: setRandomColorForUserRole(),
        permissions: Object.keys(accessList),
      };
      if (!roleData.name) {
        toast.error('Role name is required');
      }
      if (!roleData.short_name) {
        toast.error('Short role name is required');
      }
      createRole(roleData)
        .then(() => {
          setLoader(false);
          toast.success('Role is created');
          handleClose();
          getRolesList();
          setShortRoleName('');
          setRoleName('');
          setAccessList({});
        })
        .catch((err) => {
          console.log(err);
          toast.error('Role is not created');
        }).finally(() => {
          setLoader(false);
        });
    });
  };

  const updateUserRole = () => {
    axiosWrapper(() => {
      setLoader(true);
      const updateRoleData = {
        name: roleName,
        short_name: shortRoleName,
        color: role?.color,
        permissions: Object.keys(accessList),
      };
      if (!updateRoleData.name) {
        toast.error('Role name is required');
      }
      if (!updateRoleData.short_name) {
        toast.error('Short role name is required');
      }
      updateRole(role?.id, updateRoleData)
        .then(() => {
          setLoader(false);
          toast.success('Role is updated');
          handleClose();
          getRolesList();
        })
        .catch((err) => {
          console.log(err);
          toast.error('Role is not updated');
        }).finally(() => {
          setLoader(false);
        });
    });
  };

  return (
    <div className="text-center">
      {mode === 'edit'
        && <EditIcon className="edit-icon" onClick={() => setOpen(true)} />}
      {mode === 'create'
      && user?.permissions?.add_settings
      && (
        <ButtonComponent
          className="add-role"
          iconLeft={<AddSvg />}
          label="Add Role"
          handleButton={() => setOpen(true)}
        />
      )}
      <Dialog
        className="role-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="role-popup">
          <div className="header">
            <span className="title">
              {mode === 'create' && 'Add role'}
              {mode === 'edit' && 'Edit role'}
            </span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="role-inputs">
            <InputComponent
              type="text"
              iconLeft={<UserSvg />}
              upperRightText="Role name"
              id="input-role-name"
              name="role-name"
              placeholder="John Deere"
              value={roleName}
              handleInput={handleRoleName}
              autoComplete="off"
            />
            <InputComponent
              type="text"
              iconLeft={<UserSvg />}
              upperRightText="Short role name"
              id="input-short-role-name"
              name="short-role-name"
              placeholder="TL"
              value={shortRoleName}
              handleInput={handleShortRoleName}
              autoComplete="off"
            />
          </div>
          <AccessRightsLists
            accessList={accessList}
            setAccessList={setAccessList}
          />
          <Loader visible={loader} />
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={handleClose}
              label="Cancel"
            />
            {mode === 'create' && (
              <ButtonComponent
                iconLeft={<UserSvg />}
                handleButton={() => {
                  createUserRole();
                }}
                label="Add Role"
              />
            )}
            {mode === 'edit' && (
              <ButtonComponent
                iconLeft={<SaveIcon />}
                handleButton={() => {
                  updateUserRole();
                }}
                label="Save"
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>

  );
};

export default RolePopup;
