import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { getHierarchyListById } from 'api/UserAPI';
import { TableRowForUsers } from 'component/Table';
import { ReactComponent as PlusIcon } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus.svg';
import { ReactComponent as AccessAdditionalIcon } from 'assets/images/icons/AccessAdditionalIcon.svg';

import { convertColorToRgba } from 'utils/Helper';
import UserActions from './UserActions';

const UserRow = ({
  user,
  getHierarchyOfUsers,
  nestingLevel = 0,
  setOpenInfoPopup,
  setOpenUserPopup,
  setMode,
  setSelectedUserId,
  setAfterCreate,
  setLoader,
}) => {
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [children, setChildren] = useState([]);
  const isExpanded = expandedUserId === user?.id;
  const [isLoading, setIsLoading] = useState(false);
  const maxRowWidth = 100 - nestingLevel * 3;

  const toggleDetails = async (userId) => {
    if (expandedUserId === userId) {
      setExpandedUserId(null);
      setChildren([]);
    } else {
      setExpandedUserId(userId);
      setIsLoading(true);
      try {
        const response = await getHierarchyListById(userId);
        setChildren(response.data.children);
      } catch (error) {
        console.error('Error fetching user hierarchy:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <TableRowForUsers color={(user?.role?.color)} width={`${maxRowWidth}%`}>
        <td style={{ backgroundColor: convertColorToRgba(user?.role?.color, 0.7) }}>
          {user?.role?.short_name || '-'}
          {user?.changed_permissions
            && (
              <AccessAdditionalIcon
                className="additional-access-icon"
              />
            )}
        </td>
        <td style={{ flex: '140px' }}>{`${user?.name} ${user?.surname}` || ' '}</td>
        <td style={{ flex: '220px' }}>{user?.email || ''}</td>
        <td style={{ flex: '140px' }}>{user?.phone_number || ''}</td>
        <td aria-label="actions">
          <UserActions
            userFromList={user}
            setOpenInfoPopup={setOpenInfoPopup}
            setOpenUserPopup={setOpenUserPopup}
            setMode={setMode}
            setSelectedUserId={setSelectedUserId}
            setAfterCreate={setAfterCreate}
            setLoader={setLoader}
          />
        </td>
        <td>
          {isLoading ? (
            <ClipLoader
              color="rgba(0, 177, 183, 1)"
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            user?.children_count >= 1 && (
              isExpanded
                ? <MinusIcon onClick={() => toggleDetails(user?.id)} className="minus-icon">-</MinusIcon>
                : <PlusIcon onClick={() => toggleDetails(user?.id)} className="plus-icon">+</PlusIcon>
            )
          )}

        </td>
      </TableRowForUsers>
      {
        expandedUserId === user?.id && (
          <>
            {children.map((child, childIndex) => (
              <UserRow
                key={childIndex}
                user={child}
                getHierarchyOfUsers={getHierarchyOfUsers}
                nestingLevel={nestingLevel + 1}
                setOpenInfoPopup={setOpenInfoPopup}
                setOpenUserPopup={setOpenUserPopup}
                setMode={setMode}
                setSelectedUserId={setSelectedUserId}
                setAfterCreate={setAfterCreate}
                setLoader={setLoader}
              />
            ))}
          </>
        )
      }
    </>
  );
};

export default UserRow;
