import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from '../../../../component/Input';

export const HazardName = (props) => {
  if (props.formType !== 'ha') return null;

  const handleInput = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          hazard_name: value,
        },
      },
    }));
  };
  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.3</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Назва небезпечної території"
      titleEN="Hazard Name"
      children={(
        <InputComponent
          value={props.formData.info.general.hazard_name}
          name="hazard_name"
          id="hazard_name"
          handleInput={handleInput}
          blueLink
          limitChar
        />
      )}
    />
  );
};
