import React from 'react';
import { Table, TableHeader, TableRow } from 'component/Table';
import { useUser } from 'routes/UserContext';
import { DownloadDroneData } from './DownloadDroneData';
import { ViewDroneData } from './ViewDroneData';

export const DroneTable = (props) => {
  const { user } = useUser();
  return (
    <div className="drone-table">
      <Table>
        <TableHeader>
          <th className="text-with-icon">Date</th>
          <th className="text-with-icon">File name</th>
          <th>Report</th>
          <th>Action</th>
        </TableHeader>
        {props.droneData.length === 0
          && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
        {props.droneData.map((data) => (
          <TableRow key={data.id}>
            <td>{data.date}</td>
            <td>{data.name || '-'}</td>
            <td>{data.ha_report || '-'}</td>
            <td>
              <div className="icons">
                {data.file_name && (
                  <>
                    <DownloadDroneData
                      id={data.id}
                      fileName={data.file_name}
                      setLoader={props.setLoader}
                    />
                    {user?.permissions?.view_drones
                      && (
                        <ViewDroneData
                          id={data.id}
                          centroid={data.centroid?.map((coord) => Number(coord)).reverse()}
                          fileName={data.file_name || '-'}
                          date={data.date || '-'}
                          reportName={data.ha_report || '-'}
                        />
                      )}
                  </>
                )}
              </div>
            </td>
          </TableRow>
        ))}
        {props.next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                props.setLoader(true);
                props.getDroneInfo();
              }}
            >
              Load more...
            </button>
          </div>
        )}
      </Table>
    </div>
  );
};
