import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';

export const Name = (props) => (
  <AccordionBodyItem
    accordionSubId={`9.${props.id}`}
    titleUA="ПІБ"
    titleEN="Name"
    className={props.errors?.informators
      && props.errors?.informators[props.index]?.name ? 'error' : ''}
    children={(
      <InputComponent
        value={props.formData.info.informators[props.index]?.name}
        handleInput={(value) => props.handleInformator('name', value)}
        blueLink
        limitChar
      />
    )}
  />
);
