import React from 'react';
import Cleave from 'cleave.js/react';
import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';

export const HazardAreaInfo = (props) => {
  const polygonInfo = props.formData.info.polygon_info;

  const handleCoordinateSystem = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        polygon_info: {
          ...prevFormData.info.polygon_info,
          coordinate_system: value,
        },
      },
    }));
  };

  const handlePolygonCount = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        polygon_info: {
          ...prevFormData.info.polygon_info,
          polygon_count: Number(value),
        },
      },
    }));
  };

  const handleZoneNumber = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        polygon_info: {
          ...prevFormData.info.polygon_info,
          zone_number: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>5.1</span>
          <span className="star">*</span>
        </div>
      )}
      children={(
        <div className="lm-bm-header">
          <div className="flex-group no-wrap">
            <div className="flex-column-group">
              <span>Кількість полігонів:</span>
              <span>Number of polygons:</span>
            </div>
            <span className="line" />
            <Cleave
              placeholder="0"
              className="number"
              value={polygonInfo.polygon_count}
              autoComplete="off"
              options={{
                numericOnly: true,
                stripLeadingZeroes: true,
              }}
              onChange={(e) => handlePolygonCount(e.target.value.replace(/^0+/, ''))}
              disable="false"
            />
            <span className="line" />
            <div className="flex-column-group">
              <span>Система координат:</span>
              <span>Coordinate system:</span>
            </div>
            <span className="line" />
            <RadioButton
              id="coordinate_system"
              name="coordinate_system"
              value="wgs_84"
              label="WGS 84"
              checked={polygonInfo.coordinate_system.includes('wgs_84')}
              handleInput={handleCoordinateSystem}
              disable="false"
            />

          </div>
          <div className="flex-group no-wrap">
            <RadioButton
              id="zone_number"
              name="zone_number"
              value="utm"
              label="UTM"
              checked={polygonInfo.coordinate_system.includes('utm')}
              handleInput={handleCoordinateSystem}
              disable="false"
            />
            <div className="flex-column-group">
              <span>Номер зони:</span>
              <span>Zone number:</span>
            </div>
            <input
              type="text"
              className="number"
              onChange={(e) => handleZoneNumber(e.target.value)}
              value={polygonInfo.zone_number}
            />
          </div>
        </div>
      )}
    />
  );
};
