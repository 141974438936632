import React from 'react';
import { InputComponent } from 'component/Input';

export const UserTeamId = (props) => {
  const handleTeamId = (value) => {
    const isOnlyLatinLetters = /^[A-Za-z0-9-]*$/.test(value);
    if (isOnlyLatinLetters || value === '') {
      props.setUserInfo((prev) => ({
        ...prev,
        team_id: value.toUpperCase(),
      }));
    }
  };
  return (
    <InputComponent
      upperRightText="Team ID"
      type="text"
      id="user-team-id"
      name="user-team-id"
      placeholder="654D-54"
      value={props.teamId}
      handleInput={handleTeamId}
      autoComplete="off"
      disabled={false}
    />
  );
};

export const TeamIdView = (props) => (
  <div className="double-text">
    <span>Team ID</span>
    <span>{props.teamId || '-'}</span>
  </div>
);
