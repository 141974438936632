export const NTSFieldsToDeepLTranslate = (formData) => ({
  reporter_occupation: `${formData.info.general.reporter_occupation}`,
  location_description: `${formData.info.location.description}`,
  nto_result_comment: `${formData.info.nto_result?.comment}`,
});

export const HAFieldsToDeepLTranslate = (formData) => {
  const mapDescriptions = (evidences, prefix) => evidences?.items?.map((evidence, index) => ({
    [`${prefix}_${index}`]: evidence.description,
  })) || [];

  const mapInformators = (informators, prefix) => informators?.map((informator, index) => ({
    [`${prefix}_position_${index}`]: informator.position,
    [`${prefix}_address_${index}`]: informator.address,
  })) || [];

  const mapInformatorsDescriptions = (informators) => informators?.map((informator, index) => ({
    [`indirect_informator_description_${index}`]: informator,
  })) || [];

  const directEvidencesProperty = mapDescriptions(formData.info.evidences?.direct_evidences, 'direct_evidence_description');
  const indirectEvidencesProperty = mapDescriptions(formData.info.evidences?.indirect_evidences, 'indirect_evidence_description');
  const indirectInformatorsDescriptions = mapInformatorsDescriptions(formData.info.evidences
    ?.indirect_evidences?.informators);
  const informatorInfo = mapInformators(formData.info.informators, 'informator');

  return {
    reporter_occupation: formData.info.general.reporter_occupation,
    nearest_settlement_name: formData.info.location.nearest_settlement_name?.settlement_name,
    hazard_type_description: formData.info.hazard_type?.description,
    ...Object.assign({}, ...directEvidencesProperty, ...indirectEvidencesProperty),
    direct_evidences_main_description: formData.info.evidences?.direct_evidences?.description,
    indirect_evidences_main_description: formData.info.evidences?.indirect_evidences?.description,
    ...Object.assign({}, ...indirectInformatorsDescriptions),
    lm_description: formData.info.lm?.description,
    bm_description: formData.info.bm?.description,
    vegetation_other: formData.info.geographic_and_terrain_description?.vegetation_other,
    relief_other: formData.info.geographic_and_terrain_description?.relief_other,
    current_field_marking_other: formData.info.geographic_and_terrain_description
      ?.current_field_marking_other,
    reasons_and_comments_on_71_72: formData.info.next_landrelease_activity
      ?.reasons_and_comments_on_71_72,
    reasons_and_comments_on_71_72_extended: formData.info.next_landrelease_activity
      ?.reasons_and_comments_on_71_72_extended,
    recommended_clearance_depth: formData.info.next_landrelease_activity
      ?.recommended_clearance_depth,
    recommended_clearance_depth_description: formData.info.next_landrelease_activity
      ?.recommended_clearance_depth_description,
    additional_comments_on_hazard_area: formData.info.next_landrelease_activity
      ?.additional_comments_on_hazard_area,
    additional_comments_on_hazard_area_extended: formData.info.next_landrelease_activity
      ?.additional_comments_on_hazard_area_extended,
    ...Object.assign({}, ...informatorInfo),
  };
};
