import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { Table, TableHeader, TableRow } from 'component/Table';
import { InfoItem } from 'component/InfoItem';

export const DirectBeneficiariesView = (props) => {
  const { formData } = props;
  const beneficiaries = formData.info.landuse_and_beneficiaries;

  return (
    <>
      <div className="flex-column-group">
        <AccordionBodyItem
          accordionSubId=""
          titleUA="Кількість прямих бенефіціарів"
          titleEN="Number of direct beneficiaries"
        />
        <Table>
          <TableHeader>
            <th>
              <div>8.7 Чоловіків (18 та старше)</div>
              <div>Men (18+)</div>
            </th>
            <th>
              <div>8.8 Жінок (18 та старше)</div>
              <div>Women (18+)</div>
            </th>
            <th>
              <div>8.9 Хлопчиків (молодше 18)</div>
              <div>Boys</div>
            </th>
            <th>
              <div>8.10 Дівчаток (молодше 18)</div>
              <div>Girls</div>
            </th>
          </TableHeader>
          <tbody>
            <TableRow>
              <td>{beneficiaries.number_of_direct_beneficiaries.men}</td>
              <td>{beneficiaries.number_of_direct_beneficiaries.women}</td>
              <td>{beneficiaries.number_of_direct_beneficiaries.boys}</td>
              <td>{beneficiaries.number_of_direct_beneficiaries.girls}</td>
            </TableRow>
          </tbody>
        </Table>
      </div>
      <div
        className="flex-right"
        style={{ marginTop: '10px', paddingBottom: '10px' }}
      >
        <InfoItem
          titleEN="Total number of direct beneficiaries"
          titleUA="8.11 Загальна кількість прямих бенефіціарів"
          data={Object.values(beneficiaries.number_of_direct_beneficiaries)
            .reduce((acc, item) => Number(acc) + Number(item))}
        />
      </div>
    </>
  );
};
