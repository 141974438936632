import React from 'react';
import { AccordionBodyItemView2 } from 'component/Accordion';

export const AdditionalCommentsView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const landReleaseActivity = formData.info.next_landrelease_activity;

  return (
    <AccordionBodyItemView2
      accordionSubId="7.5"
      titleUA="Додаткові коментарі по небезпечній ділянці, за потреби:"
      titleEN="Additional comments on hazardous area if any"
      children={(
        <div className="flex-column-group">
          <div className="flex-group flex-wrap">
            {translateState && translatedFormData.additional_comments_on_hazard_area}
            {!translateState && landReleaseActivity.additional_comments_on_hazard_area}
          </div>
          <div className="flex-group flex-wrap">
            {translateState && translatedFormData.additional_comments_on_hazard_area_extended}
            {!translateState && landReleaseActivity.additional_comments_on_hazard_area_extended}
          </div>
        </div>
      )}
    />
  );
};
