import axios from 'axios';

export const getSettings = async (id) => {
  const url = `/core/settings/${id || 0}`;
  const response = await axios.get(url);
  return response;
};

export const updateSettings = async (data) => {
  const url = `/core/settings/${data.id}/`;
  const response = await axios.put(url, data);
  return response;
};

export const createSettings = async (data) => {
  const url = '/core/settings/';
  const response = await axios.post(url, data);
  return response;
};
