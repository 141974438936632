import { React, useState } from 'react';
import Modal from 'react-modal';
import ImageUploading from 'react-images-uploading';
import ImageIcon from 'assets/images/icons/Photo.svg';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'assets/styles/images.scss';
import ImageGallery from 'react-image-gallery';

import 'assets/styles/modal.scss';
import EXIF from 'exif-js';
import { toast } from 'react-toastify';
import { ImageDeleteDialog } from 'component/Dialog';
import { ImageDetailInfo } from './ImageDetailInfo';

export const ImageGalleryBlock = (props) => {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getInfo = (file) => new Promise((resolve) => {
    // eslint-disable-next-line func-names
    EXIF.getData(file, function () {
      const GPSLatitude = EXIF.getTag(this, 'GPSLatitude');
      const GPSLongitude = EXIF.getTag(this, 'GPSLongitude');
      const date = EXIF.getTag(this, 'DateTime');

      // Check if any of the values are undefined or not a number
      if (
        GPSLatitude
        && GPSLatitude.length === 3
        && GPSLatitude.every((coord) => !Number.isNaN(coord))
        && GPSLongitude
        && GPSLongitude.length === 3
        && GPSLongitude.every((coord) => !Number.isNaN(coord))
        && date
      ) {
        const lat = `${GPSLatitude[0]}°${GPSLatitude[1]}'${GPSLatitude[2]}"`;
        const long = `${GPSLongitude[0]}°${GPSLongitude[1]}'${GPSLongitude[2]}"`;

        resolve({
          lat,
          long,
          date,
        });
      } else {
        // Handle the case where GPS information is missing or invalid
        resolve({
          lat: '',
          long: '',
          date,
        });
      }
    });
  });

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentImageIndex(null);
    setIsModalOpen(false);
  };

  const onChangePhoto = async (imageList, addUpdateIndex) => {
    let openGallery = true;
    const photoPromises = imageList.map(async (image, index) => {
      const { file } = image;
      const { lat, long, date } = await getInfo(file);
      const dependency = props.dependency || 'general';
      const item = props.item || null;
      const order = props.photos.filter((photo) => photo.dependency === dependency
        && photo.item === item).length + index + 1;
      return {
        photo_name: file.name,
        data_url: image.data_url,
        file,
        dependency,
        item,
        description: '',
        lat,
        long,
        date,
        order,
        send_to_imsma: false,
      };
    });

    const newPhotos = await Promise.all(photoPromises);

    if (addUpdateIndex) {
      addUpdateIndex.forEach((index) => {
        const photoToAdd = newPhotos[index];
        if (!props.photos?.some((photo) => photo.photo_name === photoToAdd.photo_name
          || photo.name === photoToAdd.photo_name)) {
          props.setPhotos((prevPhotos) => ([...prevPhotos, photoToAdd]));
          openGallery = true;
        } else {
          toast.warning('You cannot upload photos with same name');
          openGallery = false;
        }
      });
    }

    setImages(imageList);
    if (props.dependency && openGallery) {
      openModal(props.photos.length);
    }
  };

  const deleteImageByIndex = (index) => {
    props.setPhotos((prevPhotos) => {
      if (index >= 0 && index < prevPhotos.length) {
        const updatedPhotos = prevPhotos.filter((_, i) => i !== index);
        return updatedPhotos;
      }

      return prevPhotos;
    });
  };

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChangePhoto}
        dataURLKey="data_url"
        acceptType={['jpg']}
      >
        {({
          onImageUpload,
          isDragging,
          dragProps,
        }) => (
          <div>
            <div className={`upload-image-wrapper ${props.imageError ? 'error' : ''}`}>
              {!props.dependency && props.photos && props.photos.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image.data_url} alt="" />
                  <div className="image-item__btn-wrapper">
                    <ImageDeleteDialog
                      deleteImage={() => deleteImageByIndex(index)}
                      svgOnly
                    />
                    <span
                      className="zoom"
                      onClick={() => openModal(index)}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.76 13.27L20.49 19L19 20.49L13.27 14.76C12.2 15.53 10.91 16 9.5 16C5.91 16 3 13.09 3 9.5C3 5.91 5.91 3 9.5 3C13.09 3 16 5.91 16 9.5C16 10.91 15.53 12.2 14.76 13.27ZM9.5 5C7.01 5 5 7.01 5 9.5C5 11.99 7.01 14 9.5 14C11.99 14 14 11.99 14 9.5C14 7.01 11.99 5 9.5 5Z" fill="#868686" />
                      </svg>
                    </span>
                    <span
                      className="check"
                    >
                      <svg className={image.dependency !== 'general' ? 'active' : ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.99387 20L2 12.8898L3.74847 11.1123L8.99387 16.4449L20.2515 5L22 6.77755L8.99387 20Z" fill="#868686" />
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
              {!props.dependency && (
                <div
                  className="new-image-item"
                  style={isDragging ? { color: 'red' } : null}
                >
                  <img src={ImageIcon} alt="icon" />
                  <button
                    className="outline"
                    onClick={(e) => {
                      e.preventDefault();
                      onImageUpload();
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dragProps}
                  >
                    + Add image
                  </button>
                </div>
              )}
              {props.dependency
                && (
                  <div onClick={(e) => {
                    e.preventDefault();
                    if (props.dependency === 'lm' && props.formData.info.lm.photo.file_name) {
                      openModal(props.photos.findIndex((item) => item.dependency === 'lm'));
                      return;
                    }

                    if (props.dependency === 'bm' && props.formData.info.bm.photo.file_name) {
                      openModal(props.photos.findIndex((item) => item.dependency === 'bm'));
                      return;
                    }

                    if (props.dependency === 'direct_evidence'
                      && props.formData.info.evidences.direct_evidences
                        .items[props.item - 1].photo.file_name) {
                      openModal(props.photos.findIndex((item) => (item.dependency === 'direct_evidence'
                        && item.item === props.item)));
                      return;
                    }

                    if (props.dependency === 'indirect_evidence'
                      && props.formData.info.evidences.indirect_evidences
                        .items[props.item - 1].photo.file_name) {
                      openModal(props.photos.findIndex((item) => (item.dependency === 'indirect_evidence'
                        && item.item === props.item)));
                      return;
                    }
                    onImageUpload();
                  }}
                  >
                    <div className="text-hover">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 4.5V16.5H8V4.5H20ZM20 2.5H8C6.9 2.5 6 3.4 6 4.5V16.5C6 17.6 6.9 18.5 8 18.5H20C21.1 18.5 22 17.6 22 16.5V4.5C22 3.4 21.1 2.5 20 2.5ZM11.5 12.17L13.19 14.43L15.67 11.33L19 15.5H9L11.5 12.17ZM2 6.5V20.5C2 21.6 2.9 22.5 4 22.5H18V20.5H4V6.5H2Z" fill="#868686" />
                      </svg>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </ImageUploading>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="react-inner-modal-class"
        overlayClassName="react-modal-class"
      >
        {currentImageIndex !== null && (
          <div className="image-gallery-wrapper">
            <ImageGallery
              items={props.photos.map((image) => ({
                original: image.data_url,
                thumbnail: image.data_url,
                thumbnailHeight: '60px',
                thumbnailWidth: '60px',
              }))}
              startIndex={currentImageIndex}
              onSlide={(index) => setCurrentImageIndex(index)}
            />
            <ImageDetailInfo
              formData={props.formData}
              photos={props.photos}
              setPhotos={props.setPhotos}
              currentImageIndex={currentImageIndex}
              closeModal={closeModal}
              deletePhoto={() => deleteImageByIndex(currentImageIndex)}
              savePhoto={() => { }}
              setLoaderPhotos={props.setLoaderPhotos}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};
