/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { AccordionHeaderTitle } from 'component/Accordion';
import './styles.scss';
import { ReactComponent as MapIcon } from 'assets/images/icons/map3.svg';
import { ReactComponent as FilesIcon } from 'assets/images/icons/Files.svg';
import { ReactComponent as DocxIcon } from 'assets/images/icons/docx.svg';
import { ReactComponent as TranslateSvg } from 'assets/images/icons/translate_24px.svg';

import PDFIcon from 'assets/images/icons/PDF_file_icon.png';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion';

import { exportDocx, exportPDF } from 'utils/Helper';
import { TranslateWrapperComponent } from 'component/Translation';
import { FileInformation } from '../blocks/FileInformation';
import { MapInfo } from '../blocks/MapInfo';
import { GeneralInfoView } from '../ViewBlocks/GeneralInfo/GeneralInfo';
import { LocationView } from '../ViewBlocks/Location/Location';
import { ContaminationView } from '../ViewBlocks/Contamination/Contamination';
import { EvidencesView } from '../ViewBlocks/Evidences/Evidences';
import { FileInfoView } from '../ViewBlocks/FileInfo/FileInfo';
import { GeographicView } from '../ViewBlocks/Geographic/Geographic';
import { LandRelease } from '../ViewBlocks/LandRelease/LandRelease';
import { BeneficiariesView } from '../ViewBlocks/Beneficiaries/Beneficiaries';
import { InformatorsView } from '../ViewBlocks/Informators/Informators';
import { CommentsFromImsmaView, CommentsView } from '../ViewBlocks/Comments';
import { PhotosView } from '../ViewBlocks/Photos/Photos';
import { AnnexMapView } from '../ViewBlocks/AnnexMap/AnnexMap';
import { getHAComments } from '../../../api/HaApi';

export const CheckTab = (props) => {
  const { formData } = props;
  const [comments, setComments] = useState([]);
  const [translatedFormData, setTranslatedFormData] = useState([]);
  const [translateState, setTranslateState] = useState(false);

  const getAllComments = () => {
    getHAComments(formData.id)
      .then((data) => {
        const allComments = data.data.iterations;
        setComments(allComments);
      })
      .catch((err) => {
        console.log(err);
        setComments([]);
      });
  };

  useEffect(() => {
    getAllComments();
  }, []);

  const ExportFilesBlock = () => (
    <div className="flex-group flex-right">
      <div
        className="text-hover"
        onClick={() => {
          props.setLoader(true);
          exportDocx(formData.id)
            .then(() => {
              props.setLoader(false);
            })
            .catch(() => {
              props.setLoader(false);
            });
        }}
      >
        <DocxIcon />
        <div>Docx</div>
      </div>
      <div
        className="text-hover"
        onClick={() => {
          props.setLoader(true);
          exportPDF(formData.id)
            .then(() => {
              props.setLoader(false);
            })
            .catch(() => {
              props.setLoader(false);
            });
        }}
      >
        <img src={PDFIcon} width="18px" alt="" />
        <div>PDF</div>
      </div>
      <div
        className="text-hover"
        onClick={() => setTranslateState(!translateState)}
      >
        <TranslateSvg />
        <div>Translate</div>
      </div>
    </div>
  );

  return (
    <TranslateWrapperComponent
      formData={formData}
      setTranslatedFormData={setTranslatedFormData}
      translationState={translateState}
    >
      <form className="form-wrapper">
        <ExportFilesBlock />
        <div className="info_block_header">
          <div className="info_block_header_left">Атрибути</div>
          <span className="info_block_header_line" />
          <div className="info_block_header_right">Attributes</div>
        </div>

        <CommentsFromImsmaView comments={comments} />

        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={[
            'general',
            'location',
            'contamination',
            'evidence',
            'fileInfo',
            'geographic',
            'landrelease',
            'beneficiaries',
            'informators',
          ]}
          className="custom-accordion"
        >
          <GeneralInfoView
            formData={formData}
            comments={comments}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <LocationView
            formData={formData}
            comments={comments}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <ContaminationView
            formData={formData}
            comments={comments}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <EvidencesView
            formData={formData}
            comments={comments}
            map={props.map}
            setMap={props.setMap}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <FileInfoView
            formData={formData}
            comments={comments}
            map={props.map}
            setMap={props.setMap}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <GeographicView
            formData={formData}
            comments={comments}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <LandRelease
            formData={formData}
            comments={comments}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

          <BeneficiariesView
            formData={formData}
            comments={comments}
          />

          <InformatorsView
            formData={formData}
            comments={comments}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />

        </Accordion>
        <div className="info_block_header">
          <div className="info_block_header_left">ГІС</div>
          <span className="info_block_header_line" />
          <div className="info_block_header_right">Gis</div>
        </div>
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={['fileInfo', 'evidenceInfo', 'map']}
          className="custom-accordion"
        >
          <AccordionItem uuid="fileInfo">
            <FileInformation
              formData={formData}
              accordionId={<FilesIcon style={{ color: '#00b1b7' }} />}
              tab="gis"
            />
          </AccordionItem>

          <AccordionItem uuid="map">
            <MapInfo
              formData={formData}
              accordionId={<MapIcon />}
              map={props.map}
              setMap={props.setMap}
            />
            <CommentsView
              comments={comments}
              block="map"
            />

          </AccordionItem>
        </Accordion>
        <div className="info_block_header">
          <div className="info_block_header_left">Фото</div>
          <span className="info_block_header_line" />
          <div className="info_block_header_right">Photos</div>
        </div>
        <Accordion
          className="custom-accordion"
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={['photos']}
        >
          <AccordionItem uuid="photos">

            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionHeaderTitle titleUA="Фото" titleEN="Photo" />
              </AccordionItemButton>
            </AccordionItemHeading>

            <PhotosView photos={props.photos} />
          </AccordionItem>
        </Accordion>
        <Accordion
          className="custom-accordion"
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={['annexMaps']}
        >
          <AccordionItem uuid="annexMaps">

            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionHeaderTitle titleUA="Annex Maps" />
              </AccordionItemButton>
            </AccordionItemHeading>

            <AnnexMapView annexImages={props.annexImages} />
          </AccordionItem>
        </Accordion>
      </form>
    </TranslateWrapperComponent>
  );
};
