import React from 'react';
import { ButtonComponent } from 'component/Button';
import { useUser } from 'routes/UserContext';
import { TeamFilter } from './Filter/Team';
import { DateFilter } from './Filter/DateFilter';
import { InternalIdFilter } from './Filter/InternalId';
import { ImsmaIdFilter } from './Filter/ImsmaId';
import { ValidationStatusFilter } from './Filter/ValidationStatus';
import { AreaTypeFilter } from './Filter/AreaType';
import { HazardTypeFilter } from './Filter/HazardTypeFilter';
import { RegionFilter } from './Filter/Region';
import { DistrictFilter } from './Filter/District';
import { LocalCommunityFilter } from './Filter/LocalCommunity';
import { SettlementFilter } from './Filter/Settlement';

export const FilterMap = (props) => {
  const { user } = useUser();
  return (
    <>
      <TeamFilter
        teamFilter={props.teamFilter}
        setTeamFilter={props.setTeamFilter}
      />
      <DateFilter
        dateFrom={props.dateFrom}
        dateTo={props.dateTo}
        setDateFromFilter={props.setDateFromFilter}
        setDateToFilter={props.setDateToFilter}
      />
      <InternalIdFilter
        internalIdFilter={props.internalIdFilter}
        setInternalIdFilter={props.setInternalIdFilter}
      />
      <ImsmaIdFilter
        imsmaIdFilter={props.imsmaIdFilter}
        setImsmaIdFilter={props.setImsmaIdFilter}
      />
      <ValidationStatusFilter
        validStatusFilter={props.validStatusFilter}
        setValidStatusFilter={props.setValidStatusFilter}
      />
      <AreaTypeFilter
        areaTypeFilter={props.areaTypeFilter}
        setAreaTypeFilter={props.setAreaTypeFilter}
      />
      <HazardTypeFilter
        hazardTypeFilter={props.hazardTypeFilter}
        setHazardTypeFilter={props.setHazardTypeFilter}
      />
      {user.role !== 'teamleadrole' && (
      <>
        <RegionFilter
          regionFilter={props.regionFilter}
          setRegionFilter={props.setRegionFilter}
          setDistrictFilter={props.setDistrictFilter}
          setLocalCommunityFilter={props.setLocalCommunityFilter}
          setSettlementFilter={props.setSettlementFilter}
        />
        <DistrictFilter
          regionFilter={props.regionFilter}
          districtFilter={props.districtFilter}
          setDistrictFilter={props.setDistrictFilter}
          setLocalCommunityFilter={props.setLocalCommunityFilter}
          setSettlementFilter={props.setSettlementFilter}
        />
        <LocalCommunityFilter
          regionFilter={props.regionFilter}
          districtFilter={props.districtFilter}
          localCommunityFilter={props.localCommunityFilter}
          setLocalCommunityFilter={props.setLocalCommunityFilter}
          setSettlementFilter={props.setSettlementFilter}
        />
        <SettlementFilter
          regionFilter={props.regionFilter}
          districtFilter={props.districtFilter}
          localCommunityFilter={props.localCommunityFilter}
          settlementFilter={props.settlementFilter}
          setSettlementFilter={props.setSettlementFilter}
        />
      </>
      )}

      <div className="buttons-group">
        <ButtonComponent
          className="warning outline"
          handleButton={() => {
            props.setLoader(true);
            props.setTeamFilter(null);
            props.setDateFromFilter(null);
            props.setDateToFilter(null);
            props.setInternalIdFilter(null);
            props.setImsmaIdFilter(null);
            props.setValidStatusFilter(null);
            props.setAreaTypeFilter(null);
            props.setHazardTypeFilter(null);
            props.setRegionFilter(null);
            props.setDistrictFilter(null);
            props.setLocalCommunityFilter(null);
            props.setSettlementFilter(null);
            props.setResetFilter(true);
          }}
          label="Reset the filter"
        />
        <ButtonComponent
          handleButton={() => {
            props.setLoader(true);
            props.updateGeojson();
            props.updateLegend();
          }}
          label="Ok"
        />
      </div>
    </>
  );
};
