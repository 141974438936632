import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { LocationInfo } from '../blocks/LocationInfo';
import { ContaminationInfo } from '../blocks/ContaminationInfo';
import { GeographicDescriptionInfo } from '../blocks/GeographicDescription';
import { LandreleaseActivityInfo } from '../blocks/LandreleaseActivity';
import { BeneficiariesInfo } from '../blocks/Beneficiaries';
import { Evidence } from '../blocks/Evidence';
import { GeneralInfo } from '../blocks/GeneralInfo';
import { FileInformation } from '../blocks/FileInformation';
import { InformatorsInfo } from '../blocks/InformatorsInfo';

export const AttributesTab = (props) => {
  const { formData } = props;
  const { setFormData } = props;
  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={[
        'general', 'location', 'contamination',
        'evidence', 'fileInfo', 'geographic',
        'landrelease', 'beneficiaries', 'informators']}
      className="custom-accordion"
    >
      <AccordionItem uuid="general">
        <GeneralInfo
          formData={formData}
          setFormData={setFormData}
          formType="ha"
          validationForm={props.validationForm}
          map1={props.map1}
          errors={props.errors}
        />
      </AccordionItem>
      <AccordionItem uuid="location">
        <LocationInfo
          formData={formData}
          setFormData={setFormData}
          fieldBlock="location"
          validationForm={props.validationForm}
          errors={props.errors}
        />
      </AccordionItem>
      <AccordionItem uuid="contamination">
        <ContaminationInfo
          formData={formData}
          setFormData={setFormData}
          validationForm={props.validationForm}
          errors={props.errors}
        />
      </AccordionItem>
      <AccordionItem uuid="evidence">
        <Evidence
          formData={formData}
          setFormData={setFormData}
          handleTabChange={props.handleTabChange}
          photos={props.photos}
          setPhotos={props.setPhotos}
          map1={props.map1}
          setMap1={props.setMap1}
          map2={props.map2}
          setMap2={props.setMap2}
          validationForm={props.validationForm}
          setLoaderPhotos={props.setLoaderPhotos}
        />
      </AccordionItem>
      <AccordionItem uuid="fileInfo">
        <FileInformation
          formData={formData}
          setFormData={setFormData}
          handleTabChange={props.handleTabChange}
          accordionId="5"
          photos={props.photos}
          setPhotos={props.setPhotos}
          map={props.map}
          setMap={props.setMap}
          map1={props.turnPointsMap}
          setMap1={props.setTurnPointsMap}
          validationForm={props.validationForm}
          errors={props.errors}
          setLoaderPhotos={props.setLoaderPhotos}
        />
      </AccordionItem>
      <AccordionItem uuid="geographic">
        <GeographicDescriptionInfo
          formData={formData}
          setFormData={setFormData}
          validationForm={props.validationForm}
          errors={props.errors}
        />
      </AccordionItem>
      <AccordionItem uuid="landrelease">
        <LandreleaseActivityInfo
          formData={formData}
          setFormData={setFormData}
          validationForm={props.validationForm}
          errors={props.errors}
        />
      </AccordionItem>
      <AccordionItem uuid="beneficiaries">
        <BeneficiariesInfo
          formData={formData}
          setFormData={setFormData}
          validationForm={props.validationForm}
          errors={props.errors}
        />
      </AccordionItem>
      <AccordionItem uuid="informators">
        <InformatorsInfo
          formData={formData}
          setFormData={setFormData}
          errors={props.errors}
        />
      </AccordionItem>
    </Accordion>
  );
};
