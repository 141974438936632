export const months = [
  'Січень / January',
  'Лютий / February',
  'Березень / March',
  'Квітень / April',
  'Травень / May',
  'Червень / June',
  'Липень / July',
  'Серпень / August',
  'Вересень / September',
  'Жовтень / October',
  'Листопад / November',
  'Грудень / December',
];
