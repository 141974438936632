/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import { Table, TableHeader, TableRow } from 'component/Table';
import { Map } from 'component/Map';
import { ButtonComponent } from 'component/Button';
import { featureCollection, point } from '@turf/helpers';
import proj4 from 'proj4';
import { LocationIcon } from 'component/LocationIcon';
import { SwapEvidenceIcon } from 'component/SwapEvidenceIcon';
import { toast } from 'react-toastify';
import { TextareaComponent } from 'component/Textarea';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { indirectEvidencesTypes } from '../../../../consts/Evidences';
import { ImageGalleryBlock } from '../ImageGallery';

proj4.defs(
  'EPSG:32637',
  '+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs +type=crs',
);
proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

export const CoordinatesIndirectEvidence = (props) => {
  const evidences = props.formData.info.evidences.indirect_evidences;

  const getGeojson = () => {
    const featureCollectionGeojson = featureCollection([]);

    evidences.items?.forEach((item) => {
      if (!item.long && !item.lat) return;
      const coords = proj4('EPSG:32637', 'EPSG:4326', [
        Number(item.long),
        Number(item.lat),
      ]);
      if (Number.isNaN(coords[0]) || Number.isNaN(coords[1])) return;
      const pointFeature = point(coords, {
        evidence_type: item.evidence_type,
        description: item.description,
        photo: item.photo,
      });

      featureCollectionGeojson.features.push(pointFeature);
    });

    return featureCollectionGeojson.features.length
      ? featureCollectionGeojson
      : featureCollection([]);
  };
  const [indirectEvidencesGeojson, setIndirectEvidencesGeojson] = useState(
    getGeojson(),
  );

  const handleOnBlur = (index) => {
    const long = Number(evidences.items[index].long);
    const lat = Number(evidences.items[index].lat);
    if (long && lat) {
      if (Number.isNaN(long) || Number.isNaN(lat)) return;
      setIndirectEvidencesGeojson((prevGeojson) => {
        const updatedFeatures = [...prevGeojson.features];
        let pointCoords;
        if (
          /^\d{2}\.\d+/.test(long.toString()) && /^\d{2}\.\d+/.test(lat.toString())
        ) {
          pointCoords = [long, lat];
        } else {
          pointCoords = proj4('EPSG:32637', 'EPSG:4326', [
            Number(long),
            Number(lat),
          ]);
        }
        const newPointFeature = point(pointCoords, {
          evidence_type: evidences.items[index].evidence_type,
          description: evidences.items[index].description,
          photo: evidences.items[index].photo,
        });

        updatedFeatures[index] = newPointFeature;

        return {
          ...prevGeojson,
          features: updatedFeatures,
        };
      });
    }
  };

  const handleEvidenceType = (index, value) => {
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidences = {
        ...prevFormData.info.evidences.indirect_evidences,
        items: prevFormData.info.evidences.indirect_evidences.items.map(
          (item, i) => (i === index ? { ...item, evidence_type: value } : item),
        ),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidences,
          },
        },
      };
    });
  };

  const handleEvidenceDescription = (index, value) => {
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidences = {
        ...prevFormData.info.evidences.indirect_evidences,
        items: prevFormData.info.evidences.indirect_evidences.items.map(
          (item, i) => (i === index ? { ...item, description: value } : item),
        ),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidences,
          },
        },
      };
    });
  };

  const handleEvidenceLong = (index, value) => {
    if (!props.formData.file_zone) {
      toast.warning('You need to upload gis files');
      return;
    }
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidences = {
        ...prevFormData.info.evidences.indirect_evidences,
        items: prevFormData.info.evidences.indirect_evidences.items.map(
          (item, i) => (i === index ? { ...item, long: Number(value) } : item),
        ),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidences,
          },
        },
      };
    });
  };

  const handleEvidenceLat = (index, value) => {
    if (!props.formData.file_zone) {
      toast.warning('You need to upload gis files');
      return;
    }
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidences = {
        ...prevFormData.info.evidences.indirect_evidences,
        items: prevFormData.info.evidences.indirect_evidences.items.map(
          (item, i) => (i === index ? { ...item, lat: Number(value) } : item),
        ),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidences,
          },
        },
      };
    });
  };

  const handleDeleteIndirectItem = (id) => {
    setIndirectEvidencesGeojson((prevGeojson) => ({
      ...prevGeojson,
      features: [...prevGeojson.features].filter((item, index) => id !== index),
    }));
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidences = {
        ...prevFormData.info.evidences.indirect_evidences,
        items: prevFormData.info.evidences.indirect_evidences.items.filter(
          (item, index) => id !== index,
        ),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidences,
          },
        },
      };
    });
    props.setPhotos((prevImages) => {
      const fileName = props.formData.info.evidences.indirect_evidences.items[id].photo
        .file_name;
      const newPhotos = prevImages.filter(
        (photo) => photo.photo_name !== fileName,
      );
      return newPhotos;
    });
  };

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  const TypeOfEvidences = (props) => {
    const options = evidences?.evidence_types?.map((item) => ({
      value: item,
      label: indirectEvidencesTypes[item],
    }));
    return (
      <SelectComponent
        options={options}
        value={{
          label: indirectEvidencesTypes[props.evidence.evidence_type],
          value: props.evidence.evidence_type,
        }}
        handleSelect={(value) => handleEvidenceType(props.itemKey, value)}
      />
    );
  };

  const handleAddIndirectEvidence = (e) => {
    e.preventDefault();
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidencesItems = {
        ...prevFormData.info.evidences.indirect_evidences,
        items: [
          ...prevFormData.info.evidences.indirect_evidences.items,
          {
            evidence_type: '',
            long: 0,
            lat: 0,
            description: '',
            photo: [],
          },
        ],
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidencesItems,
          },
        },
      };
    });
  };

  useEffect(() => {
    setIndirectEvidencesGeojson(getGeojson());
  }, [props.formData.id, props.formData.info.evidences]);

  return (
    <div className="flex-column-group">
      <AccordionBodyItem
        bgStyle={props.bgStyle}
        accordionSubId="4.5"
        titleUA="Координати непрямих доказів (за наявності)"
        titleEN="Indirect evidence coordinates (if any)"
      />
      <div className="accordion-body-wrapper bgGrey">
        <Table className="table-lines-between">
          <TableHeader>
            <th className="no-border-radius double-text">
              <div>Тип доказу</div>
              <div>Type of evidence</div>
            </th>
            <th aria-label="line">
              <div className="line" />
            </th>
            <th>Long/UTM X</th>
            <th aria-label="line">
              <div className="line" />
            </th>
            <th>Lat/UTM Y</th>
            <th aria-label="line">
              <div className="line" />
            </th>
            <th className="no-border-radius">Опис\ Description</th>
          </TableHeader>
          <tbody>
            {!evidences?.items?.length && (
              <TableRow>
                <td className="empty">Empty</td>
              </TableRow>
            )}
            {evidences
              && evidences?.items?.map((evidence, index) => (
                <TableRow key={index}>
                  <td className="flex-group">
                    <span>
                      4.5.
                      {index + 1}
                      <span className="star">*</span>
                    </span>
                    <TypeOfEvidences
                      evidence={evidence}
                      tab={props.tab}
                      itemKey={index}
                    />
                  </td>
                  <td aria-label="line">
                    <div className="line" />
                  </td>
                  <td aria-label="input">
                    <input
                      placeholder="440571"
                      id={`${index}-long`}
                      className="text-center"
                      value={evidence.long.toFixed(0) || null}
                      options={{ numericOnly: true, stripLeadingZeros: true }}
                      autoComplete="off"
                      onChange={(e) => {
                        handleEvidenceLong(index, e.target.value);
                      }}
                      onBlur={() => handleOnBlur(index)}
                    />
                  </td>
                  <td aria-label="line">
                    <div className="line" />
                  </td>
                  <td aria-label="line">
                    <input
                      id={`${index}-lat`}
                      className="text-center"
                      placeholder="5194806"
                      value={evidence.lat.toFixed(0) || null}
                      options={{ numericOnly: true, stripLeadingZeros: true }}
                      autoComplete="off"
                      onChange={(e) => {
                        handleEvidenceLat(index, e.target.value);
                      }}
                      onBlur={() => handleOnBlur(index)}
                    />
                  </td>
                  <td aria-label="line">
                    <div className="line" />
                  </td>
                  <td
                    className="flex-group"
                    aria-label="textarea"
                  >
                    <TextareaComponent
                      id={`${index}-description`}
                      value={evidence.description}
                      handleTextarea={(value) => handleEvidenceDescription(index, value)}
                      blueLink
                      limitChar
                    />

                  </td>
                  <td>
                    <div className="evidence-icons">
                      <LocationIcon
                        coords={[
                          Number(`${evidence.long}`),
                          Number(`${evidence.lat}`),
                        ]}
                        map={props.map}
                        id={index}
                        source="indirect-evidence"
                        zone={props.formData.file_zone}
                      />
                      <ImageGalleryBlock
                        formData={props.formData}
                        photos={props.photos}
                        setPhotos={props.setPhotos}
                        loader={props.loader}
                        setLoader={props.setLoader}
                        dependency="indirect_evidence"
                        item={index + 1}
                        setLoaderPhotos={props.setLoaderPhotos}
                      />
                      {evidence.lat !== 0 && evidence.long !== 0 && (
                        <SwapEvidenceIcon
                          formData={props.formData}
                          setFormData={props.setFormData}
                          photos={props.photos}
                          setPhotos={props.setPhotos}
                          evidenceIndex={index}
                          evidenceTypeFrom="indirect_evidences"
                          evidenceTypeTo="direct_evidences"
                          zone={props.formData.file_zone}
                        />
                      )}

                      <div
                        className="text-hover"
                        onClick={() => handleDeleteIndirectItem(index)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </TableRow>
              ))}
            <TableRow className="separator">
              <td style={{ justifyContent: 'center' }}>
                <ButtonComponent
                  className="outline"
                  handleButton={handleAddIndirectEvidence}
                  label="+ Add more"
                />
              </td>
            </TableRow>
          </tbody>
        </Table>
      </div>

      {indirectEvidencesGeojson.features.length > 0 && (
        <Map
          map={props.map}
          setMap={props.setMap}
          turnPointsPolygon={props.formData.info.turn_points.geojson}
          id="indirect-map"
          indirectEvidenceGeojson={indirectEvidencesGeojson}
          hazardAreaType={props.formData.info.general.hazard_area_type}
          evidences={props.formData.info.evidences}
          googleWms
        />
      )}

    </div>
  );
};
