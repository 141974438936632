import React from 'react';
import { AccordionBodyItem, AccordionHeaderTitle } from 'component/Accordion';

import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { InputComponent } from 'component/Input';
import { InternalHazardArea } from './GeneralInfo/InternalHazardArea';
import { IMSMAID } from './GeneralInfo/IMSMAID';
import { IMSMASwither } from './GeneralInfo/IMSMASwither';
import { HazardName } from './GeneralInfo/HazardName';
import { ReportDate } from './GeneralInfo/ReportDate';
import { ReportingOrganization } from './GeneralInfo/ReportingOrganization';
import { ReportProvider } from './GeneralInfo/ReportProvider';
import { CommandId } from './GeneralInfo/CommandId';
import { ReporterPosition } from './GeneralInfo/Position';
import { DateOfHA } from './GeneralInfo/DateOfHA';
import { AreaType } from './GeneralInfo/AreaType';
import { HazardType } from './GeneralInfo/HazardType';
import { Donor } from './GeneralInfo/Donor';

export const GeneralInfo = (props) => {
  const handleInput = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          hazard_name: value,
        },
      },
    }));
  };

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="1"
            validationForm={props.validationForm}
            block="general"
            titleUA={(
              <div className="flex-group">
                <span>Загальна інформація</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="General Information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <InternalHazardArea
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />

        <IMSMAID
          formType={props.formType}
          formData={props.formData}
        />

        <IMSMASwither
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />
        {(props.formType !== 'ha')
          ? (
            <AccordionBodyItem
              titleUA="Назва"
              titleEN="Name"
              children={(
                <InputComponent
                  value={props.formData.info.general.hazard_name}
                  name="hazard_name"
                  id="hazard_name"
                  handleInput={handleInput}
                  blueLink
                  limitChar
                />
              )}
            />
          )
          : ''}
        <HazardName
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />

        <ReportDate
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
          errors={props.errors}
        />

        <ReportingOrganization
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />

        <ReportProvider
          formType={props.formType}
          formData={props.formData}
        />

        <CommandId
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />

        <ReporterPosition
          formType={props.formType}
          formData={props.formData}
        />

        <DateOfHA
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
          errors={props.errors}
        />

        <AreaType
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
          map={props.map1}
        />

        <HazardType
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />

        <Donor
          formType={props.formType}
          formData={props.formData}
          setFormData={props.setFormData}
        />

      </AccordionItemPanel>
    </>
  );
};
