import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';

export const Description = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.9"
      titleUA="Опис типу забруднення (за потреби)"
      titleEN="Description of the type of contamination"
      children={(
        <TextareaComponent
          id="description"
          name="description"
          value={hazardType.description}
          handleTextarea={props.handleContaminationDescription}
          disable="false"
        />
      )}
    />
  );
};
