import { InputComponent } from 'component/Input';
import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const NumberOfSession = (props) => {
  const { formData, setFormData } = props;

  const handleNumberOfSession = (value) => {
    if (/^[0-9]*$/.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          session_information: {
            ...prevFormData.info.session_information,
            number_of_session: Number(value),
          },
        },
      }));
    }
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.10</span>
        </div>
      )}
      titleUA="Кількість сесій"
      titleEN="Number of sessions"
      className={props.errors.includes('number_of_session')
        ? 'error error-text' 
        : '' 
      }
      children={(
        <InputComponent
          id="number_of_session"
          placeholder=""
          className="number"
          name="number_of_session"
          value={formData?.info?.session_information?.number_of_session}
          handleInput={handleNumberOfSession}
        />
      )}
    />
  );
};
