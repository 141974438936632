import { React, useEffect, useState } from 'react';
import { getInternalIdAutocomplete } from 'api/FilterMapApi';
import { SelectComponent } from 'component/Select';

export const InternalIdFilter = (props) => {
  const [options, setOptions] = useState([]);

  const getTeamOptions = () => {
    getInternalIdAutocomplete()
      .then((res) => {
        const resOptions = res.data.map((item) => ({
          value: item,
          label: item,
        }));

        setOptions(resOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTeamOptions();
  }, []);

  return (
    <div className="flex-column-group">
      <span>Sort by internal Id</span>
      <SelectComponent
        className="full"
        value={props.internalIdFilter}
        options={options}
        handleSelect={props.setInternalIdFilter}
        isMulti
      />
    </div>
  );
};
