import { AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const NumberOfSessionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.10"
      titleUA="Кількість сесій"
      titleEN="Number of sessions"
      children={formData?.info?.session_information?.number_of_session}
    />
  );
};
