import { AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const DateOfSessionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.6"
      titleUA="Дата проведення"
      titleEN="The date of the event"
      children={formData.info.session_information.eore_date}
    />
  );
};
