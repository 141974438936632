import { Loader } from 'component/Loader';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowSvg } from 'assets/images/icons/arrow_back_24px.svg';
import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { EOREFormData } from 'consts/Forms';
import { useUser } from 'routes/UserContext';
import { ButtonComponent } from 'component/Button';
import {
  getEoreCurrentTemplateVersion,
  getHandouts,
  postEoreReport,
  updateEoreReportPhotos,
  updateReportAttachments,
} from 'api/EOREApi';
import { AttributesTab } from './Tabs/Attributes';
import { PhotosTab } from './Tabs/Photos';
import { TemplateVersion } from './Blocks/TemplateVersion';
import CheckEORETab from './Tabs/Check';
import { checkFormMissingFields } from './FormHandling';

export const PageTitle = (props) => {
  const navigate = useNavigate();
  return (
    <div className="page-title">
      <ArrowSvg
        className="hover-pointer"
        onClick={() => navigate(-1)}
      />
      <div>{props.title}</div>
      <TemplateVersion
        templateVersion={props.templateVersion}
        setLoader={props.setLoader}
      />
    </div>
  );
};

export const CreateEOREReportPage = () => {
  const [loader, setLoader] = useState(false);
  const [photosLoader, setPhotosLoader] = useState(false);
  const [formData, setFormData] = useState(EOREFormData);
  const [activeTab, setActiveTab] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [btnState, setBtnState] = useState(false);
  const [templateVersion, setTemplateVersion] = useState(null);
  const [handouts, setHandouts] = useState(null);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [zone, setZone] = useState(null);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();
  const { user } = useUser();

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    navigate('/reports/eore/');
  };

  useEffect(() => {
    if (!user) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          reporter_name: `${user.name} ${user.surname}`,
          reporter_position: user.reporter_position,
        },
      },
    }));
  }, [user]);

  const setGeneralPhotos = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general_photos: [...photos],
      },
    }));
  };

  useEffect(() => {
    setGeneralPhotos();
  }, [photos]);
  
  const handleDraftButton = () => {
    setSendButtonDisabled(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: true,
    }));
    setBtnState('draft');
  };

  const getTemplateVersion = () => {
    getEoreCurrentTemplateVersion()
      .then((res) => {
        if (res.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            template_version_id: res.data.id,
          }));
          setTemplateVersion(res.data);
        }
      })
      .catch((err) => {
        const error = err.response?.data?.message || 'Failed to get template version';
        toast.error(error);
        navigate('/reports/eore/');
      });
  };

  const sendFiles = (files, updateFunction, id, type) => new Promise((resolve, reject) => {
    const data = new FormData();
    files?.forEach((file) => {
      data.append(type, file.file || file);
    });
    updateFunction(id, data)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

  const sendEorePhotos = (id) => sendFiles(photos, updateEoreReportPhotos, id, 'images');

  const sendAttachments = (id) => sendFiles(attachments, updateReportAttachments, id, 'files');

  const sendForm = async () => {
    if (!checkFormMissingFields(formData, setErrors)) {
      setSendButtonDisabled(false);
      setBtnState(null);
      return;
    }
    setLoader(true);
    try {
      const response = await postEoreReport(formData);
      const photoPromise = sendEorePhotos(response.data.id);
      const attachmentPromise = sendAttachments(response.data.id);
      await Promise.all([photoPromise, attachmentPromise]);
      if (response.status === 201) {
        toast.success('EORE report created successfully');
        if (formData.draft) {
          navigate(`/reports/eore/edit/${response.data.id}`);
        } else {
          navigate('/reports/eore/');
        }
      }
    } catch (err) {
      const error = err.response?.data?.[0] || 'Failed to create EORE report';
      toast.error(error);
    } finally {
      setLoader(false);
      setBtnState(null);
      setSendButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (btnState === 'draft' || btnState === 'send') {
      sendForm();
    }
  }, [formData.draft, btnState]);

  useEffect(() => {
    if (user) {
      getTemplateVersion();
    }
  }, [user]);

  const setBeneficiariesFiles = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData?.info,
        beneficiaries: {
          files: attachments.map((attachment) => ({
            file_name: attachment?.name,
          })),
        },
      },
    }));
  };

  useEffect(() => {
    setBeneficiariesFiles();
  }, [attachments]);

  useEffect(() => {
    getHandouts()
      .then((res) => {
        setHandouts(res.data?.map((handout) => ({
          handout_id: handout.id,
          name: handout.name,
          count: 0,
        })));
        setFormData((prevFormData) => ({
          ...prevFormData,
          info: {
            ...prevFormData.info,
            session_information: {
              ...prevFormData.info.session_information,
              direct_beneficiaries: {
                ...prevFormData.info.session_information.direct_beneficiaries,
                handouts: res.data?.map((handout) => ({
                  handout_id: handout.id,
                  name: handout.name,
                  count: 0,
                })),
              },
              indirect_beneficiaries: {
                ...prevFormData.info.session_information.indirect_beneficiaries,
                handouts: res.data?.map((handout) => ({
                  handout_id: handout.id,
                  name: handout.name,
                  count: 0,
                })),
              },
            },
          },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSaveButton = () => {
    setSendButtonDisabled(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: false,
    }));
    setBtnState('send');
  };

  if (!templateVersion) return null;

  return (
    <section>
      <PageTitle
        title="Creating EORE"
        formData={formData}
        templateVersion={templateVersion}
        setLoader={setLoader}
      />
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="flex-right">
          <TabList>
            <Tab>
              <span className={`${errors.length > 0 ? 'error-text' : ''}`}>
                Attributes
              </span>
            </Tab>
            <Tab>Photos</Tab>
            <Tab>Check</Tab>
          </TabList>
        </div>
        <form className="form-wrapper">
          <TabPanel>
            <AttributesTab
              formData={formData}
              setFormData={setFormData}
              handouts={handouts}
              zone={zone}
              setZone={setZone}
              errors={errors}
            />
          </TabPanel>
          <TabPanel>
            <PhotosTab
              formData={formData}
              setFormData={setFormData}
              photos={photos}
              setPhotos={setPhotos}
              attachments={attachments}
              setAttachments={setAttachments}
              setLoader={setPhotosLoader}
              mode="create"
            />
          </TabPanel>
          <TabPanel>
            <CheckEORETab
              formData={formData}
              zone={zone}
              setZone={setZone}
              handouts={handouts}
            />
          </TabPanel>
        </form>

      </Tabs>

      <div className="form-buttons buttons-group py-10">
        <ButtonComponent
          iconLeft={<PencilSvg />}
          handleButton={handleDraftButton}
          label="Draft"
          disabled={sendButtonDisabled}
          className="warning"
        />
        <ButtonComponent
          iconLeft={<SendSvg />}
          handleButton={handleSaveButton}
          label="Send"
          disabled={sendButtonDisabled}
        />
        <ButtonComponent
          iconLeft={<CloseSvg />}
          handleButton={handleCancelButton}
          label="Cancel"
          disabled={false}
          className="danger"
        />
      </div>
      <Loader visible={photosLoader} />
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
