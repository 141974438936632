import React from 'react';
import { InputComponent } from 'component/Input';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/User.svg';

export const UserSurName = (props) => {
  const handleSurName = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      surname: value,
    }));
  };

  return (
    <InputComponent
      type="text"
      upperRightText="Surname"
      id="user-surname"
      name="user-surname"
      placeholder="John"
      value={props.surname}
      handleInput={handleSurName}
      iconLeft={<PeopleSvg />}
      autoComplete="off"
    />
  );
};

export const SurnameView = (props) => (
  <div className="double-text">
    <span>Surname</span>
    <span>{props.surname || '-'}</span>
  </div>
);
