import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { removeLeadingZeros } from '../../../../utils/Helper';

export const SocialServicesView = (props) => {
  const { formData } = props;
  const landuse = formData.info.landuse_and_beneficiaries;
  return (
    <AccordionBodyItemView
      accordionSubId="8.2"
      titleUA="Громадські/державні служби"
      titleEN="Social services"
      children={(
        <div className="flex-group flex-wrap">
          {removeLeadingZeros(landuse.social_services)}
        </div>
      )}
    />
  );
};
