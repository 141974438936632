import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { React, useState } from 'react';
import { ResidentalAreas } from './Beneficiaries/ResidentalAreas';
import { SocialServices } from './Beneficiaries/SocialServices';
import { IndustrialObjects } from './Beneficiaries/IndustrialObjects';
import { Access } from './Beneficiaries/Access';
import { Agricultural } from './Beneficiaries/Agricultural';
import { NaturalResourses } from './Beneficiaries/NaturalResourses';
import { DirectBeneficiaries } from './Beneficiaries/DirectBeneficiaries';
import { InfoItem } from '../../../component/InfoItem';
import { IndirectBeneficiaries } from './Beneficiaries/IndirectBeneficiaries';

export const BeneficiariesInfo = (props) => {
  const [directBeneficiariesSum, setDirectBeneficiariesSum] = useState(0);
  const [indirectBeneficiariesSum, setIndirectBeneficiariesSum] = useState(0);

  const checkPercent = (exceptKey) => {
    const data = props.formData.info.landuse_and_beneficiaries;
    let totalSum = 0;
    for (const key in data) {
      if (key !== 'number_of_direct_beneficiaries'
        && key !== 'number_of_indirect_beneficiaries'
        && key !== exceptKey) {
        totalSum += data[key];
      }
    }
    return totalSum;
  };

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="8"
            validationForm={props.validationForm}
            block="landuse_and_beneficiaries"
            titleUA={(
              <div className="flex-group">
                <span>Тип землекористування та прямі бенефіціари</span>
                <span className="star">*</span>
              </div>
)}
            titleEN="Landuse and beneficiaries"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <ResidentalAreas
          formData={props.formData}
          setFormData={props.setFormData}
          checkPercent={checkPercent}
        />
        <SocialServices
          formData={props.formData}
          setFormData={props.setFormData}
          checkPercent={checkPercent}
        />
        <IndustrialObjects
          formData={props.formData}
          setFormData={props.setFormData}
          checkPercent={checkPercent}
        />
        <Access
          formData={props.formData}
          setFormData={props.setFormData}
          checkPercent={checkPercent}
        />
        <Agricultural
          formData={props.formData}
          setFormData={props.setFormData}
          checkPercent={checkPercent}
        />
        <NaturalResourses
          formData={props.formData}
          setFormData={props.setFormData}
          checkPercent={checkPercent}
        />
        <div className={`text text-center 
                    ${props.errors?.landuse_and_beneficiaries?.atLeast100Landuse ? 'error' : ''} `}
        >
          <div>*В сумі має дорівнювати 100%</div>
          <div>*Total must equal 100%</div>
        </div>
        <div className="flex-column-group">
          <DirectBeneficiaries
            formData={props.formData}
            setFormData={props.setFormData}
            setBeneficiariesSum={setDirectBeneficiariesSum}
            errors={props.errors}
          />

          <div className="flex-right">
            <InfoItem
              titleEN="Total number of direct beneficiaries"
              titleUA="8.11 Загальна кількість прямих бенефіціарів"
              data={directBeneficiariesSum}
              errors={props.errors}
            />
          </div>

          <IndirectBeneficiaries
            formData={props.formData}
            setFormData={props.setFormData}
            setBeneficiariesSum={setIndirectBeneficiariesSum}
          />

          <div className="flex-right">
            <InfoItem
              titleEN="Total number of indirect beneficiaries"
              titleUA="8.16 Загальна кількість непрямих бенефіціарів"
              data={indirectBeneficiariesSum}
            />
          </div>
        </div>

      </AccordionItemPanel>
    </>
  );
};
