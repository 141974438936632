import React from 'react';
import { DatePickerWrapper } from 'component/DataPicker';
import 'assets/styles/pages/machinery.scss';
import { ButtonComponent } from 'component/Button';

export const FilterDate = (props) => {
  const handleFromDateChange = (value) => {
    props.setFromDate(value);
  };

  const handleToDateChange = (value) => {
    props.setToDate(value);
  };

  const applyDates = () => {
    props.getMachineryTracks();
  };

  return (
    <div className="machinery-filter box-map flex-column-group">
      <div className="flex-column-group">
        <span>From:</span>
        <DatePickerWrapper
          value={props.fromDate}
          handleDatePicker={handleFromDateChange}
          disableDateAfter={props.toDate ? props.toDate : null}
        />
      </div>
      <div className="flex-column-group">
        <span>To:</span>
        <DatePickerWrapper
          value={props.toDate}
          handleDatePicker={handleToDateChange}
          shouldDisableDate
          disableDateBefore={props.fromDate ? props.fromDate : null}
        />
      </div>
      <div className="buttons-group">
        <ButtonComponent
          label="Apply"
          handleButton={applyDates}
        />
      </div>
    </div>
  );
};
