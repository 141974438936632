import React from 'react';
import { ReactComponent as ExportIcon } from 'assets/images/icons/file_download.svg';
import { downloadXlsx } from 'utils/Helper';

export const DownloadExport = (props) => (
  <ExportIcon
    className="upload-icon"
    onClick={() => downloadXlsx(props.id)}
  />
);
