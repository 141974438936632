import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';

export const DirectEvidenceDescription = (props) => {
  const handleEvidenceDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        evidences: {
          ...prevFormData.info.evidences,
          direct_evidences: {
            ...prevFormData.info.evidences.direct_evidences,
            description: value,
          },
        },
      },
    }));
  };

  return (
    <div className="flex-column-group">
      <AccordionBodyItem
        accordionSubId="4.3"
        titleUA="Коротке пояснення прямих доказів за потреби"
        titleEN="A brief explanation of direct evidence as needed"
      />
      <TextareaComponent
        style={{ width: 'calc(100% - 40px)' }}
        handleTextarea={handleEvidenceDescription}
        value={props.formData.info.evidences.direct_evidences.description}
      />
    </div>
  );
};
