import React from 'react';
import { toast } from 'react-toastify';
import { getDroneDataFile } from 'api/DroneDataApi';
import { ReactComponent as UploadIcon } from 'assets/images/icons/file_upload.svg';

export const DownloadDroneData = (props) => {
  const getFile = () => {
    props.setLoader(true);
    getDroneDataFile(props.fileName)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Drone data.tiff');
        document.body.appendChild(link);
        link.click();
        props.setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoader(false);
        toast.error('Error download drone data');
      });
  };

  return (
    <UploadIcon
      className="upload-icon"
      onClick={getFile}
    />
  );
};
