import { React, useEffect, useState } from 'react';
import { SelectComponent } from 'component/Select';
import { getRoles } from '../../../api/UserAPI';

export const UserAccessLevel = (props) => {
  const [allRoles, setAllRoles] = useState(null);

  const handleAccessLevelChange = (value) => {
    props.setUserInfo((prev) => ({
      ...prev,
      role: value.id,
      permissions: Object.keys(value?.permissions),
    }));
    props.setPermissions(value?.permissions);
    props.setRole({ value: value.id, label: value.name });
  };

  const getAllRoles = () => {
    getRoles()
      .then((response) => {
        setAllRoles(response.data);
      }).catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (props.mode !== 'edit') {
      getAllRoles();
    }
  }, []);

  return (
    <div>
      <div className="select-wrapper w-100" style={{ gap: '0' }}>
        <span className="label" style={{ marginTop: '10px' }}>
          Access level
        </span>
        <SelectComponent
          className="w-100"
          placeholder="Select..."
          options={allRoles?.map((role) => ({
            value: role,
            label: role?.name,
          }))}
          value={props.roleOption}
          handleSelect={handleAccessLevelChange}
          isDisabled={props.mode === 'edit' || !props.hasEditPermission}
        />
      </div>
    </div>
  );
};

export const AccessLevelView = (props) => (
  <div className="double-text">
    <span>Access level</span>
    <span>{props.accessLevel?.label || '-'}</span>
  </div>
);
