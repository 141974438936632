import React, { useEffect, useState } from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { Map } from 'component/Map';
import { convert3857To4326 } from 'utils/Helper';
import { featureCollection, point } from '@turf/helpers';
import { PolygonInfoView } from './PolygonInfo';
import { LmBmTableView } from './LmBmTable';
import { CommentsView } from '../Comments';
import { TableFileInfo } from '../../blocks/FileInformation/TableInfo';

export const FileInfoView = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;
  const [lmGeojson, setLmGeojson] = useState(formData.info.lm.geometry);
  const [bmGeojson, setBmGeojson] = useState(formData.info.bm.geometry);

  useEffect(() => {
    if (formData.info.lm.geometry.coordinates.length) {
      setLmGeojson(
        convert3857To4326(
          featureCollection([point(formData.info.lm.geometry.coordinates)]),
          formData.file_zone,
        ),
      );
    }
  }, [formData.info.lm.geometry]);

  useEffect(() => {
    if (formData.info.bm.geometry.coordinates.length) {
      setBmGeojson(
        convert3857To4326(
          featureCollection([point(formData.info.bm.geometry.coordinates)]),
          formData.file_zone,
        ),
      );
    }
  }, [formData.info.bm.geometry]);

  return (
    <AccordionItem uuid="fileInfo">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="5"
            titleUA="Інформація про полігон"
            titleEN="File information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className="flex-column-group">
          <PolygonInfoView formData={formData} />
          <div className="accordion-body-wrapper bgGrey">
            <LmBmTableView
              formData={formData}
              translatedFormData={translatedFormData}
              translateState={translateState}
            />
          </div>

          <TableFileInfo formData={formData} />
          <Map
            map={props.map}
            setMap={props.setMap}
            id="polygon-map"
            turnPointsPolygon={formData.info.turn_points.geojson}
            hazardAreaType={formData.info.general.hazard_area_type}
            lmGeojson={lmGeojson}
            bmGeojson={bmGeojson}
            googleWms
          />
          <CommentsView comments={comments} block="polygon_info" />

        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
};
