import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { CommentsView } from '../Comments';
import { InformantView } from './Informant';

export const InformatorsView = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;
  const evidenceInformators = formData.info.informators;

  return (
    <AccordionItem uuid="informators">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="9"
            titleUA="Інформатори"
            titleEN="Informants"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {evidenceInformators.map((informator, index) => (
          <InformantView
            informator={informator}
            index={index}
            key={index}
            translatedFormData={translatedFormData}
            translateState={translateState}
          />
        ))}
        <CommentsView
          comments={comments}
          block="informators"
        />

      </AccordionItemPanel>
    </AccordionItem>
  );
};
