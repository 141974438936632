import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const AntiVehicleMineView = (props) => {
  const { formData } = props;
  return (
    <AccordionBodyItemView
      accordionSubId="3.2"
      titleUA="Протитранспортні міни"
      titleEN="Anti-vehicle mine"
      children={
        formData.info.hazard_type.anti_transport_mines && (
          <CheckBoxIcon />
        )
      }
    />
  );
};
