import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const GroundProfileView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const geographic = formData.info.geographic_and_terrain_description;

  const reliefOptions = {
    gully: 'Яр \\ Gully',
    hillside: 'Схил пагорба \\ Hillside',
    unknown: 'Невідомо \\ Unknown',
    ridge: 'Гребінь \\ Ridge',
    flat: 'Низина \\ Flat',
    other: 'Інше \\ Other',
  };

  const selectedReliefWithBackslashes = Object.keys(reliefOptions)
    .filter((option) => geographic.relief.includes(option))
    .map((option) => reliefOptions[option]);

  return (
    <AccordionBodyItem
      accordionSubId="6.2"
      titleUA="Рел’єф"
      titleEN="Ground profile"
      rightContentClassName="w-60"
      children={(
        <div className="flex-column-group align-left">
          {selectedReliefWithBackslashes.map((item, index) => (
            <div key={index} className="block-with-checkbox-icon">
              <CheckBoxIcon
                style={{ width: '20px', height: '20px' }}
              />
              {item}
            </div>
          ))}
          {geographic.relief_other && (
          <div>
            {translateState && translatedFormData.relief_other}
            {!translateState && geographic.relief_other}
          </div>
          )}
        </div>
      )}
    />
  );
};
