import React, { useState } from 'react';
import 'assets/styles/pages/details.scss';
import { AddNewMaterialsDialog } from 'component/Dialog';
import { useUser } from 'routes/UserContext';
import AccordionDetails from './AccordionDetails';

const Details = ({ setLoader }) => {
  const [refreshData, setRefreshData] = useState(false);
  const { user } = useUser();
  const handleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  return (
    <>
      <div className="details-container">
        <div className="title">
          <span>EORE Materials</span>
        </div>
        <AccordionDetails setLoader={setLoader} refreshData={refreshData} user={user} />
      </div>
      {user?.permissions?.crud_handouts_superuser
        && (
        <div className="buttons-group-tokens">
          <AddNewMaterialsDialog refreshData={handleRefreshData} />
        </div>
        )}
    </>
  );
};

export default Details;
