import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';

export const AdditionalComments = (props) => {
  const landreleaseActivity = props.formData.info.next_landrelease_activity;

  const handleAdditionalComments = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          additional_comments_on_hazard_area: value,
        },
      },
    }));
  };
  const handleAdditionalCommentsExtended = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        next_landrelease_activity: {
          ...prevFormData.info.next_landrelease_activity,
          additional_comments_on_hazard_area_extended: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId="7.5"
      titleUA="Додаткові коментарі по небезпечній ділянці, за потреби:"
      titleEN="Additional comments on hazardous area if any"
      children={(
        <div className="flex-group flex-wrap flex-right">
          <TextareaComponent
            id="additional_comments_on_hazard_area"
            name="additional_comments_on_hazard_area"
            value={landreleaseActivity.additional_comments_on_hazard_area}
            handleTextarea={handleAdditionalComments}
            blueLink
            maxLength="1500"
            disable="false"
            limitChar
          />
          <TextareaComponent
            id="additional_comments_on_hazard_area_extended"
            name="additional_comments_on_hazard_area_extended"
            value={landreleaseActivity.additional_comments_on_hazard_area_extended}
            handleTextarea={handleAdditionalCommentsExtended}
            blueLink={false}
            disable="false"
            limitChar={false}
          />
        </div>
      )}
    />
  );
};
