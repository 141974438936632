import axios from 'axios';

export const login = async (body) => {
  const url = '/users/token/';
  const response = await axios.post(url, body);
  return response;
};

export const getUser = async () => {
  const url = '/users/self/';
  const response = await axios.get(url);
  return response;
};

export const getDirectors = async (role) => {
  const url = `/users/directors/${role}/`;
  const response = await axios.get(url);
  return response;
};

export const getAllDirectors = async () => {
  const url = '/users/directors/';
  const response = await axios.get(url);
  return response;
};

export const deleteUser = async (id) => {
  const url = `/users/${id}/`;
  const response = await axios.delete(url);
  return response;
};

export const getUserById = async (id) => {
  const url = `/users/${id}/`;
  const response = await axios.get(url);
  return response;
};

export const getUserAvatarById = async (id) => {
  const url = `/users/${id}/avatar/`;
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return response;
};

export const getRoles = async () => {
  const url = '/users/roles/';
  const response = await axios.get(url);
  return response;
};

export const createUser = async (body) => {
  const url = '/users/';
  const response = await axios.post(url, body);
  return response;
};

export const updateUser = async (id, body) => {
  const url = `/users/${id}/`;
  const response = await axios.patch(url, body);
  return response;
};

export const getUserPermissions = async () => {
  const url = '/users/permissions/';
  const response = await axios.get(url);
  return response;
};

export const getHierarchyListById = async (id) => {
  const url = `/users/hierarchy/${id}`;
  const response = await axios.get(url);
  return response;
};

export const createRole = async (body) => {
  const url = '/users/roles/';
  const response = await axios.post(url, body);
  return response;
};

export const updateRole = async (id, body) => {
  const url = `/users/roles/${id}/`;
  const response = await axios.put(url, body);
  return response;
};

export const deleteRole = async (id) => {
  const url = `/users/roles/${id}/`;
  const response = await axios.delete(url);
  return response;
};

export const getAllUsers = async (hasValidations = false) => {
  const params = { has_validations: hasValidations };
  const url = '/users/';
  const response = await axios.get(url, { params });
  return response;
};

export const getAllUsersForTokens = async (hasSettings = false) => {
  const params = { has_settings: hasSettings };
  const url = '/users/';
  const response = await axios.get(url, { params });
  return response;
};

export const getAllUsersForEOREDetails = async (hasHandouts = false) => {
  const params = { has_handouts: hasHandouts };
  const url = '/users/';
  const response = await axios.get(url, { params });
  return response;
};

export const deleteSettingsById = async (id) => {
  const url = `/core/settings/${id}/`;
  const response = await axios.delete(url);
  return response;
};

export const getValidationsByUserId = async (userId) => {
  const url = `/validations/flows/?user=${userId}`;
  const response = await axios.get(url);
  return response;
};

export const getValidationsById = async (id) => {
  const url = `/validations/flows/${id}`;
  const response = await axios.get(url);
  return response;
};

export const getValidationsReportTypesByUserId = async (id) => {
  const userIdParam = id ? `?user_pk=${id}` : '';
  const url = `/validations/report-types/${userIdParam}`;
  const response = await axios.get(url);
  return response;
};

export const createValidationFlow = async (body) => {
  const url = '/validations/flows/';
  const response = await axios.post(url, body);
  return response;
};

export const deleteValidationFlow = async (body) => {
  const url = 'validations/flows/delete/';
  const response = await axios.delete(url, { data: body });
  return response;
};
