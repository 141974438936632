import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import { RadioButton } from 'component/RadioButton';
import { React, useEffect, useState } from 'react';

export const FieldMarking = (props) => {
  const geographicDescription = props.formData.info.geographic_and_terrain_description;
  const [otherCheckbox, setOtherCheckbox] = useState(false);

  useEffect(() => {
    setOtherCheckbox(geographicDescription.current_field_marking.includes('other'));
  }, [geographicDescription]);

  const handleGeographicDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          current_field_marking: value,
        },
      },
    }));
  };

  const handleOtherDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          current_field_marking_other: value,
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>6.5</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Поточне маркування на місцевості"
      titleEN="Current marking on the field"
      className={props.errors?.geographic_and_terrain_description?.current_field_marking ? 'error' : ''}
      rightContentClassName="w-60"
      children={(
        <>
          <div className="flex-group flex-wrap">
            <RadioButton
              id="official_signs"
              name="current_field_marking"
              value="official_signs"
              label="Офіційні знаки \ Official signs"
              checked={geographicDescription.current_field_marking.includes('official_signs')}
              handleInput={(value) => {
                setOtherCheckbox(false);
                handleGeographicDescription(value);
                handleOtherDescription('');
              }}
              disable="false"
            />
            <RadioButton
              id="local_signs"
              name="current_field_marking"
              value="local_signs"
              label="Неофіційні знаки \ Local signs"
              checked={geographicDescription.current_field_marking.includes('local_signs')}
              handleInput={(value) => {
                setOtherCheckbox(false);
                handleGeographicDescription(value);
                handleOtherDescription('');
              }}
              disable="false"
            />
            <RadioButton
              id="fenced"
              name="current_field_marking"
              value="fenced"
              label="Огородження \ Fenced"
              checked={geographicDescription.current_field_marking.includes('fenced')}
              handleInput={(value) => {
                setOtherCheckbox(false);
                handleGeographicDescription(value);
                handleOtherDescription('');
              }}
              disable="false"
            />
            <RadioButton
              id="current_field_marking_none"
              name="current_field_marking"
              value="none"
              label="Відсутнє \ None"
              checked={geographicDescription.current_field_marking.includes('none')}
              handleInput={(value) => {
                setOtherCheckbox(false);
                handleGeographicDescription(value);
                handleOtherDescription('');
              }}
              disable="false"
            />
            <RadioButton
              id="current_field_marking_other"
              name="current_field_marking"
              value="other"
              label="Інше \ Other"
              checked={geographicDescription.current_field_marking.includes('other')}
              handleInput={(value) => {
                setOtherCheckbox(true);
                handleGeographicDescription(value);
              }}
              disable="false"
            />
          </div>
          {otherCheckbox && (
            <InputComponent
              id="current_field_marking_other"
              style={{ width: 'calc(100% - 40px)' }}
              handleInput={handleOtherDescription}
              value={geographicDescription.current_field_marking_other || ''}
              blueLink
              limitChar
            />
          )}
        </>
      )}
    />
  );
};
