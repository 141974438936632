import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const ArmsAmmunition = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.7"
      titleUA="Боєприпаси до стрілецької зброї (<20мм)"
      titleEN="Small Arms Ammunition (<20mm)"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="amsllArmsAmmunition"
          name="small_arms_ammunition"
          value="small_arms_ammunition"
          checked={hazardType.small_arms_ammunition}
          handleInput={props.handleContamination}
          disable="false"
        />
      )}
    />
  );
};
