import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import ReportRowDetails from './ReportRowDetails';

const AccordionDetails = ({ setLoader, refreshData, user }) => (
  <Accordion
    allowMultipleExpanded
    allowZeroExpanded
    className="custom-accordion"
    preExpanded={[]}
  >
    <AccordionItem uuid="usersList">
      <ReportRowDetails setLoader={setLoader} refreshData={refreshData} userContext={user} />
    </AccordionItem>
  </Accordion>
);

export default AccordionDetails;
