import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import { InputComponent } from 'component/Input';
import React from 'react';

export const typeOfPlaceInternalOptions = [
  { value: 'admin_day', label: 'Робота в офісі / Admin Day' },
  { value: 'public_place', label: 'Громадське місце / Public Place' },
  { value: 'administrations', label: 'Адміністрації / Administrations' },
  { value: 'community', label: 'Спільнота / Community' },
  { value: 'state_institution', label: 'Державні установи / State institutions' },
  { value: 'education_facility', label: 'Навчальний заклад / Education Facility' },
  { value: 'critical_infrastructure', label: 'Критична інфраструктура / Critical infrastructure' },
  { value: 'agricultural_company', label: 'Агрофірма / Agricultural company' },
  { value: 'forestry', label: 'Лісове господарство / Forestry' },
  { value: 'hospitals', label: 'Лікарні / Hospitals' },
  { value: 'organization', label: 'Організація / Organization' },
  { value: 'event', label: 'Подія / Event' },
  { value: 'exit_entrance_crossing_point', label: 'Пункт пропуску виїзд-в\'їзд / Exit-Entrance Crossing Point' },
  { value: 'scrap_metal_yard', label: 'Склад металобрухту / Scrap Metal Yard' },
  { value: 'summer_camp', label: 'Літній табір / Summer Camp' },
  { value: 'other', label: 'Інше / Other' },
];

export const PlaceTypeOfInternal = (props) => {
  const { formData, setFormData, errors } = props;

  const setPlaceItem = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_internal: {
            ...prevFormData.info.session_information.place_type_internal,
            place_type_other: '',
            state_institution: '',
            education_facility: '',
            critical_infrastructure: '',
            value,
          },
        },
      },
    }));
  };

  const handleOtherPlaceType = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_internal: {
            ...prevFormData.info.session_information.place_type_internal,
            place_type_other: value,
          },
        },
      },
    }));
  };

  return (
    <>
      <AccordionBodyItem
        accordionSubId={(
          <div>
            <span>2.8.1</span>
          </div>
        )}
        titleUA="Оберіть тип місця (internal)"
        titleEN="Choose a type of place (internal)"
        children={(
          <SelectComponent
            placeholder="Оберіть тип місця"
            options={typeOfPlaceInternalOptions}
            value={typeOfPlaceInternalOptions.find((option) => option.value === formData
              ?.info?.session_information?.place_type_internal.value) || null}
            handleSelect={setPlaceItem}
          />
        )}
      />
      {formData?.info?.session_information?.place_type_internal.value === 'other' && (
        <AccordionBodyItem
          titleUA="Інший варіант"
          titleEN="Other"
          className={errors?.includes('place_type_internal_other') ? 'error' : ''}
          children={(
            <InputComponent
              placeholder="Введіть інший варіант"
              value={formData?.info?.session_information?.place_type_internal.place_type_other}
              handleInput={handleOtherPlaceType}
            />
          )}
        />
      )}
    </>
  );
};

export const typeOfPlaceImsmaOptions = [{
  label: 'Школа\\навчальний заклад / School\\educational facility',
  value: 'educational_facility',
}, {
  label: 'Підприємство\\організація / Enterprise\\organization',
  value: 'organisation',
}, {
  label: 'Візит в домогосподарство / Household visit',
  value: 'household_visit',
}, {
  label: 'КПВВ / ЕЕСР',
  value: 'eecp',
}, {
  label: 'Сесія під відкритим небом (свта, події, парки, зустрічі в громадах) / Open air session',
  value: 'open_air',
}, {
  label: 'Інше / Other',
  value: 'other',
}];

export const PlaceTypeOfImsma = (props) => {
  const { formData, setFormData, errors } = props;

  const setPlaceItem = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_imsma: {
            ...prevFormData.info.session_information.place_type_imsma,
            place_type_other: '',
            value,
          },
        },
      },
    }));
  };

  const handleOtherPlaceType = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_imsma: {
            ...prevFormData.info.session_information.place_type_imsma,
            place_type_other: value,
          },
        },
      },
    }));
  };

  return (
    <>
      <AccordionBodyItem
        accordionSubId={(
          <div>
            <span>2.8.2</span>
          </div>
        )}
        titleUA="Оберіть тип місця (imsma)"
        titleEN="Choose a type of place (imsma)"
        children={(
          <SelectComponent
            placeholder="Оберіть тип місця"
            options={typeOfPlaceImsmaOptions}
            value={typeOfPlaceImsmaOptions.find((option) => option.value === formData
              ?.info?.session_information?.place_type_imsma.value) || null}
            handleSelect={setPlaceItem}
          />
        )}
      />
      {formData?.info?.session_information?.place_type_imsma.value === 'other' && (
        <AccordionBodyItem
          titleUA="Інший варіант"
          titleEN="Other"
          className={errors?.includes('place_type_imsma_other') ? 'error' : ''}
          children={(
            <InputComponent
              placeholder="Enter other type of place"
              value={formData?.info?.session_information?.place_type_imsma.place_type_other}
              handleInput={handleOtherPlaceType}
            />
          )}
        />
      )}
    </>
  );
};

export const stateInstitutionOptions = [{
  label: 'Пенсійний фонд / Pension Fund',
  value: 'pension_fund',
}, {
  label: 'Центр зайнятості / Employment Center',
  value: 'employment_center',
}, {
  label: 'Департамент праці та соціального захисту / Department of Labor and Social Protection',
  value: 'department_of_labor_and_social_protection',
}, {
  label: 'Центр адміністративних послуг / Center for admin service',
  value: 'center_of_admin_services',
}];

export const StateInstitution = (props) => {
  const { formData, setFormData, errors } = props;

  const handleStateInstitution = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_internal: {
            ...prevFormData.info.session_information.place_type_internal,
            state_institution: value,
          },
        },
      },
    }));
  };

  return (
    formData?.info?.session_information?.place_type_internal.value === 'state_institution' && (
      <AccordionBodyItem
        titleUA="Оберіть тип"
        titleEN="Choose a type"
        className={errors?.includes('state_institution') ? 'error' : ''}
        children={(
          <SelectComponent
            placeholder=""
            options={stateInstitutionOptions}
            value={stateInstitutionOptions.find((option) => option.value === formData
              ?.info?.session_information?.place_type_internal.state_institution) || null}
            handleSelect={handleStateInstitution}
          />
        )}
      />
    )
  );
};

export const educationFacilityOptions = [{
  label: 'Дитячий садок / Kindergarten',
  value: 'kindergarten',
}, {
  label: 'Школа / School',
  value: 'school',
}, {
  label: 'Коледж / College',
  value: 'college',
}, {
  label: 'Інститут\\Університет / Institute\\University',
  value: 'institute_university',
}];

export const EducationFacility = (props) => {
  const { formData, setFormData } = props;

  const handleEducationFacility = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_internal: {
            ...prevFormData.info.session_information.place_type_internal,
            education_facility: value,
          },
        },
      },
    }));
  };

  return (
    formData?.info?.session_information?.place_type_internal.value === 'education_facility' && (
      <AccordionBodyItem
        titleUA="Оберіть тип"
        titleEN="Choose a type"
        children={(
          <SelectComponent
            placeholder="Оберіть тип"
            options={educationFacilityOptions}
            value={educationFacilityOptions.find((option) => option.value === formData
              ?.info?.session_information?.place_type_internal.education_facility) || null}
            handleSelect={handleEducationFacility}
          />
        )}
      />
    )
  );
};

export const criticalInfrastructureOptions = [{
  label: 'Газова компанія / Gas company',
  value: 'gas_company',
}, {
  label: 'Водяна компанія / Water company',
  value: 'water_company',
}, {
  label: 'Електрична компанія / Electric company',
  value: 'electric_company',
}, {
  label: 'Дорожна компанія / Road company',
  value: 'road_company',
}];

export const CriticalInfrastructure = (props) => {
  const { formData, setFormData, errors } = props;

  const handleCriticalInfrastructure = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          place_type_internal: {
            ...prevFormData.info.session_information.place_type_internal,
            critical_infrastructure: value,
          },
        },
      },
    }));
  };

  return (
    formData?.info?.session_information?.place_type_internal.value === 'critical_infrastructure' && (
      <AccordionBodyItem
        titleUA="Оберіть тип"
        titleEN="Choose a type"
        className={errors?.includes('critical_infrastructure') ? 'error' : ''}
        children={(
          <SelectComponent
            placeholder="Оберіть тип"
            options={criticalInfrastructureOptions}
            value={criticalInfrastructureOptions.find((option) => option.value === formData
              ?.info?.session_information?.place_type_internal.critical_infrastructure) || null}
            handleSelect={handleCriticalInfrastructure}
          />
        )}
      />
    )
  );
};
