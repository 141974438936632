export const ValidationHAInputs = {
  general: {
    internal_id: false,
    imsma_id: false,
    hazard_name: false,
    report_date: false,
    reporter_organization: false,
    reporter_name: false,
    reporter_occupation: false,
    group_id: false,
    examination_date: {
      from: false,
      to: false,
    },
    hazard_area_type: false,
    hazard_type: false,
  },
  location: {
    region: false,
    district: false,
    local_community: false,
    settlement_name: false,
  },
  hazard_type: {
    anti_personnel_mines: false,
    anti_transport_mines: false,
    unexploded_ordnance: false,
    abandoned_explosive_ordnance: false,
    cluster_munition: false,
    improvised_explosive_device: false,
    small_arms_ammunition: false,
    explosives: false,
  },
  evidences: {
    direct_evidences: true,
    indirect_evidences: true,
  },
  file_info: {
    lm: false,
    bm: false,
    turn_points: false,
  },
  geographic_and_terrain_description: {
    vegetation: false,
    relief: false,
    slope_gradient: false,
    seasonal_accessibility: false,
    current_field_marking: false,
  },
  next_landrelease_activity: {
    priority: true,
    next_most_appropriate_operation: false,
    reasons_and_comments_on_71_72: false,
    recommended_clearance_depth: false,
  },
  landuse_and_beneficiaries: true,
};
