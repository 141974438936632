import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';

export const IMSMAID = (props) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>{props.formType === 'ha' ? '1.2' : '1.1'}</span>
        <span className="star">*</span>
      </div>
    )}
    titleUA="Ідентифікаційний номер IMSMA"
    titleEN="IMSMA identification number"
    children={props.formData.info.general.imsma_id
      || (
        <span>
          Ідентифікаційний номер звіту генерується автоматично
          <br />
          The report identification number is generated automatically
        </span>
      )}
  />
);
