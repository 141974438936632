import axios from 'axios';

export const getImsmaAllLayer = async (layer, signal) => {
  const url = `/imsma/validated-readonly/?layer=${layer}`;
  const response = await axios.get(url, { signal });
  return response;
};

export const getImsmaFsdLayer = async (layer, signal) => {
  const url = `/imsma/not-validated/?layer=${layer}`;
  const response = await axios.get(url, { signal });
  return response;
};

export const getBufferZoneLayer = async (signal) => {
  const url = '/imsma/buffer-zone/';
  const response = await axios.get(url, { signal });
  return response;
};

export const getFeodalLayer = async (layer, signal) => {
  const url = `/imsma/feodal-view/?layer=${layer}`;
  const response = await axios.get(url, { signal });
  return response;
};
