import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { removeLeadingZeros } from '../../../../utils/Helper';

export const IndustrialView = (props) => {
  const { formData } = props;
  const landuse = formData.info.landuse_and_beneficiaries;

  return (
    <AccordionBodyItemView
      accordionSubId="8.3"
      titleUA="Об’єкти інфраструктури, промисловість"
      titleEN="Infrastructure, industrial objects"
      children={(
        <div className="flex-group flex-wrap">
          {removeLeadingZeros(landuse.infrastructure_industrial_objects)}
        </div>
      )}
    />
  );
};
