import React from 'react';
import 'assets/styles/card.scss';

export const Card = (props) => {
  const { header, body, footer } = props;
  return (
    <div className="card-wrapper">
      <div className="card">
        {header && <div className="header">{header}</div>}
        {body && <div className="body">{body}</div>}
        {footer && <div className="footer">{footer}</div>}
      </div>
    </div>
  );
};
