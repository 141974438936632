import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { DirectEvidence } from './Evidence/DirectEvidence';
import { CoordinatesDirectEvidence } from './Evidence/CoordinatesDirectEvidence';
import { IndirectEvidence } from './Evidence/IndirectEvidence';
import { CoordinatesIndirectEvidence } from './Evidence/CoordinatesIndirectEvidence';
import { DirectEvidenceDescription } from './Evidence/DirectEvidenceDescription';
import { IndirectEvidenceDescription } from './Evidence/IndirectEvidenceDescription';

export const Evidence = (props) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          accordionId="4"
          validationForm={props.validationForm}
          block="evidences"
          titleUA={(
            <div className="flex-group">
              <span>Докази</span>
              <span className="star">*</span>
            </div>
          )}
          titleEN="Evidence"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <DirectEvidence
        formData={props.formData}
        setFormData={props.setFormData}
      />
      <CoordinatesDirectEvidence
        bgStyle="bgGrey no-border-radius-bottom"
        formData={props.formData}
        setFormData={props.setFormData}
        handleTabChange={props.handleTabChange}
        tab="attributes"
        photos={props.photos}
        setPhotos={props.setPhotos}
        map={props.map1}
        setMap={props.setMap1}
        setLoaderPhotos={props.setLoaderPhotos}
      />
      <DirectEvidenceDescription
        formData={props.formData}
        setFormData={props.setFormData}
      />
      <IndirectEvidence
        formData={props.formData}
        setFormData={props.setFormData}
      />

      <CoordinatesIndirectEvidence
        bgStyle="bgGrey no-border-radius-bottom"
        formData={props.formData}
        setFormData={props.setFormData}
        handleTabChange={props.handleTabChange}
        tab="attributes"
        photos={props.photos}
        setPhotos={props.setPhotos}
        map={props.map2}
        setMap={props.setMap2}
        setLoaderPhotos={props.setLoaderPhotos}
      />
      <IndirectEvidenceDescription
        formData={props.formData}
        setFormData={props.setFormData}
      />
    </AccordionItemPanel>
  </>
);
