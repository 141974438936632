import React from 'react';
import moment from 'moment';
import { DatePickerWrapper } from 'component/DataPicker';

export const DateFilter = (props) => (
  <>
    <span>Sort by date</span>
    <div className="flex-group">

      <DatePickerWrapper
        value={props.dateFrom
          ? moment(props.dateFrom, 'DD.MM.YYYY')
          : null}
        handleDatePicker={(value) => props.setDateFromFilter(value, 'from')}
      />
      <DatePickerWrapper
        value={props.dateTo
          ? moment(props.dateTo, 'DD.MM.YYYY')
          : null}
        handleDatePicker={(value) => props.setDateToFilter(value, 'to')}
      />
    </div>
  </>
);
