import React from 'react';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CalendarSvg } from 'assets/images/icons/Calendar.svg';
import { ReactComponent as MapSvg } from 'assets/images/icons/map1.svg';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/People.svg';
import { ReactComponent as PhotoSvg } from 'assets/images/icons/Photo.svg';
import { engEvidencesType } from 'consts/Evidences';

export const ReportImageDetailInfo = (props) => (
  <div className="file-info">
    <div className="header">
      <span>Image information</span>
      <svg
        onClick={props.closeImageModal}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.5 6.41L18.09 5L12.5 10.59L6.91 5L5.5 6.41L11.09 12L5.5 17.59L6.91 19L12.5 13.41L18.09 19L19.5 17.59L13.91 12L19.5 6.41Z" fill="#00B1B7" />
      </svg>
    </div>
    <div className="body">
      <div className="flex-group">
        <CalendarSvg />
        <span className="double-text">
          <span>{props.mapPopupData.report_date || '-'}</span>
          <span>Report date</span>
        </span>
      </div>
      <div className="flex-group">
        <PeopleSvg />
        <span className="double-text">
          <span>{props.mapPopupData.team_id || '-'}</span>
          <span>Team</span>
        </span>
      </div>
      <div className="flex-group">
        <MapSvg />
        <span className="double-text">
          <span>{props.mapPopupData.location || '-'}</span>
          <span>Location</span>
        </span>
      </div>
      <div className="flex-group">
        <PhotoSvg />
        <span className="double-text">
          <span>
            {JSON.parse(props.mapPopupData.mines)?.map((mine, index, array) => (
              <>
                <b>{engEvidencesType[mine] || '-'}</b>
                {index < array.length - 1 && ', '}
              </>
            ))}
          </span>
          <span>Type of evidence</span>
        </span>
      </div>
    </div>
    <div className="footer">
      <div className="line" />
      <div className="flex-group flex-center">
        <ButtonComponent
          handleButton={props.closeImageModal}
          className="danger outline"
          label={(
            <div className="flex-group">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z" fill="#D26E6E" />
              </svg>
              <span>Close</span>
            </div>
)}
        />
      </div>
    </div>
  </div>
);
