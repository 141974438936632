import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { removeLeadingZeros } from '../../../../utils/Helper';

export const ResidentialAreasView = (props) => {
  const { formData } = props;
  const landuse = formData.info.landuse_and_beneficiaries;
  return (
    <AccordionBodyItemView
      accordionSubId="8.1"
      titleUA="Житлові райони"
      titleEN="Residential areas"
      children={(
        <div className="flex-group flex-wrap">
          {removeLeadingZeros(landuse.residental_areas)}
        </div>
      )}
    />
  );
};
