import { AccordionHeaderTitle } from 'component/Accordion';
import { DownloadAllPhotos } from 'pages/EORE/Reports/Tabs/Photos';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const PhotosBlockView = ({ formData, photos, setLoader }) => (
  <>
    <div className="info_block_header">
      <div className="info_block_header_left">Фото</div>
      <span className="info_block_header_line" />
      <div className="info_block_header_right">Photos</div>
    </div>
    <Accordion
      className="custom-accordion"
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={['photos']}
    >
      <AccordionItem uuid="photos">
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderTitle titleUA="Фото" titleEN="Photo" />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div
            className="photos-block"
          >
            <div
              className="photo-block-header"
            >
              <p
                className="photo-block-header-text"
              >
                Кількість фотографій:
                {' '}
                {photos?.length}
              </p>
              {photos?.length > 0
                && (
                  <div
                    className="download-all-photos-button"
                  >
                    <DownloadAllPhotos
                      reportId={formData?.id}
                      setLoader={setLoader}
                    />
                  </div>
                )}
            </div>
            {photos?.map((photo) => (
              <>
                <div className="photo-block">
                  <img src={photo?.data_url} alt={photo?.name} />
                </div>
                <div className="line" />
              </>
            ))}
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  </>
);

export default PhotosBlockView;
