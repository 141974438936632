import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const RegionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.1"
      titleUA="Область"
      titleEN="Region"
      children={formData.info.location.region}
    />
  );
};
