import React from 'react';
import { Table, TableHeader, TableRow } from 'component/Table';

export const LmBmTableView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const { lm } = formData.info;
  const { bm } = formData.info;
  return (
    <Table className="table-lines-between">
      <TableHeader>
        <th
          className="double-text"
          style={{
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>Точки відліку</div>
          <div>Reference points</div>
        </th>
        <th aria-label="line">
          <div className="line" />
        </th>
        <th>Long/UTM X</th>
        <th aria-label="line">
          <div className="line" />
        </th>
        <th>Lat/UTM Y</th>
        <th aria-label="line">
          <div className="line" />
        </th>
        <th>Опис\ Description</th>
      </TableHeader>
      <tbody>
        <TableRow>
          <td
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div className="double-text">
              <span>Фіксований орієнтир</span>
              <span>Landmark (LM)</span>
            </div>
          </td>
          <td aria-label="line">
            <div className="line" />
          </td>
          <td>{lm?.geometry?.coordinates[0]?.toFixed(0) || 0}</td>
          <td aria-label="line">
            <div className="line" />
          </td>
          <td>{lm?.geometry?.coordinates[1]?.toFixed(0) || 0}</td>
          <td aria-label="line">
            <div className="line" />
          </td>
          <td className="flex-group center">
            {translateState && (translatedFormData.lm_description || 'No data')}
            {!translateState && (lm.description || 'Немає даних')}
          </td>
        </TableRow>
        <TableRow>
          <td
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div className="double-text">
              <span>Опорна точка</span>
              <span>Benchmark (BM)</span>
            </div>
          </td>
          <td aria-label="line">
            <div className="line" />
          </td>
          <td>{bm?.geometry?.coordinates[0]?.toFixed(0) || 0}</td>
          <td aria-label="line">
            <div className="line" />
          </td>
          <td>{bm?.geometry?.coordinates[1]?.toFixed(0) || 0}</td>
          <td aria-label="line">
            <div className="line" />
          </td>
          <td className="flex-group center">
            {translateState && (translatedFormData.bm_description || 'No data')}
            {!translateState && (bm.description || 'Немає даних')}
          </td>
        </TableRow>
      </tbody>
    </Table>
  );
};
