import axios from 'axios';
import { URLS } from 'routes/Urls';

export const getPhotoByName = async (reportId, imageName, millimeter = false) => {
  const baseUrl = `${URLS.CREATE_REPORT_HA}${reportId}/images/`;
  let url = `${baseUrl}?name=${imageName}`;
  if (millimeter) {
    url = `${baseUrl}millimeter-map/?name=${imageName}`;
  }
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return response;
};

export const getEorePhotoByName = async (reportId, imageName) => {
  const url = `/reports/eore/${reportId}/images/?image_name=${imageName}`;
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return response;
};

export const getAllEorePhotos = async (reportId) => {
  const url = `/reports/eore/${reportId}/images/?all=true`;
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return response;
};

export const getAttachmentsByName = async (reportId, fileName) => {
  const url = `/reports/eore/${reportId}/files/?file_name=${fileName}`;
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return response;
}

export const getAllAttachments = async (reportId) => {
  const url = `/reports/eore/${reportId}/files/?all=true`;
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return response;
}