import Cleave from 'cleave.js/react';
import { AccordionBodyItem } from 'component/Accordion';
import { Table, TableHeader, TableRow } from 'component/Table';
import { React, useEffect } from 'react';
import { calculateBeneficiariesSum } from 'utils/Helper';

export const DirectBeneficiaries = (props) => {
  const beneficiaries = props.formData.info.landuse_and_beneficiaries;

  useEffect(() => {
    props.setBeneficiariesSum(() => calculateBeneficiariesSum(beneficiaries, 'number_of_direct_beneficiaries'));
  }, [beneficiaries.number_of_direct_beneficiaries]);

  const handleBeneficiaryChange = (category, value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        landuse_and_beneficiaries: {
          ...prevFormData.info.landuse_and_beneficiaries,
          number_of_direct_beneficiaries: {
            ...prevFormData.info.landuse_and_beneficiaries.number_of_direct_beneficiaries,
            [category]: Number(value),
          },
        },
      },
    }));
  };
  return (
    <div className="flex-column-group">
      <AccordionBodyItem
        accordionSubId=""
        titleUA="Кількість прямих бенефіціарів"
        titleEN="Number of direct beneficiaries"
        className={props.errors?.landuse_and_beneficiaries?.number_of_direct_beneficiaries ? 'error' : ''}
      />
      <Table>
        <TableHeader>
          <th>
            <div>8.7 Чоловіків (18 та старше)</div>
            <div>
              Men (18+)
              <span className="star">*</span>
            </div>
          </th>
          <th>
            <div>8.8 Жінок (18 та старше)</div>
            <div>
              Women (18+)
              <span className="star">*</span>
            </div>
          </th>
          <th>
            <div>8.9 Хлопчиків (молодше 18)</div>
            <div>
              Boys
              <span className="star">*</span>
            </div>
          </th>
          <th>
            <div>8.10 Дівчаток (молодше 18)</div>
            <div>
              Girls
              <span className="star">*</span>
            </div>
          </th>
        </TableHeader>
        <tbody>
          <TableRow>
            <td aria-label="text-box">
              <Cleave
                placeholder="0"
                id="beneficiaries-men"
                name="men"
                className="number"
                value={beneficiaries.number_of_direct_beneficiaries.men}
                autoComplete="off"
                options={{
                  numericOnly: true,
                  stripLeadingZeroes: true,
                }}
                onChange={(e) => handleBeneficiaryChange('men', e.target.value.replace(/^0+/, ''))}
                disable="false"
              />
            </td>
            <td aria-label="text-box">
              <Cleave
                placeholder="0"
                id="beneficiaries-women"
                name="women"
                className="number"
                value={beneficiaries.number_of_direct_beneficiaries.women}
                autoComplete="off"
                options={{
                  numericOnly: true,
                  stripLeadingZeroes: true,
                }}
                onChange={(e) => handleBeneficiaryChange('women', e.target.value.replace(/^0+/, ''))}
                disable="false"
              />
            </td>
            <td aria-label="text-box">
              <Cleave
                placeholder="0"
                id="beneficiaries-boys"
                name="boys"
                className="number"
                value={beneficiaries.number_of_direct_beneficiaries.boys}
                autoComplete="off"
                options={{
                  numericOnly: true,
                  stripLeadingZeroes: true,
                }}
                onChange={(e) => handleBeneficiaryChange('boys', e.target.value.replace(/^0+/, ''))}
                disable="false"
              />
            </td>
            <td aria-label="text-box">
              <Cleave
                placeholder="0"
                id="beneficiaries-girls"
                name="girls"
                className="number"
                value={beneficiaries.number_of_direct_beneficiaries.girls}
                autoComplete="off"
                options={{
                  numericOnly: true,
                  stripLeadingZeroes: true,
                }}
                onChange={(e) => handleBeneficiaryChange('girls', e.target.value.replace(/^0+/, ''))}
                disable="false"
              />
            </td>
          </TableRow>
        </tbody>
      </Table>
    </div>

  );
};
