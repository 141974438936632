import { ButtonComponent } from 'component/Button';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Keyboard, Scrollbar, Navigation, Mousewheel,
} from 'swiper/modules';
import { ReactComponent as AddIcon } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { Loader } from 'component/Loader';
import { getRoles, getValidationsById } from 'api/UserAPI';
import { ClipLoader } from 'react-spinners';
import { CheckboxInput } from 'component/Checkbox';
import { toast } from 'react-toastify';
import { DeleteValidationFlowCardDialog } from 'component/Dialog';

const SwiperValidation = ({ mode, onUpdateStepsAndRoles }) => {
  const [validationCards, setValidationCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState({});
  const [roles, setRoles] = useState([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({});

  const getValidations = (flowId) => {
    setIsLoading(true);
    getValidationsById(flowId)
      .then((response) => {
        setValidationCards(response?.data?.steps || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching validations:', error);
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getAllRoles = (index) => {
    setIsLoadingRoles((prev) => ({ ...prev, [index]: true }));
    getRoles().then((response) => {
      setRoles(response?.data);
      setIsLoadingRoles((prev) => ({ ...prev, [index]: false }));
    }).catch(() => {
      toast.error('Error fetching roles');
      console.error('Error fetching roles');
    }).finally(() => {
      setIsLoadingRoles((prev) => ({ ...prev, [index]: false }));
    });
  };

  useEffect(() => {
    if (mode?.flowId) {
      getValidations(mode?.flowId);
    } else {
      setIsLoading(false);
    }
  }, [mode?.flowId]);

  const handleEditClick = (index) => {
    if (mode?.action === 'view') return;

    setEditMode((prev) => {
      const newEditMode = { ...prev };
      Object.keys(newEditMode).forEach((key) => {
        newEditMode[key] = false;
      });
      newEditMode[index] = true;
      return newEditMode;
    });

    getAllRoles(index);

    const initialSelectedRoles = validationCards[index]?.roles?.map((role) => role?.id) || [];
    setSelectedRoles((prev) => ({
      ...prev,
      [index]: initialSelectedRoles.reduce((acc, roleId) => {
        acc[roleId] = true;
        return acc;
      }, {}),
    }));
  };

  const handleSaveClick = (index) => {
    const updatedValidationCards = [...validationCards];
    if (!updatedValidationCards[index]) {
      updatedValidationCards[index] = { order: index + 1, roles: [] };
    }
    updatedValidationCards[index].roles = Object.keys(selectedRoles[index] || {})
      .filter((roleId) => selectedRoles[index][roleId])
      .map((roleId) => roles.find((role) => role.id === Number(roleId)));

    onUpdateStepsAndRoles(updatedValidationCards);
    setValidationCards(updatedValidationCards);
    setEditMode((prev) => ({ ...prev, [index]: false }));
  };

  const handleAddNewCard = (currentIndex) => {
    if (mode?.action === 'view') return;

    const isValid = validationCards.every((card) => card.roles.length > 0);
    if (!isValid) {
      toast.error('Please select at least one role and click save');
      return;
    }

    const newCard = {
      order: currentIndex + 2,
      roles: [],
    };

    const updatedCards = validationCards.length === 0
      ? [{ order: 1, roles: [] }, newCard]
      : [
        ...validationCards.slice(0, currentIndex + 1),
        newCard,
        ...validationCards.slice(currentIndex + 1).map((card) => ({
          ...card,
          order: card.order + 1,
        })),
      ];

    setValidationCards(updatedCards);
    onUpdateStepsAndRoles(updatedCards);

    setEditMode((prev) => {
      const newEditMode = { ...prev };
      Object.keys(newEditMode).forEach((key) => {
        newEditMode[key] = false;
      });
      newEditMode[currentIndex + 1] = true;
      return newEditMode;
    });

    setSelectedRoles((prev) => ({
      ...prev,
      [currentIndex + 1]: {},
    }));

    getAllRoles(currentIndex + 1);
  };

  const handleDeleteCard = async (order) => {
    const updatedCards = validationCards.filter((card) => card.order !== order);
    const reorderedCards = updatedCards.map((card, i) => ({
      ...card,
      order: i + 1,
    }));
    setValidationCards(reorderedCards);
    await onUpdateStepsAndRoles(reorderedCards);
  };

  const handleCheckboxChange = (index, roleId) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [roleId]: !prev[index]?.[roleId],
      },
    }));
  };

  return (
    isLoading
      ? (
        <Loader visible={isLoading} />
      ) : (
        <Swiper
          direction="horizontal"
          slidesPerView={3}
          centeredSlides={false}
          grabCursor
          spaceBetween={30}
          breakpoints={{
            690: {
              slidesPerView: 4,
              slidesPerGroup: 7,
            },
          }}
          scrollbar
          navigation
          mousewheel={{
            enabled: false,
            invert: true,
          }}
          modules={[Mousewheel, Keyboard, Scrollbar, Navigation]}
          className="mySwiper"
          style={{
            '--swiper-navigation-color': '#868686',
            '--swiper-navigation-size': '24px',
            '--swiper-scrollbar-size': '4px',
          }}
        >
          {(validationCards.length === 0
            ? [{ order: 1, roles: [] }]
            : validationCards).map((validation, index) => (
              <SwiperSlide key={index}>
                {editMode[index]
                  ? (
                    <div className="first-block">
                      <div
                        className="edit-role-block"
                      >
                        <span className="validation-title">
                          Validation
                          {' '}
                          {validation?.order}
                        </span>
                        <ButtonComponent
                          iconLeft={<AddIcon />}
                          label="Save"
                          handleButton={() => handleSaveClick(index)}
                        />
                      </div>
                      <div className="roles-block">
                        {isLoadingRoles[index]
                          ? (
                            <div
                              className="loader-container"
                            >
                              <ClipLoader color="rgba(0, 177, 183, 1)" size={30} />
                            </div>
                          )
                          : roles?.map((role) => (
                            <div
                              key={role.id}
                              className="role-checkbox-container"
                            >
                              <CheckboxInput
                                id={`role-${role?.id}`}
                                value={role?.id}
                                label={role?.short_name}
                                handleInput={() => handleCheckboxChange(index, role?.id)}
                                checked={selectedRoles[index]?.[role?.id] || false}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                  : (
                    <div className="first-block">
                      <div
                        className="validation-block"
                      >
                        <span className="validation-title">
                          Validation
                          {' '}
                          {validation?.order}
                        </span>
                        <ButtonComponent
                          iconLeft={<AddIcon />}
                          className={mode?.action === 'view' ? 'disabled-add-edit-role' : 'warning'}
                          label="Add / edit role"
                          handleButton={() => handleEditClick(index)}
                        />
                      </div>
                      <div
                        className="roles-block2"
                      >
                        {validation?.roles?.map((role, roleIndex) => (
                          <span
                            key={roleIndex}
                            className="role-badge"
                            style={{
                              backgroundColor: `${role?.color || '#0032E21A'}`,
                            }}
                          >
                            {role?.short_name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                <div className="second-block">
                  <div
                    className={mode?.action === 'view'
                      ? 'button-add-block-disabled'
                      : 'button-add-block'}
                    onClick={() => handleAddNewCard(index)}
                  >
                    <AddIcon className="add-icon" />
                  </div>
                  <div
                    className={mode?.action === 'view'
                      ? 'button-remove-block-disabled'
                      : 'button-remove-block'}
                  >
                    {mode?.action === 'view'
                      ? <DeleteIcon />
                      : (
                        <DeleteValidationFlowCardDialog
                          onDelete={handleDeleteCard}
                          validation={validation}
                        />
                      )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )
  );
};

export default SwiperValidation;
