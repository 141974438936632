import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const SettlementIdView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.5"
      titleUA="Код нас. пункту (згідно кодифікатору)"
      titleEN="Settlement ID (new kodyficator)"
      children={formData.info.location?.settlement_code}
    />
  );
};
