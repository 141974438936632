import { Loader } from 'component/Loader';
import { React, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings_24px.svg';
import 'assets/styles/pages/settings.scss';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { useUser } from 'routes/UserContext';
import { Donors } from './Donors';
import Tokens from './Tokens/Tokens';
import { Roles } from './Roles';
import Validation from './Validation/Validation';
import Details from './Details/Details';

export const SecretsSettingsPage = () => {
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useUser();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    document.title = 'Settings';
  }, []);

  return (
    <section>
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="settings-header">
          <div className="title">
            <SettingsIcon />
            Secrets settings
          </div>
          <div className="flex-right">
            <TabList>
              <Tab>Tokens</Tab>
              {user?.permissions?.view_donors
                && (<Tab>Donors</Tab>)}
              {user?.permissions?.add_user
                && (<Tab>Roles</Tab>)}
              {user?.permissions?.view_flows
                && (<Tab>Validation</Tab>
                )}
              <Tab>Details</Tab>
            </TabList>
          </div>
        </div>
        <TabPanel>
          <Tokens setLoader={setLoader} />
        </TabPanel>
        {user?.permissions?.view_donors
          && (
            <TabPanel>
              <Donors
                setLoader={setLoader}
              />
            </TabPanel>
          )}
        {user?.permissions?.add_user
          && (
            <TabPanel>
              <Roles />
            </TabPanel>
          )}
        {user?.permissions?.view_flows
          && (
            <TabPanel>
              <Validation />
            </TabPanel>
          )}
        <TabPanel>
          <Details setLoader={setLoader} />
        </TabPanel>
      </Tabs>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
