import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ButtonComponent } from 'component/Button';
import { TextareaComponent } from 'component/Textarea';
import { alphaVal } from 'utils/Helper';

export const BriefExplanation = (props) => {
  const evidences = props.formData.info.evidences.indirect_evidences;

  const handleDeleteInformator = (id) => {
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidencesInformators = {
        ...prevFormData.info.evidences.indirect_evidences,
        informators: prevFormData.info.evidences.indirect_evidences
          .informators.filter((item, index) => id !== index),
      };

      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidencesInformators,
          },
          informators: prevFormData.info.informators.filter((item, index) => id !== index),
        },
      };
    });
  };

  const handleAddInformant = (e) => {
    e.preventDefault();
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidencesInformators = {
        ...prevFormData.info.evidences.indirect_evidences,
        informators: [
          ...prevFormData.info.evidences.indirect_evidences.informators,
          '',
        ],
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidencesInformators,
          },
          informators: [
            ...prevFormData.info.informators,
            {
              name: '',
              position: '',
              age: 0,
              sex: 'male',
              phone: '',
              address: '',
            },
          ],
        },
      };
    });
  };

  const handleInformatorInfo = (value, id) => {
    props.setFormData((prevFormData) => {
      const updatedIndirectEvidencesInformators = {
        ...prevFormData.info.evidences.indirect_evidences,
        informators: prevFormData.info.evidences.indirect_evidences
          .informators.map((item, index) => (index === id ? value : item)),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            indirect_evidences: updatedIndirectEvidencesInformators,
          },
        },
      };
    });
  };

  return (
    <div className="flex-column-group">
      {!evidences.informators.length
        && (
          <AccordionBodyItem
            titleUA="Інформатори відсутні"
            titleEN="No informants"
          />
        )}
      {evidences?.informators?.map((informator, index) => (
        <div key={index}>
          <AccordionBodyItem
            titleUA={`Інформатор ${alphaVal(index + 1)}`}
            titleEN={`Informant ${alphaVal(index + 1)}`}
            children={(
              <div
                className="text-hover"
                onClick={() => handleDeleteInformator(index)}
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 3.5V4.5H20V6.5H19V19.5C19 20.6 18.1 21.5 17 21.5H7C5.9 21.5 5 20.6 5 19.5V6.5H4V4.5H9V3.5H15ZM7 19.5H17V6.5H7V19.5ZM9 8.5H11V17.5H9V8.5ZM15 8.5H13V17.5H15V8.5Z"
                    fill="#868686"
                  />
                </svg>
              </div>
            )}
          />

          <div className="accordion-body-wrapper flex-left">
            <div className="numberId" />
            <div className="accordion-title w-100">
              <TextareaComponent
                className="full"
                id={index}
                value={informator}
                handleTextarea={(value) => {
                  handleInformatorInfo(value, index);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="flex-center">
        <ButtonComponent
          className="outline"
          handleButton={handleAddInformant}
          label="+ Add informant"
        />
      </div>
    </div>
  );
};
