import React from 'react';
import { Dialog } from '@mui/material';
import { ReactComponent as CheckmarkIcon } from 'assets/images/icons/Done-1.svg';
import { ReactComponent as EditSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/cancel_24px.svg';
import { ButtonComponent } from 'component/Button';
import { useUser } from 'routes/UserContext';
import { AvatarView } from './Avatar';
import { NameView } from './Name';
import { SurnameView } from './SurName';
import { MiddleNameView } from './MiddleName';
import { TeamIdView } from './TeamId';
import { EmailView } from './Email';
import { AccessLevelView } from './AccessLevel';
import { FunctionView } from './Function';
import { PasswordView } from './Password';
import { PhoneNumberView } from './PhoneNumber';

export const UserInfoPopup = ({
  userInfo,
  setAfterCreate,
  setMode,
  setOpen,
  setOpenUserPopup,
  open,
  afterCreate,
  avatarImage,
  role,
  resetState,
  getHierarchyOfUsers,
}) => {
  const { user } = useUser();

  const handleDlgClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setAfterCreate(false);
    setOpen(false);
    if (afterCreate) {
      resetState();
      setAfterCreate(false);
      setOpen(false);
      getHierarchyOfUsers(user?.id);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      open={open}
      onClose={handleDlgClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="confirm-user-popup-wrapper"
    >
      <div className="confirm-user-popup info-user-popup">
        <div className="header">
          <span className="title">
            User info
          </span>
          <span
            className="closeBtn"
            onClick={() => {
              handleDlgClose();
            }}
          />
        </div>
        {afterCreate && (
          <div className="flex-center page-title">
            <CheckmarkIcon />
            <span className="blue-text">User has been saved</span>
          </div>
        )}
        <div className="body-section">

          <div className="first-section">
            <AvatarView avatarImage={avatarImage} />
            <div className="user-fields">
              <NameView name={userInfo.name} />
              <SurnameView surname={userInfo.surname} />
              <MiddleNameView middlename={userInfo.middle_name} />
              <TeamIdView teamId={userInfo.team_id} />
            </div>
          </div>
          <div className="second-section">
            <div className="user-fields">
              <EmailView email={userInfo.email} />
              <AccessLevelView accessLevel={role} />
              <FunctionView function={userInfo.function} />
              <PhoneNumberView phoneNumber={userInfo.phone_number} />
              {afterCreate && userInfo.password
                && <PasswordView password={userInfo.password} />}
            </div>
          </div>
        </div>

        <div className="buttons-group">
          <ButtonComponent
            iconLeft={<CancelSvg />}
            className="danger"
            handleButton={() => {
              handleDlgClose();
            }}
            label="Close"
          />
          {user?.permissions.change_user
            && (
              <ButtonComponent
                iconLeft={<EditSvg />}
                className="warning"
                handleButton={() => {
                  setOpenUserPopup(true);
                  setOpen(false);
                  setMode('edit');
                  setAfterCreate(false);
                }}
                label="Edit"
              />
            )}
        </div>
      </div>
    </Dialog>
  );
};
