import axios from 'axios';

export const getNotifications = async () => {
  const url = '/notifications/';
  const response = await axios.get(url);
  return response;
};

export const deleteNotification = async (id) => {
  const url = `/notifications/${id}/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteAllNotification = async () => {
  const url = '/notifications/all/';
  const response = await axios.delete(url);
  return response;
};

export const downloadNotificationsLogs = async (startDate, endDate) => {
  const url = `/notifications/logs/download/?date_from=${startDate || ''}&date_to=${endDate || ''}`;
  const response = await axios.get(url, { responseType: 'blob' });
  return response;
};
