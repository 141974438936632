/* eslint-disable no-shadow */
import {
  React,
  useRef,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CalendarSvg } from 'assets/images/icons/Calendar.svg';
import { ReactComponent as MapSvg } from 'assets/images/icons/map1.svg';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/People.svg';
import { ReactComponent as PhotoSvg } from 'assets/images/icons/Photo.svg';
import { axiosWrapper } from 'utils/Helper';
import { getPhotoByName } from 'api/ImagesApi';
import { engEvidencesType } from 'consts/Evidences';
import { ReportImageDetailInfo } from './ReportImageDetailInfo';

const ImagePreview = (props) => (
  <img
    src={props.url}
    alt={props.url}
    onClick={() => {
      props.openImageModal(props.index);
    }}
  />
);

export const MapDonorPopup = (props) => {
  const [mapPopupData, setMapPopupData] = useState(null);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [clickedReport, setClickedReport] = useState(null);

  const clickHandlerRef = useRef(null);

  useEffect(() => {
    if (!props.map) return;
    if (clickHandlerRef.current) {
      props.map.off('click', 'turnPointsPolygon-layer', clickHandlerRef.current);
      props.map.off('click', 'ntsSource-layer', clickHandlerRef.current);
    }
    const newClickHandler = (e) => {
      props.setLoader(true);
      setPhotos([]);
      setMapPopupData(e.features[0].properties);
    };

    props.map.on('click', () => {
      setMapModalOpen(false);
      setClickedReport(null);
    });
    props.map.on('click', 'turnPointsPolygon-layer', (e) => {
      newClickHandler(e);
      setClickedReport('ha');
    });
    props.map.on('click', 'ntsSource-layer', (e) => {
      newClickHandler(e);
      setClickedReport('nts');
    });

    clickHandlerRef.current = newClickHandler;
  }, [props.map]);

  useEffect(() => {
  }, [photos, mapPopupData]);

  const getPhoto = (reportId, imageFile) => {
    if (!imageFile) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile, {
                type: 'image/jpeg',
              });

              setPhotos((prevPhotos) => [
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  name: imageFile.file_name,
                },
              ]);

              resolve();
            } else {
              reject(new Error('Photo request failed'));
            }
          })
          .catch((err) => {
            toast.error(
              `Photo with file name ${imageFile} not loaded`,
            );
            props.setLoader(false);
            reject(err);
          });
      });
    });
  };

  useEffect(() => {
    if (!mapPopupData) return;
    const photos = mapPopupData?.photos ? JSON.parse(mapPopupData?.photos) : [];
    if (!photos.length) {
      setMapModalOpen(true);
      props.setLoader(false);
      return;
    }
    photos.forEach((imageName) => {
      getPhoto(mapPopupData.id, imageName)
        .then(() => {
          props.setLoader(false);
          setMapModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
          props.setLoader(false);
          setMapModalOpen(true);
        });
    });
  }, [mapPopupData]);

  const openImageModal = (index) => {
    setCurrentImageIndex(index);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setCurrentImageIndex(null);
    setIsImageModalOpen(false);
  };

  return (
    mapModalOpen && (
      <div className="mapbox-popup donor-popup-info">
        <div className="header">Report description</div>
        <div className="body">
          <div className="flex-group">
            <CalendarSvg />
            <span className="double-text">
              <span>{mapPopupData.report_date || '-'}</span>
              <span>Report date</span>
            </span>
          </div>
          <div className="flex-group">
            <PeopleSvg />
            <span className="double-text">
              <span>{mapPopupData.team_id || '-'}</span>
              <span>Team</span>
            </span>
          </div>
          {clickedReport === 'ha' && (
            <>
              <div className="flex-group">
                <MapSvg />
                <span className="double-text">
                  <span>{mapPopupData.location || '-'}</span>
                  <span>Location</span>
                </span>
              </div>
              <div className="flex-group">
                <PhotoSvg />
                <span className="double-text">
                  <span>
                    {JSON.parse(mapPopupData.mines)?.map((mine, index, array) => (
                      <>
                        <b>{engEvidencesType[mine] || '-'}</b>
                        {index < array.length - 1 && ', '}
                      </>
                    ))}
                  </span>
                  <span>Type of evidence</span>
                </span>
              </div>
              <div className="flex-column-group flex-center">
                {photos.length > 0 && (
                  <>
                    <span className="images-preview">
                      {photos.map((image, index) => {
                        if (index < 5) {
                          return (
                            <ImagePreview
                              openImageModal={openImageModal}
                              index={index}
                              url={image.data_url}
                              key={index}
                            />
                          );
                        }
                        return null;
                      })}
                      {photos.length > 4
                      && photos.length - 5 > 0
                      && (
                        <span
                          className="flex-center text"
                          style={{ 'margin-left': '40px' }}
                        >
                          +
                          {' '}
                          {photos.length - 5}
                          {' '}
                          photos more

                        </span>
                      )}

                    </span>
                    <div className="flex-center">
                      <ButtonComponent
                        className="outline"
                        label="Open image gallery"
                        handleButton={() => openImageModal(0)}
                      />
                    </div>
                  </>
                )}

                <Modal
                  isOpen={isImageModalOpen}
                  onRequestClose={closeImageModal}
                  className="react-inner-modal-class"
                  overlayClassName="react-modal-class"
                >
                  {currentImageIndex !== null && (
                    <div className="image-gallery-wrapper">
                      <ImageGallery
                        items={photos.map((image) => ({
                          original: image.data_url,
                          thumbnail: image.data_url,
                          thumbnailHeight: '60px',
                          thumbnailWidth: '60px',
                        }))}
                        startIndex={currentImageIndex}
                        onSlide={(index) => setCurrentImageIndex(index)}
                      />
                      <ReportImageDetailInfo
                        mapPopupData={mapPopupData}
                        photos={photos}
                        currentImageIndex={currentImageIndex}
                        closeImageModal={closeImageModal}
                      />
                    </div>
                  )}
                </Modal>
              </div>
            </>
          )}
        </div>
        <div className="footer">
          <div className="text-center">
            <ButtonComponent
              className="danger"
              label="Close"
              handleButton={() => {
                setMapModalOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};
