import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';

export const Explosives = (props) => {
  const hazardType = props.formData.info.hazard_type;

  return (
    <AccordionBodyItem
      accordionSubId="3.8"
      titleUA="Вибухові речовини, порохи"
      titleEN="Explosives"
      className={props.errors.hazard_type ? 'error' : ''}
      children={(
        <CheckboxInput
          id="explosives"
          name="explosives"
          value="explosives"
          checked={hazardType.explosives}
          handleInput={props.handleContamination}
          disable="false"
        />
      )}
    />
  );
};
