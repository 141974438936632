import React from 'react';
import 'assets/styles/buttons.scss';

export const ButtonComponent = (props) => {
  const {
    type,
    className,
    handleButton,
    disabled,
    iconLeft,
    iconRight,
    label,
  } = props;

  return (
    <button
      type={type ? type : 'submit'}
      className={className}
      onClick={handleButton}
      disabled={disabled}
    >
      <div className="btn-inside">
        {iconLeft}
        <span>{label}</span>
        {iconRight}
      </div>
    </button>
  );
};
