import React from 'react';
import Cleave from 'cleave.js/react';
import { AccordionBodyItem } from 'component/Accordion';

export const Age = (props) => (
  <AccordionBodyItem
    accordionSubId={`9.${props.id}`}
    titleUA="Вік"
    titleEN="Age"
    children={(
      <Cleave
        placeholder="0"
        id="beneficiaries-men"
        name="men"
        className="number"
        value={props.formData.info.informators[props.index].age}
        autoComplete="off"
        options={{
          numericOnly: true,
          stripLeadingZeroes: true,
        }}
        onChange={(e) => props.handleInformator('age', Number(e.target.value))}
        disable="false"
      />
    )}
  />
);
