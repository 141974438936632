import { AccordionBodyItem, AccordionHeaderTitle } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { React, useState } from 'react';
import { LocationComponent } from './Location/Location';
import { SettlementId } from './Location/SettlementId';
import { NTSmap } from './NTSmap';

export const NonTechnical = (props) => {
  const [map, setMap] = useState(null);

  const handleDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        location: {
          ...prevFormData.info.location,
          description: value,
        },
      },
    }));
  };

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            titleUA={(
              <div className="flex-group">
                <span>Місце проведення нетехнічного обстеження</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="Place of non-technical examination"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <LocationComponent
          formData={props.formData}
          setFormData={props.setFormData}
          fieldBlock="location"
          setCoords3857={props.setCoords3857}
          setZone={props.setZone}
          pointExist={props.pointExist}
          setPointExist={props.setPointExist}
          errors={props.errors}
        />
        <SettlementId
          settlementId={props.formData.info.location.settlement_code}
        />
        <AccordionBodyItem
          accordionSubId="2.6"
          titleUA="Опис місця проведення нетехнічного обстеження"
          titleEN="Description of the location of the non-technical inspection"
        />
        <InputComponent
          classNameWrapper="w-100"
          style={{ width: '-webkit-fill-available' }}
          handleInput={handleDescription}
          value={props.formData.info.location.description}
          placeholder="Description"
          blueLink
          limitChar
        />
        <NTSmap
          map={map}
          setMap={setMap}
          formData={props.formData}
          setFormData={props.setFormData}
          coords3857={props.coords3857}
          setCoords3857={props.setCoords3857}
          zone={props.zone}
          errors={props.errors}
          mode={props.mode}
        />

      </AccordionItemPanel>
    </>
  );
};
