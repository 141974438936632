import { React, useEffect, useState } from 'react';
import { SelectComponent } from 'component/Select';
import { getAllDirectors } from '../../../api/UserAPI';

export const UserDirectors = (props) => {
  const [allDirectors, setAllDirectors] = useState(null);

  const getDirectors = () => {
    getAllDirectors()
      .then((response) => {
        setAllDirectors(response.data?.directors);
      }).catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (allDirectors === null && props.hasEditPermission) {
      getDirectors();
    }
  }, [props.hasEditPermission]);

  const handleDirectorsChange = (selectedOptions) => {
    props.setUserInfo((prev) => ({
      ...prev,
      directors: selectedOptions.map((option) => option.value),
    }));
    props.setDirectors(selectedOptions);
  };

  return (
    <div className="select-wrapper w-100" style={{ gap: '0' }}>
      <span className="label" style={{ marginTop: '10px' }}>
        Director
      </span>
      <SelectComponent
        className="w-100"
        placeholder="Select..."
        value={props.directorsOption}
        options={allDirectors?.map((director) => ({
          value: director?.id,
          label: director?.name,
        }))}
        handleSelect={handleDirectorsChange}
        isDisabled={!props.hasEditPermission}
        isMulti
      />
    </div>
  );
};
