import React from 'react';
import { AccordionBodyItem, AccordionHeaderTitle } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import { RadioButton } from 'component/RadioButton';
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export const NTOResult = (props) => {
  const handleNTOReason = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        nto_result: {
          ...prevFormData.info.nto_result,
          nto_reason: value,
        },
      },
    }));
  };

  const handleNTOEvidences = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        nto_result: {
          ...prevFormData.info.nto_result,
          evidences: value === 'true' ? true : false,
        },
      },
    }));
  };

  const handleNTOVillageByVillage = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        nto_result: {
          ...prevFormData.info.nto_result,
          village_by_village: value === 'true' ? true : false,
        },
      },
    }));
  };

  const handleNTOAmmoReport = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        nto_result: {
          ...prevFormData.info.nto_result,
          single_ammo_report_needed: value === 'true' ? true : false,
        },
      },
    }));
  };

  const handleNTONewHazard = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        nto_result: {
          ...prevFormData.info.nto_result,
          new_hazard_area_report_needed: value === 'true' ? true : false,
        },
      },
    }));
  };

  const handleNTOComment = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        nto_result: {
          ...prevFormData.info.nto_result,
          comment: value,
        },
      },
    }));
  };

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            titleUA="Результат НТО"
            titleEN="Result of NTO"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <AccordionBodyItem
          accordionSubId="3.0"
          titleUA="Village by village"
          titleEN="Village by village"
          rightContentClassName="w-40"
          children={(
            <div className="grid-columns-2">
              <RadioButton
                label="Так / Yes"
                checked={props.formData.info.nto_result.village_by_village === true}
                id="village_by_village_yes"
                value
                name="village_by_village"
                disabled={false}
                handleInput={handleNTOVillageByVillage}
              />
              <RadioButton
                label="Ні / No"
                checked={props.formData.info.nto_result.village_by_village === false}
                id="village_by_village_no"
                value={false}
                name="village_by_village"
                disabled={false}
                handleInput={handleNTOVillageByVillage}
              />
            </div>
)}
        />
        <AccordionBodyItem
          accordionSubId="3.1"
          titleUA="Причини проведення HTO"
          titleEN="Reasons for performing NTS"
          rightContentClassName="w-40"
          children={(
            <div className="grid-columns-2">
              <RadioButton
                label={(
                  <>
                    <div>Заплановане НТО /</div>
                    <div>Planned NTO</div>
                  </>
)}
                checked={props.formData.info.nto_result.nto_reason === 'planned'}
                id="nto_reason_planned"
                value="planned"
                name="nto_reason"
                disabled={false}
                handleInput={handleNTOReason}
              />
              <RadioButton
                label={(
                  <>
                    <div>По заявці інше /</div>
                    <div>Application other</div>
                  </>
)}
                checked={props.formData.info.nto_result.nto_reason === 'application'}
                id="nto_reason_application"
                value="application"
                name="nto_reason"
                disabled={false}
                handleInput={handleNTOReason}
              />
              <RadioButton
                label={(
                  <>
                    <div>По заявці від фермерів (Feodal) /</div>
                    <div>Application from farmers (Feodal)</div>
                  </>
)}
                checked={props.formData.info.nto_result.nto_reason === 'farmer'}
                id="nto_reason_farmer"
                value="farmer"
                name="nto_reason"
                disabled={false}
                handleInput={handleNTOReason}
              />
              <RadioButton
                label={(
                  <>
                    <div>Повторне НТО /</div>
                    <div>Repeated NTO</div>
                  </>
)}
                checked={props.formData.info.nto_result.nto_reason === 'repeated'}
                id="nto_reason_repeated"
                value="repeated"
                name="nto_reason"
                disabled={false}
                handleInput={handleNTOReason}
              />
            </div>
)}
        />
        <AccordionBodyItem
          accordionSubId="3.2"
          titleUA="Чи виявлені будь-які докази?"
          titleEN="Have any evidence been found?"
          rightContentClassName="w-40"
          children={(
            <div className="grid-columns-2">
              <RadioButton
                label="Так / Yes"
                checked={props.formData.info.nto_result.evidences === true}
                id="evidences_yes"
                value
                name="evidences"
                disabled={false}
                handleInput={handleNTOEvidences}
              />
              <RadioButton
                label="Ні / No"
                checked={props.formData.info.nto_result.evidences === false}
                id="evidences_no"
                value={false}
                name="evidences"
                disabled={false}
                handleInput={handleNTOEvidences}
              />
            </div>
)}
        />
        <AccordionBodyItem
          accordionSubId="3.3"
          titleUA="Чи має бути створено звіт про виявлення одиничного боєприпасу?"
          titleEN="Should a report be created for the detection of a single ordnance?"
          rightContentClassName="w-40"
          children={(
            <div className="grid-columns-2">
              <RadioButton
                label="Так / Yes"
                checked={props.formData.info.nto_result.single_ammo_report_needed === true}
                id="ammo_report_yes"
                value
                name="single_ammo_report_needed"
                disabled={false}
                handleInput={handleNTOAmmoReport}
              />
              <RadioButton
                label="Ні / No"
                checked={props.formData.info.nto_result.single_ammo_report_needed === false}
                id="ammo_report_no"
                value={false}
                name="single_ammo_report_needed"
                disabled={false}
                handleInput={handleNTOAmmoReport}
              />
            </div>
)}
        />
        <AccordionBodyItem
          accordionSubId="3.4"
          titleUA="Чи має бути створено нову небезпечну територію?"
          titleEN="Should a new hazardous area be established?"
          rightContentClassName="w-40"
          children={(
            <div className="grid-columns-2">
              <RadioButton
                label="Так / Yes"
                checked={props.formData.info.nto_result.new_hazard_area_report_needed === true}
                id="new_hazard_yes"
                value
                name="new_hazard_area_report_needed"
                disabled={false}
                handleInput={handleNTONewHazard}
              />
              <RadioButton
                label="Ні / No"
                checked={props.formData.info.nto_result.new_hazard_area_report_needed === false}
                id="new_hazard_no"
                value={false}
                name="new_hazard_area_report_needed"
                disabled={false}
                handleInput={handleNTONewHazard}
              />
            </div>
)}
        />
        <AccordionBodyItem
          accordionSubId="3.5"
          titleUA="Коментар"
          titleEN="Comment"
        />
        <InputComponent
          classNameWrapper="w-100"
          style={{ width: '-webkit-fill-available' }}
          value={props.formData.info.nto_result.comment}
          handleInput={handleNTOComment}
          id="nto-comment"
          name="nto-comment"
        />
      </AccordionItemPanel>
    </>
  );
};
