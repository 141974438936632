import React from 'react';
import moment from 'moment';

export const MachineryPopup = (props) => (props.properties
  && (
    <div>
      <div className="double-text">
        <span>Name</span>
        <span>{props.properties.machineryName}</span>
      </div>
      <div className="double-text">
        <span>Date</span>
        <span>
          {moment(props.properties.fromDate).format('L HH:mm')}
          -
          {moment(props.properties.toDate).format('L HH:mm')}
        </span>
      </div>
      <div className="double-text">
        <span>Type</span>
        <span>{props.properties.type}</span>
      </div>
    </div>
  )
);
