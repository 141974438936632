import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import 'assets/styles/select.scss';

export const SelectComponent = (props) => {
  const extractLabelText = (userLabel) => {
    if (typeof userLabel === 'string') {
      return userLabel;
    }
    if (userLabel.props && userLabel.props.user) {
      const { user } = userLabel.props;
      return `${user.name} ${user.surname} ${user.email}`.toLowerCase();
    }
    return '';
  };

  const filterOption = (option, inputValue) => {
    const labelText = extractLabelText(option.label);
    return labelText.includes(inputValue);
  };

  return (
    <Select
      isMulti={props.isMulti}
      value={props.value ? props.value : null}
      options={props.options}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isSearchable
      filterOption={filterOption}
      className={`custom-select-container ${props.className
        ? props.className
        : ''}`}
      onChange={(e) => {
        if (Array.isArray(e)) {
          return props.handleSelect(e);
        }
        return props.handleSelect(e.value, e.label);
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#DFDFDF',
          primary: '#00B1B7',
        },
      })}
    />
  );
};

export const AsyncSelectComponent = (props) => (
  <AsyncSelect
    defaultOptions
    loadOptions={props.promiseOptions}
    className="custom-select-container"
    isSearchable
    isDisabled={props.isDisabled}
    placeholder={props.placeholder}
    value={props.value}
    onChange={(e) => props.handleSelect(e.value)}
    theme={(theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: '#DFDFDF',
        primary: '#00B1B7',
      },
    })}
  />
);
