import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { ReactComponent as CheckBoxIcon } from 'assets/images/icons/check_box_24(1).svg';

export const CurrentMarkingView = (props) => {
  const {
    formData,
    translatedFormData,
    translateState,
  } = props;
  const geographic = formData.info.geographic_and_terrain_description;

  const currentFieldMarkingOptions = {
    official_signs: 'Офіційні знаки \\ Official signs',
    local_signs: 'Неофіційні знаки \\ Local signs',
    fenced: 'Огородження \\ Fenced',
    none: 'Відсутнє \\ None',
    other: 'Інше \\ Other',
  };

  const selectedCurrentFieldMarkingWithBackslashes = Object.keys(currentFieldMarkingOptions)
    .filter((option) => geographic.current_field_marking.includes(option))
    .map((option) => currentFieldMarkingOptions[option]);

  return (
    <AccordionBodyItem
      accordionSubId="6.5"
      titleUA="Поточне маркування на місцевості"
      titleEN="Current marking on the field"
      rightContentClassName="w-60"
      children={(
        <div className="flex-column-group align-left">
          {selectedCurrentFieldMarkingWithBackslashes.map(
            (item, index) => (
              <div key={index} className="block-with-checkbox-icon">
                <CheckBoxIcon
                  style={{ width: '20px', height: '20px' }}
                />
                {item}
              </div>
            ),
          )}
          {geographic.current_field_marking_other && (
          <div>
            {translateState && translatedFormData.current_field_marking_other}
            {!translateState && geographic.current_field_marking_other}
          </div>
          )}
        </div>
      )}
    />
  );
};
