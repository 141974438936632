import React, { useEffect, useState } from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { getHandouts } from 'api/EOREApi';
import { DirectBeneficiaries } from './DirectBeneficiaries';
import { IndirectBeneficiaries } from './IndirectBeneficiaries';
import { Handouts } from './Handouts';

export const Beneficiaries = (props) => {
  const { formData, setFormData, handouts } = props;

  const handleBeneficiaries = (type, key, value) => {
    if (/[^0-9]/.test(value)) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          [type]: {
            ...prevFormData.info.session_information[type],
            [key]: Number(value),
          },
        },
      },
    }));
  };


  return (
    <>
      <AccordionBodyItem
        accordionSubId={(
          <div>
            <span>2.11</span>
          </div>
        )}
        titleUA="Бенефіціари"
        titleEN="Beneficiaries"
      />
      <div className="flex-column-group">
        <DirectBeneficiaries
          formData={formData}
          handleBeneficiaries={handleBeneficiaries}
        />
        <Handouts
          formData={formData}
          setFormData={setFormData}
          handouts={handouts}
          type="direct_beneficiaries"
        />
        <IndirectBeneficiaries
          formData={formData}
          handleBeneficiaries={handleBeneficiaries}
        />
        <Handouts
          formData={formData}
          setFormData={setFormData}
          handouts={handouts}
          type="indirect_beneficiaries"
        />
      </div>
    </>
  );
};
