import { Dialog } from '@mui/material';
import { ButtonComponent } from 'component/Button';
import { React, useState } from 'react';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as EoreIcon } from 'assets/images/icons/eore.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/cancel_24px.svg';
import { InputComponent } from 'component/Input';
import moment from 'moment';
import { postEoreTemplate } from 'api/EOREApi';
import { toast } from 'react-toastify';
import { UploadEoreTemplateFile } from './File';

export const NewEORETemplate = (props) => {
  const [open, setOpen] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [file, setFile] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTemplateName(null);
    setFile(null);
  };

  const createNewTemplate = () => {
    if (!file) {
      toast.error('Please select a file');
      return;
    }
    if (!templateName) {
      toast.error('Please enter a template name');
      return;
    }
    props.setLoader(true);
    const body = new FormData();
    body.append('file', file[0]);
    postEoreTemplate(body, templateName)
      .then(() => {
        handleClose();
        toast.success('Template created');
        props.getEoreTepmlatesList(1);
      })
      .catch((err) => {
        console.log(err);
        props.setLoader(false);
        toast.error('Template not created');
      });
  };

  return (
    <div className="drone-new-btn">
      <ButtonComponent
        iconLeft={<AddSvg />}
        handleButton={handleClickOpen}
        label="Create template"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Create EORE template</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="flex-column-group">
            <InputComponent
              id="template-name"
              name="template-name"
              label="File name"
              placeholder="Template name"
              value={templateName}
              handleInput={setTemplateName}
              iconLeft={<EoreIcon />}
              classNameWrapper="align-left"
            />
            <span className="rounded-bg-grey">
              <div className="flex-group">
                <span>Date</span>
                <span>{moment().format('DD.MM.YYYY')}</span>
              </div>
            </span>
            <div className="card-wrapper">
              <div className="card tiff-card">
                <UploadEoreTemplateFile
                  file={file}
                  setFile={setFile}
                  types="*"
                />
              </div>
            </div>
          </div>
          <div className="buttons-group">
            <ButtonComponent
              className="danger"
              iconLeft={<CancelSvg />}
              handleButton={() => {
                handleClose();
              }}
              label="Close"
            />
            <ButtonComponent
              iconLeft={<AddSvg />}
              handleButton={createNewTemplate}
              label="Create"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
