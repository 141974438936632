export const initialUserInfo = {
  id: null,
  team_id: '',
  name: '',
  surname: '',
  middle_name: '',
  function: '',
  email: '',
  password: null,
  phone_number: '',
  reporter_position: '',
  permissions: {},
  role: null,
  directors: null,
};
